<template>
	<v-data-table
		:headers="table.headers"
		:items="table.data"
		class="elevation-0 vertical-border small-font"
		:loading="table.loading"
		:items-per-page="50"
		:footer-props="{'items-per-page-options': [15, 15, 50, -1]}"
	></v-data-table>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementOnProductCardStatsComponent',
	props: ['campaign_id', 'nmid'],
    data() {
        return {
			table:{
				loading: false,
				data: [],
				headers: [
					{ text: 'Дата', value: 'date', sortable: true, width: '1%' },
					{ text: 'Добавление в корзину', value: 'add_to_basket', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'Средняя позиция', value: 'auto_avg_position', sortable: false, width: '1%' },
					{ text: 'Заказы', value: 'orders', sortable: false, width: '1%' },
					{ text: 'Дельта заказы', value: 'delta_orders', sortable: false, width: '1%' },
					{ text: 'CPC', value: 'cpc', sortable: false, width: '1%' },
					{ text: 'CR', value: 'cr', sortable: false, width: '1%' },
					{ text: 'CTR', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'FRQ', value: 'frq', sortable: false, width: '1%' },
					{ text: 'CPM', value: 'cpm', sortable: false, width: '1%' },
					{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
					{ text: 'Выручка', value: 'revenue', sortable: false, width: '1%' },
					{ text: 'Дельта выручка', value: 'delta_revenue', sortable: false, width: '1%' },
					{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
					{ text: 'Дельта ДРР', value: 'delta_drr', sortable: false, width: '1%' },
					{ text: 'ВП, шт', value: 'vp_unit_plan_simple', sortable: false, width: '1%' },
					{ text: 'ВП, сумма', value: 'vp_total_plan_simple', sortable: false, width: '1%' },
					{ text: 'Дельта ВП, сумма', value: 'vp_total_plan_delta', sortable: false, width: '1%' },
					{ text: 'Доход', value: 'profit_plan_simple', sortable: false, width: '1%' },
					{ text: 'Дельта доход', value: 'profit_plan_delta', sortable: false, width: '1%' },
					{ text: 'Дельта конверсия', value: 'calc_delta_conversion', sortable: false, width: '1%' },
				],
			},
		}		
    },
    methods: {
		async get_advertisement_stats(){
            this.table.loading = true
            try {
				let filters = {
					"campaign_id": this.campaign_id, "nmid": this.nmid,
					"date_after": this.get_date_start(), limit: 100
				}
                const response = await Advertisement.fetchAdvertisementStats(filters)
                this.table.data = response.data.results
            } catch (err) {
                console.error(err.response)
            }
            this.table.loading = false
        },
		get_date_start(){
			var date = new Date();
			const delta_days = 7 * 24 * 60 * 60 * 1000;			
			date.setTime(date.getTime() - delta_days);
			return date.toISOString().split('T')[0]
		}
    },
	async mounted(){
		await this.get_advertisement_stats()
	}
}
</script>