<template>
	<v-container fluid class="intervals_container">
        <v-row class="justify-center">
            <v-btn 
                class="mt-5" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" 
                @click="is_robot_interval_show=!is_robot_interval_show"
            >{{ !is_robot_interval_show ? 'Включить': 'Выключить' }}</v-btn>
        </v-row>
        <v-row class="justify-center">
            <v-data-table
                :headers="table_headers" :items="table_data" class="elevation-0 mt-5" 
                hide-default-footer hide-default-header dense v-if="is_robot_interval_show"
            >
                <template v-slot:header="{ props }">
                    <thead><tr><th v-for="header in props.headers" :key="header.text">
                        <div class="text-center">{{ header.text }}</div>
                        <div v-if="header.text != ''" class="d-flex justify-center">
                            <v-checkbox 
                                v-model="column_selected[header.value]" hide-details
                                :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" class="mt-0 pt-0" 
                                dense outline @change="select_column(header.value)"
                            ></v-checkbox>
                        </div>
                    </th></tr></thead>
                </template>
                <template v-slot:item.name="{ item }">
                    <div class="d-flex">
                        {{ item.name }}
                        <v-checkbox 
                            :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  hide-details 
                            class="mt-0 pt-0 ml-3" dense outline @change="select_row(item.idx)" 
                            v-model="row_selected[item.idx]"
                        ></v-checkbox>
                    </div> 
                </template>
                <template v-for="prop in list_day" v-slot:[`item.${prop}`]="{ item }">
                    <div :key="`field_${prop}`">
                        <v-checkbox 
                            v-model="item[prop]" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" 
                            hide-details class="mt-0 pt-0" dense outline
                        ></v-checkbox>
                    </div>
                </template>
            </v-data-table>
        </v-row>
        <v-row class="justify-center" v-if="is_robot_interval_show">
            <v-btn class="mt-1" text dense outlined @click="select_all">Все дни</v-btn>
        </v-row>
        <v-row>
            <v-btn 
                class="mt-5" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  
                @click="save_data" :loading="loading_save"
            >Сохранить</v-btn>
        </v-row>
	</v-container>
</template>

<script>
import { Advertisement } from '@/api/advertisement';

export default {
	name: 'DialogAdvertisementManagementIntervalShow',
	props: ['robot_settings'],
	data() {
		return {
			is_robot_interval_show: false,
            robot_interval_show_matrix: [],
            loading_save: false,
            row_selected: [false, false, false, false, false, false, false],
            column_selected: [
                false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false
            ],
            table_data: [],
            table_headers: [],
            list_day: [
                0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 
                14, 15, 16, 17, 18, 19, 20, 21, 22, 23
            ],
            weekday_key: {
                "0": 'Пн', "1": 'Вт', "2": 'Ср', "3": 'Чт',
                "4": 'Пт', "5": 'Сб', "6": 'Вс',
            }
		};
	},
	methods: {
        select_all(){
            for (let i = 0; i < 7; i++){
                for (let j = 0; j < 24; j++){
                    this.table_data[i][j] = true
                }
            }
        },
        select_row(row_idx){
            let index = this.table_data.findIndex((el) => el.idx == row_idx)
            for (let i = 0; i < 24; i++){
                this.table_data[index][i] = this.row_selected[row_idx] ? true: false
            }
        },
        select_column(column_idx){
            for (let i = 0; i < this.table_data.length; i++){
                this.table_data[i][column_idx] = this.column_selected[column_idx] ? true: false
            }
        },
        prepare_pre_save_table(){
            if (!this.is_robot_interval_show){
                return []
            }
            let matrix = []
            for (let i = 0; i < 7; i++){
                let tmp = []
                for (let j = 0; j < 24; j++){
                    tmp.push(this.table_data[i][j])
                }
                matrix.push(tmp)
            }
            return matrix
        },
		async save_data(){
            let matrix = this.prepare_pre_save_table()
            let json_data = {
                'is_robot_interval_show': this.is_robot_interval_show,
                'robot_interval_show_matrix': matrix
            }
			try{
				await Advertisement.updateAdvertisementCampaignRobotSettings(this.robot_settings.id, json_data)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
                this.$emit('successSetDisplayArea')
			} catch(err){
				this.$toasted.show(`Ошибка изменения данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		},
        prepare_table(){
            this.table_headers = [{text: "", value: "name", sortable: false, width: "1%"}]
            for (let i = 0; i < 24; i++) {
                this.table_headers.push(
                    {text: `${i}`, value: i, sortable: false, width: "1%"}
                )
            }
            if (this.robot_interval_show_matrix.length > 0){
                this.robot_interval_show_matrix.forEach((i_value, i) => {
                    let tmp = {"name": `${this.weekday_key[i]}`, idx: i}
                    i_value.forEach((j_value, j) => {
                        tmp[j] = j_value
                    })
                    this.table_data.push(tmp)
                })
            } else{
                for (let i = 0; i < 7; i++) {
                    let tmp = {"name": `${this.weekday_key[i]}`, idx: i}
                    for (let j = 0; j < 24; j++) {
                        tmp[j] = false
                    }
                    this.table_data.push(tmp)
                }
            }
        }
	},
	async mounted() {
        if (this.robot_settings){
            this.is_robot_interval_show = this.robot_settings.is_robot_interval_show
            this.robot_interval_show_matrix = this.robot_settings.robot_interval_show_matrix
        }
        this.prepare_table()
	},
};
</script>

<style lang="scss">
    .intervals_container .v-input--selection-controls__input{
        margin-right: 0px !important;
    }
    .intervals_container th, .intervals_container td{
        padding: 0 7px !important;
    }
</style>