<template>
    <div class="">
        <ProductsList></ProductsList>
    </div>
</template>

<script>
	import ProductsList from '@/components/ProductsList';

	export default {
		name: 'Products',
		components: {
			ProductsList,
		},
		mounted() {
			document.title = 'Товары WB | mp.lomex-group.ru';
			this.$store.commit('setPageTitle', `Список товаров`);
		},
	};
</script>

<style scoped>

</style>
