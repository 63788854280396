<template>
    <v-container>
		<v-card max-width="900">
            <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
            </template>
            <v-card-title>Создание автоматической рекламной кампании</v-card-title>
            <v-card-text>
				<div class="main_form">
					<v-autocomplete
                        v-model="data.supplier" :items="choices.accounts" item-text="name" item-value="id"
                        label="Аккаунт" @click:clear="clearSubject" outlined hide-details dense clearable
                    ></v-autocomplete>
					<v-text-field
                        label="Название кампании" outlined dense v-model="data.name"
						maxlength="128" counter class="mt-5"
                    ></v-text-field>
					<v-text-field
						v-model="data.budget" label="Бюджета" outlined
						type="number" dense clearable hide-details
					></v-text-field>
					<v-autocomplete
						v-model="data.purpose" :items="choices.purpose" item-text="name"
						item-value="value" label="Цель" outlined dense hide-details clearable class="mt-5"
					></v-autocomplete>
					<v-text-field
						v-model="data.comment" label="Комментарий" outlined dense clearable 
						class="mt-5" hide-details
					></v-text-field>
					<div class="d-flex mt-5">
						<v-autocomplete
							v-model="data.subject_id" :items="choices.subject_ids" 
							item-text="value" item-value="key" label="Категория" 
							outlined dense hide-details clearable 
						></v-autocomplete>
						<v-btn
							class="ml-5" color="primary" @click="fetchSubjects" 
							:loading="loading.subject" :disabled="!this.data.supplier"
						>Получить категории</v-btn>
					</div>
				</div>
				<v-btn
					v-if="check" color="primary" @click="createAdvertisement"
					:loading="loading.create" class="mt-5"
				>Создать</v-btn>	
            </v-card-text>
        </v-card>
	</v-container>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import {General} from '@/api/general'

export default {
    name: 'AdvertisementNewCreateAuto',
    data() {
        return {
			choices: {
				purpose: [],
				accounts: [],
				subject_ids: []
			},
			data:{
				supplier: null,
				name: null,
				budget: null,
				purpose: null,
				comment: null,
				subject_id: null
			},
			loading: {
				subject: false,
				create: false
			}
        }
    },
	computed: {
		check(){
			if(this.data.supplier && this.data.name && this.data.budget && this.data.subject_id)
				return true
			return false
		}
	},
    methods: {
		async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices.purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		async createAdvertisement(){
			this.loading.create = true
			let query = {
				'supplier': this.data.supplier, 'name': this.data.name, 
				"budget": this.data.budget, "comment": this.data.comment,
				"purpose": this.data.purpose, "subject_id": this.data.subject_id,
			}
			try {
				await AdvertisementNew.createAutoCampaign(query)
				this.$toasted.show(`Кампания успешно создана`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000
				});
			}catch (err) {
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
				});
			}
			this.loading.create = false
		},
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.choices.accounts = response.data
        },
		clearSubject(){
			this.choices.subject_ids = []
			this.data.subject_id = null
		},
		async fetchSubjects(){
			this.loading.subject = true
			try{
				const responce = await AdvertisementNew.getSubjectByAccount(this.data.supplier)
				this.choices.subject_ids = responce.data
			} catch	(err){
				console.log(err)
			}
			this.loading.subject = false
		}
	},
	async mounted(){
		await this.fetchWbAccounts()
		await this.getAdvertisementPurpose()
	}
}
</script>

<style lang="scss" scoped>

</style>