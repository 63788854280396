<template>
    <div>
        <v-dialog v-model="dialog_delete" max-width="500px" v-if="dialog_delete">
			<v-card>
				<v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="dialog_delete = false">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
				<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<router-link class="ml-3" target="_blank" :to="{name: 'management_product_list'}">
			<v-btn
				color="primary"
				dark
				class="mt-5 ml-5"
			>Создать новый шаблон</v-btn>
		</router-link>
		<router-link class="ml-3" target="_blank" :to="{name: 'management_product_delayed_task'}">
			<v-btn color="primary" dark class="mt-5 ml-5">
				<v-icon title="Отложенные задачи" class="pr-3">mdi-timetable</v-icon>
				Список отложенных задач				
			</v-btn>
		</router-link>
		
        <v-data-table
			ref="block_list_templates"
			:headers="headers"
			:items="data"
			:loading="table_loading"
			class="elevation-0 mt-3 vertical-border"
			:pageCount="pagination.numberOfPages"
			@update:page="getTempalteResponse"
			:options.sync="options"
			:items-per-page="50"
			:server-items-length="pagination.totalItems"
			:footer-props="{
				'items-per-page-options': [5, 10, 25, 50, 100]
			}"
        >
			<template v-slot:item.name="{ item }">
				<router-link target="_blank" :to="{ name: 'management_product_main', params: { template_id: item.id }}">
					{{item.name}}
				</router-link>
            </template>
			<template v-slot:item.date_created="{ item }">
				<div v-if="item.date_created">{{ item.date_created | formatDateToLocale }}</div>
				<div v-else>-</div>
            </template>
			<template v-slot:item.actions="{ item }">
				<v-icon small @click="deleteTemplate(item)">mdi-delete</v-icon>
            </template>
			<template v-slot:item.auto_updating="{ item }">
					<v-switch 
						color="primary" class="mt-0" hide-details dense
						v-model="item.is_auto_update" @change="change_auto_update(item)"
				></v-switch>
				</template>
        </v-data-table>
    </div>
</template>

<script>
	import {ManagementProduct} from '@/api/management_product';

	export default {
		name: 'ManagementWbProductTemplates',
		data() {
			return {
				dialog_delete: false,
				deleted_item: null,
				table_loading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				data: [],
				headers: [
					{ text: 'Название', sortable: false, value: 'name', width: '20%' },
					{ text: 'Дата создания', sortable: false, value: 'date_created', width: '10%' },
					{ text: 'Товары', sortable: false, value: 'products', width: '55%' },
					{ text: 'Аккаунты', sortable: false, value: 'accounts', width: '55%' },
					{ text: 'Автообновление', sortable: false, value: 'auto_updating', width: '5%' },
					{ text: 'Действия', value: 'actions', sortable: false, width: '5%' },
				],
			};
		},
		methods: {
			async change_auto_update(item){
				try{
					await ManagementProduct.changeAutoUpdate(item.id)
				} catch(err){
					console.log(err)
				}
			},
			deleteTemplate (item) {
				this.deleted_item = item.id
				this.dialog_delete = true
			},  
			async deleteItemConfirm () {
				try {
					const response = await ManagementProduct.deleteTemplateManagement(this.deleted_item)
					this.getTempalteResponse()
				}
				catch (err){
					console.log(err)
				}
				this.dialog_delete = false
			},
			async getTempalteResponse(){
				this.table_loading = true
				const { page, itemsPerPage } = this.options
				let pageNumber = page - 1
				try {
					const res = await ManagementProduct.fetchListTemplateManagement(
						{
							...{
								'offset': itemsPerPage*pageNumber,
								'limit': itemsPerPage,
							}
						}
					)
					this.data = res.data.results
					this.pagination.totalItems = res.data.count
					this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
					this.$vuetify.goTo(this.$refs.block_list_templates, {
						duration: 300,
						offset: 150,
						easing: 'linear',
					})
				} catch (err) {
					console.log(err)
				}
				this.table_loading = false
			}
		},
		async mounted() {
			document.title = 'Управление ценой (список шаблонов)';
			this.$store.commit('setPageTitle', `Управление ценой (список шаблонов)`)
			this.getTempalteResponse()
		},
	};
</script>

<style scoped lang="scss">
    
</style>
