<template>
	<div v-if="loading_data" style="display: flex; justify-content:center;">
		<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
		></v-progress-circular>
	</div>
	<div v-else-if="!loading_data && data">
		<apexchart 
			type="line" 
			height="600" 
			:options="chartOptions"
			:series="seriesMain"
			ref="chart_test"
		></apexchart>
		<button @click="hidden">hidden all</button>
	</div>
	
	<p v-else>error</p>
</template>

<script>
	import { AdvertisementNew } from '@/api/advertisement_new'
	import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'AllStatsChart',
		components: {
			apexchart: VueApexCharts,
        },
		props:['filters'],
        data() {
            return {
                data: null,
				loading_data: false,
				chartOptions: {
                    chart: {
                        height: 1000,
						width: '100%',
                        type: 'line',
                        zoom: {
                            enabled: true,
                        },
						toolbar: {
							show: true,
						}
                    },
					stroke: {
						curve: 'smooth',
						width: [3, 3, 3, 3, 3, 3],
					},
					dataLabels: {
						enabled: false
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
					grid: {
						row: {
							colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
							opacity: 0.5,
						},
					},
					fill: {
						type: "gradient",
						gradient: {
							shade: 'light',
							type: 'vertical',
							shadeIntensity: 1,
							opacityFrom: 1,
							opacityTo: 0.1,
							stops: [0, 75, 75]
						}
					},
					yaxis: [
						{
							seriesName: 'ДРР(%)',
							opposite: true,
							showAlways: true,
							title: {
								text: '%'
							},
							labels: {
								rotate:'45',
								show: true,
								formatter: function(val) {
									return `${val}%`;
								},
							}
						},
						{
							seriesName: 'Расходы',
							showAlways: true,
							title: {
								text: 'Рубли',
							},
							labels: {
								rotate:'-45',
								show: true,
								formatter: function(val) {
									return `${val}₽`;
								},
							}
						}, 
						{
							seriesName: 'Расходы',
							show: false
						}, 
						{
							seriesName: 'Расходы',
							show: false
						}, 
						{
							seriesName: 'ДРР(%)',
							opposite: true,
							show: false
						},
					],
					xaxis: {
						type: 'datetime'
					},
                },
            }
        },
		computed: {
            seriesMain() {
                return [
					{
						name: 'ДРР(%)',
						type: 'area',
						data: this.seriesDrr,
						color: "#1e73be",
					},
					{
						name: 'Расходы',
						type: 'area',
						data: this.seriesExpenditure,
						color: "#ffbc23",
					},
					{
						name: 'ВП',
						type: 'area',
						data: this.seriesVP,
						color: "#4d5258",
					},
					{
						name: 'Доходность',
						type: 'area',
						data: this.seriesProfit,
						color: "#f2a1b0",
					},
					{
						name: 'ROMI (%)',
						type: 'area',
						data: this.seriesROMI,
						color: "#4ea853",
					},
				];
            },
			seriesExpenditure(){
				const map_result =  this.data.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: (item.expenditure || 0),
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesVP(){
				const map_result =  this.data.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: (item.vp || 0),
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesProfit(){
				const map_result =  this.data.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: (item.profit || 0),
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesDrr(){
				const map_result =  this.data.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: (item.drr || 0),
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesROMI(){
				const map_result =  this.data.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: (item.romi || 0),
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
        },
        methods: {
			hidden(){
				console.log("work")
				this.seriesMain.forEach(item=>{
					console.log(item.name)
					this.$refs.chart_test.hideSeries(item.name)
				})
			},
			show(){
				console.log("work")
				this.seriesMain.forEach(item=>{
					console.log(item.name)
					this.$refs.chart_test.showSeries(item.name)
				})
			},
			async getData(){
				console.log(this.$refs.chart_test)
				this.loading_data = true
				try {
					const response = await AdvertisementNew.getStatsDataForChart(this.filters)
					this.data = response.data
				} catch (err) {
					console.log(err.data)
				}
				this.loading_data = false
			},
		},
		async mounted(){
			await this.getData()
		}
	}
</script>

<style scoped>

</style>