<template>
    <v-card flat class="my-3 elevation-2 flat">
		<v-card-title>Рекламные кампании</v-card-title>
		<v-card-text>
			<v-data-table
				:headers="table.headers" :items="table.data" :loading="table.loading"
				class="elevation-0 mt-0 vertical-border small-font"
				single-expand :expanded.sync="table.expanded" show-expand
				hide-default-footer disable-pagination item-key="campaign_id"
			>
				<template v-slot:expanded-item="{ item }">
					<td :colspan="table.headers.length" v-if="item" style="padding: 0 0 0 0;">
						<AdvertisementOnProductCardStatsComponent 
							:campaign_id="item.campaign_id" 
							:key="item.campaign_id" 
							:nmid="product_id"
						/>
					</td>
				</template>
				<template v-slot:item.date="{ item }">
					<div style="white-space: nowrap;">
						{{ item.date_create }}
						<br>
						{{ item.date_change }}
					</div>
				</template>
				<template v-slot:item.campaign_name="{ item }">
					<router-link target="_blank" :to="{ 
						name: 'advertisement_detail', params: { campaign_id: item.campaign_id }
					}">{{ item.campaign_name }}</router-link>
				</template>
				<template v-slot:item.cpc="{ item }">
					<div>
						{{ item.clicks > 0 && item.expenses > 0 ? (item.expenses / item.clicks).toFixed(2): 0 }}
					</div>
				</template>
				<template v-slot:item.ctr="{ item }">
					<div>
						{{ item.clicks > 0 && item.views > 0 ? (item.clicks / item.views * 100).toFixed(2): 0 }}
					</div>
				</template>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>

<script>
	import {Advertisement} from '@/api/advertisement';
	import AdvertisementOnProductCardStatsComponent from '@/components/Advertisement/AdvertisementOnProductCard/AdvertisementOnProductCardStatsComponent'
	
	export default {
		name: 'AdvertisementOnProductCardComponent',
		props: ['product_id'],
		components: { AdvertisementOnProductCardStatsComponent },
		data() {
			return {
				table: {
					headers: [
						{ text: '', value: 'data-table-expand', width: '1%' },
						{ text: 'Название', value: 'campaign_name', sortable: false, width: '2%' },
						{ text: 'Дата создания / изменения', value: 'date', sortable: false , width: '1%'},
						{ text: 'Цель', value: 'purpose_display', sortable: false, width: '1%' },
						{ text: 'Тип', value: 'campaign_type', sortable: false , width: '1%'},
						{ text: 'Статус', value: 'campaign_status_display', sortable: false , width: '1%'},
						{ text: "Просмотры", value: 'views', sortable: false, width: '1%'},
						{ text: "Клики", value: 'clicks', sortable: false, width: '1%'},
						{ text: "CPC", value: 'cpc', sortable: false, width: '1%'},
						{ text: "CTR(%)", value: 'ctr', sortable: false, width: '1%'},
						{ text: "Заказы", value: 'orders', sortable: false, width: '1%'},
						{ text: "Расход", value: 'expenses', sortable: false, width: '1%'},
						{ text: "Доход", value: 'profit', sortable: false, width: '1%'},
						{ text: "ДРР", value: 'drr', sortable: false, width: '1%'},
						{ text: 'Комментарий', value: 'comment', sortable: false, width: '1%' },
					],
					data: [],
					loading: false,
					expanded: [],
				},
			};
		},
		methods: {
			async fetchAdvertisementData() {
				this.table.loading = true
				try{
					const response = await Advertisement.fetchAdvertisementList(
						{nmid: this.product_id, limit: 100, "is_in_product_card": true}
					)
					this.table.data = response.data.results
				} catch(err){
					console.log(err)
				}
				this.table.loading = false
				
			},
			successUpdateStock(){
				alert("Остатки обновлены")
			}
		},
		async mounted() {
			this.fetchAdvertisementData();
		},
	};
</script>