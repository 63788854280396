<template>
	<v-container>
		<v-card>
			<v-card-title>Планирование поставок</v-card-title>
			<v-card-text>
				<v-tabs v-model="current_tab" color="deep-purple accent-4" grow>
					<v-tab v-for="tab in tabs_list" :key="tab">{{ tab }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="current_tab">
					<v-tab-item v-for="tab in tabs_list" :key="tab">
						<SupplyPlanningListComponent :marketplace="tab" :key="tab"/>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import SupplyPlanningListComponent from '@/components/SupplyPlanning/SupplyPlanningListComponent'

export default {
	name: 'SupplyPlanningList',
	components: { SupplyPlanningListComponent },
	data(){
		return {
			current_tab: null,
			tabs_list: ["Wildberries", "Ozon"]
		}
	},
	mounted () {
		document.title = 'Планирование поставок | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Планирование поставок`)
	}
}
</script>

<style scoped>

</style>
