<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<div v-if="adver_data">
			<AdvertisementDetailSearch v-if="adver_data.campaign_object_type=='SEARCH_PROMO'" :data="adver_data"/>
			<AdvertisementDetailSku v-else-if="adver_data.campaign_object_type=='SKU'" :data="adver_data"/>
			<AdvertisementDetailBrand v-else-if="adver_data.campaign_object_type=='BRAND_SHELF'" :data="adver_data"/>
			<AdvertisementDetailAll v-else :data="adver_data"/>
		</div>
	</v-container>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'
import AdvertisementDetailSearch from '@/components/OzonAdvertisement/AdverDetail/AdvertisementDetailSearch.vue'
import AdvertisementDetailSku from '@/components/OzonAdvertisement/AdverDetail/AdvertisementDetailSku.vue'
import AdvertisementDetailAll from '@/components/OzonAdvertisement/AdverDetail/AdvertisementDetailAll.vue'
import AdvertisementDetailBrand from '@/components/OzonAdvertisement/AdverDetail/AdvertisementDetailBrand.vue'

export default {
	name: "OzonAdvertisementDetail",
	data() {
		return {
			adver_data: null,		
		}
	},
	components: {
		AdvertisementDetailAll,
		AdvertisementDetailSearch,
		AdvertisementDetailSku,
		AdvertisementDetailBrand
	},
	computed: {
		advertisement_id() {
			return this.$route.params.campaign_id;
		},
	},
	methods: {
		async getAdvertisementDetail() {
			try {
				let response = await OzonAdvertisement.getDetailAdvertisements(
					this.advertisement_id
				);
				this.adver_data = response.data;
			} catch (err) {
				console.error(err.response);
			}
		},
	},
	async mounted() {
		document.title = `Реклама Ozon | ${this.advertisement_id} | mp.lomex-group.ru`;
		this.$store.commit("setPageTitle", `Ozon Рекламная кампания ${this.advertisement_id}`);
		await this.getAdvertisementDetail()
	}
}
</script>

<style lang="scss" scoped>

</style>