<template>
    <div>
        <v-row class="py-10">
            <v-col sm="12" lg="12" class="px-10">
                <div class="title">Фильтры</div>
                <v-row class="mt-3">
                    <v-col>
                        <div class="d-flex flex-column">
                            <v-menu 
                                v-model="datePicker.datepicker_date_after" :close-on-content-click="false" 
                                transition="scale-transition" offset-y="offset-y" nudge-right="40" 
                                min-width="290px" ref="datePicker.datepicker_date_after"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable @click:clear="filters.date_after = null"
                                        v-model="dateAfter" label="Дата, от" readonly="readonly"
                                        v-bind="attrs" hide-details outlined dense v-on="on"
                                    >  
                                    </v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_after" :first-day-of-week="1"
                                    @input="datePicker.datepicker_date_after = false"
                                ></v-date-picker>
                            </v-menu>
                            <v-menu 
                                v-model="datePicker.datepicker_date_before" :close-on-content-click="false" 
                                transition="scale-transition" offset-y="offset-y" nudge-right="40" 
                                min-width="290px" ref="datePicker.datepicker_date_before"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        clearable @click:clear="filters.date_before = null"
                                        v-model="dateBefore" label="Дата, до" readonly="readonly"
                                        v-bind="attrs" hide-details outlined dense v-on="on" class="mt-5"
                                    ></v-text-field>
                                </template>
                                <v-date-picker 
                                    v-model="filters.date_before" 
                                    @input="datePicker.datepicker_date_before = false"
                                    :first-day-of-week="1"
                                ></v-date-picker>
                            </v-menu>
                        </div>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.type" :items="advertisement_main_types" item-text="name"
                            item-value="id" label="Вид рекламы" outlined multiple hide-details
                            dense clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid" clearable outlined dense
                            append-icon="mdi-magnify" label="Артикул" hide-details="hide-details"
                        ></v-text-field>
                    </v-col> 
                    <v-col>
                        <v-select
                            v-model="filters.suppliers" :items="choices.suppliers"
                            item-text="name" item-value="value" label="Аккаунт" outlined
                            multiple hide-details dense clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-select
                            v-model="filters.product_group" :items="choices.product_group"
                            item-text="name" item-value="value" label="Группа товаров" outlined
                            multiple hide-details dense clearable
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="applyFilter()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="12" lg="12">
                <v-data-table
                    :headers="headers"
                    :items="stats_data"
                    class="elevation-0 mt-0 small-font w-100"
                    :loading="table_loading"
                    @update:page="pageChanged"
                    :options.sync="options"
                    :items-per-page="10"
                    :server-items-length="pagination.totalItems"
                >
					<template v-slot:item.expenses="{ item }">
						<div v-if="item.expenses">
							{{item.expenses | toRUB}}
						</div>
						<div v-else></div>
					</template>
                    <template v-slot:top>
                        <div class="d-flex px-7">
                            <v-btn 
                                color="primary" dark class="mb-5" 
                                @click="exportStats" :loading="export_loading"
                            >Экспорт в excel</v-btn>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'
import { General } from '@/api/general'

export default {
	name: 'OzonAdvertisementAllStats',
	data() {
		return {
            export_loading: false,

            filters: {
                type: null,
                nmid:null,
                date_after: null,
                date_before: null,
                product_group: null,
                suppliers: null
            },
            choices: {
                suppliers: [],
                product_group: []
            },
            advertisement_main_types: [
                {"id": "SKU", "name": "Трафареты"},
                {"id": "SEARCH_PROMO", "name": "Продвижение в поиске"},
            ],
            datePicker: {
                datepicker_date_before: null,
                datepicker_date_after: null
            },
            headers: [
                { text: 'Дата', value: 'date', sortable: false, width: '1%' },
                { text: 'Кампания', value: 'campaign_id', sortable: false, width: '1%' },
				{ text: 'Артикул', value: 'sku', sortable: false, width: '1%' },
				{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
                { text: 'CPO', value: 'cpo', sortable: false, width: '1%' },

				{ text: 'Цена продажи(sku)', value: 'price', sortable: false, width: '1%' },
				{ text: 'Просмотры(sku)', value: 'views', sortable: false, width: '1%' },
				{ text: 'Клики(sku)', value: 'clicks', sortable: false, width: '1%' },
				{ text: 'CTR(sku)', value: 'ctr', sortable: false, width: '1%' },
				{ text: 'Средняя ставка(sku)', value: 'avg_bid', sortable: false, width: '1%' },
				{ text: 'Заказы шт(sku)', value: 'orders', sortable: false, width: '1%' },
				{ text: 'Выручка(sku)', value: 'revenue', sortable: false, width: '1%' },
				{ text: 'Заказы модели(sku)', value: 'orders_model', sortable: false, width: '1%' },
				{ text: 'Выручка с заказов модели(sku)', value: 'revenue_model', sortable: false, width: '1%' },

				{ text: 'Кол-во(search)', value: 'search_quantity', sortable: false, width: '1%' },
				{ text: 'Стоимость(search)', value: 'search_avg_cost', sortable: false, width: '1%' },
				{ text: 'Ставка %(search)', value: 'search_avg_bid_percent', sortable: false, width: '1%' },
				{ text: 'Ставка, руб(search)', value: 'search_avg_bid', sortable: false, width: '1%' },
				{ text: 'Цена продажи(search)', value: 'search_avg_sale_price', sortable: false, width: '1%' },

				{ text: 'Заказы по товару', value: 'all_orders_from_api', sortable: false, width: '1%' },
				{ text: 'Расходы по эквайрингу', value: 'acquiring_sum', sortable: false, width: '1%' },
				{ text: 'Себестоимость', value: 'calc_cost_price', sortable: false, width: '1%' },
				{ text: 'ВП', value: 'calc_vp', sortable: false, width: '1%' },
				{ text: 'Выручка', value: 'calc_revenue', sortable: false, width: '1%' },
				{ text: 'ДРР', value: 'calc_drr', sortable: false, width: '1%' },
				{ text: 'Комиссия', value: 'calc_commission', sortable: false, width: '1%' },
				{ text: 'Логистика', value: 'calc_logistic', sortable: false, width: '1%' },
				{ text: 'Налог', value: 'calc_seller_tax_rub', sortable: false, width: '1%' },
				{ text: 'Доходность', value: 'calc_profit', sortable: false, width: '1%' },				
            ],
            stats_data: [],
            table_loading: false,
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
		}
	},    
    computed: {
        dateAfter () {
            return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
        },
        dateBefore () {
            return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
        },
        transfromFiltersForQuery(){
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
            }
            return query
        },
    },
	methods: {
        async pageChanged () {
            await this.getAdvertisementStats()
        },
        async getAdvertisementStats(){
            this.table_loading = true
            const { page, itemsPerPage} = this.options
            let pageNumber = page - 1
            try {
                let response = await OzonAdvertisement.getOzonProductAllStats(
                    {
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                        },
                        ...this.transfromFiltersForQuery,
                    }
                )
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.stats_data = response.data.results
            } catch (err) {
                console.error(err.response)
            }
            this.table_loading = false
        },
        showDate(date) {
            return date ? this.$options.filters.formatDateToLocaleNoTime(date) : "-";
        },
        async applyFilter(){
            this.options.page = 1
            await this.getAdvertisementStats()
        },
        async getChoicesSuppliers(){
            try {
                const responce = await General.fetchSellersChoices()
                this.choices.suppliers = responce.data
            } catch (err) {
                console.log(err)
            }
        },
        async getChoicesProductGroup(){
            try {
                const response = await General.fetchProductGroup()
                this.choices.product_group = response.data
            } catch (err) {
                 console.log(err.data)
            }
		},
        async exportStats(){
            this.export_loading = true
            try {
                let response = await OzonAdvertisement.exportOzonProductAllStats(
                    {...this.transfromFiltersForQuery}
                )
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'ozon_adver_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.error(err.response)
            }
            this.export_loading = false
        },
	},
    async mounted () {
		document.title = `Статистика рекламы OZON| mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Статистика рекламных кампаний OZON`)
        await this.getAdvertisementStats()
        await this.getChoicesSuppliers()
        await this.getChoicesProductGroup()
	}
}
</script>