<template>
    <v-container>
		<h2 class="mt-5" fl>Индексы цен и локализации</h2>
		<div class="mt-5 d-flex">
			<div>
				<p>Дата обновления идексов локализации: {{ data.last_date_update_localization_index ? data.last_date_update_localization_index : '-' }}</p>
				<p>Дата обновления идексов цен: {{ data.last_date_update_price_index ? data.last_date_update_price_index : '-' }}</p>
			</div>
			<div class="ml-10 d-flex">
				<v-select
					v-model="filter_supplier" :items="suppliers_list" item-text="value" item-value="key" 
					label="Аккаунт" outlined multiple hide-details dense clearable
				></v-select>
				<v-btn 
					depressed color="purple darken-2" class="ml-5" dark @click="fetchData" :loading="loading"
				>Применить</v-btn>
			</div>
			
		</div>
		<v-card class="mt-5" v-for="(item, index) in data.accounts" :key="index">
			<v-card-title>{{ item.value }}</v-card-title>
			<v-card-text>
				<div class="title">
					Индекс локализации: {{getDataByAccount(data.localization_indices, item.key, 0)}} %
				</div>
				<div class="mt-5 d-flex justify-space-around">
					<div class="card red_text">
						<p class="title">Невыгодный индекс</p>
						<div class="red_text title" style="text-align: center;">
							{{getDataByAccount(data.prices_indices, item.key, {}).part_index_non_profit}} %
						</div>
					</div>
					<div class="card orange_text">
						<p class="title">Умеренный индекс</p>
						<div class="orange_text title" style="text-align: center;">
							{{getDataByAccount(data.prices_indices, item.key, {}).part_index_avg_profit}} %
						</div>
					</div>
					<div class="card green_text">
						<p class="title">Выгодный индекс</p>
						<div class="green_text title" style="text-align: center;">
							{{getDataByAccount(data.prices_indices, item.key, {}).part_index_profit}} %
						</div>
					</div>
				</div>
			</v-card-text>
		</v-card>	
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';

	export default {
		name: 'ProductsOzonIndices',
		data() {
			return {
				data: [],
				suppliers_list: [],
				filter_supplier: null,
				loading: false
			};
		},
		methods: {
			async fetchData(){
				this.loading = true
				try{
					let query = {}
					if (this.filter_supplier){
						query = {'accounts': this.filter_supplier.join(',')}
					}
					const response = await Products.ozonProductIndicesByAccount(query)
					this.data = response.data
				} catch(err){
					console.log(err)
				}
				this.loading = false
			},
			getDataByAccount(data, account, default_value){
				try{
					return data[account] ? data[account] : 0
				} catch(err){
					return default_value
				}
			},
			async getOzonAccounts(){
				try{
					const response = await Products.ozonAccounts()
					this.suppliers_list = response.data
				} catch(err){
					console.log(err)
				}
			}
		},
		async mounted() {
			document.title = `Товары OZON(Индексы) | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Товары OZON(Индексы)`);
			await this.fetchData()
			await this.getOzonAccounts()
		},
		
	};
</script>

<style scoped>
	.green_text{
		color: green;
	}
	.red_text{
		color: rgb(157, 17, 17);
	}
	.orange_text{
		color: rgb(215, 143, 9);
	}
</style>