<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="balancesLoading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <v-tabs
                    v-model="sizeTab"
                    background-color="transparent"
                    color="basil"
            >
                <v-tab
                        v-for="(balance, size) in balances"
                        :key="size"
                >
                    {{ size }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="sizeTab">
                <v-tab-item
                        v-for="(balance, size) in balances"
                        :key="size"
                >
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Время
                                </th>
                                <th class="text-left">
                                    Остаток
                                </th>
                                <th class="text-left">
                                    Изменение, шт
                                </th>
                                <th class="text-left">
                                    Тип
                                </th>
                                <th class="text-left">
                                    Цена
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(row, index) in balance"
                                :key="index"
                            >
                                <td>{{ row.date_created | onlyTime }}</td>
                                <td>{{ row.stock_balance }}</td>
                                <td>{{ row.stock_balance_change }}</td>
                                <td>{{ row.stock_balance_change_type }}</td>
                                <td>{{ row.price_in_the_moment | toRUB }}</td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-sheet>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "ProductStockBalances",
    props: {
      product_id: {
        type: String
      },
      date: {
        type: String
      }
    },
    data () {
      return {
        balances: null,
        balancesLoading: true,
          sizeTab: null,
      }
    },
    computed: {
      isSingleVariation () {
        return this.balances.length === 1
      }
    },
    methods: {
      async fetchProductStockBalancesDay () {
        this.balancesLoading = true
        try {
          const res = await Products.fetchProductBalancesDay(
            this.product_id,
            this.$options.filters.formatDateToQuery(this.date)
          )
          this.balances = res.data
        } catch (err) {
          console.log(err)
        }
        this.balancesLoading = false
      }
    },
    async mounted () {
      await this.fetchProductStockBalancesDay()
    }
  }
</script>

<style scoped>

</style>
