<template>
	<v-data-table :headers="headers" :items="products" hide-default-footer dense>
        <template v-slot:item.image="{ item }">
            <div class="d-flex align-center justify-center py-1">
                <img style="max-height: 50px" :src="get_image_path(item.child_product.nmid)" alt="Фото удалено"/>
            </div>
        </template>
        <template v-slot:item.nmid="{ item }">
            <router-link 
                class='dashed' target="_blank" :to="{name: 'product_card', params: { id: item.child_product.nmid }}"
            >{{ item.child_product.nmid }}</router-link>
        </template>
    </v-data-table>
</template>

<script>

export default {
	name: 'DialogAdvertisementDetailProduct',
	props: ['products'],
    data(){
        return {
            headers: [
                { text: "Изображение", value: "image", sortable: false, width: "1%", align: 'center' },
                { text: "Артикул", value: "nmid", sortable: false, width: "1%", align: 'center' },
                { text: "Название", value: "child_product.name", sortable: false, width: "1%", align: 'center' },
                { text: "Артикул 1С", value: "child_product.code_1c", sortable: false, width: "1%", align: 'center' },
            ],
        }
    },
    methods: {
        calculate_vol_image(t){
			if (t >= 0 && t <= 143) return '01'
			if (t >= 144 && t <= 287) return '02'
			if (t >= 288 && t <= 431) return '03'
			if (t >= 432 && t <= 719) return '04'
			if (t >= 720 && t <= 1007) return '05'
			if (t >= 1008 && t <= 1061) return '06'
			if (t >= 1062 && t <= 1115) return '07'
			if (t >= 1116 && t <= 1169) return '08'
			if (t >= 1170 && t <= 1313) return '09'
			if (t >= 1314 && t <= 1601) return '10'
			if (t >= 1602 && t <= 1655) return '11'
			if (t >= 1656 && t <= 1919) return '12'
			if (t >= 1920 && t <= 2045) return '13'
			if (t >= 2046 && t <= 2189) return '14'
			if (t >= 2190 && t <= 2405) return '15'
			return '16'
		},
		get_image_path (nmid) {
			let first_part = nmid.slice(0, -3)
			let second_part = nmid.slice(0, -5)
			let third_part = this.calculate_vol_image(Number(second_part))
			return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
		},
    }
}
</script>