<template>
    <v-container fluid>
		<v-card max-width="1200" style="margin: 25px auto 0 auto;">
            <v-card-title>Массовое создание реклам поиска</v-card-title>	
			<v-card-text>
				<div class="mt-5">
					<div class="title_block text-center">Выбор ЛК</div>
					<v-autocomplete
						class="mt-2" v-model="form.supplier" :items="choices_data.wb_accounts"
						item-text="name" item-value="id" label="Выберите личный кабинет"
						outlined hide-details dense clearable
					></v-autocomplete>
					<hr class="mt-5">
				</div>
				<div v-if="form.supplier" class="mt-5">
					<div class="title_block text-center">Выбор основного товара</div>
					<v-row class="mt-2" >
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.nmid = null"
								v-model="search.nmid" label="Введите артикул" hide-details
								outlined @change="getProducts" dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.name = null" v-model="search.name"
								label="Введите название" hide-details @change="getProducts"
								outlined dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-autocomplete
								v-model="form.main_product" :items="choices_data.list_products"
								item-text="text" item-value="id" :loading="loading_products"
								label="Выберите товар" outlined hide-details dense clearable
							></v-autocomplete>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<div class="mt-5" v-if="form.supplier && form.main_product">
					<div class="title_block text-center">Выбор поисковых запросов с mpstats</div>
					<v-row class="mt-2" >
						<v-col cols="12" md="4">
							<v-btn
								depressed color="purple darken-2" dark
								@click="getSearchPhrasesFromMpstats"
							>Загрузить запросы</v-btn>
						</v-col>
						<v-col cols="12" md="8">
							<v-autocomplete
								v-model="form.search_phrases" :items="choices_data.search_phrases"
								item-text="text" item-value="name" :loading="loading_phrases" multiple
								label="Выберите запросы" outlined hide-details dense clearable
							></v-autocomplete>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<div class="mt-5" v-if="form.supplier && form.main_product">
					<div class="title_block text-center">Ручное добавление/удаление поисковых запросов</div>
					<v-row class="mt-2">
						<v-col cols="12" md="6">
							<v-textarea
								class="elevation-0"
								outlined
								clearable
								auto-grow
								rows="3"
								col="3"
								v-model="form.manual_phrases"
								label="Введите название запросов (каждый ключ с новой строки)"
									hide-details
							></v-textarea>
							<div class="d-flex mt-3">
								<v-btn
									depressed color="purple darken-2" dark
									@click="addManualKeyword"
								>Добавить</v-btn>
								<v-btn
									depressed color="purple darken-2" class="ml-3" dark
									@click="removeManualKeyword"
								>Удалить</v-btn>
							</div>
						</v-col>
						<v-col cols="12" md="6">
							<v-textarea
									class="elevation-0"
									outlined
									auto-grow
									rows="3"
									col="3"
									readonly
									v-model="formattedSearchPhrases"
									label="Все поисковые фразы"
									hide-details
							></v-textarea>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<div class="mt-5" v-if="form.supplier && form.main_product && form.search_phrases">
					<div class="title_block text-center">Заполнение основых полей</div>
					<v-text-field
						label="Название кампании" outlined dense v-model="form.name"
						hide-details maxlength="50" counter class="mt-5"
					></v-text-field>
					<v-autocomplete
						v-model="form.purpose" :items="choices_data.purpose" item-text="name" 
						item-value="value" label="Цель" outlined dense hide-details 
						clearable class="mt-5"
					></v-autocomplete>
					<v-text-field
						v-model="form.payment_budget" 
						label="Пополнение бюджета" outlined type="number" class="mt-5" dense
						clearable hint="Сумма должна быть в пределе [100; 10000] и быть кратна 50"
					></v-text-field>
					<v-text-field
						v-model="form.comment" label="Комментарий" outlined dense 
						clearable hide-details class="mt-5"
					></v-text-field>
					<v-text-field
						v-model="form.wanted_placement"
						label="Желаемое место" outlined class="mt-5" type="number" dense clearable hide-details
					></v-text-field>
					<v-text-field
						v-model="form.max_bet"
						label="Максимальная ставка" outlined class="mt-5" type="number" dense clearable hide-details
					></v-text-field>
					<div class="d-flex mt-5 align-center">
						<div class="ml-5 d-flex align-center" style="font-size:14px">
							Робот:
							<v-switch color="primary" class="mt-0 ml-3" hide-details v-model="form.bet_robot"></v-switch>
						</div>
						<div class="ml-5 d-flex align-center" style="font-size:14px">
							Автопополнение бюджета:
							<v-switch color="primary" class="mt-0 ml-3" hide-details v-model="form.budget_robot"></v-switch>
							<v-text-field
								v-if="form.budget_robot" v-model="form.budget_value" label="Автопополнение бюджета"
								outlined type="number" dense clearable hide-details
							></v-text-field>
						</div>
					</div>
					<hr class="mt-5">
				</div>
				<div v-if="form.supplier && form.main_product && form.search_phrases" class="mt-5">
					<div class="title_block text-center">Выбор дополнительных товаров</div>
					<v-row class="mt-2">
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.second_nmid = null"
								v-model="search.second_nmid" label="Введите артикул" hide-details
								outlined @change="getProductsSecond" dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search.second_name = null" v-model="search.second_name"
								label="Введите название" hide-details @change="getProductsSecond"
								outlined dense
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-autocomplete
								v-model="form.products_second" :items="choices_data.list_products_second"
								item-text="text" item-value="id" :loading="loading_products_second"
								label="Выберите товар" outlined hide-details dense clearable multiple
							></v-autocomplete>
						</v-col>
					</v-row>
					<hr class="mt-5">
				</div>
				<v-btn
					depressed color="purple darken-2" class="mt-5" dark
					@click="checkFillData" :loading="loading_check"
				>Проверка</v-btn>
				<div class="check" v-if="Object.keys(prepared_data).length > 0">
					<div class="check__item">
						<v-simple-table v-if="finaly_advertisement" dense class="mb-5 small-font">
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">Название</th>
										<th class="text-left">Поля</th>
										<th class="text-left">Статус</th>
										<th class="text-left">Сообщение</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(i, index) in finaly_advertisement" :key="index">
										<td>{{ i.name }}</td>
										<td>
											<p>Аккаунт: {{ i.supplier_id }}</p>
											<p>Товары: {{ i.products }}</p>
											<p>Поисковая фраза: {{ i.search_phrase }}</p>
											<p>Цель: {{ i.purpose }}</p>
											<p>Пополнение бюджета: {{ i.budget }}</p>
											<p>Комментарий: {{ i.comment }}</p>
											<p>Желаемое место: {{ i.wanted_placement }}</p>
											<p>Максимальная ставка: {{ i.max_bet }}</p>
											<p>Робот по ставкам: {{ i.bet_robot }}</p>
											<p>Робот автопополнения: {{ i.budget_robot }}({{ i.budget_robot_value }})</p>
										</td>
										<td>{{ i.status }}</td>
										<td>{{ i.message }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<div class="check__item__name"></div>
						<div class="check__item__product"></div>
					</div>
					<v-btn
						depressed color="purple darken-2" class="mt-5" dark
						@click="createAdvertisement" :loading="loading_create"
					>Создать</v-btn>
				</div>
			</v-card-text>			
        </v-card>
	</v-container>
</template>

<script>
import { AdvertisementNew } from '@/api/advertisement_new'
import { General } from '@/api/general'

export default {
    name: 'AdvertisementNewSearchPluralCreate',
	data(){
		return {
			step: 0,
			loading_check: false,
			loading_create: false,
			loading_products: false,
			loading_products_second: false,
			loading_phrases: false,
			search: {
				nmid: null,
				name: null,
				second_nmid: null,
				second_name: null
			},
			form: {
				search_phrases: [],
				supplier: null,
				main_product: null,
				products_second: [],
				manual_phrases: null,
				name: null,
				purpose: null,
				payment_budget: null,
				comment: null,
				wanted_placement: null,
				max_bet: null,
				bet_robot: true,
				budget_robot: true,
				budget_value: 1000
			},
			prepared_data: {},
			finaly_advertisement: [],
			choices_data: {
				search_phrases: [],
				suppliers: [],
				wb_accounts: [],
				list_products: [],
				list_products_second: [],
				purpose: []
			}
		}
	},
	computed: {
		formattedSearchPhrases(){
			if (this.form.search_phrases && this.form.search_phrases.length > 0)
				return this.form.search_phrases.join('\n')
			return []
		}
	},
	methods: {
		async createAdvertisement(){
			this.loading_create = true
			for (const item of this.finaly_advertisement){
				let json_data = {
					"supplier_id": item.supplier_id,
					"products": item.products,
					"search_phrase": item.search_phrase,
					"name": item.name,
					"purpose": item.purpose,
					"budget": item.budget,
					"comment": item.comment,
					"wanted_placement": item.wanted_placement,
					"max_bet": item.max_bet
				}
				if (item.bet_robot)
					json_data["bet_robot"] = true
				
				if (item.budget_robot){
					json_data["budget_robot"] = true
					json_data["budget_robot_value"] = item.budget_robot_value ? item.budget_robot_value : 1000
				}
				try{
					const resp = await AdvertisementNew.CreatePluralSearchAdvertisement(json_data)
					item.status = true
					item.message = resp.data.message
				} catch(err){
					item.status = false
					item.message = err.response.data.error
				}
			}
			this.loading_create = false
		},
		calculateFinalyAdvertisement(){
			this.finaly_advertisement = []
			if (this.form.search_phrases.length <= 0)
				return
			this.form.search_phrases.forEach(item => {
				let json_data = {
					supplier_id: this.form.supplier,
					products: this.prepared_data,
					search_phrase: item,
					name: this.form.name + " - " + item,
					purpose: this.form.purpose,
					budget: this.form.payment_budget,
					comment: this.form.comment,
					wanted_placement: this.form.wanted_placement,
					bet_robot: this.form.bet_robot,
					budget_robot: this.form.budget_robot,
					budget_robot_value: this.form.budget_value,
					max_bet: this.form.max_bet,
					status: false,
					message: '',
				}
				this.finaly_advertisement.push(json_data)
			})
		},
		async checkFillData(){
			this.loading_check = true
			let products = [this.form.main_product]
			if (this.form.products_second)
				products.push(...this.form.products_second)
			try {
                const resp = await AdvertisementNew.PrepareProducts(
					{"products": products, "supplier": this.form.supplier}	
				)
				this.prepared_data = resp.data
				this.calculateFinalyAdvertisement()
            } catch (err) {
                console.log(err)
            }
			this.loading_check = false
		},
		async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices_data.purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		addManualKeyword(){
			let manual_phrases = this.form.manual_phrases.split('\n')
			manual_phrases.forEach(item => {
				if (this.form.search_phrases.indexOf(item) == -1){
					this.form.search_phrases.push(item)
				}
			})
		},
		removeManualKeyword(){
			let manual_phrases = this.form.manual_phrases.split('\n')
			manual_phrases.forEach(item => {
				let index = this.form.search_phrases.indexOf(item)
				if (index != -1){
					this.form.search_phrases.splice(index, 1);
				}
			})
		},
		async getSuppliers(){
			try {
				const resp = await AdvertisementNew.getSuppliers()
				this.choices_data.suppliers = resp
			} catch (err) {
				console.log(err)
			}
		},
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.choices_data.wb_accounts = response.data
        },
		async getProducts (){
			if (!this.form.supplier)
				return
			try{
				this.loading_products = true
				let query = {"wbaccount": this.form.supplier}
				if(this.search.nmid)
					query['nmid'] = this.search.nmid
				if (this.search.name)
					query['name'] = this.search.name
				const resp = await AdvertisementNew.getListProductsForCreateAdverSearch(query)
				this.choices_data.list_products = resp.data
			} catch(err){
				console.log(err)
			}
			this.loading_products = false
		},
		async getProductsSecond (){
			if (!this.form.supplier)
				return
			try{
				this.loading_products_second = true
				let query = {"wbaccount": this.form.supplier}
				if(this.search.second_nmid)
					query['nmid'] = this.search.second_nmid
				if (this.search.second_name)
					query['name'] = this.search.second_name
				const resp = await AdvertisementNew.getListProductsForCreateAdverSearch(query)
				this.choices_data.list_products_second.push(...resp.data)
			} catch(err){
				console.log(err)
			}
			this.loading_products_second = false
		},
		async getSearchPhrasesFromMpstats(){
			if (!this.form.main_product)
				return
			try{
				this.loading_phrases = true
				let query = {"product_id": this.form.main_product}
				const resp = await AdvertisementNew.getSearchPhrasesFromMpstats(query)
				this.choices_data.search_phrases = resp.data
			} catch(err){
				console.log(err)
			}
			this.loading_phrases = false
		}
	},
	async mounted(){
		await this.getSuppliers()
		await this.fetchWbAccounts()
		await this.getAdvertisementPurpose()
	}
}
</script>

<style lang="scss" scoped>
	.title_block{
		color: #000;
		font-weight: bold;
	}
	hr{
		height: 1px;
		background-color: #000;
	}
	.check{
		border: 1px dashed black;
		margin-top: 25px;
	}
</style>