<template>
    <div>
        <h1>ПРодажи</h1>
    </div>
</template>

<script>
  export default {
    name: "Sales_returns"
  }
</script>

<style scoped>

</style>
