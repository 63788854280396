<template>
    <v-container fluid>
        <v-dialog v-model="dialog_delete" max-width="500px" v-if="dialog_delete">
			<v-card>
				<v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="dialog_delete = false">Cancel</v-btn>
				<v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
				<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-data-table
			dense
			:headers="headers"
			:items="data"
			:loading="table_loading"
			class="elevation-0 mt-0 vertical-border small-font"
			:pageCount="pagination.numberOfPages"
			@update:page="getTempalteResponse"
			:options.sync="options"
			:items-per-page="50"
			:server-items-length="pagination.totalItems"
        >
            <template v-slot:top>
                <v-toolbar flat class="mt-5">
                    <v-row class="justify-space-between mt-2" style="padding:0 24px 24px 24px">
                        <v-dialog
                            v-model="form_created"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Добавить шаблон
                                </v-btn>
                            </template>
							<v-card>
								<v-card-title>
									<span class="text-h5">Создание шаблона ответа</span>
								</v-card-title>
								<v-card-text>
									<v-container>
										<v-col cols="12" md="12">
											<v-text-field
												v-model="form_data.name"
												label="Название"
												outlined
												clearable
												dense
											></v-text-field>
											<v-text-field
												v-model="form_data.text"
												label="Текст"
												outlined
												clearable
												dense
											></v-text-field>
										</v-col>
									</v-container>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn
										color="blue darken-1"
										text
										@click="form_created=false"
									>
										Закрыть
									</v-btn>
									<v-btn
										color="blue darken-1"
										text
										@click="createTemplateResponse"
									>
										Создать
									</v-btn>
								</v-card-actions>
							</v-card>    
                        </v-dialog>
                    </v-row>
                </v-toolbar>
            </template>
			<template v-slot:item.actions="{ item }">
				<v-icon
                    small
                    @click="deleteRedemption(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';

	export default {
		name: 'TemplateResponsesFeedback',
		data() {
			return {
				form_created: false,
				dialog_delete: false,
				form_data: {
					name: null,
					text: null
				},
				deleted_item: null,
				table_loading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
				data: [],
				headers: [
					{ text: 'Название', sortable: false, value: 'name' },
					{ text: 'Текст', sortable: false, value: 'text' },
					{ text: 'Действия', value: 'actions', sortable: false, width: '1%' },
				],
			};
		},
		methods: {
			deleteRedemption (item) {
				this.deleted_item = item.id
				this.dialog_delete = true
			},  
			async createTemplateResponse(){
				try{
					const response = await Products.createFeedbackTemplateResponse({
						"name": this.form_data.name,
						"text": this.form_data.text
					})
					this.form_data.name = null
					this.form_data.text = null
					this.form_created = false
					this.getTempalteResponse()
				} catch (err){
					console.log(err)
				}   
            },
			async deleteItemConfirm () {
				try {
					const response = await Products.deleteFeedbackTemplateResponse(this.deleted_item)
					this.getTempalteResponse()
				}
				catch (err){
					console.log(err)
				}
				this.dialog_delete = false
			},
			async getTempalteResponse(){
				this.table_loading = true
				const { page, itemsPerPage } = this.options
				let pageNumber = page - 1
				try {
					const res = await Products.getFeedbackTemplateResponse(
						{
							...{
							'offset': itemsPerPage*pageNumber,
							'limit': itemsPerPage,
							}
						}
					)
					this.data = res.data.results
					this.pagination.totalItems = res.data.count
					this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
				} catch (err) {
					console.log(err)
				}
				this.table_loading = false
			}
		},
		async mounted() {
			this.getTempalteResponse()
		},
	};
</script>

<style scoped lang="scss">
    
</style>
