import Vue from 'vue'
import Vuex from 'vuex'

import router from '@/router'

import general from './modules/general'
import auth from './modules/auth'
import products from './modules/products'
import suppl from './modules/suppl'
import dashboard from './modules/dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    auth,
    products,
    suppl,
    dashboard
  }
})
