import { HTTP } from './http'

export const Products = {
  async getFeedbacks (query=null) {
    return HTTP.get('/products/feedbacks/', {
      params: query
    } )
  },
  async getSingleFeedback (feedback_id) {
    return HTTP.get(`/products/feedbacks/${feedback_id}/`,)
  },
  async sellerFeedbackStatus () {
    return HTTP.get(`/products/seller_feedbacks_status/`,)
  },
  async feedbackSourceStats (query) {
    return HTTP.get(`/products/feedbacks/stat/`, {
      params: query
    })
  },
  async fetchSingleProductFeedbacks (nmid) {
    return HTTP.post('/products/single_product_fetch_feedbacks/', {'nmid':nmid})
  },
  async fetchCatalog (query=null)  {
    return HTTP.get('/catalog/mp/', {
      params: query
    } )
  },
  async fetchCatalogChoices () {
    return HTTP.get('/catalog/mp/choices/')
  },
  async fetchProducts (query=null) {
    return HTTP.get('/products/get_products/', {
      params: query
    } )
  },

  async fetchAnalyticsProducts (query=null) {
    return HTTP.get('/products/get_analytics_products/', {
      params: query
    } )
  },
  async fetchProductBalances (product_id, query=null) {
    return HTTP.get(`/products/${product_id}/stock_balances_v2/`, {
      params: query
    })
  },
  async fetchProductBalancesDay (product_id, date, cost_amount=null) {
    return HTTP.get(`/products/${product_id}/stock_balances/${date}/`, {
      params: cost_amount
    })
  },
  async fetchPriceDay (product_id, date) {
    return HTTP.get(`/products/${product_id}/price_by_date/${date}/`)
  },
  async fetchPriceApiDay (product_id, date) {
    return HTTP.get(`/products/${product_id}/price_api_by_date/${date}/`)
  },
  async fetchProductWarehousesDay (product_id, date) {
    return HTTP.get(`/stats/stocks/${product_id}/${date}/`)
  },
  async fetchBaseProductData (product_id) {
    return HTTP.get(`/products/${product_id}/`)
  },
  async fetchProductOrders (product_id, query=null) {
    return HTTP.get(`/stats/orders/${product_id}/`, {
      params: query
    })
  },
  async fetchProductSales (product_id, query=null) {
    return HTTP.get(`/stats/sales/${product_id}/`, {
      params: query
    })
  },
  async fetchProductReports (product_id, query=null) {
    return HTTP.get(`/stats/reports/${product_id}/`, {
      params: query
    })
  },
  async setSourceToFeedback (source, feedback_id) {
    return HTTP.put(`/products/feedbacks/set_source`, {'source':source, 'feedback_id': feedback_id})
  },
  async setDeletionStatusToFeedback (status, feedback_id) {
    return HTTP.put(
		`/products/feedbacks/set_deletion_status`, 
		{'status':status, 'feedback_id': feedback_id}
	)
  },
  
  async updateProduct (data, product_nmid) {
    return HTTP.put(`/products/${product_nmid}/`, data)
  },
  async setKeywordMonitoring (query=null) {
    return HTTP.post(`/products/product_monitoring/`, query)
  },
  async updateFavoriteKeyword (query=null) {
    return HTTP.put('/products/product_monitoring/', query)
  },
  async getKeywordMonitoring (query=null) {
    return HTTP.get('/products/product_monitoring/', {
      params: query
    })
  },
  async copyKeywordMonitoring (query=null) {
    return HTTP.post(`/products/product_monitoring/copy_keyword/`, query)
  },
  async deleteKeyword (keyword_id) {
    return HTTP.delete(`/products/product_monitoring/${keyword_id}/`)
  },
  async addCompetitors (query=null) {
    return HTTP.post(`/products/competitors/`, query)
  },
  async fetchCompetitors (query=null) {
    return HTTP.get(`/products/competitors/`, {
      params: query
    })
  },
  async deleteCompetitors (id) {
    return HTTP.delete(`/products/competitors/${id}/`)
  },
  // OZON
  async fetchOzonProducts (query=null) {
    return HTTP.get('/products/ozon/products/', {
      params: query
    } )
  },
  async fetchOzonProductData (product_id) {
    return HTTP.get(`/products/ozon/products/${product_id}/`)
  },
  async fetchOzonProductDataDays (product_id, query=null) {
    return HTTP.get(`/products/ozon/products/${product_id}/data_days/`, {
      params: query
    })
  },
  async exportOzonProducts(query=null) {
	return HTTP.get('/products/ozon/export_product_list/', {
		responseType:"blob", 
		params: query
	})
  },
  async exportProducts(query=null) {
		return HTTP.get('/products/export_product_list_to_xlsx/', {
			responseType:"blob", 
			params: query
		})
	},
  async updateProductArtificialDays ({product_id, dates, marketplace}) {
    return HTTP.put('/products/set_artificial_days/', {
      product_id,
      dates,
      marketplace
    })
  },
  async fetchProductCharacteristics (product_id) {
    return HTTP.get(`/products/chars/${product_id}/`)
  },
  async fetchProductCharacteristicsByDate (product_id, date) {
    return HTTP.get(`/products/chars/${product_id}/${date}/`)
  },
  async updateCharsProduct(id, new_name){
	return HTTP.post(`/products/chars/update_chars/${id}/`, {'new_name': new_name})
  },
  async updateCharsProductNew(id, val, item){
	return HTTP.post(`/products/chars/update_chars_new/${id}/`, {
		'char_info': item,
		'char_value': val
	})
  },
  async fetchProductChartsData (query=null) {
    return HTTP.get('/products/product_charts_data/', {
      params: query
    } )
  },
  async updateStockFBS(variation_id, stock){
	return HTTP.post(`/products/stocks/update_fbs/${variation_id}/`, {'stock': stock})
  },
  async deleteStockFBS(variation_id){
	return HTTP.delete(`/products/stocks/update_fbs/${variation_id}/`)
  },
  async fetchProductLogs (nmid) {
    return HTTP.get(`/products/logs/${nmid}/`)
  },
  async ChangeIncreaseMode (product_id) {
	return HTTP.put(`/products/change_increase_mode/${product_id}/`)
  },
  async changeIsStoragePallet (product_id) {
	return HTTP.put(`/products/change_is_storage_pallet/${product_id}/`)
  },
  async changeIsMixedLogistic (product_id) {
	return HTTP.put(`/products/change_is_mixed_logistic/${product_id}/`)
  },
  async changeIsFbsLogistic (product_id) {
    return HTTP.put(`/products/change_is_fbs_logistic/${product_id}/`)
  },
  async changeQuantityOnPallet (product_id, quantity) {
	return HTTP.post(`/products/change_quantity_on_pallet/${product_id}/`, {"quantity": quantity})
  },
  async saveMpstats(data){
	return HTTP.post('/products/change_mpstats_stats_product/', data)
  },
  async uploadStatsFromExcel (query=null) {
	return HTTP.post('/products/upload_product_stats_from_excel/', query)
  },
  async importAdditionalData (query=null) {
	return HTTP.post('/products/ozon/import_additional_info_from_excel/', query)
  },
  async uploadUpdateStatsFromExcel (query=null) {
	return HTTP.post('/products/update_product_stats_from_excel/', query)
  },
  async updateAdditionalData (query=null) {
	return HTTP.post('/products/ozon/update_additional_info_from_excel/', query)
  },
  async saveComment(data){
	return HTTP.post('/products/change_comment_for_products/', data)
  },
  async saveOzonComment(data){
	return HTTP.post('/products/ozon/change_comment_for_products/', data)
  },
  async fetchShippingPacksPrice(code_1c, date){
	return HTTP.get(`/analytics/shipping_packs/`, {
		params: {'date_from': date, 'date_to': date, 'code1c': code_1c}
	})
  },
  async savePriceAPI(product_id, data){
	return HTTP.post(`/products/${product_id}/change_price_api/`, data)
  },
  async getFeedbackTemplateResponse(query=null){
	return HTTP.get('/products/feedbacks/template_response/', { params: query })
  },
  async createFeedbackTemplateResponse(data){
	return HTTP.post('/products/feedbacks/template_response/', data)
  },
  async deleteFeedbackTemplateResponse(id){
	return HTTP.delete(`/products/feedbacks/template_response/${id}/`)
  },
  async createFeedbackSupportRequest(uid, data){
	return HTTP.post(`/products/feedbacks/create_request_for_delete/${uid}/`, data)
  },
  async fetchProductPlansWB(product_id){
	return HTTP.get(`/products/${product_id}/plans/`)
  },
  async fetchProductPlansOzon(product_id){
	return HTTP.get(`/products/ozon/products/${product_id}/plans/`)
  },
  async fetchProductRecommendationStock(product_id){
	return HTTP.get(`/products/get_recommendation_stock/${product_id}/`)
  },
  async fetchRecommendationCommission(nmid, query=null){
	return HTTP.get(`/products/get_recommendation_commission/${nmid}/`, { params: query })
  },
  async exportProductDetailData(product_id, query=null){
	return HTTP.get(`/products/export_product_detail_to_xlsx/${product_id}/`, {
		params: query,
		responseType:"blob"
	})
  },
  async downloadTemplateMpstatsStatas (params) {
	return HTTP.get('/products/download_template_import_mpstats_stats/', {responseType:"blob", params: params})
  },
  async downloadOzonTemplateAdditionalData () {
	return HTTP.get('/products/ozon/template_additional_info/', {responseType:"blob"})
  },
  
  async checkUpdateError (product_id) {
	return HTTP.get(`/products/chars/${product_id}/check_error/`)
  },
  async getAllPossibleChars (product_id) {
	return HTTP.get(`/products/chars/${product_id}/possible_characteristics/`)
  },
  async changeFavoriteCompetitor(id){
	return HTTP.post(`/products/change_favorite_competitors/${id}/`)
  },
  async fetchOzonProductStocksByWarehouse(product_id, date){
	return HTTP.get(`/products/ozon/products/${product_id}/stocks_warehouse_by_date/${date}/`)
  },
  async fetchDetailCompetitorsData(competitors_id){
	return HTTP.get(`/products/competitors/${competitors_id}/detail/`)
  },
  async saveOzonPrice(product_id, json_data){
	return HTTP.post(`/products/ozon/products/${product_id}/change_price/`, json_data)
  },
  async fetchWbBookmarks () {
	return HTTP.get(`/products/bookmarks_user/`)
  },
  async toggleWbBookmarks (product_id) {
    return HTTP.post(`/products/bookmarks_user/`, {"product": product_id})
  },
  async fetchOzonBookmarks () {
	return HTTP.get(`/products/ozon/bookmarks_user/`)
  },
  async toggleOzonBookmarks (product_id) {
    return HTTP.post(`/products/ozon/bookmarks_user/`, {"product": product_id})
  },
  async fetchOzonProductChars(product_id, date=null){
	return HTTP.get(`/products/ozon/products/${product_id}/chars/`, {'params': {'date': date}})
  },
  async fetchProductRecommendations(product_id){
	return HTTP.get(`/products/${product_id}/recommendations/`)
  },
  async logisticAndStorageCostByWh(product_id, date){
	return HTTP.get(`/products/${product_id}/logistic_and_storage_cost_by_wh/`, {params: {'date': date}})
  },
  async ozonProductIndicesByAccount(query=null){
	return HTTP.get('/products/ozon/products_indices/', {params: query})
  },
  async wbProductIndicesByAccount(query=null){
	return HTTP.get('/products/indices_by_accounts/', {params: query})
  },
  async ozonAccounts(){
	return HTTP.get('/products/ozon/ozon_accounts/')
  },
  async fetchDetmirProductsList(query=null){
	return HTTP.get('/products/detmir/products/', {params: query})
  },
  async fetchDetmirProductDetail(product_id) {
    return HTTP.get(`/products/detmir/products/${product_id}/`)
  },
  async fetchDetmirProductDataDays (product_id, query=null) {
    return HTTP.get(`/products/detmir/products/${product_id}/data_days/`, {params: query})
  },
  async fetchListWbPromotions(query=null){
	return HTTP.get('/products/promotions/', {params: query})
  },
  async updateWbPromotions(promotion_id, data){
	return HTTP.patch(`/products/promotions/${promotion_id}/`, data)
  },
  async exportFeedbacks(query=null){
	return HTTP.get(`/products/export_feedbacks/`, {
		params: query,
		responseType:"blob"
	})
  },
  async fetchListOzonPromotion(query=null){
    return HTTP.get('/products/ozon/promotions/', {params: query})
  },
  async updateOzonPromotion(data){
    return HTTP.post('/products/ozon/set_commission_promotions/', data)
  },
  async exportWBPriceCompetitors(query=null){
		return HTTP.get(`/products/export_price_competitors/`, {
			params: query, responseType:"blob"
		})
	},
  async uploadPlannedUnitEconomic (data) {
    return HTTP.post('/products/planned_unit_economic/upload_files/', data)
  },
  async fetchPlannedUnitEconomic (query=null) {
    return HTTP.get('/products/planned_unit_economic/', {params: query})
  },
  async updatePlannedUnitEconomic (id, data) {
    return HTTP.patch(`/products/planned_unit_economic/${id}/`, data)
  },
  async exportTemplatePlannedUnitEconomic () {
    return HTTP.get('/products/planned_unit_economic/template/', {responseType:"blob"})
  },  
  async exportPlannedUnitEconomic (query=null) {
    return HTTP.get('/products/planned_unit_economic/export/', {responseType:"blob", params: query})
  },  
  async fetchFiltersPlannedUnitEconomic () {
    return HTTP.get('/products/planned_unit_economic/filters/')
  },
  async fetchDynamicIndicatorsDay (query=null) {
    return HTTP.get('/products/dynamic_indicators/', {params: query})
  },
  async exportDynamicIndicatorsDay(query=null) {
		return HTTP.get('/products/dynamic_indicators/', {responseType:"blob", params: query})
	},
  async fetchOzonDynamicIndicatorsDay (query=null) {
    return HTTP.get('/products/ozon/dynamic_indicators/', {params: query})
  },
  async exportOzonDynamicIndicatorsDay(query=null) {
		return HTTP.get('/products/ozon/dynamic_indicators/', {responseType:"blob", params: query})
	},
  async versionsPlannedUnitEconomic (id) {
    return HTTP.get(`/products/planned_unit_economic/${id}/versions/`)
  },
  async fetchProductPlans(product_id){
    return HTTP.get(`/products/${product_id}/plans_v2/`)
  },
  async fetchOzonProductPlans(product_id){
    return HTTP.get(`/products/ozon/products/${product_id}/plans_v2/`)
  },
  async fetchRootProductsList(query=null){
    return HTTP.get('/products/get_products_by_root/', {
      params: query
    } )
  },
  async exportWBWithoutPlans(query){
    return HTTP.get(`/products/planned_unit_economic/export_wb_without_plan/`, {responseType:"blob", params: query})
  },
  async exportOzonWithoutPlans(query){
    return HTTP.get(`/products/planned_unit_economic/export_ozon_without_plan/`, {responseType:"blob", params: query})
  },
  async fetchCatalogMPHistory(query){
    return HTTP.get(`/products/catalog_mp_history/`, {params: query})
  },
}

