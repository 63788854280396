import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import BeeGridTable from "beegridtable"
import "beegridtable/dist/styles/beegridtable.css"
import ToastedPlugin from "vue-toasted"
import VueApexCharts from 'vue-apexcharts'
import ru from 'apexcharts/dist/locales/ru.json'

Vue.use(BeeGridTable, {
  capture: true,
  transfer: true,
  zIndex: 2000,   //set z-index of beegridtable
})
Vue.use(ToastedPlugin)
Vue.use(VueApexCharts)
window.Apex.chart = {
  locales: [ru],
  defaultLocale: "ru"
}

// для доступа к протоколу и хосту ({protocol}://{host})
Vue.prototype.origin = window.location.origin

import './filters/format_date'
import './filters/currency'

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
