<template>
    <v-app>
        <v-container class="grey lighten-3" fill-height="fill-height" fluid="fluid" background-color="grey">
            <v-row align="center" justify="center">
                <v-spacer></v-spacer>
                <v-col cols="12" md="4">
                    <v-card flat="flat">
                      <v-alert density="compact" type="warning">
                        Если возникают ошибки при авторизации, попробуйте в логине использовать почту @ivalar
                      </v-alert>
                        <v-toolbar dark="" flat="flat">
                            <v-toolbar-title>Вход</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-form v-model="valid" ref="form" lazy-validation="lazy-validation" @submit="submitLogin" @keyup.native.enter="submitLogin">
                                <v-text-field label="Логин" v-model="login" :rules="loginRules"></v-text-field>
                                <v-text-field label="Пароль" v-model="password" :rules="passwordRules" type="password"></v-text-field>
                            </v-form>
                            <v-alert class="my-2" type="error" :value="auth_error" color="error">Данные неверны</v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-on:click="submitLogin" color="green lighten-1" dark="dark" v-bind:disabled="!valid">Вход</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
  export default {
    data() {
      return {
        formTextFields: [
          {
            name: 'login',
            label: 'Login',
            type: 'text',
            icon: 'person',
            rule: 'loginRule',
            model: this.login
          },
          {
            name: 'password',
            label: 'Password',
            type: 'password',
            icon: 'lock',
            rule: 'passwordRule',
            model: this.password
          }
        ],
        login: '',
        password: '',
        valid: false,
        auth_error: false,
        loginRules: [
          v => !!v || 'Введите логин'
        ],
        passwordRules: [
          v => !!v || 'Введите пароль'
        ]
      }
    },
    methods: {
      async submitLogin() {
        this.auth_error = false
        console.log(this.login)
        if (this.$refs.form.validate()) {
          try {
            const response = await this.$store.dispatch('login', {username: this.login, password: this.password})
            console.log(response)
          } catch (err) {
            this.auth_error = true
          }
        }
      }
    }
  }
</script>

<style scoped>
    /deep/ .v-content__wrap {
        background: #f0f0f0;
    }
</style>
