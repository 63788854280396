<template>
	<div>
		<router-link :to="{ name: 'ozon_supply_planning_create'}" v-if="marketplace == 'Ozon'">
			<v-btn block depressed color="purple darken-2" class="mt-5" dark>Создать новый план</v-btn>
		</router-link>
		<router-link :to="{ name: 'supply_planning_create'}" v-else>
			<v-btn block depressed color="purple darken-2" class="mt-5" dark>Создать новый план</v-btn>
		</router-link>
		<v-data-table
			:headers="table.headers" :items="table.data" class="elevation-0 mt-5 vertical-border"
			:items-per-page="50" :footer-props="{'items-per-page-options': [5, 15, 25, 50, 100]}"
			:loading="table.loading" :options.sync="table.options" :server-items-length="table.pagination.totalItems"
		>
			<template v-slot:item.id="{ item }">
				<router-link 
					:to="{ name: 'ozon_supply_planning_detail', params: {id: item.id}}" 
					v-if="item.status == 'SUCCESSFULL' && marketplace == 'Ozon'"
				>Посмотреть расчет</router-link>
				<router-link 
					:to="{ name: 'supply_planning_detail', params: {id: item.id}}" 
					v-else-if="item.status == 'SUCCESSFULL'"
				>Посмотреть расчет</router-link>
				<div v-else></div>
            </template>
			<template v-slot:item.limit_turnover="{ item }">
				{{ marketplace != 'Ozon' ? item.limit_turnover : 60 }}
            </template>
			<template v-slot:item.period="{ item }">
				[{{ item.date_from }} ; {{ item.date_to }}] 
            </template>
			<template v-slot:item.status="{ item }">
				<div>
					{{ item.status_display }}
					<v-tooltip bottom max-width=350 v-if="item.status == 'ERROR'">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" style="cursor: pointer">
								<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
							</span>
						</template>
						<span>{{ item.error_text }}</span>
					</v-tooltip>
				</div>
				<div v-if="item.status == 'IN_PROGRESS'">
					<v-progress-linear v-model="item.progress" height="20">
						<strong>{{ Math.ceil(item.progress) }}%</strong>
					</v-progress-linear> 
				</div>
            </template>
			<template v-slot:item.action="{ item }">
				<v-icon small @click="delete_supply_planning(item.id)">mdi-delete</v-icon>
            </template>
		</v-data-table>
	</div>
</template>

<script>
import { Suppl } from '@/api/suppl';

export default {
	name: 'SupplyPlanningListComponent',
	props: ['marketplace'],
	data() {
		return {
			interval: null,
			table: {
				headers: [
					{ text: "id", value: "id", sortable: false, width: "1%" },
					{ text: "Дата создания", value: "date_create", sortable: false, width: "1%" },
					{ text: "Пользователь",  value: "username", sortable: false, width: "1%" },
					{ text: 'ЛК', value: 'account_name', sortable: false, width: "1%" },
					{ text: "Период", value: "period", sortable: false, width: "1%" },
					{ text: "Статус", value: "status", sortable: false, width: "1%" },
					{ text: 'Ограничение оборачиваемости', value: 'limit_turnover', sortable: false, width: '1%'},
					{ text: 'Действия', value: 'action', sortable: false, width: "1%" }
				],
				data: [],
				loading: false,
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
			},
		}
	},
	watch:{
        'table.options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && newVal) {
				await this.fetch_list_supply_planning()
			}
        },
	},
	methods: {
		async loop_check_progress(){
			let task_in_progress = []
			this.table.data.forEach(item => {
				if (item.status == 'IN_PROGRESS')
					task_in_progress.push(item.id)
			});
			if (task_in_progress.length == 0)
				clearInterval(this.interval);
			try{
				let response = {}
				if (this.marketplace == 'Ozon'){
					response = await Suppl.ozonCheckProgressSupplyPlanning({"id_list": task_in_progress})
				} else{
					response = await Suppl.checkProgressSupplyPlanning({"id_list": task_in_progress})	
				}
				response.data.forEach(item=>{
					let index = this.table.data.findIndex((el) => el.id == item.id)
					this.table.data[index]['progress'] = item.progress
					this.table.data[index]['status'] = item.status
					this.table.data[index]['status_display'] = item.status_display
				})
			} catch(err){
				clearInterval(this.interval)
			}	
		},
		async fetch_list_supply_planning(){
			if (this.interval){
				clearInterval(this.interval);
			}
			this.table.loading = true
			const { page, itemsPerPage, sortDesc, sortBy} = this.table.options
			try {
				let pageNumber = page - 1
				if (this.table.data.length != 0)
					window.scrollTo(0,0);
				let data = {
					'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage, 
					'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				}
				let response = {}
				if (this.marketplace == 'Ozon'){
					response = await Suppl.ozonListSupplyPlanning(data)
				} else{
					response = await Suppl.listSupplyPlanning(data)	
				}
				this.table.data = response.data.results
				this.table.pagination.totalItems = response.data.count
				this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				this.interval = setInterval(this.loop_check_progress, 2000);
			} catch (err) {
				console.error(err.response)
			}
			this.table.loading = false
		},
		async delete_supply_planning(id){
			try{
				if (this.marketplace == 'Ozon'){
					await Suppl.ozonDeleteSupplyPlanning(id)
				} else{
					await Suppl.deleteSupplyPlanning(id)	
				}
				await this.fetch_list_supply_planning()
			} catch(err){
				this.$toasted.show(`Ошибка удаления`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
		}
	},
	async mounted(){
		if (this.interval){
			clearInterval(this.interval)
		}
		await this.fetch_list_supply_planning()
	},
	beforeDestroy () {
		clearInterval(this.interval)
	}
};
</script>
