<template>
	<AdvertisementTable/>
</template>

<script>
import AdvertisementTable from '@/components/OzonAdvertisement/AdvertisementTable'

export default {
	name: 'OzonAdvertisement',
	components:{
		AdvertisementTable
	},
	mounted () {
		document.title = `Реклама OZON | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Рекламные кампании OZON`)
	}
}
</script>