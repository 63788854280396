<template>
    <v-card>
		<v-card-title>Изменение цен</v-card-title>
		<v-card-text>
			<v-data-table
				item-key="nmid"
				:headers="table.headers"
				:items="table.data"
				:loading="table.loading"
				class="elevation-0 small-font mt-3 vertical-border"
				hide-default-footer
				v-model="table.selected_items"
				disable-pagination
				:item-class="row_color_fill"
				dense
				show-select
			>
				<template v-slot:item.img="{ item }">
					<img style="max-height:50px" :src="getImgPath(item.nmid)" alt="">
				</template>
				<template v-slot:item.old_price="{ item }">
					{{ item.old_price | toRUB}}
				</template>
				<template v-slot:item.new_price="{ item }">
					{{ item.new_price | toRUB}}
				</template>
				<template v-slot:item.cost_price="{ item }">
					{{ item.cost_price | toRUB}}
				</template>
				<template v-slot:item.old_final_price="{ item }">
					{{ item.old_final_price | toRUB}}
				</template>
				<template v-slot:item.final_price="{ item }">
					{{ item.final_price | toRUB}}
				</template>
				<template v-slot:item.old_discount="{ item }">
					{{ item.old_discount ? item.old_discount : 0 }}%
				</template>
				<template v-slot:item.new_discount="{ item }">
					{{ item.new_discount ? item.new_discount : 0 }}%
				</template>
				<template v-slot:item.changing="{ item }">
					<div v-if="item.new_price != item.old_price">Изменение цены</div>
					<div v-if="item.new_discount != item.old_discount">Изменение скидки</div>
				</template>
			</v-data-table>
			<div class="d-flex mt-10">
				<v-btn 
					color="blue" :loading="table.loading" 
					@click="fetch_price_sales"
				>Обновить данные в таблице</v-btn>
				<v-btn 
					color="lime" class="ml-5" @click="update_new_price" :loading="loading_update"
				>Изменить цену</v-btn>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
	import {PriceManagement} from '@/api/price_management';

	export default {
		name: 'PriceManagementTemplates',
		data() {
			return {
				table:{
					data: [],
					headers: [
						{ text: 'Изображение', sortable: true, value: 'img'},
						{ text: 'Артикул', sortable: true, value: 'nmid'},
						{ text: 'Аккаунт', value: 'account', sortable: false},
						{ text: 'Название', sortable: false, value: 'name'},
						{ text: 'Старая цена', value: 'old_price', sortable: false},
						{ text: 'Новая цена', value: 'new_price', sortable: false},
						{ text: 'Старая скидка', value: 'old_discount', sortable: false},
						{ text: 'Новая скидка', value: 'new_discount', sortable: false},
						{ text: 'Старая фин. цена', value: 'old_final_price', sortable: false},
						{ text: 'фин. цена', value: 'final_price', sortable: false},
						{ text: 'С/С', value: 'cost_price', sortable: false},
						{ text: 'Что изменится', value: 'changing', sortable: false},
					],
					loading: false,
					selected_items: []
				},
				loading_update: false
			};
		},
		computed: {
			template_id(){
				return this.$route.params.template_id
			}
		},
		methods: {
			async fetch_price_sales(){
				this.table.loading = true
				try{
					const response = await PriceManagement.fetchPriceSalesByGoogleSheet(this.template_id)
					this.table.data = response.data
				} catch(err){
					console.log(err)
				}
				this.table.loading = false
			},
			async update_new_price(){
				this.loading_update = true
				try{
					await PriceManagement.updatePrice(this.template_id, this.table.selected_items)
					this.$toasted.show('Цены успешно изменены', {
						theme: 'bubble', type: 'success', position: 'top-center',duration: 7000,
                    });
				} catch(err){
					this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
                    });
				}
				this.loading_update = false
			},
			calculateVolImage(t){
				t = Number(t)
				if (t >= 0 && t <= 143)
					return '01'
				if (t >= 144 && t <= 287)
					return '02'
				if (t >= 288 && t <= 431)
					return '03'
				if (t >= 432 && t <= 719)
					return '04'
				if (t >= 720 && t <= 1007)
					return '05'
				if (t >= 1008 && t <= 1061)
					return '06'
				if (t >= 1062 && t <= 1115)
					return '07'
				if (t >= 1116 && t <= 1169)
					return '08'
				if (t >= 1170 && t <= 1313)
					return '09'
				if (t >= 1314 && t <= 1601)
					return '10'
				if (t >= 1602 && t <= 1655)
					return '11'
				if (t >= 1656 && t <= 1919)
					return '12'
				if (t >= 1920 && t <= 2045)
					return '13'
				if (t >= 2046 && t <= 2189)
					return '14'
				if (t >= 2190 && t <= 2405) 
					return '15'
				return '16'
			},
			getImgPath (nmid) {
				let first_part = nmid.slice(0, -3)
				let second_part = nmid.slice(0, -5)
				let third_part = this.calculateVolImage(second_part)
				return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
			},
			row_color_fill(item){
				if (!item.final_price && item.final_price <= 0)
					return 'super_red_checked_new'
				if (item.cost_price > 0 && item.final_price > 0 && item.final_price < item.cost_price)
					return 'red_checked_new'
				if (item.old_finally_price > 0 && item.final_price > 0 && Math.abs(item.final_price - item.old_finally_price / item.final_price * 100) > 15)
					return 'orange_checked_new'
				if (item.final_price > 0)
					return 'green_checked_new'
				return ''
			}
		},
		async mounted() {
			document.title = 'Управление ценой (изменение цен)';
			this.$store.commit('setPageTitle', `Управление ценой (изменение цен)`)
			await this.fetch_price_sales()
		},
	};
</script>

<style lang="scss">
    .super_red_checked_new{
		background-color: #eb9191 !important;
	}
	.red_checked_new{
		background-color: #ebc3c3 !important;
	}
	.orange_checked_new{
		background-color: rgb(241, 201, 127) !important;
	}
	.green_checked_new{
		background-color: #d3fbd3 !important;
	}
</style>
