<template>
	<SupplyPlanningDetailComponent 
		:warehouses_list="table.warehouses_list" :id="id"
	/>
</template>

<script>
import { Suppl } from '@/api/suppl';
import SupplyPlanningDetailComponent from '@/components/SupplyPlanning/SupplyPlanningDetailComponent';

export default {
	name: 'SupplyPlanningListComponent',
	components: { SupplyPlanningDetailComponent },
	data() {
		return {
			table: {
				data: [],
				loading: false,
				warehouses_list: []
			}
		}
	},
	computed: {
		id() {
			return this.$route.params.id;
		},
	},
	
	methods: {
		async fetch_detail(){
			this.table.loading = true
			try{
				const response = await Suppl.fetchWarehouseListSuppl(this.id)
				this.table.warehouses_list = response.data.warehouses_list
			} catch(err){
				console.log(err)
			}
			this.table.loading = false
		},
	},
	async mounted(){
		document.title = `Планирование поставок(${this.id}) | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Планирование поставок(${this.id})	`)
		await this.fetch_detail()
	},
};
</script>
