<template>
	<div v-if="loading" style="display: flex; justify-content:center;">
		<v-progress-circular :size="50" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" indeterminate></v-progress-circular>
	</div>
    <div v-else>
        <v-data-table 
			:headers="headers" class="elevation-1" :items="data" :items-per-page="5"	
		>
            <template v-slot:item.cpc="{ item }">
                {{ item.cpc | toRUB }}
            </template>
		</v-data-table>
        <v-switch 
            label="Отображение статистики за все время" v-model="is_show_all_stats"
            :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
        ></v-switch>
		<apexchart 
            type="line" height="500" :options="chartOptions" :series="seriesMain" ref="chart"
            class="mt-5"
		></apexchart>
        <div class="d-flex justify-center">
			<button @click="hidden_all">Скрыть все</button>
			<button @click="show_all" class="ml-3">Показать все</button>
		</div>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'DialogAdvertisementStatsCluster',
    props: ['campaign_id', 'keyword'],
    components: { apexchart: VueApexCharts },
    data() {
        return {
            is_show_all_stats: false,
            data: [],
            headers: [
                { text: 'Дата', sortable: false, value: 'date', width: '1%' },
				{ text: 'Средняя позиция', sortable: false, value: 'avg_position', width: '1%' },
				{ text: 'CTR', sortable: false, value: 'ctr', width: '1%' },
				{ text: 'Просмотры', sortable: true, value: 'views', width: '1%' },
				{ text: 'Расход', sortable: true, value: 'sum', width: '1%' },
				{ text: 'Клики', sortable: true, value: 'clicks', width: '1%' },
				{ text: 'CPC', sortable: true, value: 'cpc', width: '1%' },
			],
            loading: false,
            chartOptions: {
                theme: { mode: this.$vuetify.theme.dark ? 'dark': 'light'},
                chart: { height: 1000, width: '100%', type: 'line', zoom: { enabled: true }, toolbar: { show: true }, background: 'rgba(0, 0, 0, 0)' },
                stroke: { curve: 'smooth', width: [3, 3, 3, 3, 3, 3] },
                yaxis: [
                    { showAlways: true, seriesName: 'Ср. позиция', labels: { rotate:'-45', show: true, formatter: function(val) { return val }}, opposite: true}, 
                    { showAlways: true, seriesName: 'Просмотры', labels: { rotate:'-45', show: true, formatter: function(val) { return val }} }, 
                    { showAlways: true, seriesName: 'CTR(%)', opposite: true, title: { text: '%' }, labels: { rotate:'45', show: true, formatter: function(val) {return `${val}%`}} },
                    { seriesName: 'Клики', show:false },
                    { seriesName: 'CPC', show:false },
                    { seriesName: 'Расход', show:false, opposite: true },
                ],
                xaxis: { type: 'datetime' },
            },
        }
    },
    computed: {
        date_chart(){
            if (!this.data || this.data.length <= 0 || this.is_show_all_stats)
                return this.data
            return this.data.slice(0, 40)
        },
        seriesMain() {
            return [
                { name: 'Ср. позиция', type: 'column', data: this.prepare_series('avg_position'), color: this.color_scheme("#c5d7c0", '#32472d')},
                { name: 'Просмотры', type: 'line', data: this.prepare_series('views'), color: this.color_scheme('#22a7ea', "#22a7ea") },
                { name: 'CTR(%)', type: 'line', data: this.prepare_series('ctr'), color: this.color_scheme("#9f7868", "#e9b791") },
                { name: 'Клики', type: 'line', data: this.prepare_series('clicks'), color: this.color_scheme("#ef6829", "#ef6829") },
                { name: 'CPC', type: 'line', data: this.prepare_series('cpc'), color: this.color_scheme("#054084", "#8731d0") },
                { name: 'Расход', type: 'line', data: this.prepare_series('sum'), color: this.color_scheme("#ea0e17", "#ea0e17") },
            ];
        },
    },
    methods: {
        color_scheme(color1, color2){
            if (this.$vuetify.theme.dark)
                return color2
            return color1
        },
        hidden_all(){
            this.seriesMain.forEach(item=>{
                this.$refs.chart.hideSeries(item.name)
            })
        },
        show_all(){
            this.seriesMain.forEach(item=>{
				this.$refs.chart.showSeries(item.name)
			})
		},
        prepare_series(field){
            const map_result =  this.date_chart.map(item => ({ x: item.date, y: item[field] ? item[field].toFixed(2) : 0 }))
            return map_result.sort(function(a, b){ return new Date(b.x) - new Date(a.x) })
        },
        async get_campaign_stats(){
            this.loading = true
            try {
                const response = await Advertisement.fetchClusterStats(this.campaign_id, this.keyword)
                this.data = response.data
            } catch (err) {
                console.log(err.data)
            }
            this.loading = false		
        },
    },
    async mounted(){
        await this.get_campaign_stats()
    }
}
</script>
