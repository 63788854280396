<template>
	<div class="py-5">
		<div class="filters px-5">
			<v-row>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_begin_after" 
						:close-on-content-click="false"  
						prepend-icon="mdi-calendar"
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px"
						ref="datePicker.datepicker_date_begin_after"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_begin_after = null"
								v-model="dateBeginFormattedAfter"
								label="Начало периода (с)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								v-on="on"
								dense
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_begin_after" 
							@input="datePicker.datepicker_date_begin_after = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_beggin_before" 
						:close-on-content-click="false" 
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px" 
						ref="datePicker.datepicker_date_begin_before"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_begin_before = null"
								v-model="dateBeginFormattedBefore"
								label="Начало периода (до)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								dense
								v-on="on"
							>  
							</v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_begin_before" 
							@input="datePicker.datepicker_date_begin_before = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_end_after" 
						:close-on-content-click="false"  
						prepend-icon="mdi-calendar"
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px"
						ref="datePicker.datepicker_date_end_after"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_end_after = null"
								v-model="dateEndFormattedAfter"
								label="Конец периода (с)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								v-on="on"
								dense
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_end_after" 
							@input="datePicker.datepicker_date_end_after = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_end_before" 
						:close-on-content-click="false" 
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px" 
						ref="datePicker.datepicker_date_end_before"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_end_before = null"
								v-model="dateEndFormattedBefore"
								label="Конец периода (до)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								dense
								v-on="on"
							>  
							</v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_end_before" 
							@input="datePicker.datepicker_date_end_before = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-autocomplete
						v-model="filters.account"
						:items="accounts"
						item-text="name"
						item-value="value"
						multiple
						label="Аккаунт"
						clearable
						outlined
						hide-details
						dense
					></v-autocomplete>
				</v-col>
				<v-col cols="12" sm="1">
					<v-text-field
						v-model="filters.report_id"
						clearable
						outlined
						dense
						label="№ отчета"
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="12" sm="1">
					<v-btn
						depressed
						color="purple darken-2"
						dark
						@click="fetchFinanceReports"
					>Применить</v-btn>
				</v-col>
			</v-row>
		</div>
		<v-data-table
			ref="start_block"
			:headers="table.headers"
			:pageCount="pagination.numberOfPages"
			:items="table.data"
			:loading="table.loading"
			:options.sync="options"
			:items-per-page="25"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border small-font mt-5"
			:footer-props="{
				'items-per-page-options': [5, 15, 25, 50, 100]
			}"
        >
			<template v-slot:item.orders_amount="{ item }">
                {{ item.orders_amount | toRUB }}
            </template>
			<template v-slot:item.returns_amount="{ item }">
                {{ item.returns_amount | toRUB }}
            </template>
			<template v-slot:item.commission_amount="{ item }">
                {{ item.commission_amount | toRUB }}
            </template>
			<template v-slot:item.services_amount="{ item }">
                {{ item.services_amount | toRUB }}
            </template>
			<template v-slot:item.item_delivery_and_return_amount="{ item }">
                {{ item.item_delivery_and_return_amount | toRUB }}
            </template>
		</v-data-table>
	</div>
</template>

<script>

import { Stats } from '@/api/stats'
import { General } from '@/api/general'

export default {
	name: 'OzonFinanceReports',
	data() {
		return {
			table: {
				data: [],
				headers: [
					{ text: '№ отчета', value: 'report_id', sortable: true, width: '1%' },
					{ text: 'Аккаунт', value: 'account', sortable: true, width: '1%' },
					{ text: 'Начало периода', value: 'date_begin', sortable: true, width: '1%' },
					{ text: 'Конец периода', value: 'date_end', sortable: true, width: '1%' },
					{ text: 'Сумма цен реализованных товаров', value: 'orders_amount', sortable: true, width: '1%' },
					{ text: 'Сумма цен возвращённых товаров', value: 'returns_amount', sortable: true, width: '1%' },
					{ text: 'Комиссия Ozon за реализацию товаров', value: 'commission_amount', sortable: true, width: '1%' },
					{ text: 'Сумма дополнительных услуг', value: 'services_amount', sortable: true, width: '1%' },
					{ text: 'Сумма услуг логистики', value: 'item_delivery_and_return_amount', sortable: true, width: '1%' },
					{ text: 'Код валюты', value: 'currency_code', sortable: false, width: '1%' },
				],
				loading: false
			},
			first_init: false,
			options: {
				sortBy: ['date_begin'],
				sortDesc: [true]
			},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			filters:{
				report_id: null,
				account: null,
				date_begin_after: "",
                date_begin_before: "",
				date_end_after: "",
                date_end_before: "",
			},
			accounts: [],
			datePicker: {
                datepicker_date_begin_after: "",
                datepicker_date_begin_before: "",
				datepicker_date_end_after: "",
                datepicker_date_end_before: "",
            },
		}
	},
	computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
		dateBeginFormattedAfter () {
            return this.filters.date_begin_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_begin_after) : null
        },
        dateBeginFormattedBefore () {
            return this.filters.date_begin_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_begin_before) : null
        },
		dateEndFormattedAfter () {
            return this.filters.date_end_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_end_after) : null
        },
        dateEndFormattedBefore () {
            return this.filters.date_end_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_end_before) : null
        },
	},
	watch: {
		'options': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.fetchFinanceReports()
			}
		},
	},
	methods: {
		async fetchFinanceReports(){
			this.table.loading = true
			this.$vuetify.goTo(this.$refs.start_block, {
				duration: 500,
				offset: 150,
				easing: 'linear',
			})
			const { page, itemsPerPage, sortBy, sortDesc } = this.options
			let pageNumber = page - 1
			try {
				const response = await Stats.fetchOzonFinanceReports(
					{
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
						...this.transfromFiltersForQuery
					}
				)
				this.table.data = response.data.results
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.log(err.data)
			}
			this.table.loading = false
		},
		async fetchSuppliers(){
            try {
                const responce = await General.fetchSellersChoices()
                this.accounts = responce.data
            } catch (err) {
                console.log(err)
            }
        },
	},
	async mounted() {
		document.title = 'Финансовые отчеты Ozon | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Финансовые отчеты Ozon`);
		await this.fetchFinanceReports()
		this.first_init = true
		await this.fetchSuppliers()
	},
};
</script>

<style scoped>

</style>