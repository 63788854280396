<template>
    <div>
		<div class="d-flex">
			<v-dialog
				width="700"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						@click="getRedemptionTypeStats()"
						icon
						title="Сводка по типам выкупа"
						color="grey"
						v-bind="attrs"
						v-on="on"
						class="ml-5"
						dark
					>
						<v-icon dark>
							mdi-poll
						</v-icon>
					</v-btn>
				</template>

				<v-card>
					<v-card-title class="text-h6 darken-2">
						Сводка по типам выкупа
					</v-card-title>
					<div style="position:relative;">
						<v-data-table 
							v-if="redemption_type_stats"
							class="elevation-1 pb-5 mt-3"
							:class="{'loading': redemption_type_stats_loading}"
							dense
							:headers="redemptionTypeStatsHeader"
							:items="redemption_type_stats"
							hide-default-footer
						></v-data-table>
					</div>
				</v-card>
			</v-dialog>
			<router-link 
				:to="{name: 'selection_account_redemption'}" 
				class="d-flex ml-5 align-center"
				target="_blank"
			>
				<v-icon>mdi-account-arrow-right</v-icon>
			</router-link>
		</div>
        
        <RedemptionFilters @filtersData='filtersData'/>
		<v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <v-data-table
			ref="block_redemption_product"
            :headers="dataTable.headers"
            :items="dataTable.listRedemption"
            :loading="tableLoading"
            class="elevation-0 mt-0 vertical-border small-font"
            v-else
            :pageCount="pagination.numberOfPages"
            :options.sync="options"
            :items-per-page="20"
            :footer-props="{
                'items-per-page-options': [5, 10, 20, 50]
            }"
            :server-items-length="pagination.totalItems"
        >
            
            <template v-slot:item.product.nmid="{ item }">
                <router-link 
                    :to="{ name: 'product_card', params: { id: item.product.nmid }}"
                    target="_blank"
                >
                    {{ item.product.nmid }}
                </router-link>
            </template>
            <template v-slot:item.date_order="{ item }">
                {{ showDate(item.date_order)  }}
            </template>
            <template v-slot:item.real_price="{ item }">
                
                <div v-if="item.real_price">
                    {{ item.real_price.calculated_price ? item.real_price.calculated_price : "" }} ({{ item.real_price.real_price }})
                </div>
                <div v-else>-</div>
            </template>        
            <template v-slot:item.date_buy="{ item }">
                {{ showDate(item.date_buy)  }}
            </template>
            <template v-slot:item.phone="{ item }">
                {{phoneMask(item.phone)}}
				<div>
					<v-icon small v-if="!item.sim_card_is_active" title="Симкарта не работает">
						mdi-sim-off
					</v-icon>
					<v-icon small v-if="item.is_virtual_sim_card" title="Виртуальная сим карта">
						mdi-virtual-reality
					</v-icon>
					<v-icon small v-if="item.is_refusal_to_receive" title="Отказ от получения">
						mdi-cancel
					</v-icon>
				</div>
            </template>
            <template v-slot:item.cashback="{ item }">
                <div v-if="item.cashback">
                    {{item.cashback}}% ({{item.cashback_in_money}}руб.)
                </div>
            </template>
            <template v-slot:item.product.art="{ item }">
                <v-tooltip bottom max-width=350>
                    <template v-slot:activator="{ on, attrs }">
                        <div 
                            v-bind="attrs" 
                            v-on="on" 
                            class="art_product" 
                        >
                            {{item.product.art}} {{transformProductSize(item.variations)}}
                        </div>
                    </template>
                    <span>{{item.product.art}} {{transformProductSize(item.variations)}}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.feedback.source="{ item }">
                <div v-if="isRedemptionManager && item.feedback.source">
                    <v-select 
                        :value="item.feedback.base_source"
                        :items="sourceFeedback"
                        item-text="name"
                        @change="updateSourceFeedback($event, item)"
                        item-value="value"
                        label="Источник отзыва"
                        hide-details
                        outlined
                        dense
                        style="font-size: .8rem;"
                    ></v-select>
                </div>
                <div v-else>{{item.feedback.source}}</div>
            </template>
            <template v-slot:item.feedback.text="{ item }">
                <div v-if="item.feedback.text">
                    <v-tooltip bottom max-width=350>
                        <template v-slot:activator="{ on, attrs }">
                            <div 
                                v-bind="attrs" 
                                v-on="on" 
                                class="art_product" 
                            >
                                {{item.feedback.text}}
                            </div>
                        </template>
                        <span>{{item.feedback.text}}</span>
                    </v-tooltip>
                </div>
				<div v-else-if="item.type_redemption == 'Отзыв' && item.feedback_deleted_from_lk">
					<v-icon
						small
						title="Отзыв удален"
					>
						mdi-file-document-remove-outline
					</v-icon> Отзыв удален с ЛК
				</div>
                <div v-else>
                    <v-autocomplete
                        v-if="item.type_redemption == 'Отзыв'"
                        :loading="loading_feedback_autocomplete[item.id]"
                        item-text="text"
                        item-value="uid"
                        :search-input.sync="search_feedback[item.id]"
                        cache-items
                        label="Отзыв"
                        tabindex="1"
                        outlined
                        hide-details
                        dense
                        style="font-size: .8rem;"
                        v-model="feedback_model[item.id]"
                        :items="feedback_items[item.id]"
                        @focus="currentFeedback(item)"
                        @change="saveFeedback(item)"
                    ></v-autocomplete>
                </div>
            </template>
            <template v-slot:item.report_sale="{ item }">
                <div v-for="(report, index) in item.report_sale" :key="index">
                    <div v-if="report.type.toLowerCase() === 'продажа'">
                        <v-tooltip bottom max-width=350>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    {{report.realizationreport_id}}
                                </div>
                            </template>
                            <span>{{showDate(report.date)}} | {{report.sum}}</span>
                        </v-tooltip>
                    </div>
                </div>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="mt-5">
                    <v-row class="justify-space-between" style="padding:0 24px 24px 24px">
                        <v-dialog
                            v-model="dialog_upload"
                            max-width="500px"
                            v-if="isRedemptionUser || isRedemptionManager"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    :loading="loading_upload"
                                >
                                    Импорт из Excel
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="pb-0">
                                    <v-file-input
                                        label="File input"
                                        outlined
                                        dense
                                        id="upload_input"
                                        class="pt-5"
                                        hide-details
                                    ></v-file-input>
                                    <p 
                                        class="text-download mt-3"
                                        @click="downloadTempateExport"
                                    >Скачать шаблон</p>
                                </v-card-text>
                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="dialog_upload = false"
                                    >
                                        Закрыть
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="uploadFromExel"
                                    >
                                        Импортировать
                                    </v-btn>    
                                </v-card-actions>
                            </v-card>  
                        </v-dialog>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="exportExcel"
                        >
                            Экспорт в excel
                        </v-btn>
                         <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="exportExcelWarehouse"
                        >
                            Экспорт для склада
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="autoBundleReport"
                            :loading="loading_bundle"

                        >
                            Автосвязка документа продажи
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            @click="startBundleCashback"
							:loading="loading_bundle_cashback"
                        >
                            Загрузка кешбэков
                        </v-btn>
                        <v-dialog
                            v-model="dialog"
                            max-width="500px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    dark
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Добавить выкуп
                                </v-btn>
                            </template>
                                <RedemptionFormCreate
                                    v-if="editedIndex === -1"
                                    @close="close"
                                />
                                <RedemptionFormUpdate
                                    v-else
                                    :item=item
                                    @close="close"
                                />
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    v-if="isRedemptionManager || isRedemptionUser"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    v-if="isRedemptionManager"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
				<v-icon
                    small
					class="mr-2"
                    v-if="isRedemptionManager"
					@click="changeFeedackDeleted(item)"
					title="Отзыв удален"
                >
                    mdi-file-document-remove-outline
                </v-icon>
				<v-icon
                    small
                    v-if="isRedemptionManager"
					@click="changeSimCard(item)"
					title="Симкарта не работает"
                >
					mdi-sim-off
                </v-icon>
				<v-icon
                    small
                    v-if="isRedemptionManager"
					@click="changeVirtualSimCard(item)"
					title="Виртуальная сим карта"
                >
					mdi-virtual-reality
                </v-icon>
				<v-icon
                    small
                    v-if="isRedemptionManager"
					@click="changeRefusalReceive(item)"
					title="Отказ от получения"
                >
					mdi-cancel
                </v-icon>
            </template>
        </v-data-table>
        <div class="stat px-5 pt-5 pb-5">
            <div class="body-1">Общая сумма заказа - {{stats.sum_order}}</div>
            <div class="body-1">Общее количество товаров - {{stats.sum_quantity}}</div>
            <div class="body-1">Общее количество выкупов - {{stats.count_redemption}}</div>
        </div>
    </div>
</template>

<script>

import { Redemption } from '@/api/redemption'
import RedemptionFilters from '@/components/Redemption/RedemptionFilters'
import RedemptionFormCreate from '@/components/Redemption/RedemptionFormCreate'
import RedemptionFormUpdate from '@/components/Redemption/RedemptionFormUpdate'

import _ from 'lodash';

export default {
    name: 'RedemptionMain',
    components: {
        RedemptionFilters,
        RedemptionFormCreate,
        RedemptionFormUpdate
    },
    data () {
      return {
        loading_bundle_cashback: false,
        redemption_type_stats_loading: false,
        redemption_type_stats: null,
        first_init: false,
        type_redemption_choise: [
            {'name': 'Выкуп', 'value': 'REDEMPTION'},
            {'name': 'Отзыв', 'value': 'FEEDBACK'},
            {'name': 'Раздача', 'value': 'DISTRIBUTION'}
        ],
        redemptionTypeStatsHeader: [
            {text: 'Тип', value: 'type_by_name',  sortable: false},
            {text: 'Всего', value: 'total',  sortable: false},
            {text: 'С документом', value: 'with_report',  sortable: false},
            {text: 'Без документа', value: 'without_report',  sortable: false},
        ],
        list_manager: [],
        current_feedback: "",
        feedback_model:{},
        search_feedback: {},
        feedback_items: {},
        loading_feedback_autocomplete: {},
        loading_bundle: false,
        loading_upload: false,
        dialog_upload: false,
        current_delete: null,
        dialogDelete: false,
        initLoading: false,
        stats: {
            sum_order:0,
            sum_quantity: 0,
            count_redemption: 0
        },
        changedSourceFeedback:null,
        sourceFeedback: null,
        filters_str: {},
        tableLoading: false,
        pagination: {
            next: null,
            totalItems: 0,
            numberOfPages: 0,
            currentPage: 0
        },
        options: {},
        dataTable: {
			headers: [
                {text: 'ID', align: 'start', value: 'id', width: '1%'},
				{text: 'Артикул товара', align: 'start', value: 'product.nmid', width: '1%', sortable: false},
				{text: 'Артикул 1С', align: 'start', value: 'code_1c', width: '1%', sortable: false},
                {text: 'Наименование товара', align: 'start', value: 'product.art', width: '1%'},
				{text: 'Кол-во товаров', align: 'start', sortable: false, value: 'quantity_product', width: '1%'},
				{text: 'Вид выкупа', align: 'start', sortable: false, value: 'type_redemption', width: '1%'},
				{text: 'Дата заказа', align: 'start', value: 'date_order', width: '1%'},
				{ text: 'Сумма выкупа', value: 'sum_order', width: '1%' },
                { text: 'Цена выкупа за шт', value: 'real_price', width: '1%', sortable: false },
				{ text: 'Кешбек', value: 'cashback', width: '1%', sortable: false },
				{ text: 'Менеджер', value: 'manager', width: '1%', sortable: false },
				{ text: 'ФИО покупателя', value: 'account_buyer', width: '1%', sortable: false },
                { text: 'Номер аккаунта', value: 'phone', width: '2%', sortable: false },
				{ text: 'Дата покупки', value: 'date_buy', width: '1%'},
                { text: 'Статус отзыва', value: 'feedback.deleted', width: '1%', sortable: false },
                { text: 'Текст отзыва', value: 'feedback.text', width: '7%', sortable: false },
                { text: 'Источник отзыва', value: 'feedback.source', width: '1%', sortable: false },
				{ text: 'Адрес ПВЗ', value: 'address_pvz', width: '1%', sortable: false },
                { text: 'Документ продажи', value: 'report_sale', width: '1%', sortable: false },
                { text: 'Расход', value: 'expenses', width: '1%', sortable: false },
                { text: 'Действия', value: 'actions', sortable: false, width: '1%' },
			],
			listRedemption:[]
        },
        dialog: false,
        editedIndex: -1,
        item: null,
      }
    },
    computed: {
        isRedemptionManager() {
            return this.$store.getters.isRedemptionManager || false
        },
        isRedemptionUser() {
            return this.$store.getters.isRedemptionUser || false
        }
    },
    watch: {
		'options': async function (newVal, oldVal) {
			let change_sort_by = oldVal.sortBy != newVal.sortBy
			let change_sort_desc = oldVal.sortDesc != newVal.sortDesc
			let change_page= oldVal.page != newVal.page
			if ((change_sort_by || change_sort_desc || change_page) && this.first_init) {
				await this.getRedemption()
			}
        },
        dialog (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val
        },
        search_feedback:{
            deep: true,
            handler(val){
                if (val[this.current_feedback] && val[this.current_feedback].length > 2){
                    this.loading_feedback_autocomplete[this.current_feedback] = true
                    this.debouncedGetFilteredFeedback(val[this.current_feedback])
                }
            }
        }
    },

    methods: {
        async startBundleCashback(){
            this.loading_bundle_cashback = true
            try{
                await Redemption.startFetchRedemptionDistribution()
            } catch (err){
                console.log(err)
            }
            this.$toasted.show(`Задача по получению выкупов запущена`, {
                theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
            });
            this.loading_bundle_cashback = false
        },
		async changeFeedackDeleted(item){
			try {
                await Redemption.PutRedemption(item.id, 'feedback_deleted')
                this.getRedemption()
            }
            catch (err){
                console.log(err)
            }
		},
		async changeSimCard(item){
			try{
				await Redemption.PutRedemption(item.id, 'sim_card')
				this.getRedemption()
			} catch (err){
				console.log(err)
			}
		},
		async changeVirtualSimCard(item){
			try{
				await Redemption.PutRedemption(item.id, 'virtual_sim_card')
				this.getRedemption()
			} catch (err){
				console.log(err)
			}
		},
		async changeRefusalReceive(item){
			try{
				await Redemption.PutRedemption(item.id, 'is_refusal_to_receive')
				this.getRedemption()
			} catch (err){
				console.log(err)
			}
		},
        transformProductSize(variations){
            if (variations)
                return "( " + variations.size + " )"
            return ""
        },
        async downloadTempateExport(){
            try {
                const response = await Redemption.downloadTemplateRedemption()
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'template.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
        async exportExcel(){
            try {
                const response = await Redemption.exportRedemptionToExel({
                    ...this.filters_str
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'redemption.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
        async exportExcelWarehouse(){
            try {
                const response = await Redemption.exportRedemptionToExel({
                    ...this.filters_str,
                    for_warehouse: true
                })
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'redemption.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
        },
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.list_manager = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
        async saveFeedback(item){
            let redemption_id = item.id
            let manager = this.list_manager.find(obj => obj['username'] === item.manager)
            let type_redemption = this.type_redemption_choise.find(obj => obj['name'] === item.type_redemption).value
            let report_sale = []
            item.report_sale.forEach(el => {
                if (el.type === 'Продажа') {
                    report_sale.push(el.id)
                }
            })
            const data = {
                'feedback': this.feedback_model[item.id],
                "product": item.product_id,
                "quantity_product": item.quantity_product,
                "sum_order": item.sum_order,
                "cashback": item.cashback,
                "manager": manager.id,
                "type_redemption": type_redemption,
                "date_order": new Date(item.date_order),
                "report_sale": report_sale,
            }
            try{
                const response = await Redemption.updateRedemption(redemption_id, data)
				if (response.status == 200){
					this.$toasted.show(`Отзыв успешно прикреплен`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-center',
						duration: 3000,
					});
					console.log(response.data)
				}
				else{
					this.$toasted.show(`Ошибка прикрепления отзыва`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
				}
                this.getRedemption()
            } catch (err){
                console.log(err)
				this.$toasted.show(`Ошибка прикрепления отзыва`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-center',
					duration: 3000,
				});
            }   
        },
        currentFeedback(item){
            this.current_feedback = item.id
        },
        async getFilteredFeedback(val){
            let page_size = 15
            let product_id = this.dataTable.listRedemption.find(el => el.id == this.current_feedback).product_id
            try {
                let query = {
                    'id': product_id,
                    'text': val,
                    'page_size': page_size 
                }
                const response = await Redemption.fetchRedemptionFeedback(query)
                
                this.feedback_items[this.current_feedback] = response.data.results
            } catch (err) {
                console.log(err.data)
            }     
            this.loading_feedback_autocomplete = {}    
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        phoneMask(phone){
            if(phone)
                return "+7(" + phone.substring(1, 4) + ") " + phone.substring(4, 7) + "-" + phone.substring(7,9) + "-" + phone.substring(9,11) 
            return "-"
        },
        deleteItem (item) {
            this.current_delete = item.id
            this.dialogDelete = true
        },  
        async deleteItemConfirm () {
            try {
                const response = await Redemption.deleteRedemption(this.current_delete)
                this.getRedemption()
            }
            catch (err){
                console.log(err)
            }
            this.dialogDelete = false
        },
        async uploadFromExel(){
            let fileToUpload = document.getElementById('upload_input').files[0]
            let formData = new FormData()
            formData.append('fileToUpload', fileToUpload)

            try {
                this.loading_upload = true
                this.dialog_upload = false
                const response = await Redemption.uploadRedemptionFromExel(
                    formData
                )
                this.loading_upload = false
                this.getRedemption()
            }
            catch (err){
                console.log(err)
            }
        },
        async autoBundleReport(){
            return await Redemption.setReportRedemption()
        },
        showDate(date){
            return date  ? this.$options.filters.formatDateToLocaleNoTime(date ) : null
        },
        async updateSourceFeedback(e, item){            
            try{
                const response = await Redemption.updateSourceFeedback(item.feedback.id, {"source": e})
                this.getRedemption()
            }
            catch (err) {
                console.log(err.data)
            }
        },
        filtersData(query){
            this.filters_str = query
            this.options.page = 1
            this.getRedemption()
        },
        editItem (item) {
            this.editedIndex = this.dataTable.listRedemption.indexOf(item)
            this.item = item
            this.dialog = true
        },
        async close (status="") {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
            if (status === "success")
                await this.getRedemption()
        },
        async getRedemption () {
            this.tableLoading = true
			if (this.dataTable.listRedemption.length != 0)
				this.scrollToTop()
            const { page, itemsPerPage, sortBy, sortDesc } = this.options;
            try {
                const response = await Redemption.fetchRedemption({
                    ...this.filters_str,
                    'page': page,
                    'page_size': itemsPerPage,
                    'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]

                })
                
                this.dataTable.listRedemption = response.data.results.objects
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.stats.sum_order = response.data.results.stats.sum_order
                this.stats.sum_quantity = response.data.results.stats.sum_quantity
                this.stats.count_redemption = response.data.count
            } catch (err) {
                console.log(err.data)
            }
            this.tableLoading = false
        },
        async getBaseSourceFeedback(){
            try {
                const responce = await Redemption.fetchBaseSourceFeedback()
                this.sourceFeedback = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
        async getRedemptionTypeStats() {
                this.redemption_type_stats_loading = true
                try {
                    const response = await Redemption.redemptionTypeStats({
                        ...this.filters_str
                    })
                    this.redemption_type_stats = response.data.results
                } catch (err) {
                    console.error(err)
                }
                this.redemption_type_stats_loading = false
            },
    },
    created: function () {
        this.debouncedGetFilteredFeedback = _.debounce(this.getFilteredFeedback, 1000)
    }, 
    async mounted () {
		this.initLoading = true
        await this.getBaseSourceFeedback()
		await this.getManagers()
		await this.getRedemption()
		this.initLoading = false
        this.first_init = true
    },
  }
  
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .field-name{
        font-size: .7rem;
        color: #888888;
    }
    .v-data-table{
        margin-top: 50px;
    }
    .art_product{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 0.775rem;        
    }
    .text-download{
        cursor: pointer;
        &:hover{
            text-decoration: underline;
        }
    }
</style>
