import store from '@/store'

import App from '@/App'
import Login from '@/views/Login'

const authRoutes ={
  path: '/auth',
  name: 'Auth',
  component: App,
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login
    },
    {
      path: 'logout',
      name: 'logout',
      beforeEnter () {
        store.dispatch('logout')
      }
    }
  ]
}

export default authRoutes
