import BaseLayout from '@/views/BaseLayout';
import Feedbacks from '@/views/Feedbacks';
import Feedback from '@/views/Feedback';
import FeedbacksTemplateResponseView from '@/views/FeedbacksTemplateResponseView'
import Products from '@/views/Products';
import Product from '@/views/Product';
import ProductRecommendationStock from '@/views/ProductRecommendationStock';
import SalesReturns from '@/views/SalesReturns';
import Orders from '@/views/Orders';
import Sales from '@/views/Sales';
import Dashboard from '@/views/Dashboard';
import Analytics from '@/views/Analytics';
import AdministrationMain from '@/views/Administration/Main';
import Supples from '@/views/Supples';
import Catalog from '@/views/Catalog';
import Redemption from '@/views/Redemption';
import RedemptionOzon from '@/views/RedemptionOzon';
import RedemptionReceiption from '@/views/RedemptionReceiption';
import OzonRedemptionReceiption from '@/views/OzonRedemptionReceiption';
import RedemptionSelectionAccount from '@/views/RedemptionSelectionAccount'
import ProductsOzon from '@/views/Products/ProductsOzon';
import ProductsOzonCard from '@/views/Products/ProductsOzonCard';
import ProductsOzonIndices from '@/views/Products/ProductsOzonIndices'
import ProductsWBIndices from '@/views/ProductsWBIndices'
import ProductCharPage from '@/views/ProductCharPage';
import KitsComponents from '@/views/KitsComponents';
import KitsComponentsItem from '@/views/KitsComponentsItem';
import PriceManagementTemplates from '@/views/PriceManagement/PriceManagementTemplates'
import PriceManagementCreateTemplates from '@/views/PriceManagement/PriceManagementCreateTemplates'
import PriceManagementAdminTemplate from '@/views/PriceManagement/PriceManagementAdminTemplate'
import PriceManagementChangePrice from '@/views/PriceManagement/PriceManagementChangePrice'
import AdvertisementNew from '@/views/AdvertisementNew';
import AdvertisementNewDetail from '@/views/AdvertisementNewDetail';
import AdvertisementNewStats from '@/views/AdvertisementNewStats';
import AdvertisementNewStatsV2 from '@/views/AdvertisementNewStatsV2';
import AdvertisementCreateViewOld from '@/views/AdvertisementCreateViewOld';
import AdvertisementNewCreateAuto from '@/views/AdvertisementNewCreateAuto'
import OzonAdvertisement from '@/views/OzonAdvertisement'
import OzonAdvertisementNew from '@/views/OzonAdvertisementNew'
import OzonAdvertisementDetail from '@/views/OzonAdvertisementDetail'
import OzonAdvertisementDetailNew from '@/views/OzonAdvertisementDetailNew'
import OzonAdvertisementKeywords from '@/views/OzonAdvertisementKeywords'
import OzonAdvertisementAllStats from '@/views/OzonAdvertisementAllStats'
import CatalogTax from '@/views/CatalogTax'
import CatalogRedemptionPercent from '@/views/CatalogRedemptionPercent'
import WeeklyWbReports from '@/views/WeeklyWbReports'
import RegionSalesReportViews from '@/views/RegionSalesReportViews'
import OzonFinanceReports from '@/views/OzonFinanceReports'
import ManagementWbProductList from '@/views/ManagementWbProductViews/ManagementWbProductList'
import ManagementWbProductTemplates from '@/views/ManagementWbProductViews/ManagementWbProductTemplates'
import ManagementWbProductMain from '@/views/ManagementWbProductViews/ManagementWbProductMain'
import ManagementWbProductDelayedTask from '@/views/ManagementWbProductViews/ManagementWbProductDelayedTask'
import ManagementWbChangeList from '@/views/ManagementWbProductViews/ManagementWbChangeList'
import ManagementOzonProductCreate from '@/views/ManagementOzonProductViews/ManagementOzonProductCreate'
import ManagementOzonProductDetail from '@/views/ManagementOzonProductViews/ManagementOzonProductDetail'
import ManagementOzonProductChange from '@/views/ManagementOzonProductViews/ManagementOzonProductChange'
import ManagementOzonProductList from '@/views/ManagementOzonProductViews/ManagementOzonProductList'
import AdvertisementNewSearchPluralCreate from '@/views/AdvertisementNew/AdvertisementNewSearchPluralCreate'
import Incomes from '../../views/Incomes';
import PriceMonitoringPage from '@/views/PriceMonitoring/PriceMonitoringPage'
import OzonTransactionsReportsView from '@/views/OzonTransactionsReports/OzonTransactionsReportsView.vue';
import AdvertisementListView from '@/views/Advertisement/AdvertisementListView'
import AdvertisementStatsView from '@/views/Advertisement/AdvertisementStatsView'
import AdvertisementDetailView from '@/views/Advertisement/AdvertisementDetailView'
import AdvertisementCreateView from '@/views/Advertisement/AdvertisementCreateView'
import AdvertisementTaskHistory from '@/views/Advertisement/AdvertisementTaskHistory'
import DetmirProductsList from '@/views/DetmirProducts/DetmirProductsList'
import DetmirProductsDetail from '@/views/DetmirProducts/DetmirProductsDetail'
import ProductPromotionWB from '@/views/ProductPromotionWB'
import ProductPromotionOzon from '@/views/ProductPromotionOzon'
import SupplyPlanningList from '@/views/SupplyPlanning/SupplyPlanningList'
import SupplyPlanningCreate from '@/views/SupplyPlanning/SupplyPlanningCreate'
import SupplyPlanningDetail from '@/views/SupplyPlanning/SupplyPlanningDetail'
import OzonSupplyPlanningCreate from '@/views/SupplyPlanning/OzonSupplyPlanningCreate'
import OzonSupplyPlanningDetail from '@/views/SupplyPlanning/OzonSupplyPlanningDetail'
import PlannedUnitEconomicView from '@/views/PlannedUnitEconomic/PlannedUnitEconomicView'
import DynamicIndicatorsDay from '@/views/DynamicIndicatorsDay'
import OzonDynamicIndicatorsDay from '@/views/OzonDynamicIndicatorsDay'
import SupplCheckFreeCoef from '@/views/SupplCheckFreeCoef'

const generalRoutes = {
	path: '/',
	name: 'Home',
	component: BaseLayout,
	meta: {
		requiresAuth: true,
	},
	children: [
		{
			key: 'dashboard',
			name: 'dashboard',
			path: '/',
			component: Dashboard,
		},
		{
			key: 'feedbacks',
			name: 'feedbacks',
			path: 'feedbacks',
			component: Feedbacks,
		},
		{
			key: 'feedbacks_template_response',
			name: 'feedbacks_template_response',
			path: 'feedbacks/template_response',
			component: FeedbacksTemplateResponseView,
		},
		{
			path: 'feedbacks/:id',
			name: 'singleFeedback',
			component: Feedback,
			props: true,
		},
		{
			key: 'products',
			path: 'products',
			name: 'products',
			component: Products,
		},
		{
			path: 'products/indices',
			name: 'product_indices',
			component: ProductsWBIndices,
		},
		{
			path: 'products/promotions',
			name: 'wb_product_promotions',
			component: ProductPromotionWB,
		},
		{
			path: 'ozon-products/promotions',
			name: 'ozon_product_promotions',
			component: ProductPromotionOzon,
		},
		{
			path: 'products/:id',
			name: 'product_card',
			component: Product,
		},
		{
			path: 'products/:id/char',
			name: 'product_char',
			component: ProductCharPage,
		},
		{
			path: 'products/:id/get_recommendation_stocks',
			name: 'product_recommendation_stock',
			component: ProductRecommendationStock,
		},
		{
			key: 'ozon-products',
			path: 'ozon-products',
			name: 'ozon-products',
			component: ProductsOzon,
		},
		{
			path: 'ozon-products/indices',
			name: 'ozon_product_indices',
			component: ProductsOzonIndices,
		},
		{
			path: 'ozon-products/:id',
			name: 'ozon_product_card',
			component: ProductsOzonCard,
		},
		{
			key: 'detmir-products',
			path: 'detmir-products',
			name: 'detmir-products',
			component: DetmirProductsList,
		},
		{
			key: 'detmir-product-detail',
			path: 'detmir-products/:id',
			name: 'detmir-product-detail',
			component: DetmirProductsDetail,
		},
		{
			key: 'kits-components',
			path: 'kits-components',
			name: 'kits-components',
			component: KitsComponents,
		},
		{
			path: 'kits-components/:id',
			name: 'kits_component_card',
			component: KitsComponentsItem,
		},
		{
			key: 'ozon-redemption',
			path: 'ozon-redemtion',
			name: 'ozon-redemption',
			component: RedemptionOzon,
		},
		{
			key: 'redemption',
			path: 'redemption',
			name: 'redemption',
			component: Redemption,
		},
		{
			key: 'selection_account_redemption',
			path: 'redemption/selection_account',
			name: 'selection_account_redemption',
			component: RedemptionSelectionAccount,
		},

		{
			key: 'orders',
			path: 'orders',
			name: 'orders',
			component: Orders,
		},
		{
			key: 'sales',
			path: 'sales',
			name: 'sales',
			component: Sales,
		},
		{
			key: 'analytics',
			path: 'analytics',
			name: 'analytics',
			component: Analytics,
		},
		{
			key: 'supples',
			path: 'supples',
			name: 'supples',
			component: Supples,
		},
		{
			key: 'supply_planning_list',
			path: 'supply_planning',
			name: 'supply_planning_list',
			component: SupplyPlanningList,
		},
		{
			key: 'supply_planning_create',
			path: 'supply_planning/create',
			name: 'supply_planning_create',
			component: SupplyPlanningCreate,
		},
		{
			key: 'supply_planning_detail',
			path: 'supply_planning/:id',
			name: 'supply_planning_detail',
			component: SupplyPlanningDetail,
		},
		{
			key: 'ozon_supply_planning_create',
			path: 'ozon/supply_planning/create',
			name: 'ozon_supply_planning_create',
			component: OzonSupplyPlanningCreate,
		},
		{
			key: 'ozon_supply_planning_detail',
			path: 'ozon/supply_planning/:id',
			name: 'ozon_supply_planning_detail',
			component: OzonSupplyPlanningDetail,
		},
		{
			key: 'incomes-from-wb',
			path: 'incomes-from-wb',
			name: 'incomes-from-wb',
			component: Incomes,
		},
		{
			key: 'catalog',
			path: 'catalog',
			name: 'catalog',
			component: Catalog,
		},
		{
			key: 'catalog_tax',
			path: 'catalog/tax',
			name: 'catalog_tax',
			component: CatalogTax
		},
		{
			key: 'catalog_redemption_percent',
			path: 'catalog/redemption_pecent',
			name: 'catalog_redemption_percent',
			component: CatalogRedemptionPercent
		},
		{
			key: 'advertisement_new',
			name: 'advertisement_new',
			path: 'advertisement_new',
			component: AdvertisementNew,
		},
		{
			name: 'advertisement_new_stats',
			path: 'advertisement_new/stats',
			component: AdvertisementNewStats,
		},
		{
			name: 'advertisement_new_stats_v2',
			path: 'advertisement_new/stats_v2',
			component: AdvertisementNewStatsV2,
		},
		
		{
			name: 'advertisement_new_create',
			path: 'advertisement_new/create_new',
			component: AdvertisementCreateViewOld,
		},
		{
			name: 'advertisement_new_create_auto',
			path: 'advertisement_new/create_auto',
			component: AdvertisementNewCreateAuto
		},
		{
			name: 'advertisement_new_create_search_plural',
			path: 'advertisement_new/search/create',
			component: AdvertisementNewSearchPluralCreate,
		},
		{
			name: 'advertisement_new_detail',
			path: 'advertisement_new/:id',
			component: AdvertisementNewDetail,
		},
		{
			key: 'ozon_advertisement',
			name: 'ozon_advertisement',
			path: 'ozon-advertisement',
			component: OzonAdvertisement,
		},
		{
			key: 'ozon_advertisement_new',
			name: 'ozon_advertisement_new',
			path: 'ozon_advertisement_new',
			component: OzonAdvertisementNew,
		},
		{
			key: 'ozon_advertisement_stats',
			name: 'ozon_advertisement_stats',
			path: 'ozon_advertisement_new/stats',
			component: OzonAdvertisementAllStats,
		},
		{
			key: 'ozon_advertisement_detail',
			name: 'ozon_advertisement_detail',
			path: 'ozon-advertisement/:campaign_id',
			component: OzonAdvertisementDetail,
		},
		{
			key: 'ozon_advertisement_detail_new',
			name: 'ozon_advertisement_detail_new',
			path: 'ozon_advertisement_new/:campaign_id',
			component: OzonAdvertisementDetailNew,
		},
		{
			key: 'ozon_advertisement_keywords',
			name: 'ozon_advertisement_keywords',
			path: 'ozon_advertisement_new/:campaign_id/keywords/:product_id',
			component: OzonAdvertisementKeywords,
		},
		{
			key: 'redemption_receiption',
			name: 'redemption_receiption',
			path: 'redemption/receiption',
			component: RedemptionReceiption
		},
		{
			key: 'ozon_redemption_receiption',
			name: 'ozon_redemption_receiption',
			path: 'ozon-redemption/receiption',
			component: OzonRedemptionReceiption
		},
		{
			key: 'ozon_finance_report',
			name: 'ozon_finance_report',
			path:'ozon_finance_report',
			component: OzonFinanceReports
		},
		{
			key: 'weekly_wb_reports',
			name: 'weekly_wb_reports',
			path:'weekly_wb_reports',
			component: WeeklyWbReports
		},
		{
			key: 'region_sale_reports',
			name: 'region_sale_reports',
			path:'region-sale',
			component: RegionSalesReportViews
		},
		{
			key: 'management_product_list',
			name: 'management_product_list',
			path:'management_product',
			component: ManagementWbProductList
		},
		{
			key: 'change_management_product_template',
			name: 'change_management_product_template',
			path:'management_product/change/:id',
			component: ManagementWbChangeList
		},
		{
			key: 'management_product_templates',
			name: 'management_product_templates',
			path:'management_product/templates',
			component: ManagementWbProductTemplates
		},
		{
			key: 'management_product_delayed_task',
			name: 'management_product_delayed_task',
			path:'management_product/delayed_task',
			component: ManagementWbProductDelayedTask
		},
		{
			key: 'management_product_main',
			name: 'management_product_main',
			path:'management_product/:template_id',
			component: ManagementWbProductMain
		},
		{
			key: 'ozon_management_product_create',
			name: 'ozon_management_product_create',
			path:'ozon_management_product/create',
			component: ManagementOzonProductCreate
		},
		{
			key: 'ozon_management_product_list',
			name: 'ozon_management_product_list',
			path:'ozon_management_product/list',
			component: ManagementOzonProductList
		},
		{
			key: 'ozon_management_product_change',
			name: 'ozon_management_product_change',
			path:'ozon_management_product/change/:id',
			component: ManagementOzonProductChange
		},
		{
			key: 'ozon_management_product_detail',
			name: 'ozon_management_product_detail',
			path:'ozon_management_product/:template_id',
			component: ManagementOzonProductDetail
		},
		{
			key: 'administration',
			name: 'administration',
			path: 'administration',
			component: AdministrationMain,
		},
		{
			key: 'price_monitoring',
			name: 'price_monitoring',
			path: 'price_monitoring',
			component: PriceMonitoringPage,
		},
		{
			key: 'ozon_transactions_reports',
			name: 'ozon_transactions_reports',
			path: 'ozon_transactions_reports',
			component: OzonTransactionsReportsView,
		},
		{
			key: 'price_management_templates',
			name: 'price_management_templates',
			path:'price_management/templates',
			component: PriceManagementTemplates
		},
		{
			key: 'price_management_create_templates',
			name: 'price_management_create_templates',
			path:'price_management/templates/create',
			component: PriceManagementCreateTemplates
		},
		{
			key: 'price_management_change_price',
			name: 'price_management_change_price',
			path:'price_management/templates/:template_id/change_price',
			component: PriceManagementChangePrice
		},
		{
			key: 'price_management_detail_templates',
			name: 'price_management_detail_templates',
			path:'price_management/templates/:template_id',
			component: PriceManagementAdminTemplate
		},
		{
			key: 'advertisement_list',
			name: 'advertisement_list',
			path: 'advertisement',
			component: AdvertisementListView
		},
		{
			key: 'advertisement_stats',
			name: 'advertisement_stats',
			path: 'advertisement/stats',
			component: AdvertisementStatsView
		},
		{
			key: 'advertisement_create',
			name: 'advertisement_create',
			path: 'advertisement/create',
			component: AdvertisementCreateView
		},
		{
			key: 'advertisement_task_history',
			name: 'advertisement_task_history',
			path: 'advertisement/task_history',
			component: AdvertisementTaskHistory
		},
		{
			key: 'advertisement_detail',
			name: 'advertisement_detail',
			path: 'advertisement/:campaign_id',
			component: AdvertisementDetailView
		},
		{
			key: 'planned_unit_economics',
			name: 'planned_unit_economics',
			path: 'planned_unit_economics',
			component: PlannedUnitEconomicView
		},
		{
			key: 'dynamic_indicators_day',
			name: 'dynamic_indicators_day',
			path: 'dynamic_indicators_day',
			component: DynamicIndicatorsDay
		},
		{
			key: 'ozon_dynamic_indicators_day',
			name: 'ozon_dynamic_indicators_day',
			path: 'ozon_dynamic_indicators_day',
			component: OzonDynamicIndicatorsDay
		},
		{
			key: 'check_free_suppl_coef',
			name: 'check_free_suppl_coef',
			path: 'check_free_suppl_coef',
			component: SupplCheckFreeCoef
		}
	],
};

export default generalRoutes;
