<template>
    <div class="d-flex justify-center align-center pa-10">
        <apexchart type="pie" width="500" :options="options" :series="values"></apexchart>
    </div>
</template>

<script>
	import VueApexCharts from 'vue-apexcharts';

	export default {
		name: 'CoefficientChart',
		components: {
			apexchart: VueApexCharts,
		},
		props: [
			'values',
            'labels'
		],
		data() {
			return {
				// series: [44, 55, 13, 43, 22],
				chartOptions: {
					dataLabels: {
						enabled: true,
						formatter: function(val) {
							return val + "%"
						},
					},
					legend: {
						show: true,
					},
					chart: {
						width: 380,
						type: 'pie',
					},
					labels: ['Team As', 'Team B', 'Team C', 'Team D', 'Team E'],
					responsive: [
						{
							breakpoint: 480,
							options: {
								chart: {
									width: 200,
								},
								legend: {
									position: 'bottom',
								},
							},
						}],
				},
			};
		},
		computed: {
			options() {
				return {
					...this.chartOptions,
                    ...{"labels": this.labels}
				}
            }
		},
	};
</script>

<style scoped>

</style>