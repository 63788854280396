<template>
    <div class="competitors_height">
		<v-dialog v-model="dialog_detail" v-if="dialog_detail">
			<v-card>
				<v-card-title class="text-h5">
					Исторические данные по конкурентамм
				</v-card-title>
				<div>
					<CompetitorsDetailDialog :nmid="detail_info_active"></CompetitorsDetailDialog>
				</div>
			</v-card>
		</v-dialog>
        <v-data-table
                :headers="headers"
                :items="list_competitors"
                :loading="tableLoading"
                class="elevation-1 mt-0 competitors"
                single-expand
                :expanded.sync="expanded"
                show-expand
                hide-default-footer
                disable-pagination>
            >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div class="pb-5">
                        <KeywordTable
                                :product_id="item.art"
                                :key="item.id"
                                favorite="true"
                                not_show_admin="true"
                                ref='child_table'
                        />
                    </div>
                </td>
            </template>
			<template v-slot:item.is_favorite="{ item }">
                <v-switch
                    v-if="item.our"
                    color="primary"
                    class="mt-0"
                    hide-details
                    :input-value="item.is_favorite"
                    v-model="item.is_favorite"
                    @change="changeFavoriteCompetitor(item)"
                ></v-switch>                
            </template>
            <template v-slot:item.link_mpstats="{ item }">
                <a :href="getLinkMpStats(item.art)" target="_blank" style="padding: 0 16px;">
                    <v-icon>
                        mdi-magnify
                    </v-icon>
                </a>
            </template>
            <template v-slot:item.art="{ item }">
                <a
                        :href="`https://www.wildberries.ru/catalog/${item.art}/detail.aspx`"
                        target="_blank"
                        style="padding: 0 16px;"
                >
                    {{item.art}}
                </a>
            </template>
            <template v-slot:item.brand="{ item }">
                <div style="padding: 0 16px;">
                    {{item.brand != 0 ? item.brand : '-'}}
                </div>
            </template>
            <template v-slot:item.pic="{ item, index }">
                <div style="padding: 5px 16px 0 16px; position: relative;" class="img_competitors_container">
                    <img class="img_competitors" style="max-height:80px" :src="getImgPath(item.art)" alt="">
                    <img
                            class="img_competitors_big"
                            style="max-height:80px"
                            :src="getImgPath(item.art)"
                            alt=""
                            :class="{'bottomImg': isBottomScale(index)}"
                    >
                </div>
            </template>
            <template v-slot:item.price="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.price_new.price_with_spp < list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0, 
                        'good': item.price_new.price_with_spp > list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0,
                        'neutral': item.price_new.price_with_spp == list_competitors[0].price_new.price_with_spp && item.our && item.price_new.price_with_spp != 0,
                        'not-data': item.our && !list_competitors[0].price_new.price_with_spp,
                    }"
                >
                    {{(item.price_new.price_with_spp ? item.price_new.price_with_spp : '-' )|toRUB}}
                </div>
            </template>
            <template v-slot:item.price_without_spp="{ item }">
                <div
					style="padding: 0 16px; display: flex;"
					:class="{
						'bad': item.price_new.price_without_spp < list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0, 
						'good': item.price_new.price_without_spp > list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0,
						'neutral': item.price_new.price_without_spp == list_competitors[0].price_new.price_without_spp && item.our && item.price_new.price_without_spp != 0,
						'not-data': item.our && !list_competitors[0].price_new.price_without_spp,
					}"
                >
                    {{(item.price_new.price_without_spp ? item.price_new.price_without_spp : '-')|toRUB}}
					<v-tooltip bottom max-width=350 v-if="item.promotion_parser">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="ml-1">
								<a href="#" class="dashed small-text">(А)</a>
							</div>
						</template>
						<span>{{ item.promotion_parser }}</span>
					</v-tooltip>
				</div>
            </template>
            <template v-slot:item.spp="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'good': item.price_new.percent_spp < list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0, 
                        'bad': item.price_new.percent_spp > list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0,
                        'neutral': item.price_new.percent_spp == list_competitors[0].price_new.percent_spp && item.our && item.price_new.percent_spp != 0,
                        'not-data': item.our && !list_competitors[0].price_new.percent_spp,
                    }"
                >
                    {{item.price_new.percent_spp ? item.price_new.percent_spp : 0}}%
                </div>
            </template>
            <template v-slot:item.sales_30days="{ item }">
                <div
                        v-if="item.our"
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.sales_30days > list_competitors[0].sales_30days && item.our && item.sales_30days != 0, 
                        'good': item.sales_30days < list_competitors[0].sales_30days && item.our && item.sales_30days != 0,
                        'neutral': item.sales_30days == list_competitors[0].sales_30days && item.our && item.sales_30days != 0,
                        'not-data': item.our && !list_competitors[0].sales_30days,
                    }"
                >
                    {{(item.sales_30days != 0 ? item.sales_30days : '-') | toRUB}}
                </div>
                <v-tooltip bottom max-width=350 v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-bind="attrs"
                                v-on="on"
                                style="padding: 0 16px;"
                        >
                            {{(item.sales_30days != 0 ? item.sales_30days : '-')| toRUB}}
                        </div>
                    </template>
                    <span>
						<p>
							Сумма выкупов за 30 дней: {{item.sum_redemption.redemption_30days ? (item.sum_redemption.redemption_30days | toRUB) : 0}}
						</p>
						<p>
							Продажи без выкупов за 30 дней: {{(item.sales_30days - item.sum_redemption.redemption_30days) | toRUB}}
						</p>
					</span>
                </v-tooltip>
            </template>
            <template v-slot:item.delta_sales_30days="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.delta_sales_30days > list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0, 
                        'good': item.delta_sales_30days < list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0,
                        'neutral': item.delta_sales_30days == list_competitors[0].delta_sales_30days && item.our && item.delta_sales_30days != 0,
                        'not-data': item.our && !list_competitors[0].delta_sales_30days,
                    }"
                >
                    {{item.delta_sales_30days != 0 ? item.delta_sales_30days + '%' : '-'}}
                </div>
            </template>
            <template v-slot:item.sales_14days="{ item }">
                <div
                        v-if="item.our"
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.sales_14days > list_competitors[0].sales_14days && item.our && item.sales_14days != 0, 
                        'good': item.sales_14days < list_competitors[0].sales_14days && item.our && item.sales_14days != 0,
                        'neutral': item.sales_14days == list_competitors[0].sales_14days && item.our && item.sales_14days != 0,
                        'not-data': item.our && !list_competitors[0].sales_14days,
                    }"
                >
                    {{(item.sales_14days != 0 ? item.sales_14days : '-' ) | toRUB}}
                </div>
                <v-tooltip bottom max-width=350 v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-bind="attrs"
                                v-on="on"
                                style="padding: 0 16px;"
                        >
                            {{(item.sales_14days != 0 ? item.sales_14days : '-') | toRUB}}
                        </div>
                    </template>
                    <span>
						<p>
							Сумма выкупов за 14 дней: {{item.sum_redemption.redemption_14days ? item.sum_redemption.redemption_14days : 0}}
						</p>
						<p>
							Продажи без выкупов за 14 дней: {{item.sales_14days - item.sum_redemption.redemption_14days}}
						</p>
					</span>
                </v-tooltip>
            </template>
            <template v-slot:item.delta_sales_14days="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.delta_sales_14days > list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0, 
                        'good': item.delta_sales_14days < list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0,
                        'neutral': item.delta_sales_14days == list_competitors[0].delta_sales_14days && item.our && item.delta_sales_14days != 0,
                        'not-data': item.our && !list_competitors[0].delta_sales_14days,
                    }"
                >
                    {{item.delta_sales_14days != 0 ? item.delta_sales_14days + '%' : '-'}}
                </div>
            </template>
            <template v-slot:item.sales_7days="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.sales_7days > list_competitors[0].sales_7days && item.our && item.sales_7days != 0, 
                        'good': item.sales_7days < list_competitors[0].sales_7days && item.our && item.sales_7days != 0,
                        'neutral': item.sales_7days == list_competitors[0].sales_7days && item.our && item.sales_7days != 0,
                        'not-data': item.our && !list_competitors[0].sales_7days,
                    }"
                >
                    {{(item.sales_7days != 0 ? item.sales_7days : '-') |toRUB}}
                </div>
            </template>
            <template v-slot:item.delta_sales_7days="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.delta_sales_7days > list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0, 
                        'good': item.delta_sales_7days < list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0,
                        'neutral': item.delta_sales_7days == list_competitors[0].delta_sales_7days && item.our && item.delta_sales_7days != 0,
                        'not-data': item.our && !list_competitors[0].delta_sales_7days, 
                    }"
                >
                    {{item.delta_sales_7days != 0 ? item.delta_sales_7days + '%' : '-'}}
                </div>
            </template>
            <template v-slot:item.sales_3days="{ item }">
                <div
                        v-if="item.our"
                        style="padding: 0 16px"
                        :class="{
                        'bad': item.sales_3days > list_competitors[0].sales_3days && item.our && item.sales_3days != 0, 
                        'good': item.sales_3days < list_competitors[0].sales_3days && item.our && item.sales_3days != 0,
                        'neutral': item.sales_3days == list_competitors[0].sales_3days && item.our && item.sales_3days != 0, 
                        'not-data': item.our && !list_competitors[0].sales_3days,
                    }"
                >
                    {{(item.sales_3days != 0 ? (item.sales_3days) : '-' )|toRUB}}
                </div>
                <v-tooltip bottom max-width=350 v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-bind="attrs"
                                v-on="on"
                                style="padding: 0 16px;"
                        >
                            {{(item.sales_3days != 0 ? item.sales_3days : '-') | toRUB}}
                        </div>
                    </template>
                    <span>
						<p>
							Сумма выкупов за 3 дня: {{item.sum_redemption.redemption_3days ? item.sum_redemption.redemption_3days : 0}}
						</p>
						<p>
							Продажи без выкупов за 3 дня: {{item.sales_3days - item.sum_redemption.redemption_3days}}
						</p>
					</span>
                </v-tooltip>
            </template>
            <template v-slot:item.delta_sales_3days="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.delta_sales_3days > list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0, 
                        'good': item.delta_sales_3days < list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0,
                        'neutral': item.delta_sales_3days == list_competitors[0].delta_sales_3days && item.our && item.delta_sales_3days != 0, 
                        'not-data': item.our && !list_competitors[0].delta_sales_3days,
                    }"
                >
                    {{item.delta_sales_3days != 0 ? item.delta_sales_3days + '%' : '-'}}
                </div>
            </template>
            <template v-slot:item.avg_sales="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.avg_sales > list_competitors[0].avg_sales && item.our && item.avg_sales != 0, 
                        'good': item.avg_sales < list_competitors[0].avg_sales && item.our && item.avg_sales != 0,
                        'neutral': item.avg_sales == list_competitors[0].avg_sales && item.our && item.avg_sales != 0, 
                        'not-data': item.our && !list_competitors[0].avg_sales,
                    }"
                >
                    {{item.avg_sales != 0 ? item.avg_sales : '-'}}
                </div>
            </template>
            <template v-slot:item.warehouse_balance="{ item }">
                <v-tooltip bottom max-width=350>
                    <template v-slot:activator="{ on, attrs }">
                        <div
                                v-bind="attrs"
                                v-on="on"
                                style="padding: 0 16px;"
                                :class="{
								'bad': +item.warehouse.sum_balance > +list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0, 
								'good': +item.warehouse.sum_balance < +list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0,
								'neutral': item.warehouse.sum_balance == list_competitors[0].warehouse.sum_balance && item.our && item.warehouse.sum_balance != 0, 
								'not-data': item.our && !list_competitors[0].warehouse.sum_balance,
							}"
                        >
                            {{item.warehouse.sum_balance != 0 ? item.warehouse.sum_balance : '-'}}
							<v-chip
								class="mx-1 px-1 my-1 small-text" color="red" outlined
								x-small v-if="item.is_fbs_competitors.flag && item.our"
							>fbs</v-chip>
                        </div>
                    </template>
                    <span>
						<p v-for="(el, index) in item.warehouse.all_data" :key="index">
							{{ el.store }} - {{el.balance}}
						</p>
						<p>{{ item.is_fbs_competitors.warehouse }}(Парсинг) - {{ item.is_fbs_competitors.stock }}</p>
					</span>
                </v-tooltip>
            </template>
            <template v-slot:item.feedback="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': +item.feedback > +list_competitors[0].feedback && item.our && item.feedback != 0, 
                        'good': +item.feedback < +list_competitors[0].feedback && item.our && item.feedback != 0,
                        'neutral': +item.feedback == +list_competitors[0].feedback && item.our && item.feedback != 0, 
                        'not-data': item.our && !list_competitors[0].feedback,
                    }"
                >
                    {{item.feedback != 0 ? item.feedback : '-'}}
                </div>
            </template>
            <template v-slot:item.rating="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': +item.rating > +list_competitors[0].rating && item.our && item.rating != 0, 
                        'good': +item.rating < +list_competitors[0].rating && item.our && item.rating != 0,
                        'neutral': +item.rating == +list_competitors[0].rating && item.our && item.rating != 0, 
                        'not-data': item.our && !list_competitors[0].rating,
                    }"
                >
                    {{item.rating != 0 ? item.rating : '-'}}
                </div>
            </template>
            <template v-slot:item.quantity_category="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': +item.quantity_category > +list_competitors[0].quantity_category && item.our && item.quantity_category != 0, 
                        'good': +item.quantity_category < +list_competitors[0].quantity_category && item.our && item.quantity_category != 0,
                        'neutral': +item.quantity_category == +list_competitors[0].quantity_category && item.our && item.quantity_category != 0, 
                        'not-data': item.our && !list_competitors[0].quantity_category,
                    }"
                >
                    {{item.quantity_category != 0 ? item.quantity_category : '-'}}
                </div>
            </template>
            <template v-slot:item.visibility="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.visibility > list_competitors[0].visibility && item.our && item.visibility != 0, 
                        'good': item.visibility < list_competitors[0].visibility && item.our && item.visibility != 0,
                        'neutral': item.visibility == list_competitors[0].visibility && item.our && item.visibility != 0, 
                        'not-data': item.our && !list_competitors[0].visibility,
                    }"
                >
                    {{item.visibility != 0 ? item.visibility : '-'}}
                </div>
            </template>
            <template v-slot:item.avg_pos="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.avg_pos < list_competitors[0].avg_pos && item.our && item.avg_pos != 0, 
                        'good': item.avg_pos > list_competitors[0].avg_pos && item.our && item.avg_pos != 0,
                        'neutral': item.avg_pos == list_competitors[0].avg_pos && item.our && item.avg_pos != 0, 
                        'not-data': item.our && !list_competitors[0].avg_pos,
                    }"
                >
                    {{item.avg_pos != 0 ? item.avg_pos : '-'}}
                </div>
            </template>
            <template v-slot:item.min_pos="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.min_pos < list_competitors[0].min_pos && item.our && item.min_pos != 0, 
                        'good': item.min_pos > list_competitors[0].min_pos && item.our && item.min_pos != 0,
                        'neutral': item.min_pos == list_competitors[0].min_pos && item.our && item.min_pos != 0, 
                        'not-data': item.our && !list_competitors[0].min_pos,
                    }"
                >
                    {{item.min_pos != 0 ? item.min_pos : '-'}}
                </div>
            </template>
            <template v-slot:item.warehouse_store="{ item }">
                <div
                        style="padding: 0 16px;"
                        :class="{
                        'bad': item.warehouse.quantity_store > list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0, 
                        'good': item.warehouse.quantity_store < list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0,
                        'neutral': item.warehouse.quantity_store == list_competitors[0].warehouse.quantity_store && item.our && item.warehouse.quantity_store != 0, 
                        'not-data': item.our && !list_competitors[0].warehouse.quantity_store,
                    }"
                >
                    {{item.warehouse.quantity_store != 0 ? item.warehouse.quantity_store : '-'}}
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
				<div class="d-flex" style="padding: 0 16px;">
					<v-icon
						style="padding: 0px;" small v-if="item.our" @click="deleteItem(item)"
					> mdi-delete </v-icon>
					<v-icon
						style="padding: 0px;" class="ml-3" small @click="openDialogDetailCompetitors(item)"
					> mdi-history </v-icon>
				</div>
            </template>
            <template v-slot:top>
                <v-toolbar flat class="mt-5">
                    <v-row class="justify-space-between" style="padding:0 24px 24px 24px">
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-row>
                </v-toolbar>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import {Products} from '@/api/products';
	import KeywordTable from '@/components/Product/KeywordTable';
	import CompetitorsDetailDialog from '@/components/Product/CompetitorsDetailDialog';

	export default {
		name: 'Competitors',
		components: {KeywordTable, CompetitorsDetailDialog},
		props: ['product_id'],
		data() {
			return {
				dialog_detail: false,
				detail_info_active: null,
				expanded: [],
				dialogDelete: false,
				headers: [
					{text: 'Избранный', value: 'is_favorite', width: '1%', sortable: false},
					{text: 'Артикул', value: 'art', width: '1%', sortable: false},
					{text: 'Ссылка mpstats', value: 'link_mpstats', width: '1%', sortable: false},
					{text: 'Картинка', align: 'start', sortable: false, value: 'pic', width: '1%'},
					{text: 'Бренд', value: 'brand', width: '1%', sortable: false},
					{text: 'Цена без СПП', sortable: false, value: 'price_without_spp', width: '1%'},
					{text: 'СПП, %', sortable: false, value: 'spp', width: '1%'},
					{text: 'Цена с СПП', sortable: false, value: 'price', width: '1%'},
					{text: 'Продажи 30 дней', sortable: false, value: 'sales_30days', width: '1%'},
					{text: 'Прирост/спад 30 дней', value: 'delta_sales_30days', width: '1%', sortable: false},
					{text: 'Продажи 14 дней', value: 'sales_14days', width: '1%', sortable: false},
					{text: 'Прирост/спад 14 дней', value: 'delta_sales_14days', width: '1%', sortable: false},
					{text: 'Продажи 7 дней', value: 'sales_7days', width: '1%', sortable: false},
					{text: 'Прирост/спад 7 дней', value: 'delta_sales_7days', width: '2%', sortable: false},
					{text: 'Продажи 3 дней', value: 'sales_3days', width: '1%', sortable: false},
					{text: 'Прирост/спад 3 дней', value: 'delta_sales_3days', width: '1%', sortable: false},
					{text: 'Ср. продажи за 14 дней', value: 'avg_sales', width: '7%', sortable: false},
					{text: 'Остатки', value: 'warehouse_balance', width: '1%', sortable: false},
					{text: 'Кол-во складов', value: 'warehouse_store', width: '1%', sortable: false},
					{text: 'Отзывы', value: 'feedback', width: '1%', sortable: false},
					{text: 'Рейтинг', value: 'rating', width: '1%', sortable: false},
					{text: 'Кол-во категорий', value: 'quantity_category', sortable: false, width: '1%'},
					{text: 'Видимость по запросам', value: 'visibility', sortable: false, width: '1%'},
					{text: 'Ср. значение по запросам', value: 'avg_pos', sortable: false, width: '1%'},
					{text: 'Позиции по ключевому слову', value: 'min_pos', sortable: false, width: '1%'},
					{text: 'Действия', value: 'actions', sortable: false, width: '1%'},
				],
				list_competitors: [],
				tableLoading: false,
			};
		},
		watch: {
			dialogDelete(val) {
				val;
			},
		},
		methods: {
			openDialogDetailCompetitors(item){
				this.dialog_detail = true
				this.detail_info_active = item.art
			},
			isBottomScale(index) {
				if (5 - index < 4)
					return true;
				return false;
			},
			calculateVolImage(t){
				t = Number(t)
				if (t >= 0 && t <= 143)
					return '01'
				if (t >= 144 && t <= 287)
					return '02'
				if (t >= 288 && t <= 431)
					return '03'
				if (t >= 432 && t <= 719)
					return '04'
				if (t >= 720 && t <= 1007)
					return '05'
				if (t >= 1008 && t <= 1061)
					return '06'
				if (t >= 1062 && t <= 1115)
					return '07'
				if (t >= 1116 && t <= 1169)
					return '08'
				if (t >= 1170 && t <= 1313)
					return '09'
				if (t >= 1314 && t <= 1601)
					return '10'
                if (t >= 1602 && t <= 1655)
					return '11'
				if (t >= 1656 && t <= 1919)
					return '12'
				if (t >= 1920 && t <= 2045)
					return '13'
                if (t >= 2046 && t <= 2189)
					return '14'
                if (t >= 2190 && t <= 2405) 
                    return '15'
				return '16'
			},
			getImgPath (nmid) {
				let first_part = nmid.slice(0, -3)
				let second_part = nmid.slice(0, -5)
				let third_part = this.calculateVolImage(second_part)
				return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
			},
			deleteItem(item) {
				this.current_delete = item.id;
				this.dialogDelete = true;
			},
			async deleteItemConfirm() {
				try {
					const response = await Products.deleteCompetitors(this.current_delete);
					this.getCompetitors();
				} catch (err) {
					console.log(err);
				}
				this.dialogDelete = false;
			},
			async getCompetitors() {
				this.tableLoading = true;
				const response = await Products.fetchCompetitors(
					{'product_nmid': this.product_id},
				);
				this.list_competitors = response.data;
				this.tableLoading = false;
			},
			getLinkMpStats(art) {
				return `https://mpstats.io/wb/item/${art}`;
			},
			async changeFavoriteCompetitor(item){
				try{
					await Products.changeFavoriteCompetitor(item.id)
					this.$toasted.show(`Успешно`, {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				} catch(err){
					console.log(err)
				}
			}
		},
		mounted() {
			this.getCompetitors();
		},
	};
</script>

<style lang="scss">
    .competitors .bee-table-wrapper {
        max-width: calc(100vw - 120px);
    }
</style>
<style lang="scss">
    .competitors td:not(:first-of-type) {
        padding: 0 !important;
    }

    .good {
        background-color: #def3d9;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    .bad {
        background-color: #f5d4d7;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    .neutral {
        background-color: #f0e8a0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    .not-data {
        background-color: #f5d4d7;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
    }

    .img_competitors_big {
        display: none;
    }

    .img_competitors_container:hover {
        .img_competitors_big {
            display: block;
            position: absolute;
            z-index: 100;
            top: 125px;
            right: -145px;
            transform: scale(4);

            &.bottomImg {
                top: -115px;
                right: -145px;
            }
        }
    }

    .competitors_height .v-data-table__wrapper {
        min-height: 520px;
    }
</style>
