<template>
	<v-row class="mt-3">
		<v-col>
			<v-select
				v-model="filters.account" :items="choices.account" label="Аккаунт"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>

		<v-col>
			<v-select
				v-model="filters.campaign_type" :items="choices.campaign_type" label="Тип рекламы"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-select
				v-model="filters.campaign_status" :items="choices.campaign_status" label="Статус"
				item-text="name" item-value="value" outlined multiple
				hide-details dense clearable :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-select>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.nmid" clearable label="Артикул" outlined dense append-icon="mdi-magnify" 
				hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.campaign_id" label="id РК" clearable outlined dense append-icon="mdi-magnify" 
				hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.campaign_name" clearable outlined dense append-icon="mdi-magnify" label="Название РК" 
				hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-text-field
				v-model="filters.code_1c" clearable outlined dense  append-icon="mdi-magnify" label="Артикул 1С" 
				hide-details :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
			></v-text-field>
		</v-col>
		<v-col>
			<v-btn
				@click="apply_filters" depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  class="mb-3"
			>Применить</v-btn>
		</v-col>
	</v-row>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementListFilterComponent',
    data() {
        return {
			filters: {
				account: null,
				campaign_type: null,
				campaign_status: ["ACTIVE"],
				nmid: null,
				campaign_id: null,
				campaign_name: null,
				code_1c: null
			},
			choices: {
				account: [],
				campaign_type: [],
				campaign_status: [],
			}
		}		
    },
    computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
    },
    methods: {
		async fetch_advertisement_filters(){
			try {
                const responce = await Advertisement.fetchAdvertisementChoices()
				this.choices.account = responce.data.accounts
				this.choices.campaign_type = responce.data.campaign_type
				this.choices.campaign_status = responce.data.campaign_status
            } catch (err) {
                console.log(err)
            }
		},
		apply_filters(){
			this.$emit('applyFilters', this.transfromFiltersForQuery)
		}
    },
    async mounted(){
        await this.fetch_advertisement_filters()
		this.apply_filters()
    }
}
</script>

<style lang="scss" scoped>

</style>