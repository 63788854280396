<template>
	<div>
		<v-progress-linear v-if="loading" indeterminate color="deep-purple-accent-4"></v-progress-linear>
		<div v-for="(value, key, index) in data" class="mt-2" :key="index" v-else>
			<p class="title px-3 text--secondary">Артикул: {{ key }}</p>
			<v-data-table
				:headers="headers" :items="value.detail" class="elevation-0 mt-1 vertical-border small-font advertisement_stats"
				:items-per-page="7" :footer-props="{'items-per-page-options': [7, 14, 30, 60]}"
				fixed-header height="450px"
			>
				<template v-slot:item.profit_plan_simple="{ item }">
					<div :class="{
						'green_stats': item.profit_plan_simple >= 0, 
						'red_stats': item.profit_plan_simple < 0
					}">{{ item.profit_plan_simple }}</div>
				</template>
				
				<template v-slot:item.auto_avg_position="{ item }">
					<div class="d-flex align-center">
						{{ item.auto_avg_position }}
						<v-icon v-if="calc_position_delta(item, value.detail)> 0" color="red">
							mdi-arrow-up-thin
						</v-icon>
						<v-icon v-else-if="calc_position_delta(item, value.detail) < 0" color="green">
							mdi-arrow-down-thin
						</v-icon>
					</div>
				</template>
				<template v-slot:item.profit_plan_delta="{ item }">
					<div :class="{
						'green_stats': item.profit_plan_delta >= 0, 
						'red_stats': item.profit_plan_delta < 0
					}">{{ item.profit_plan_delta }}</div>
				</template>
				<template v-slot:body.prepend>
					<tr style="font-weight: bold;" v-if="value.detail.length != 0">
						<td v-for="col, index in headers" :key="index">
							<div v-if="index == 0">Всего: </div>
							<div v-else-if="!col.summary"></div>
							<div v-else>{{ value.summary[col.value] ? value.summary[col.value].toFixed(1) : 0 }}</div>
						</td>
					</tr>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
	name: "AdvertisementDetailStatsComponent",
	props: ['campaign_id'],
	data(){
		return {
			data: {},
			headers: [
				{ text: "Дата", value: "date", sortable: true, width: "2%", summary: true },
				{ text: "Показы", value: "views", sortable: false, width: "1%", summary: true },
				{ text: "CPM",value: "cpm",sortable: false, width: "1%", summary: true },
				{ text: "Клики", value: "clicks", sortable: false, width: "1%", summary: true },
				{ text: "CTR(%)", value: "ctr", sortable: false, width: "1%", summary: true },
				{ text: "CPC", value: "cpc", sortable: false, width: "1%", summary: true },
				{ text: "Корзина",  value: "add_to_basket", sortable: false, width: "1%", summary: true },
				{ text: "Заказы", value: "delta_orders", sortable: false, width: "1%", summary: true },
				{ text: 'CPO', value: 'delta_cpo', sortable: false, width: '1%', summary: true },
				{ text: 'CR', value: 'calc_delta_conversion', sortable: false, width: '1%', summary: true },
				{ text: "Затраты", value: "expenses", sortable: false, width: "1%", summary: true },
				{ text: 'Средняя позиция', value: 'auto_avg_position', sortable: false, width: '1%', summary: true  },
				{ text: 'ВП дельта, общ', value: 'vp_total_plan_delta', sortable: false, width: '1%', summary: true },
				{ text: 'Доходность дельта', value: 'profit_plan_delta', sortable: false, width: '1%', summary: true },
				{ text: 'Заказы с парсинга', value: 'calc_orders_from_parsing', sortable: false, width: '1%', summary: true },
			],
			loading: false
		}
	},
	methods: {
		async get_advertisement_detail_stats(){
			this.loading = true
			try{
				const response = await Advertisement.fetchAdvertisementDetailStats(this.campaign_id)
				this.data = response.data
			} catch (err){
				console.log(err)
			}
			this.loading = false
		},
		calc_position_delta(item, data){
			let cur_index = data.indexOf(item)
			if (cur_index == -1 || cur_index == data.length || !data[cur_index] || !data[cur_index + 1])
				return 0
			return data[cur_index].auto_avg_position - data[cur_index + 1].auto_avg_position
		}
	},
	async mounted(){
		await this.get_advertisement_detail_stats()
	}
}
</script>

<style lang="scss" scoped>
	.green_stats{
		height: 100%;
		width: calc(100% + 32px);
		margin-left: -16px;
		background-color: #c0f1b4;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.red_stats{
		height: 100%;
		background-color: #f1b7bd;
		width: calc(100% + 32px);
		margin-left: -16px;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.theme--dark .red_stats{
		background-color: #a0535b !important;
	}
	.theme--dark .green_stats{
		background-color: #4f7844 !important;
	}
</style>
<style>
	.advertisement_stats th{
		background-color: #13b9c6 !important;
		color: white !important;
		i {
			color: white !important;
		}
	}
	.theme--dark .advertisement_stats th{
		background-color: #208379 !important;
		color: white !important;
		i {
			color: white !important;
		}
	}
	.theme--dark .advertisement_stats tbody tr:hover {
    background-color: #1a1a1a !important;
}

</style>