<template>
    <div class="w-100">
        <v-data-table
            :headers="headers"
            :items="data_stats"
            :loading="table_loading"
            class="elevation-0 mt-0 vertical-border small-font"
            hide-default-footer
            disable-pagination>
        >
            <template v-slot:item.ctr="{ item }">
                <dir :class="{
                    'green_stats': item.ctr > 2, 'red_stats': item.ctr == 0, 'yellow_stats': item.ctr > 0 && item.ctr <= 2
                }">{{item.ctr}}</dir>
            </template>
            <template v-slot:item.sum="{ item }">
                {{roundNumber(item.sum)}}
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'

export default {
	name: 'AdvertisementStatsKeyword',
    props: ['data_stats',],
	data() {
		return {
			//data_stats: [],
            headers: [
                { text: "Ключевая фраза", value: "keyword", sortable: false, width: "1%" },
                { text: "Просмотры", value: "views", sortable: false, width: "1%" },
                { text: "Частота", value: "frq", sortable: false, width: "1%" },
                { text: "Клики", value: "clicks", sortable: false, width: "1%" },
                { text: "CTR (%)", value: "ctr", sortable: false, width: "1%" },
                { text: "CPC", value: "cpc", sortable: false, width: "1%" },
                { text: "Затраты", value: "sum", sortable: false, width: "1%" },
            ],
            table_loading: false
		}
	},    
	methods: {
		async getStatsKeyword(){
            this.table_loading = true
			try {
				let res = await AdvertisementNew.getStatsKeyword(this.advert_id)
                this.data_stats = res.data
			} catch (err) {
				console.log(err)
			}
            finally{
                this.table_loading = false
            }
            
		},
        roundNumber(x){
            return Math.round(x * 100) / 100
        }
	},
	async mounted(){
		//await this.getStatsKeyword()
	}   
}
</script>
<style scoped>
    .green_stats{
        height: 100%;
        width: calc(100% + 32px);
        margin-left: -16px;
        background-color: #def3d9;
        display: flex;
        justify-content: flex-start;
        padding: 0 16px;
        align-items: center;
    }
    .red_stats{
        height: 100%;
        background-color: #f5d4d7;
        width: calc(100% + 32px);
        margin-left: -16px;
        display: flex;
        justify-content: flex-start;
        padding: 0 16px;
        align-items: center;
    }
    .yellow_stats{
        height: 100%;
        background-color: #f3ebb9;
        width: calc(100% + 32px);
        margin-left: -16px;
        display: flex;
        justify-content: flex-start;
        padding: 0 16px;
        align-items: center;
    }
</style>