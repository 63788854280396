<template>
    <div class="">
        <OzonProductsList></OzonProductsList>
    </div>
</template>

<script>
	import OzonProductsList from '@/components/Products/OzonProductsList'
	export default {
		name: 'ProductsOzon',
		components: {
			OzonProductsList
		},
		mounted () {
			document.title = 'Товары OZON | mp.lomex-group.ru';
			this.$store.commit('setPageTitle', `Список товаров OZON`)
		}
	}
</script>

<style scoped>

</style>
