<template>
    <v-container fluid>
		<v-card>
			<v-card-title>
				Акции Ozon
				<v-text-field
					v-model="search_title" clearable outlined dense label="Поиск по названию"
					hide-details @change="fetch_promotions_with_filter" class="ml-5"
				></v-text-field>
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="table.headers"
					:items="table.data"
					class="elevation-0 vertical-border small-font"
					:loading="table.loading"
					:options.sync="table.options"
					:items-per-page="15"
					:footer-props="{'items-per-page-options': [5, 15, 25, 50, -1]}"
					:server-items-length="table.pagination.totalItems"
				>
					<template v-slot:item.commission_reduction="{ item }">
						<v-text-field
							v-model="item.commission_reduction" clearable outlined dense
							type='number' hide-details @change="update_promotion(item.id, item.commission_reduction)"
						></v-text-field>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
    </v-container>
</template>

<script>
	import {Products} from '@/api/products';

	export default {
		name: 'ProductPromotionOzon',
		data() {
			return {
				search_title: null,
				table:{
					first_init: false,
					loading: false,
					data: [],
					headers: [
						{ text: 'Название', value: 'title', sortable: true, width: '2%' },
						{ text: 'Дата начала', value: 'date_from', sortable: true , width: '1%'},
						{ text: 'Дата окончания', value: 'date_to', sortable: true , width: '1%'},
						{ text: 'Снижение комиссии', value: 'commission_reduction', sortable: true , width: '1%'},
					],
					options: {},
					pagination: {
						next: null,
						totalItems: 0,
						numberOfPages: 0,
						currentPage: 0
					},
				}
			};
		},
        watch:{
			'table.options.sortDesc': async function (newVal, oldVal) {
				if (oldVal && this.table.first_init) {
					await this.fetch_promotions()
				}
			},
		},
		methods: {
			async fetch_promotions_with_filter(){
				this.table.options.page = 1
				await this.fetch_promotions()
			},
			async fetch_promotions(){
				this.table.loading = true
				const { page, itemsPerPage, sortDesc, sortBy } = this.table.options
				try {
					let pageNumber = page - 1
					if (this.table.data.length != 0)
						window.scrollTo(0,0);
					let data = {
						'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage, 
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					}	
					if (this.search_title)
						data['title'] = this.search_title
					const response = await Products.fetchListOzonPromotion(data)	
					this.table.data = response.data.results
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.error(err.response)
				}
				this.table.loading = false
				if (!this.table.first_init)
					this.table.first_init = true
			},
			async update_promotion(id, new_val){
                let json_data = {
                    "promotion_id": id, "commission_reduction": new_val
                }
				try {Products.updateOzonPromotion(json_data)
					this.$toasted.show(`Успешно изменено`, {
						theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
					});
				} catch(err){
					this.$toasted.show(`Ошибка изменения данных`, {
						theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
					});
				}
			},
		},
		async mounted() {
			document.title = `Товары OZON(Акции) | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Товары OZON(Акции)`);
			await this.fetch_promotions()
		},
		
	};
</script>

<style scoped>
	.green_text{
		color: green;
	}
	.red_text{
		color: rgb(157, 17, 17);
	}
	.orange_text{
		color: rgb(215, 143, 9);
	}
</style>