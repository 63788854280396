<template>
    <div>
        <CatalogList/>
    </div>
</template>

<script>
	import CatalogList from '@/components/CatalogList';

	export default {
		name: 'Catalog',
		components: {
			CatalogList,
		},
		mounted() {
			this.$store.commit('setPageTitle', `Каталог`);
		},
	};
</script>

<style scoped>

</style>