<template>
    <div>
        <SalesList></SalesList>
    </div>
</template>

<script>
    import SalesList from '@/components/SalesList'

    export default {
        name: "Orders",
      components: {
        SalesList
      },
      mounted () {
        this.$store.commit('setPageTitle', `Список продаж/возвратов`)
      }
    }
</script>

<style scoped>

</style>
