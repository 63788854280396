<template>
    <v-sheet>
        <v-skeleton-loader v-if="loading" type="table-heading, table-row@5" class="pa-4"></v-skeleton-loader>
		<v-simple-table dense v-else>
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">Дата</th>
						<th class="text-left">Склад</th>
						<th>
							<v-tooltip bottom max-width=350>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on">
										<a class="small-text dashed">free_to_sell_amount<v-icon x-small class="mb-1">mdi-help</v-icon></a>
									</div>
								</template>
								<span>Количество товара, доступное к продаже на Ozon.</span>
							</v-tooltip>
						</th>
						<th>
							<v-tooltip bottom max-width=350>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on">
										<a class="small-text dashed">reserved_amount<v-icon x-small class="mb-1">mdi-help</v-icon></a>
									</div>
								</template>
								<span>Количество товара, зарезервированное для покупки, возврата и перевозки между складами.</span>
							</v-tooltip>
						</th>
						<th>
							<v-tooltip bottom max-width=350>
								<template v-slot:activator="{ on, attrs }">
									<div v-bind="attrs" v-on="on">
										<a class="small-text dashed">promised_amount<v-icon x-small class="mb-1">mdi-help</v-icon></a>
									</div>
								</template>
								<span>Количество товара, указанное в подтверждённых будущих поставках.</span>
							</v-tooltip>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in data" :key="index">
						<td>{{ item.date }}</td>
						<td>{{ item.warehouse_name }}</td>
						<td>{{ item.free_to_sell_amount }}</td>
						<td>{{ item.reserved_amount }}</td>
						<td>{{ item.promised_amount }}</td>
						
					</tr>
				</tbody>
			</template>
		</v-simple-table>
    </v-sheet>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "OzonStocksFboByWarehouse",
    props: {
		product_id: {type: String},
		date: {type: String}
    },
    data () {
		return {
			loading: false,
			data: []
		}
    },
    methods: {
		async fetchStocksByWarehouse(){
			this.loading = true
			try{
				const response = await Products.fetchOzonProductStocksByWarehouse(this.product_id, this.date)
				this.data = response.data
			} catch(err){
				console.log(err)
			}
			this.loading = false
		}
    },
    async mounted () {
		await this.fetchStocksByWarehouse()
    }
  }
</script>