<template>
	<OzonSupplyPlanningDetailComponent :id="id" :warehouses_list="warehouses_list"/>
</template>

<script>
import { Suppl } from '@/api/suppl';
import OzonSupplyPlanningDetailComponent from '@/components/SupplyPlanning/OzonSupplyPlanningDetailComponent';

export default {
	name: 'OzonSupplyPlanningListComponent',
	components: { OzonSupplyPlanningDetailComponent },
	data(){
		return {
			warehouses_list: []
		}
	},
	computed: {
		id() {
			return this.$route.params.id;
		},
	},
	methods: {
		async fetch_detail(){
			try{
				const response = await Suppl.ozonFetchWarehouseListSuppl(this.id)
				this.warehouses_list = response.data.warehouses_list
			} catch(err){
				console.log(err)
			}
		},
	},
	async mounted(){
		document.title = `Планирование поставок ОЗОН(${this.id}) | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Планирование поставок(${this.id})`)
		await this.fetch_detail()
	},
};
</script>
