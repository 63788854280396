<template>
    <v-container fluid>
		<v-card class="mt-5">
			<v-card-title>Создание рекламных кампаний</v-card-title>
			<v-card-text>
				<v-tabs v-model="active_tab" fixed-tabs>
					<v-tab v-for="tab in tabs" :key="tab.name">{{ tab.value }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="active_tab">
					<v-tab-item v-for="tab in tabs" :key="tab.name">
						<AdvertisementCreateSearchComponent v-if="tab.name == 'search'" :choices="choices"/>
						<AdvertisementCreateAutoComponent v-else-if="tab.name == 'auto'" :choices="choices"/>
					</v-tab-item>
				</v-tabs-items>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import AdvertisementCreateSearchComponent from '@/components/Advertisement/AdvertisementCreate/AdvertisementCreateSearchComponent'
import AdvertisementCreateAutoComponent from '@/components/Advertisement/AdvertisementCreate/AdvertisementCreateAutoComponent'
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementCreateView',
	components: {AdvertisementCreateSearchComponent, AdvertisementCreateAutoComponent},
	data(){
		return {
			active_tab: null,
			tabs: [
				{"name": 'search', "value": 'Реклама в поиске'},
				{"name": 'auto', "value": 'Автоматическая реклама'},
			],
			choices: {
				purpose: [],
				account: []
			}
		}
	},
	methods: {
		async fetch_advertisement_filters(){
			try {
                const responce = await Advertisement.fetchAdvertisementChoices()
                this.choices.purpose = responce.data.campaign_purpose
				this.choices.account = responce.data.accounts
            } catch (err) {
                console.log(err)
            }
		},
	},
	async mounted(){
		document.title = `Создание рекламных кампаний | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Создание рекламных кампаний`)
		await this.fetch_advertisement_filters()
	}
}
</script>