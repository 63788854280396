<template>
	<v-card>
		<v-card-title>Акции</v-card-title>
		<v-card-text>
			<v-select
				v-model="selected_account" :items="accounts" item-text="name" item-value="value"
				class="mt-5" label="ЛК" outlined hide-details dense clearable
			></v-select>
			<div class="block_promotion">
				<div class="block_promotion__element">
					<h2>Акция 1: </h2>
					<div v-if="promotions.first_promotion" class="ml-5 d-flex" style="align-items:center;">
						<span>{{promotions.first_promotion.title}}</span>
						<v-btn 
							class="ml-5 delete_button" depressed 
							@click="delete_promotions(promotions.first_promotion.id)"
						>Удалить</v-btn>
					</div>
					<div v-else>
						<v-btn class="ml-5" depressed @click="openAddDialog(1)">Добавить</v-btn>
					</div>
					
				</div>
				<div class="block_promotion__element">
					<h2>Акция 2: </h2>
					<div v-if="promotions.second_promotion" class="ml-5 d-flex" style="align-items:center;">
						<span>{{promotions.second_promotion.title}}</span>
						<v-btn 
							class="ml-5 delete_button" depressed
							@click="delete_promotions(promotions.second_promotion.id)"
						>Удалить</v-btn>
					</div>
					<div v-else>
						<v-btn class="ml-5" depressed @click="openAddDialog(2)">Добавить</v-btn>
					</div>
				</div>
				<div class="block_promotion__element">
					<h2>Акция 3: </h2>
					<div v-if="promotions.third_promotion" class="ml-5 d-flex" style="align-items:center;">
						<span>{{promotions.third_promotion.title}}</span>
						<v-btn 
							class="ml-5 delete_button" depressed
							@click="delete_promotions(promotions.third_promotion.id)"
						>Удалить</v-btn>
					</div>
					<div v-else>
						<v-btn class="ml-5" depressed @click="openAddDialog(3)">Добавить</v-btn>
					</div>
				</div>
			</div>
		</v-card-text>
		<v-dialog v-model="add_data.dialog" v-if="add_data.dialog" max-width="1200" scrollable>
			<v-card>
				<v-card-title>Текущие акции ВБ акций ({{ add_data.number }})</v-card-title>
				<v-card-text>
					<v-data-table 
						:headers="add_data.headers" :items="add_data.data" 
						:loading="add_data.loading" class="elevation-1"
					>
						<template v-slot:item.date_from="{ item }">
							{{ item.date_from }} - {{ item.date_to }}
						</template>
						<template v-slot:item.is_actual="{ item }">
							<v-icon small color="green" v-if="!item.is_completed">mdi-checkbox-marked-circle</v-icon>
							<v-icon v-else color="red" small>mdi-close-circle</v-icon>
						</template>
						<template v-slot:item.action="{ item }">
							<v-btn 
								icon :loading="add_data.loading_add" 
								@click="add_promotion_to_template(item.id)"
							><v-icon>mdi-plus</v-icon></v-btn>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
	import {PriceManagement} from '@/api/price_management'
	import {ManagementProduct} from '@/api/management_product'

	export default {
		name: 'PriceManagementPromotionComponent',
		props: ['template_id'],
		data() {
			return {
				add_data: {
					dialog: false,
					number: null,
					loading: false,
					loading_add: false,
					data: [],
					headers: [
						{ text: 'Название', sortable: true, value: 'title'},
						{ text: 'Период', sortable: true, value: 'date_from'},
						{ text: 'Если зашли в акцию', sortable: false, value: 'is_entered_commission'},
						{ text: 'Если не зашли в акцию', value: 'is_not_entered_commission', sortable: false},
						{ text: 'Минимальная комиссия', value: 'min_commission', sortable: false},
						{ text: 'Товаров в акции', value: 'cnt_in_products', sortable: false},
						{ text: 'Товаров вне акции', value: 'cnt_out_products', sortable: false},
						{ text: 'Актуальная акция', value: 'is_actual', sortable: false},
						{ text: 'Добавить', value: 'action', sortable: false},
					]
				},
				add_dialog: false,
				accounts: [],
				selected_account: null,
				promotions:{
					data: [],
					first_promotion: null,
					second_promotion: null,
					third_promotion: null
				}
			}
		},
		watch: {
			'selected_account': function (newVal, oldVal) {
				if (newVal && oldVal != newVal) {
					console.log("Аккаунт поменялся")
					this.promotionsByNumber()
				}
			},
		},
		computed: {
			filterPromotions(){
				return this.promotions.data.filter(item => item.account == this.selected_account)
			},
		},
		methods: {
			promotionsByNumber(){
				let filter_promotions = this.filterPromotions
				this.promotions.first_promotion = null
				this.promotions.second_promotion = null
				this.promotions.third_promotion = null
				filter_promotions.forEach(item => {
					if (item.number == 1)
						this.promotions.first_promotion = item
					else if (item.number == 2)
						this.promotions.second_promotion = item
					else if (item.number == 3)
						this.promotions.third_promotion = item
				})
			},
			async fetch_accounts_by_template(){
				try{
					const response = await PriceManagement.accountsByTemplates(this.template_id)
					this.accounts = response.data
				} catch(err){
					console.log(err)
				}
			},
			async fetch_promotions_by_template(){
				try{
					const response = await PriceManagement.promotionsByTemplates(this.template_id)
					this.promotions.data = response.data
				} catch(err){
					console.log(err)
				}
			},
			async openAddDialog(number){
				this.add_data.data = []
				this.add_data.dialog = true
				this.add_data.number = number
				this.add_data.loading = true
				try{
					const response = await ManagementProduct.fetchExistPromotion()
					this.add_data.data = response.data
				} catch(err){
					console.log(err)
				}
				this.add_data.loading = false
			},
			async delete_promotions(id){
				try{
					await PriceManagement.deletePromotions(id)
					await this.fetch_promotions_by_template()
					this.promotionsByNumber()
				} catch(err){
					console.log(err)
				}
			},
			async add_promotion_to_template(id){
				this.add_data.loading_add = true
				let json_data = {
					"number": this.add_data.number, "account": this.selected_account, "promotion": id
				}
				try{
					await PriceManagement.addPromotionToTemplate(this.template_id, json_data)
					await this.fetch_promotions_by_template()
					this.promotionsByNumber()
				} catch(err){
					console.log(err)
				}
				this.add_data.dialog = false
				this.add_data.loading_add = false
			}
		},
		async mounted() {
			await this.fetch_accounts_by_template()
			await this.fetch_promotions_by_template()
			if (this.accounts){
				this.selected_account = this.accounts[0].value
			}
		},
	};
</script>

<style scoped lang="scss">
    
</style>
