<template>
    <div class="block-plans">
        <div :class="{'green_block': data.plan_execution >= 99 && data.plan_vp_mp != 0, 'orange_block': data.plan_execution >= 90 && data.plan_execution < 99 && data.plan_vp_mp != 0, 'red_block': data.plan_execution < 90 && data.plan_vp_mp != 0}">
            <h3>План / прогноз по ВП_МП</h3>
            <p>План: {{ data.plan_vp_mp ? data.plan_vp_mp : 0 | toRUB }}</p>
            <p>Прогноз: {{ data.progress_vp_mp ? data.progress_vp_mp : 0 | toRUB }}</p>
            <p style="font-weight: bold;">Выполнение: {{ data.plan_execution ? data.plan_execution.toFixed(2) : 0 }}%</p>
        </div>
        <v-divider vertical></v-divider>
        <div :class="{'green_block': data.plan_execution_orders >= 99 && data.plan_orders != 0, 'orange_block': data.plan_execution_orders >= 90 && data.plan_execution_orders < 99 && data.plan_orders != 0, 'red_block': data.plan_execution_orders < 90 && data.plan_orders != 0}">
            <h3>План / прогноз по сумме заказов</h3>
            <p>План: {{ data.plan_orders ? data.plan_orders : 0 | toRUB }}</p>
            <p>Прогноз: {{ data.progress_orders ? data.progress_orders : 0 | toRUB }}</p>
            <p style="font-weight: bold;">Выполнение: {{ data.plan_execution_orders ? data.plan_execution_orders.toFixed(2) : 0 }}%</p>
        </div>
    </div>
</template>

<script>
import { Products } from '@/api/products'

export default {
	name: "PlansBlockComponent",
	props: ['nmid', 'is_ozon'],
	data () {
		return {
			data: {}
		}
	},
	methods: {
		async fetch_data(){
            try{
                let response = {}
                if (this.is_ozon)
                    response = await Products.fetchOzonProductPlans(this.nmid)
                else
                    response = await Products.fetchProductPlans(this.nmid)
                this.data = response.data
            } catch(err){
                console.log(err)
            }
        }
	},
	async mounted () {
		await this.fetch_data()
	}
}
</script>

<style scoped>
    .block-plans{
		color: black;
		margin-left: auto;
		display: flex;
		justify-content: space-between;
		border: 1px dashed #c6c6c6;
		background: #f4f4f4;
        h3{
            white-space: nowrap;
        }
        div{
            padding: 10px 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
        }
	}
    .green_block{
		background-color: #edf9e9;
	}
	.orange_block{
		background-color: #f1f1d2;
	}
	.red_block{
		background-color: #ffe0e0;
	}
</style>
