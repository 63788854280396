<template>
    <div>
		<router-link class="ml-3" target="_blank" :to="{name: 'ozon_management_product_create'}">
			<v-btn color="primary" dark class="mt-5 ml-5">Создать новый шаблон</v-btn>
		</router-link>
		
        <v-data-table
			:headers="table.headers" :items="table.data" :loading="table.loading"
			class="elevation-0 mt-3 vertical-border" :pageCount="table.pagination.numberOfPages"
			@update:page="fetch_list_templates" :options.sync="table.options"
			:items-per-page="50" :server-items-length="table.pagination.totalItems"
			:footer-props="{'items-per-page-options': [5, 10, 25, 50, 100]}"
        >
			<template v-slot:item.name="{ item }">
				<router-link target="_blank" :to="{ name: 'ozon_management_product_detail', params: { template_id: item.id }}">
					{{item.name}}
				</router-link>
            </template>
			<template v-slot:item.date_created="{ item }">
				{{ item.date_created | formatDateToLocale }}
            </template>
			<template v-slot:item.actions="{ item }">
				<v-icon small @click="delete_template(item.id)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.auto_update="{ item }">
                <v-switch 
                    color="primary" class="mt-0" hide-details dense
                    v-model="item.is_auto_update" @change="change_auto_update(item)"
                ></v-switch>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import {ManagementProduct} from '@/api/management_product';

	export default {
		name: 'ManagementOzonProductList',
		data() {
			return {
                table: {
                    loading: false,
                    data: [],
                    options: {},
                    pagination: {
                        next: null,
                        totalItems: 0,
                        numberOfPages: 0,
                        currentPage: 0
                    },
                    headers: [
                        { text: 'Название', sortable: false, value: 'name', width: '20%' },
                        { text: 'Дата создания', sortable: false, value: 'date_created', width: '10%' },
                        { text: 'Товары', sortable: false, value: 'products', width: '55%' },
                        { text: 'Аккаунты', sortable: false, value: 'accounts', width: '55%' },
                        { text: 'Автообновление', value: 'auto_update', sortable: false, width: '5%' },
                        { text: 'Действия', value: 'actions', sortable: false, width: '5%' },
                    ]
                }
			};
		},
		methods: {
            async change_auto_update(item){
                try{
                    await ManagementProduct.autoUpdateOzonTemplates(item.id)
                } catch(err){
                    console.log(err)
                }
            },
            async delete_template(id){
                try{
                    await ManagementProduct.deleteOzonTemplates(id)
                    this.$toasted.show('Шаблон удален', {
						theme: 'bubble', type: 'success', position: 'top-right', duration: 3000,
                    })
                    await this.fetch_list_templates()
                } catch(err){
                    this.$toasted.show('Ошибка удаления шаблона', {
						theme: 'bubble', type: 'error', position: 'top-right', duration: 3000,
                    })
                }
            },
            async fetch_list_templates(){
                this.table.loading = true
				const { page, itemsPerPage } = this.table.options
				let pageNumber = page - 1
				try {
                    if (this.table.data.length != 0)
                        window.scrollTo(0,0);
					const response = await ManagementProduct.fetchOzonListTemplates({
                        'offset': itemsPerPage*pageNumber, 'limit': itemsPerPage,
					})
					this.table.data = response.data.results
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err)
				}
				this.table.loading = false
            }
		},
		async mounted() {
			document.title = 'Управление товаром OZON (список шаблонов)';
			this.$store.commit('setPageTitle', `Управление товаром OZON (список шаблонов)`)
            await this.fetch_list_templates()
		},
	};
</script>
