<template>
	<v-container fluid>
        <v-sheet class="px-5 py-3">
            <router-link target="_blank" :to="{name: 'ozon_advertisement_stats'}">
                <v-icon> mdi-poll </v-icon>
            </router-link>
        </v-sheet>
		
        <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                v-for="item in advertisement_main_types"
                :key="item.id"
				style="border-bottom: 2px solid #c6c6c6;"
            >
                {{ item.name }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in advertisement_main_types"
            :key="item.id"
        >
            <AdvertisementTableAll v-if="item.id==''"/>
			<AdvertisementTableSku v-if="item.id=='SKU'"/>
			<AdvertisementTableSearch v-if="item.id=='SEARCH_PROMO'"/>
            <AdvertisementTableBrand v-if="item.id == 'BRAND_SHELF'"/>
        </v-tab-item>
        </v-tabs-items>
   </v-container>
</template>

<script>
import AdvertisementTableAll from '@/components/OzonAdvertisement/AdverTableList/AdvertisementTableAll.vue'
import AdvertisementTableSku from '@/components/OzonAdvertisement/AdverTableList/AdvertisementTableSku.vue'
import AdvertisementTableSearch from '@/components/OzonAdvertisement/AdverTableList/AdvertisementTableSearch.vue'
import AdvertisementTableBrand from '@/components/OzonAdvertisement/AdverTableList/AdvertisementTableBrand'

export default {
	name: 'OzonAdvertisementNew',
	components:{
		AdvertisementTableAll,
		AdvertisementTableSku,
		AdvertisementTableSearch,
        AdvertisementTableBrand
	},
	data() {
        return {
            advertisement_main_types: [
                {"id": "", "name": "Все"},
                {"id": "SKU", "name": "Трафареты"},
                {"id": "SEARCH_PROMO", "name": "Продвижение в поиске"},
                {"id": "BRAND_SHELF", "name": "Брендовая полка"}
            ],
            tab: 2
        }
    },
	mounted () {
		document.title = `Реклама OZON | mp.lomex-group.ru`;
		this.$store.commit('setPageTitle', `Рекламные кампании OZON`)
	}
}
</script>