<template>
    <div class="w-100">
		<div class="d-flex mt-3">
			<v-select
				v-model="city"
				:items="city_dict"
				item-text="name"
				item-value="value"
				label="Город"
				clearable
				outlined
				dense
			></v-select>
			<v-btn @click="getData()" depressed color="purple darken-2 ml-5" dark>Загрузить</v-btn>
		</div>
        <v-data-table
            :headers="headers"
            :items="data"
            :loading="table_loading"
            class="elevation-0 mt-0 vertical-border small-font"
			:item-class="rowOurCompany"
            hide-default-footer
            disable-pagination>
        >
			<template v-slot:item.new_bet="{ item }">
                <div>{{ calculate_new_bet(item) }}</div>
            </template>
            <template v-slot:item.time="{ item }">
                <div>{{ item.time }}ч</div>
            </template>
			<template v-slot:item.cpm="{ item }">
                <div>{{ item.cpm | toRUB}}</div>
            </template>
            <template v-slot:item.weight_delivery="{ item }">
                {{item.weight_delivery.toFixed(3)}}
            </template>
			<template v-slot:item.weight_cpm="{ item }">
                {{item.weight_cpm.toFixed(3)}}
            </template>
			<template v-slot:item.weight="{ item }">
                {{item.weight.toFixed(3)}}
            </template>
        </v-data-table>
		
    </div>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'

export default {
	name: 'AdvertisementCheckerBets',
    props: ['keyword', 'adver_id'],
	data() {
		return {
            headers: [
				{ text: "Робот ставка", value: 'new_bet', sortable: false, width: "1%"},
				{ text: "Место", value: "placement", sortable: true, width: "1%" },
                { text: "Реклама", value: "advertId", sortable: true, width: "1%" },
                { text: "Артикул", value: "id", sortable: true, width: "1%" },
                { text: "Категория", value: "subject", sortable: true, width: "1%" },
                { text: "Срок доставки", value: "time", sortable: true, width: "1%" },
                { text: "Ставка", value: "cpm", sortable: true, width: "1%" },
                { text: "Вес доставки", value: "weight_delivery", sortable: true, width: "1%" },
                { text: "Вес ставка", value: "weight_cpm", sortable: true, width: "1%" },
				{ text: "Общий вес", value: "weight", sortable: true, width: "1%" },
            ],
			data: [],
			new_data: [],
            table_loading: false,
			city: 'msk',
			city_dict: [
				{value: 'msk', name: 'Москва'},
				{value: 'spb', name: 'Санкт-петербург'},
				{value: 'chl', name: 'Челябинск'},
				{value: 'vlg', name: 'Волгоград'},
				{value: 'vol', name: 'Вологда'},
				{value: 'novg', name: 'Нижний новгород'},
				{value: 'ekt', name: 'Екатеринбург'},
				{value: 'krd', name: 'Краснодар'},
			]
		}
	},    
	methods: {
		async getData(){
            this.table_loading = true
			try {
				let query = {'keyword': this.keyword, "advert": this.adver_id, "city": this.city}
				let res = await AdvertisementNew.checkBetsBySearch(query)
                this.data = res.data.result
				this.new_data = res.data.new_result
			} catch (err) {
				console.log(err)
			}
            this.table_loading = false
		},
		rowOurCompany(item) {
			return item.advertId == this.adver_id ? 'our_company' : ''
		},
		calculate_new_bet(item){
			let test = this.new_data.filter(i => i.place == item.placement)
			if (test.length > 0)
				return !test[0]['not_change'] ? test[0]['bet']: null
			return null
		}
	},
	async mounted(){
		await this.getData()
	}   
}
</script>
<style>
    .our_company{
        background-color: #bdeab1;
    }
</style>