<template>
    <apexchart 
        type="line" 
        height="350" 
        :options="chartOptions"
        :series="seriesSales"
    ></apexchart>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'SalesChart',
        components: {
            apexchart: VueApexCharts,
        },
        props: [
            'series',
        ],
        data() {
            return {
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'bar',
                        zoom: {
                            enabled: true,
                        },
                    },
                    animations: {
                        enabled: true,
                    },
                    colors: ['#cd5b45', '#27a327', '#000099', '#db8b00'],
                    dataLabels: {
                        enabled: false,
                    },
                    markers: {
                        size: [4, 4, 0],
                        strokeColors: '#fff',
                        strokeWidth: 1,
                        strokeOpacity: 0.9,
                        strokeDashArray: 1,
                    },
                    stroke: {
                        curve: 'straight',
                        width: [3, 3],
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5,
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                    },
                    yaxis: [
                        {
                            seriesName: 'Рублей',
                            title: {
                                text: 'Рублей',
                                style: {
                                    color: '#000',
                                },
                            },
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#000',
                            },
                            labels: {
                                style: {
                                    colors: '#000',
                                },
                                formatter: function(val) {
                                    return val.toFixed(0);
                                },
                            },
                        },
                    ],
                    tooltip: {
                        shared: false,
                          intersect: true,
                        y: {
                            formatter: function (val) {
                            return val + "Р"
                            }
                        }
                    }
                },
            };
        },
        computed: {
            seriesSales() {
                return [
                    {
                        name: 'Выручка',
                        type: 'bar',
                        data: this.seriesProfit,
                    },
                    {
                        name: 'ВП',
                        type: 'bar',
                        data: this.seriesVP,
                    },
                    {
                        name: 'Расходы',
                        type: 'bar',
                        data: this.seriesSum,
                    },
                ];
            },
            seriesProfit() {
                let result = {}
                let map_result = []
                this.series.forEach(element => {
                    if(element.date in result)
                        result[element.date] = result[element.date] + element.revenue 
                    else{
                        result[element.date] = element.revenue
                    }	
                })
                for (const [key, value] of Object.entries(result)){
                    map_result.push({x: key, y: value})
                }
                return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
            seriesVP() {
                let result = {}
                let map_result = []
                this.series.forEach(element => {
                    if(element.date in result){
                        result[element.date] = result[element.date] + element.vp_total
                    }else{
                        result[element.date] = element.vp_total
                    }	
                })

                for (const [key, value] of Object.entries(result)){
                    map_result.push({x: key, y: value})
                }
                return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
            seriesSum() {
                let result = {}
                let map_result = []
                this.series.forEach(element => {
                    if(element.date in result)
                        result[element.date] = result[element.date] + element.expenses
                    else{
                        result[element.date] = element.expenses
                    }	
                })

                for (const [key, value] of Object.entries(result)){
                    map_result.push({x: key, y: value})
                }
                return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
        },
    };
</script>

<style scoped>

</style>