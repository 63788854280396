<template>
	<v-container>
		<v-card max-width="1200" class="mx-auto mt-5">
			<v-card-title>Реклама в поиске</v-card-title>
			<v-card-text>
				<div class="mt-5">
					<div class="text-subtitle-1 font-weight-medium text-center">Выбор ЛК</div>
					<v-autocomplete
						class="mt-2" v-model="form.account" :items="choices.account"
						item-text="name" item-value="value" label="Выберите личный кабинет"
						outlined hide-details dense clearable @change="get_supplier_products"
					></v-autocomplete>
				</div>
				<div class="mt-5" v-if="form.account">
					<div class="text-subtitle-1 font-weight-medium text-center">Выбор товаров с нужными категориями</div>
					<v-row class="mt-2" >
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search_products.nmid = null"
								v-model="search_products.nmid" label="Введите артикул" hide-details
								outlined dense @change="get_supplier_products"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="3">
							<v-text-field
								clearable @click:clear="search_products.name = null" v-model="search_products.name"
								label="Введите название" hide-details 
								outlined dense @change="get_supplier_products"
							></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-autocomplete
								v-model="form.products" :items="search_products.data"
								item-text="text" item-value="nmid" :loading="search_products.loading"
								label="Выберите товар" outlined hide-details dense clearable multiple
							></v-autocomplete>
						</v-col>
					</v-row>
					{{ form.products }}
				</div>
				<div class="mt-5" v-if="form.account && form.products.length > 0">
					<div class="text-subtitle-1 font-weight-medium text-center">Заполнение основых полей</div>
					<v-text-field
						label="Название кампании" outlined dense v-model="form.name"
						hide-details maxlength="50" counter class="mt-5"
					></v-text-field>
					<v-autocomplete
						v-model="form.purpose" :items="choices.purpose" item-text="name" 
						item-value="value" label="Цель" outlined dense hide-details 
						clearable class="mt-5"
					></v-autocomplete>
					<v-text-field
						v-model="form.comment" label="Комментарий" outlined dense 
						clearable hide-details class="mt-5"
					></v-text-field>
					<v-text-field
						v-model="form.budget" label="Бюджет" outlined dense 
						clearable hide-details class="mt-5"
					></v-text-field>
					<v-text-field
						v-model="form.cpm" label="Ставка" outlined type="number" dense 
						clearable hide-details class="mt-5"
					></v-text-field>
					<div class="mt-5">
						<div class="d-flex align-center" style="font-size:14px">
							Автопополнение бюджета:
							<v-switch color="primary" class="mt-0" hide-details v-model="form.is_robot_budget"></v-switch>
							<v-text-field
								v-if="form.is_robot_budget" v-model="form.robot_budget_value" label="Автопополнение бюджета"
								outlined type="number" dense clearable hide-details
							></v-text-field>
						</div>
						<div class="mt-5 d-flex align-center" style="font-size:14px">
							Дневной лимит:
							<v-switch color="primary" class="mt-0" hide-details v-model="form.is_robot_daily_budget"></v-switch>
							<v-text-field
								v-if="form.is_robot_daily_budget" v-model="form.robot_daily_budget_value" label="Дневной лимит"
								outlined type="number" dense clearable hide-details
							></v-text-field>
						</div>
					</div>
				</div>
				<v-btn
					depressed color="primary" class="mt-5" dark v-if="check_for_create"
					@click="create_advertisements" :loading="created_data.loading"
				>Создать</v-btn>
				<div class="mt-5" v-if="check_for_create && this.created_data.new_adverts.length > 0">
					<div class="text-subtitle-1 font-weight-medium text-center">Созданные РК</div>
					<div class="mt-2">{{ created_data.new_adverts }}</div>
				</div>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import {Advertisement} from '@/api/advertisement'

export default {
    name: 'AdvertisementCreateAutoComponent',
	props: ['choices'],
    data() {
		return {
			form: {
				account: null,
				products: [],
				name: null,
				purpose: null,
				comment: null,
				is_robot_budget: false,
				robot_budget_value: null,
				is_robot_daily_budget: false,
				robot_daily_budget_value: null,
				budget: null,
				cpm: null
			},
			search_products: {
				nmid: null,
				name: null,
				data: [],
				loading: false,
			},
			created_data:{
				new_adverts: [],
				loading: false
			}
		}
    },
	computed: {
		check_for_create(){
			if (this.form.account && this.form.products.length > 0 && this.form.name)
				return true
			return false
		}
	},
    methods: {
		async get_supplier_products (){
			if (!this.form.account || (!this.search_products.name && !this.search_products.nmid))
				return
			this.search_products.loading = true
			try{
				const resp = await Advertisement.fetchSupplierProducts({
					"account": this.form.account, "name": this.search_products.name, "nmid": this.search_products.nmid
				})
				this.search_products.data = resp.data
			} catch(err){
				this.$toasted.show(`Ошибка загрузки товаров(попробуйте через несколько секунд)`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.search_products.loading = false
		},
		async create_advertisements(){
			if (!this.check_for_create){
				this.$toasted.show(`Заполните все необходимые поля`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
				return
			}
			this.created_data.loading = true
			try{
				const resp = await Advertisement.postCreateAutoAdvertisement(this.form)
				this.created_data.new_adverts = resp.data
				this.$toasted.show(`Кампании успешно созданы`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка созданий кампаний.`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.created_data.loading = false
		}
	},
	async mounted(){
	}
}
</script>

<style lang="scss" scoped>

</style>
