<template>
	<div v-if="series && seriesMain">
		<apexchart 
			type="line" 
			height="600" 
			:options="chartOptions"
			:series="seriesMain"
			ref="chart"
			@mounted="mountedHiddenSeries"
			@updated="mountedHiddenSeries"
		></apexchart>
		<div class="control">
			<button @click="hiddenAll">Скрыть все</button>
			<button @click="showRub">Показать в руб</button>
			<button @click="showUnit">Показать в шт</button>
		</div>
	</div>
	
</template>

<script>
	import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'ChartsDetailProductData',
        props: ['series'],
		components: {
			apexchart: VueApexCharts,
        },
        data() {
            return {
				counter: 0,
				chartOptions: {
					theme: { mode: this.$vuetify.theme.dark ? 'dark': 'light'},
                    chart: {
                        height: 1000,
						width: '100%',
                        type: 'area',
						stacked: false,
                        zoom: {
                            enabled: true,
                        },
						toolbar: {
							show: true,
						},
						background: 'rgba(0, 0, 0, 0)'
                    },
					fill: {
						type: "gradient",
						gradient: {
							shade: 'light',
							type: 'vertical',
							shadeIntensity: 1,
							opacityFrom: 1,
							opacityTo: 0.1,
							stops: [0, 75, 75]
						}
					},
					stroke: {
						curve: 'smooth',
						width: 3,
					},
                    //colors: ['#cd5b45', '#cd5b45', '#27a327', '#27a327', '#000099', '#000099'],
					dataLabels: {
						enabled: false
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
					yaxis: [
						{
							showAlways: true,
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							title: {
								text: 'шт'
							},
							labels: {
								rotate:'45',
								show: true,
								formatter: function(val) {
									return `${val}шт`;
								},
							}
						},
						{
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							show:false
						}, 
						{
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							show:false
						}, 
						{
							seriesName: 'Заказы API (руб)',
							showAlways: true,
							title: {
								text: 'Рубли',
							},
							labels: {
								rotate:'-45',
								show: true,
								formatter: function(val) {
									return `${val}₽`;
								},
							},
						}, 
						{
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							show:false
						}, 
						{
							seriesName: 'Заказы API (руб)',
							show:false
						}, 
						{
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							show:false
						}, 
						{
							seriesName: 'Заказы API (руб)',
							show:false
						}, 
						{
							seriesName: 'Заказы API (руб)',
							show:false
						}, 
						{
							seriesName: 'Остаток 1С (шт)',
							opposite: true,
							show:false
						}, 
						
					],
					xaxis: {
						type: 'datetime'
					},
                },
            }
        },
		computed: {
            seriesMain() {
                return [
					{
						name: 'Остаток 1С (шт)',
						type: 'area',
						data: this.seriesStock1C,
						color: "#f2a1b0",
					},
					{
						name: 'Остаток WB (шт)',
						type: 'area',
						data: this.seriesStockWB,
						color: "#1e73be",
					},
					{
						name: 'Заказы API (шт)',
						type: 'area',
						data: this.seriesOrdersCountAPI,
						color: "#4ea853",
					},
					{
						name: 'Заказы API (руб)',
						type: 'area',
						data: this.seriesOrdersSumAPI,
						color: "#ffbc23",
					},
					{
						name: 'Заказы WB (шт)',
						type: 'area',
						data: this.seriesOrdersCountWB,
						color: "#5800a3",
					},
					{
						name: 'Заказы WB (руб)',
						type: 'area',
						data: this.seriesOrdersSumWB,
						color: "#4d5258",
					},
					{
						name: 'Продажи (шт)',
						type: 'area',
						data: this.seriesSalesCount,
						color: "#4cbfc0",
					},
					{
						name: 'Продажи (руб)',
						type: 'area',
						data: this.seriesSalesSum,
						color: "#8f2410",
					},
					{
						name: 'Сумма выкупов (руб)',
						type: 'area',
						data: this.seriesRedemptionSum,
						color: "#bde7b3",
					},
					{
						name: 'Видимость ключевых слов',
						type: 'area',
						data: this.seriesVisibility,
						color: "#415e9b",
					},
					
				];
            },			
			seriesRedemptionSum(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.redemption_sum || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesStock1C(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.catalog_count || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesStockWB(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.stock_balance || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesOrdersCountAPI(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: item.orders_count || 0
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesOrdersSumAPI(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: item.orders_sum || 0
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesOrdersCountWB(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.parsing_orders_count || 0) * -1
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesOrdersSumWB(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.parsing_orders_sum || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesSalesCount(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.report_sales_count || 0) != 0 ? item.report_sales_count : (item.sales_count || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesSalesSum(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.report_sales_sum || 0) != 0 ? item.report_sales_sum : (item.sales_sum || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			seriesVisibility(){
				const map_result =  this.series.map(item => (
					{
						x: item.date,
						y: (item.product_monitoring_visibility || 0)
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
			
        },
		methods: {
			showRub(){
				this.hiddenAll()
				let array = [
					"Заказы API (руб)", "Заказы WB (руб)", 
					"Продажи (руб)", "Сумма выкупов (руб)"
				]
				array.forEach(item=>{
					this.$refs.chart.showSeries(item)
				})
			},
			showUnit(){
				this.hiddenAll()
				let array = [
					"Остаток 1С (шт)", "Остаток WB (шт)", "Заказы API (шт)",
					"Продажи (шт)" , "Видимость ключевых слов", "Заказы WB (шт)"
				]
				array.forEach(item=>{
					this.$refs.chart.showSeries(item)
				})
			},
			mountedHiddenSeries(){
				if (this.$refs.chart && this.counter < 1){
					this.counter += 1
					this.$refs.chart.hideSeries("Заказы WB (руб)")
					this.$refs.chart.hideSeries("Заказы WB (шт)")
				}
			},
			hiddenAll(){
				this.seriesMain.forEach(item=>{
					this.$refs.chart.hideSeries(item.name)
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.control{
		display: flex;
		justify-content: center;
		width: 100%;
		button {
			margin: 0 10px;
			&:hover{
				text-decoration: underline;
			}
			
		}
	}
</style>