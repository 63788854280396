<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="loading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
        <div v-else>
            <div v-if="data.item.data.is_kit">
                <v-chip
                        class="ma-2"
                        color="green"
                        text-color="white"
                        small
                >
                    Комплект
                </v-chip>
                <v-simple-table v-if="data.item" dense class="mb-1 small-font vertical-border elevation-1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Название
                            </th>
                            <th class="text-left">
                                Артикул
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ data.item.data.name }}</td>
                            <td>{{ data.item.data.code }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-subheader>Остатки</v-subheader>
                <v-simple-table v-if="data.item.stocks" dense class="mb-1 small-font vertical-border elevation-1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Кол-во (Остатки)
                            </th>
                            <th class="text-left">
                                Кол-во закупок
                            </th>
                            <th class="text-left">
                                Кол-во товаров в закупках
                            </th>
                            <th class="text-left">
                                Кол-во комплектов
                            </th>
                            <th class="text-left">
                                Кол-во комплектов(c\c)
                            </th>
                            <th class="text-left">
                                Себест
                            </th>
                            <th class="text-left">
                                Цена (Остатки)
                            </th>
                            <th class="text-left">
                                Цена (Закупка)
                            </th>
                            <th class="text-left">
                                Цена (Комплект)
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="stock in data.item.stocks" :key="stock.id">
                            <td>{{ stock.count }}</td>
                            <td>{{ stock.purchases_count }}</td>
                            <td>{{ stock.purchases_products_count }}</td>
                            <td>{{ stock.kit_count }}</td>
                            <td>{{ stock.kit_count_purchases }}</td>
                            <td>{{ stock.price }}</td>
                            <td>{{ stock.stock_price }}</td>
                            <td>{{ stock.purchases_price }}</td>
                            <td>{{ stock.kit_price }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-subheader>Закупки</v-subheader>
                <v-simple-table v-if="data.item.purchases" dense class="mb-5 small-font vertical-border elevation-1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Дата
                            </th>
                            <th class="text-left">
                                Номер
                            </th>
                            <th class="text-left">
                                Кол-во
                            </th>
                            <th class="text-left">
                                Цена
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="p in data.item.purchases" :key="p.id" :class="{'green lighten-4': p.for_cost_price_calculation}">
                            <td>{{ p.purchase.date }}</td>
                            <td>{{ p.purchase.number }}</td>
                            <td>{{ p.count }}</td>
                            <td>{{ p.price_per_item }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-subheader>Компоненты</v-subheader>
                <div v-for="(component, component_id) in data.components" :key="component_id" dense class="mb-1 small-font vertical-border elevation-1">
                    <div class="px-2">
                        <v-badge
                                bordered
                                color="brown darken-1"
                                :content="component.data.count"
                                overlap
                        >
                            <v-btn
                                    class="white--text"
                                    color="brown lighten-1 disabled"
                                    depressed
                                    small
                            >
                                {{ component.data.kit_component_name }} ({{ component.data.kit_component_code }})
                            </v-btn>
                        </v-badge>
                    </div>
                    <section v-if="component.stocks.length > 0" >
                        <v-subheader>Остатки</v-subheader>
                        <v-simple-table dense class="mb-1 small-font vertical-border elevation-1">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Кол-во (Остатки)
                                    </th>
                                    <th class="text-left">
                                        Кол-во закупок
                                    </th>
                                    <th class="text-left">
                                        Кол-во товаров в закупках
                                    </th>
                                    <th class="text-left">
                                        Себест
                                    </th>
                                    <th class="text-left">
                                        Цена (Остатки)
                                    </th>
                                    <th class="text-left">
                                        Цена (Закупка)
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="stock in component.stocks" :key="stock.id">
                                    <td>{{ stock.count }}</td>
                                    <td>{{ stock.purchases_count }}</td>
                                    <td>{{ stock.purchases_products_count }}</td>
                                    <td>{{ stock.price }}</td>
                                    <td>{{ stock.stock_price }}</td>
                                    <td>{{ stock.purchases_price }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </section>
                    <section v-if="component.purchases.length > 0">
                        <v-subheader>Закупки</v-subheader>
                        <v-simple-table dense class="mb-5 small-font vertical-border elevation-1">
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Дата
                                    </th>
                                    <th class="text-left">
                                        Номер
                                    </th>
                                    <th class="text-left">
                                        Кол-во
                                    </th>
                                    <th class="text-left">
                                        Цена
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="p in component.purchases" :key="p.id" :class="{'green lighten-4': p.for_cost_price_calculation}">
                                    <td>{{ p.purchase.date }}</td>
                                    <td>{{ p.purchase.number }}</td>
                                    <td>{{ p.count }}</td>
                                    <td>{{ p.price_per_item }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </section>
                </div>
            </div>
            <div v-else>
                <v-simple-table v-if="data.stocks" dense class="mb-5 small-font vertical-border elevation-1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Кол-во (Остатки)
                            </th>
                            <th class="text-left">
                                Кол-во закупок
                            </th>
                            <th class="text-left">
                                Кол-во товаров в закупках
                            </th>
                            <th class="text-left">
                                Себест
                            </th>
                            <th class="text-left">
                                Цена (Остатки)
                            </th>
                            <th class="text-left">
                                Цена (Закупка)
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="stock in data.stocks" :key="stock.id">
                            <td>{{ stock.count }}</td>
                            <td>{{ stock.purchases_count }}</td>
                            <td>{{ stock.purchases_products_count }}</td>
                            <td>{{ stock.price }}</td>
                            <td>{{ stock.stock_price }}</td>
                            <td>{{ stock.purchases_price }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-subheader>Закупки</v-subheader>
                <v-simple-table v-if="data.item.purchases" dense class="mb-5 small-font vertical-border elevation-1">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Дата
                            </th>
                            <th class="text-left">
                                Номер
                            </th>
                            <th class="text-left">
                                Кол-во
                            </th>
                            <th class="text-left">
                                Цена
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="p in data.item.purchases" :key="p.id" :class="{'green lighten-4': p.for_cost_price_calculation}">
                            <td>{{ p.purchase.date }}</td>
                            <td>{{ p.purchase.number }}</td>
                            <td>{{ p.count }}</td>
                            <td>{{ p.price_per_item }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>
    </v-sheet>
</template>

<script>
	import {Catalog} from '@/api/catalog';

	export default {
		name: 'CostPriceDetails',
		props: {
			catalog_item_id: {
				type: String,
			},
			date: {
				type: String,
			},
		},
		data() {
			return {
				loading: true,
				data: null,
				componentsTableHeaders: [
					{text: 'Название', value: 'kit_component_name', sortable: false, width: '1%'},
					{text: 'Артикул 1С', value: 'kit_component_code', sortable: false, width: '1%'},
					{text: 'Кол-во', value: 'count', sortable: false, width: '1%'},
					{text: 'Закупки', value: 'data-table-expand', sortable: false, width: '1%'},
				],
				componentsPurchasesTableHeaders: [
					{text: 'Дата', value: 'purchase.date', sortable: false, width: '1%'},
					{text: 'Номер', value: 'purchase.number', sortable: false, width: '1%'},
					{text: 'Кол-во', value: 'count', sortable: false, width: '1%'},
					{text: 'Цена', value: 'price_per_item', sortable: false, width: '1%'},
				],
                componentTableExpanded: []
			};
		},
		computed: {},
		async mounted() {
			await this.fetchProductCostPriceDetails();
		},
		methods: {
			async fetchProductCostPriceDetails() {
				this.loading = true;
				try {
					const res = await Catalog.fetchProductCostPriceDetails(
						this.catalog_item_id,
						this.$options.filters.formatDateToQuery(this.date),
					);
					this.data = res.data;
				} catch (err) {
					console.log(err);
				}
				this.loading = false;
			},
		},
	};
</script>

<style scoped>

</style>