

<template>
    <v-row>
		<v-dialog v-model="export_advert_cpm.dialog" v-if="export_advert_cpm.dialog" max-width="800px">
			<v-card>
				<v-card-title>Выгрузка по местам и ставкам</v-card-title>
				<v-card-text class="mt-5">
					<v-text-field
						v-model="export_advert_cpm.adverts"
						label="Список РК(через запятую)"
						outlined
						class="mt-3"
						dense
						clearable
						hint="Если поле пустое, то выгрузка будет включать все РК. "
					></v-text-field>
					<v-checkbox
						v-model="export_advert_cpm.only_search"
						class="mt-0"
						label="Только реклама в поиске"
					></v-checkbox>
					<v-row>
						<v-col>
							<v-text-field
								v-model="export_advert_cpm.date_from"
								label="Дата начала(в формате YYYY-mm-dd)"
								outlined
								class="mt-3"
								dense
								clearable
								hint="Оставьте поля пустыми, чтобы выгрузить все дни"
							></v-text-field>
						</v-col>
						<v-col>
							<v-text-field
								v-model="export_advert_cpm.date_to"
								label="Дата конца(в формате YYYY-mm-dd)"
								outlined
								class="mt-3"
								dense
								clearable
								hint="Оставьте поля пустыми, чтобы выгрузить все дни"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					<v-btn 
						color="blue darken-1" 
						text 
						@click="exportAdvertCpmPlacement"
						:loading="export_advert_cpm.loading"
					>Скачать</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_charts" max-width="100%">
			<v-card>
				<v-card-title>График</v-card-title>
				<v-card-text class="mt-5">
					<AllStatsChart ref="chart_adver" :filters="transfromFiltersForQuery"/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog_charts=false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_new_token" max-width="600px">
			<v-card>
				<v-card-title>Новый токен рекламы</v-card-title>
				<v-card-text class="mt-5">
					<div class="item-form">
						<v-text-field
							label="Токен"
							outlined
							v-model="add_new_token.token"
							hide-details
							class="item-form__input"
						></v-text-field>
						<v-btn 
							@click="setNewToken" 
							class="mt-3" 
							tabindex="4"
							:loading="add_new_token.loading"
							color="primary"
						>Обновить</v-btn>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-col sm="12" lg="12" class="px-10">
            <v-row>
                <v-col>
                    <router-link target="_blank" :to="{name: 'advertisement_new_stats'}">
                        <v-icon> mdi-poll </v-icon>
                    </router-link>
					<router-link target="_blank" class="ml-5" :to="{name: 'advertisement_new_stats_v2'}">
                        <v-icon> mdi-poll </v-icon>
                    </router-link>
					<v-icon class="ml-5" @click="dialog_new_token=true"> mdi-information </v-icon>
					<v-icon color="green" v-if="check_status_token" class="ml-5">mdi-checkbox-marked-circle</v-icon>
					<v-icon color="red" v-else class="ml-5">mdi-close-circle</v-icon>
                </v-col>
                <v-col>
                    <div>
                        Расход вчера/неделя:
                        <br>
                        <span :class="{'red-font': deltaExpenditureDay > 0, 'green-font': deltaExpenditureDay < 0}">
                            {{stats_advertisement.expenditure.today}}р ({{deltaExpenditureDay}}%)
                        </span>
                        /
                        <span :class="{'red-font': deltaExpenditureWeek > 0, 'green-font': deltaExpenditureWeek < 0}">
                            {{stats_advertisement.expenditure.current_week}}р ({{deltaExpenditureWeek}}%)
                        </span>
                    </div>
                </v-col>
                <v-col>
                    <div>
                        ВП вчера/неделя:
                        <br>
                        <span :class="{'red-font': deltaVpDay < 0, 'green-font': deltaVpDay > 0}">
                            {{stats_advertisement.vp.today}}р ({{deltaVpDay}}%)
                        </span>
                        /
                        <span :class="{'red-font': deltaVpWeek < 0, 'green-font': deltaVpWeek > 0}">
                            {{stats_advertisement.vp.current_week}}р ({{deltaVpWeek}}%)
                        </span>
                    </div>
                </v-col>
                <v-col>
                    <div>
                        Доходность вчера/неделя:
                        <br>
                        <span :class="{'red-font': deltaProfitDay < 0, 'green-font': deltaProfitDay > 0}">
                            {{stats_advertisement.profit.today}}р ({{deltaProfitDay}}%)
                        </span>
                        /
                        <span :class="{'red-font': deltaProfitWeek < 0, 'green-font': deltaProfitWeek > 0}">
                            {{stats_advertisement.profit.current_week}}р ({{deltaProfitWeek}}%)
                        </span>
                    </div>
                   
                </v-col>
                <v-col>
                    <div>
                        ДРР вчера/неделя:
                        <br>
                        <span :class="{'red-font': deltaDrrDay > 0, 'green-font': deltaDrrDay < 0}">
                            {{stats_advertisement.drr.today}}% ({{deltaDrrDay}}%)
                        </span>
                        /
                        <span :class="{'red-font': deltaDrrWeek > 0, 'green-font': deltaDrrWeek < 0}">
                            {{stats_advertisement.drr.current_week}}% ({{deltaDrrWeek}}%)
                        </span>
                    </div>
                </v-col>
				<v-col style="display: flex; align-items:center;">
					<div>
						<v-btn
							small
							dense
							dark
							color="purple darken-2"
							@click="openDiagCharts"
						>Показать график</v-btn>
					</div>
				</v-col>
            </v-row>
            
            <div class="title">Фильтры</div>
            <v-row class="mt-3">
                <v-col>
                    <v-select
                        v-model="filters.suppliers"
                        :items="suppliers"
                        item-text="name"
                        item-value="id"
                        label="Аккаунт"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="filters.type"
                        :items="choices.advertisement_type"
                        item-text="name"
                        item-value="name"
                        label="Вид рекламы"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="filters.status"
                        :items="choices.advertisement_status"
                        item-text="name"
                        item-value="name"
                        label="Статус"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="filters.nmid"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Артикул"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                        v-model="filters.id_pk"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="id РК"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-select
                        v-model="filters.group"
                        :items="choices.advertisement_group"
                        item-text="name"
                        item-value="value"
                        label="Группа товаров"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-select
                        v-model="filters.purpose"
                        :items="choices.advertisement_purpose"
                        item-text="name"
                        item-value="value"
                        label="Цель РК"
                        outlined
                        multiple
                        hide-details
                        dense
                        clearable
                    ></v-select>
                </v-col>
				<v-col>
                    <v-text-field
                        v-model="filters.comment"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Комментарий"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
				<v-col>
                    <v-text-field
                        v-model="filters.product_name"
                        clearable
                        outlined
                        dense
                        append-icon="mdi-magnify"
                        label="Название товара"
                        hide-details="hide-details">
                    </v-text-field>
                </v-col>
                <v-col>
                    <v-btn
                            @click="applyFilter()"
                            depressed
                            color="purple darken-2"
                            class="mb-3"
                            dark
                    >
                        Применить
                    </v-btn>
                </v-col>
            </v-row>
            
        </v-col>
        <v-col sm="12" lg="12">
            <v-data-table
                :headers="headers"
                :items="data_advertisement"
                class="elevation-0 mt-0 vertical-border small-font"
                :loading="tableLoading"
                @update:page="pageChanged"
                :options.sync="options"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 15, 25, 50]
                }"
                :server-items-length="pagination.totalItems"
            >
				<template v-slot:header.profit_plan="{ header }">
					<div v-if="header.value=='profit_plan'">
						<div 
							@click="changeCustomSearch('profit_plan')"
							:class="['custom_search', {
								'active_search_by': isCurrentSearchBy('profit_plan'),
								'active_search_desc': isCurrentSearchDesc('profit_plan')
							}]"
						>
							Доход 
							<v-icon>
								mdi-arrow-up
							</v-icon>
							
						/</div>
						<div 
							@click="changeCustomSearch('drr_plan')"
							:class="['custom_search', {
								'active_search_by': isCurrentSearchBy('drr_plan'),
								'active_search_desc': isCurrentSearchDesc('drr_plan')
							}]"
						>
							ДРР
							<v-icon>
								mdi-arrow-up
							</v-icon>
						</div>
					</div>
				</template>

                <template v-slot:item.banner="{ item }">
                    <div style="display: flex; padding: 5px 0;">
                        <img 
                            style="max-height:70px" 
                            :src="el" 
                            alt="Фото удалено"
                            v-for="(el, index) in item.banner" 
                            :key="index"
                        >
                    </div>
                </template>
                <template v-slot:item.campaign_id="{ item }">
                    <a 
                        target="_blank" 
                        :href="`https://cmp.wildberries.ru/campaigns/list/active/edit/${getUrlTypeName(item)}/${item.campaign_id}`"
                    >{{item.campaign_id}}</a>
                    <hr>
                    <router-link 
                        target="_blank" 
                        :to="{ 
                            name: 'advertisement_new_detail', 
                            params: { id: item.id }
                    }">
                        {{ item.campaign_name }}
                    </router-link>
                </template>
                <template v-slot:item.campaign_type="{ item }">
                    <div>{{item.campaign_type}}</div>
                    <hr>
                    <div>{{item.status}}</div>
                </template>
                <template v-slot:item.create_date="{ item }">
                    <p>{{showDate(item.create_date)}}</p>
                </template>
                <template v-slot:item.general_sum="{ item }">
                    <div>{{item.general_orders}}</div>
                    <hr>
                    <div>{{item.general_sum}}</div>
                </template>
                <template v-slot:item.current_bet="{ item }">
                    <div>{{item.current_bet}}</div>
                    <hr>
                    <div 
                        v-if="item.recommended_bet_test && item.current_bet != item.recommended_bet_test && !getPreloaderItem(item.id)" 
                        :class="{
                            'green-recommended-bet': item.current_bet > item.recommended_bet_test, 
                            'red-recommended-bet': item.current_bet < item.recommended_bet_test
                        }"
                        style="cursor: pointer;"
                        @click="updateBetToRecommended(item, item.recommended_bet_test)"
                    >
                        {{item.recommended_bet_test}} Ок
                    </div>
                    <div v-else-if="getPreloaderItem(item.id)">
                        <v-progress-circular
                            :size="15"
                            :width="2"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </template>
                <template v-slot:item.all_budget="{ item }">
                    <div>{{item.all_budget}}</div>
                    <hr>
                    <div>{{item.daily_budget}}</div>
                </template>
                <template v-slot:item.wanted_placement="{ item }">
                    <div>{{item.wanted_placement}}</div>
                    <hr>
                    <div>{{item.max_bet}}</div>
					<hr>
                    <div 
                        v-if="item.recommended_max_bet && item.max_bet != item.recommended_max_bet && !getPreloaderMaxBet(item.id)" 
                        :class="{
                            'green-recommended-bet': item.max_bet > item.recommended_max_bet, 
                            'red-recommended-bet': item.max_bet < item.recommended_max_bet
                        }"
                        style="cursor: pointer;"
						@click="updateMaxBetToRecommended(item, item.recommended_max_bet)"
                    >
                        {{item.recommended_max_bet}} Ок
                    </div>
					<div v-else-if="getPreloaderMaxBet(item.id)">
                        <v-progress-circular
                            :size="15"
                            :width="2"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
					<hr>
					<div 
                        v-if="	item.recommended_max_bet_by_wanted_placement && 
								item.max_bet != item.recommended_max_bet_by_wanted_placement && 
								!getPreloaderMaxBet(item.id)
							"
						class="yellow-recommended-bet" 
                        style="cursor: pointer;"
						@click="updateMaxBetToRecommended(item, item.recommended_max_bet_by_wanted_placement)"
                    >
                        {{item.recommended_max_bet_by_wanted_placement}} Ок
                    </div>
					<div v-else-if="getPreloaderMaxBet(item.id)">
                        <v-progress-circular
                            :size="15"
                            :width="2"
                            color="primary"
                            indeterminate
                        ></v-progress-circular>
                    </div>
                </template>
                <template v-slot:item.profit_plan="{ item }">
                    <div
                        :class="{'green-recommended-bet': item.profit_plan >= 0 && item.profit_plan, 'red-recommended-bet': item.profit_plan < 0}"
                    >{{item.profit_plan ? item.profit_plan: 0}}</div>
                    <hr>
                    <div>{{item.drr_plan ? item.drr_plan: 0}}</div>
                </template>
                <template v-slot:item.group="{ item }">
                    <div>{{item.group}}</div>
                    <hr>
                    <div>{{item.purpose_display}}</div>
                </template>
				<template v-slot:item.top_keyword="{ item }">
                    <div>
						{{item.top_keyword}} 
					</div>
					<div style="color:red; font-size:20px;" v-if="!item.is_real_keyword">!</div>
                </template>
                <template v-slot:item.all_bet_from_RK="{ item }">
                    <div v-if="item.all_bet_from_RK">
                        <v-tooltip bottom max-width=350>
                            <template v-slot:activator="{ on, attrs }">
                                <div 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    v-if="item.wanted_placement" 
                                >
                                    {{item.wanted_placement}}e: 
                                    {{
                                        item.all_bet_from_RK[item.wanted_placement - 1] ? 
                                        item.all_bet_from_RK[item.wanted_placement - 1] : ""
                                    }}
                                </div>
                                <div 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    v-else
                                >
                                    1e: {{item.all_bet_from_RK[0]}}
                                </div>
                            </template>
                            <div 
                                v-for="(el, index) in item.all_bet_from_RK" 
                                :key="index"
                            >
                                {{index + 1}}e: {{el}}
                            </div>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.all_bet_from_parsing="{ item }">
                    <div v-if="item.all_bet_from_parsing && item.all_bet_from_parsing.length != 0">
                        <v-tooltip bottom max-width=350>
                            <template v-slot:activator="{ on, attrs }">
                                <div 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    v-if="item.wanted_placement" 
                                >
                                    {{item.wanted_placement}}e: {{
                                        item.all_bet_from_parsing[item.wanted_placement - 1] ? 
                                        item.all_bet_from_parsing[item.wanted_placement - 1] : 
                                        ""
                                        }}
                                </div>
                                <div 
                                    v-bind="attrs" 
                                    v-on="on" 
                                    v-else
                                >
                                    1e: {{item.all_bet_from_parsing[0]}}
                                </div>
                            </template>
                            
                            <div 
                                v-for="(el, index) in item.all_bet_from_parsing" 
                                :key="index"
                            >
                                {{index + 1}}e: {{el}}
                            </div>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.update_item="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="updateOneItem(item)"
                    >
                        mdi-reload
                    </v-icon>
                </template>   
				<template v-slot:item.robot="{ item }">
                    <v-switch
						v-if="item.url_type_name != 'catalog'"
						color="primary"
						class="mt-0"
						hide-details
						v-model="item.robot"
						@change="changeRobotStatus(item)"
					></v-switch>
                </template>       
				<template v-slot:item.rentab_value="{ item }">
					<div style="white-space: nowrap;" :class="{'green-recommended-bet': item.rentab.rentab_plan >= 15, 'red-recommended-bet': item.rentab.rentab_plan < 15}">
						План: {{ item.rentab.rentab_plan ? item.rentab.rentab_plan : 0 }}% ({{ item.rentab.vp_plan ? item.rentab.vp_plan : 0 | toRUB }})
					</div>
					<hr>
					<div style="white-space: nowrap;" :class="{'green-recommended-bet': item.rentab.rentab_sales >= 15, 'red-recommended-bet': item.rentab.rentab_sales < 15}">
						Факт: {{ item.rentab.rentab_sales ? item.rentab.rentab_sales : 0 }}% ({{ item.rentab.vp_sales ? item.rentab.vp_sales : 0 | toRUB }})
					</div>
				</template>         
                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editAdvertisementCampaign(item)"
                    >
                        mdi-pencil
                    </v-icon>
                </template>
                <template v-slot:top>
					<v-toolbar flat>
						<v-row class="justify-space-between" style="padding:0 24px 24px 24px">
							<v-dialog v-model="dialog_editable" max-width="500px">
								<v-card>
									<v-card-title>Окно редактирования</v-card-title>
									<v-card-text class="mt-5">
										<v-text-field
											v-model="editData.wanted_placement"
											label="Желаемое место"
											outlined
											type="number"
											dense
											clearable
										></v-text-field>
										<v-text-field
											v-model="editData.current_bet"
											label="Ставка"
											outlined
											type="number"
											dense
											clearable
										></v-text-field>
										<v-text-field
											v-model="editData.max_bet"
											label="Максимальная ставка"
											outlined
											type="number"
											dense
											clearable
										></v-text-field>
										<v-text-field
											v-model="editData.daily_budget"
											label="Дневной лимит"
											outlined
											type="number"
											dense
											clearable
										></v-text-field>
										<v-text-field
											v-model="editData.comment"
											label="Комментарий"
											outlined
											dense
											clearable
										></v-text-field>
										<v-select
											v-model="editData.purpose"
											:items="choices.advertisement_purpose"
											item-text="name"
											item-value="value"
											label="Цель"
											outlined
											dense
											hide-details
											clearable
										></v-select>
										<v-text-field
											v-model="editData.change_budget"
											label="Пополнение бюджета"
											outlined
											type="number"
											class="mt-6"
											dense
											clearable
											hint="Сумма должна быть в пределе [100; 10000] рублей. Сумма должна быть кратна 50"
										></v-text-field>
									</v-card-text>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="blue darken-1" text @click="closeWindowEditable">Закрыть</v-btn>
										<v-btn color="blue darken-1" text @click="updateAdvertisement()">Изменить</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</v-row>
						<v-btn
							color="primary"
                            dark
                            class="mb-2"
                            @click="export_advert_cpm.dialog = true"
						>Выгрузка по местам и ставкам</v-btn>
						<v-btn
                            color="primary"
                            dark
                            class="mb-2 ml-5"
                            @click="$router.push({name: 'advertisement_new_create'})"
                        >
                            Создание РК
                        </v-btn>
						<v-btn
                            color="primary"
                            dark
                            class="mb-2 ml-5"
                            @click="$router.push({name: 'advertisement_new_create_search_plural'})"
                        >
                            Массовое создание РК
                        </v-btn>
						<v-btn
                            color="primary" dark class="mb-2 ml-5"
                            @click="$router.push({name: 'advertisement_new_create_auto'})"
                        >
                            Создание автоматической РК
                        </v-btn>
					</v-toolbar>

                </template>
            </v-data-table>
        </v-col>
        <v-col sm="12" lg="12" class="justify-space-between d-flex px-10">
            <v-btn @click="changeStep(1)">
                Войти под другим аккаунтом
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import {General} from '@/api/general'
import AllStatsChart from "@/components/AdvertisementNew/AllStatsChart"

export default {
    name: 'AdvertisementTable',
	components: {
		AllStatsChart
	},
    data() {
        return {
			export_advert_cpm: {
				dialog: false,
				loading: false,
				adverts: null,
				only_search: true,
				date_from: null,
				date_to: null
			},
			check_status_token: false,
			add_new_token: {
				token: null,
                loading: false,
			},
			dialog_new_token: false,
			dialog_charts: false,
            tableLoading: false,
			first_init: false,
            data_advertisement: [],
            stats_advertisement: null,
            preloader_array_bool: "",
			preloader_max_bet_array_bool: "",
            current_advertisement_item_form: null,
            filter_suppliers: null,
            filters: {
                type: null,
                status: ["Активна"],
                nmid: null,
                id_pk: null,
                group: null,
                purpose: null,
				product_name: null,
				comment: null,
				suppliers: null
            },
			suppliers: null,
            dialog_editable: false,
            options: {},
            editData: {
                wanted_placement: null,
                current_bet: null,
                max_bet: null,
                comment: null,
                purpose: null,
                daily_budget: null,
                change_budget: null
            },
            choices: {
                advertisement_type: null,
                advertisement_status: null,
                advertisement_group: null,
                advertisement_purpose: null
            },
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
            headers: [
                { text: 'Баннер', value: 'banner', sortable: false, width: '1%' },
                { text: 'ID / Название', value: 'campaign_id', sortable: false, width: '1%' },
                { text: 'Вид рекламы / Статус', value: 'campaign_type', sortable: false , width: '1%'},
                { text: 'Создан', value: 'create_date', sortable: true , width: '1%'},
                { text: 'Бюджет / Дневн. бюджет', value: 'all_budget', sortable: true , width: '1%'},
                { text: 'Ставка', value: 'current_bet', sortable: false , width: '1%'},
                { text: 'Место в карточке', value: 'place', sortable: false , width: '1%'},
                { text: 'Желаемое место / Макс. ставка', value: 'wanted_placement', sortable: false , width: '1%'},
                { text: 'Ставка за желаемое место', value: 'all_bet_from_RK', sortable: false, width: '1%' },
                { text: "Ставка парсинг", value: 'all_bet_from_parsing', sortable: false, width: '1%'},
                { text: "Oбщее кол-во заказов / расход", value: 'general_sum', sortable: true, width: '1%'},
                { text: "Доход / ДРР", value: 'profit_plan', sortable: false, width: '3%'},
				{ text: 'Маржинальность (ВП)', value: 'rentab_value', width: '2%', sortable: false },
                { text: 'Группа товаров / Цель', value: 'group', sortable: false, width: '1%' },
                { text: 'Топовая поисковая фраза', value: 'top_keyword', sortable: false, width: '1%' },
                { text: 'Комментарий', value: 'comment', sortable: false, width: '1%' },
				{ text: 'Робот', value: 'robot', sortable: false, width: '1%' },
                { text: 'Обновление', value: 'update_item', sortable: false, width: '1%', align: 'center'},
                { text: 'Изменить', value: 'actions', sortable: false, width: '1%', align:"center"},
            ]
        }
    },
    computed: {
		isAdvertisementRoot() {
            return this.$store.getters.isAdvertisementRoot || false
        },
        transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
        deltaExpenditureDay(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.expenditure.today / this.stats_advertisement.expenditure.prev_day - 1) * 10000) / 100
            return 0
        },
        deltaExpenditureWeek(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.expenditure.current_week / this.stats_advertisement.expenditure.prev_week - 1) * 10000) / 100
            return 0
        },
        deltaVpDay(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.vp.today / this.stats_advertisement.vp.prev_day - 1) * 10000) / 100
            return 0
        },
        deltaVpWeek(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.vp.current_week / this.stats_advertisement.vp.prev_week - 1) * 10000) / 100
            return 0
        },
        deltaProfitDay(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.profit.today / this.stats_advertisement.profit.prev_day - 1) * 10000) / 100
            return 0
        },
        deltaProfitWeek(){
            if(this.stats_advertisement)
                return Math.round((this.stats_advertisement.profit.current_week / this.stats_advertisement.profit.prev_week - 1) * 10000) / 100
            return 0
        },
        deltaDrrDay(){
            if(this.stats_advertisement.drr.prev_day != 0)
                return Math.round((this.stats_advertisement.drr.today / this.stats_advertisement.drr.prev_day - 1) * 10000) / 100
            else if (this.stats_advertisement.drr.prev_day == 0 && this.stats_advertisement.drr.today != 0)
                return 100
            return 0
        },
        deltaDrrWeek(){
            if(this.stats_advertisement.drr.prev_week != 0)
                return Math.round((this.stats_advertisement.drr.current_week / this.stats_advertisement.drr.prev_week - 1) * 10000) / 100
            else if (this.stats_advertisement.drr.prev_week == 0 && this.stats_advertisement.drr.current_week != 0)
                return 100
            return 0
        },
    },
	watch: {
        'options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.getAdvertisementData()
			}
        },
	},
    methods: {
		async fetchWbAccounts () {
            const response = await General.getWbAccounts()
            this.suppliers = response.data
        },
		async openDiagCharts(){
			this.dialog_charts = true
			console.log(this.$refs.dialog_charts)
			if (this.$refs.chart_adver){
				this.$refs.chart_adver.getData()
			}
		},
		isCurrentSearchBy(name){
			return this.options.sortBy && this.options.sortBy[0] == name
		},
		isCurrentSearchDesc(name){
			return this.options.sortDesc && this.options.sortDesc[0]
		},
		changeCustomSearch(name){
			if(this.options.sortBy && this.options.sortBy[0] == name){
				if(this.options.sortDesc && this.options.sortDesc[0] == false){
					this.options.sortDesc = [true]
				}
				else{
					this.options.sortBy = []
					this.options.sortDesc = []
				}
			}
			else{
				this.options.sortDesc = [false]
				this.options.sortBy = [name]
			}
		},
		getPreloaderMaxBet(id){
			if (this.preloader_max_bet_array_bool == id)
                return true
            return false
		},
        getPreloaderItem(id){
            if (this.preloader_array_bool == id)
                return true
            return false
        },
        async updateOneItem(item){
            try {
                const response = await AdvertisementNew.updateOneAdvertisementData(item.id)
                if (response.status == 200)
                    await this.getAdvertisementData()
                } catch (err) {
                 console.log(err.data)
            }
        },
        async applyFilter(){
            this.options.page = 1
            await this.getAdvertisementData()
        },
        editAdvertisementCampaign (item) {
            this.current_advertisement_item_form = item
            this.editData.wanted_placement = item.wanted_placement
            this.editData.current_bet = item.current_bet
            this.editData.comment = item.comment
            this.editData.max_bet = item.max_bet
            this.editData.purpose = item.purpose
            this.editData.daily_budget = item.daily_budget
            this.editData.change_budget = null
            this.dialog_editable = true
        },

        async closeWindowEditable () {
            this.dialog_editable = false
            this.current_advertisement_item_form = null
        },
		async changeRobotStatus(item){
			let send_dict = {'robot': item.robot}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					item.id, 
					send_dict
				)
				if (response.status == 200){
					await this.getAdvertisementData() 
				}
			} catch (err) {
				console.error(err.response)
			}
		},
        async updateAdvertisement (){
            let send_dict = {}
            if (this.current_advertisement_item_form.id && this.editData.wanted_placement != this.current_advertisement_item_form.wanted_placement)
                send_dict['wanted_placement'] = this.editData.wanted_placement ? this.editData.wanted_placement : null
            if (this.current_advertisement_item_form.id && this.editData.current_bet != this.current_advertisement_item_form.current_bet)
                send_dict['current_bet'] = this.editData.current_bet ? this.editData.current_bet : null
            if (this.current_advertisement_item_form.id && this.editData.max_bet != this.current_advertisement_item_form.max_bet)
                send_dict['max_bet'] = this.editData.max_bet ? this.editData.max_bet : null
            if (this.current_advertisement_item_form.id && this.editData.comment != this.current_advertisement_item_form.comment)
                send_dict['comment'] = this.editData.comment ? this.editData.comment : null
            if (this.current_advertisement_item_form.id && this.editData.purpose != this.current_advertisement_item_form.purpose)
                send_dict['purpose'] = this.editData.purpose ? this.editData.purpose : null
            if (this.editData.daily_budget != this.current_advertisement_item_form.daily_budget)
				send_dict['daily_budget'] = this.editData.daily_budget ? this.editData.daily_budget : null
            if (this.editData.change_budget && this.editData.change_budget >= 100 && this.editData.change_budget <= 10000 && this.editData.change_budget % 50 === 0)
				send_dict['change_budget'] = this.editData.change_budget ? this.editData.change_budget : 0
            if (send_dict){
                try {
                    let response = await AdvertisementNew.updateAdvertisement(
                        this.current_advertisement_item_form.id, 
                        send_dict
                    )
                    if (response.status == 200){
                         await this.getAdvertisementData() 
                        this.dialog_editable = false
                        this.current_advertisement_item_form = null
                    }1
                } catch (err) {
                    console.error(err.response)
                }
            }
        },
		async updateMaxBetToRecommended(obj, new_max_bet){
			let send_dict = {}
            this.preloader_max_bet_array_bool = obj.id
            if (obj.max_bet != new_max_bet){
                send_dict['max_bet'] = new_max_bet
                send_dict['recommended'] = true
            }
            if (send_dict){                
                try {
                    let response = await AdvertisementNew.updateAdvertisement(
                        obj.id, 
                        send_dict
                    )
                    if (response.status == 200){
                        await this.getAdvertisementData() 
                    }
                } catch (err) {
                    console.error(err.response)
                } finally {
                    this.preloader_max_bet_array_bool = ""
                }
            }
            this.preloader_max_bet_array_bool = ""
		},
        async updateBetToRecommended (obj, new_bet){
            let send_dict = {}
            this.preloader_array_bool = obj.id
            if (obj.current_bet != new_bet){
                send_dict['current_bet'] = new_bet
                send_dict['recommended'] = true
            }
            if (send_dict){                
                try {
                    let response = await AdvertisementNew.updateAdvertisement(
                        obj.id, 
                        send_dict
                    )
                    if (response.status == 200){
                        await this.getAdvertisementData() 
                    }
                } catch (err) {
                    console.error(err.response)
                } finally {
                    this.preloader_array_bool = ""
                }
            }
            this.preloader_array_bool = ""
        },
        async pageChanged () {
            await this.getAdvertisementData()
        },
        showDate(date){
            return date ? this.$options.filters.formatDateToLocale(date ) : "-"
        },
        async getAdvertisementData(){
            this.tableLoading = true
            const { page, itemsPerPage, sortDesc, sortBy} = this.options
			let sort_dict = {}
			if (sortDesc && sortBy)
				sort_dict['sort'] = sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]

            let pageNumber = page - 1
            try {
                let response = await AdvertisementNew.getAdvertisementCampaigns(
                    {
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                        },
                        ...this.transfromFiltersForQuery,
						...sort_dict
                    }
                )
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
                this.stats_advertisement = response.data.results.stats
                this.data_advertisement = response.data.results.objects 
            } catch (err) {
                console.error(err.response)
            }
            this.tableLoading = false
        },
        async getAdvertisementTypeAndStatusAndGroup(){
            try {
                const response = await General.fetchAdvertisementType()
                this.choices.advertisement_type = response.data
            } catch (err) {
                 console.log(err.data)
            }
            try {
                const response2 = await General.fetchAdvertisementStatus()
                this.choices.advertisement_status = response2.data
            } catch (err) {
                 console.log(err.data)
            }
            try {
                const response3 = await General.fetchProductGroup()
                this.choices.advertisement_group = response3.data
            } catch (err) {
                 console.log(err.data)
            }
        },
        async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices.advertisement_purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		async checkStatusToken(){
            try {
                const responce = await AdvertisementNew.checkStatusToken()
                if (responce.status == 200)
					this.check_status_token = true
				else{
					this.check_status_token = false
				}
            } catch (err) {
				this.check_status_token = false
            }
        },
        changeStep(n){
            this.$emit('changeStep', n)
        },
		getUrlTypeName(item){
			if (item.url_type_name == 'recom')
				return 'recommendation'
			return item.url_type_name
		},
        async setNewToken() {
            this.add_new_token.loading = true
            try {
                await AdvertisementNew.setNewTokenCmp(
                    this.add_new_token.token
                )
                this.$toasted.show(`Токен успешно обновлен`, {
                    theme: "bubble",
                    type: 'success',
                    position: "top-center",
                    duration: 3000
                })
            } catch (err) {
                if (err.response.status) {
                    this.$toasted.show("Ошибка обновления токена", {
                        theme: "bubble",
                        type: 'error',
                        position: "top-center",
                        duration: 3000
                    })
                }
            }
			await this.checkStatusToken()
            this.add_new_token.loading = false
			this.dialog_new_token = false
        },
		async exportAdvertCpmPlacement(){
			this.export_advert_cpm.loading = true
			let query = {}
			if (this.export_advert_cpm.only_search)
				query['is_search'] = true
			if (this.export_advert_cpm.adverts)
				query['adverts'] = this.export_advert_cpm.adverts
			if (this.export_advert_cpm.date_from)
				query['date_from'] = this.export_advert_cpm.date_from
			if (this.export_advert_cpm.date_to)
				query['date_to'] = this.export_advert_cpm.date_to
			try {
                const response = await AdvertisementNew.exportAdvertCpmPlacement(query)
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'advert_cpm_placement_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.export_advert_cpm.loading = false
			this.export_advert_cpm.dialog = false
		}
    },
    async mounted(){
		await this.fetchWbAccounts()
        await this.getAdvertisementData()
        await this.getAdvertisementTypeAndStatusAndGroup()
        await this.getAdvertisementPurpose()
		await this.checkStatusToken()
		this.first_init = true
    }
}
</script>

<style lang="scss" scoped>
	.custom_search{
		transition-duration: .3s;
		white-space: nowrap;
		cursor: pointer;
		i{
			font-size: 17px;
			transition-duration: .3s;
			opacity: 0;
		}
		&:hover{
			color: black;
			i{
				opacity: 1;
			}
			
		}
		&.active_search_by{
			color: black;
			i{
				color: black;
				opacity: 1;
			}
		}
		&.active_search_desc{
			i{
				transform: rotate(180deg);
			}
		}
	}
    .red-recommended-bet{
        background-color: #f1b7bd;
    }
    .green-recommended-bet{
        background-color: #c0f1b4;
    }
	.yellow-recommended-bet{
        background-color: #f1dbb4;
    }
    .red-font {
        color: red
    }
    .green-font {
        color: green;
    }
	.item-form{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__input{
            width: 70%;
            max-width: 70%;
        }
        &:not(:first-of-type){
            margin-top: 25px;
        }
    }
</style>