<template>
    <v-container fluid grey lighten-4 class="pa-5">
		<v-dialog v-model="form.dialog" max-width="500px" v-if="form.dialog">
			<v-card>
				<v-card-title>Создание шаблона</v-card-title>
				<v-card-text>
					<v-text-field
						v-model="form.name"
						clearable
						outlined
						dense
						label="Введите название"
						hide-details="hide-details">
					</v-text-field>
					<v-text-field
						v-model="form.email"
						class="mt-5"
						clearable
						outlined
						dense
						label="Введите email google"
						hide-details="hide-details">
					</v-text-field>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
                        @click="create_template"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
						:loading="form.loading"
                        dark
                    >Сохранить</v-btn>
				</v-card-actions>
			</v-card>    
		</v-dialog>
        <v-card class="mt-5">
            <v-card-title>
				Добавление товаров в список
				<router-link class="ml-3" target="_blank" :to="{name: 'price_management_templates'}">
					<v-icon title="Список шаблонов">mdi-frequently-asked-questions</v-icon>
				</router-link>
			</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.account"
                            :items="filters_data.list_account"
                            item-text="name"
                            item-value="id"
                            label="ЛК"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.group"
                            :items="filters_data.list_product_group"
                            item-text="name"
                            item-value="value"
                            label="Группа"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.brand"
                            :items="filters_data.list_brand"
                            item-text="name"
                            item-value="name"
                            label="Бренд"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>               
                    <v-col>
                        <v-autocomplete
                            v-model="filters.manager" :items="filters_data.list_manager"
                            label="Менеджер" outlined hide-details dense clearable multiple item-text="name" item-value="id"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                          <v-autocomplete
                            v-model="filters.status"
                            :items="filters_data.list_status"
                            item-text="name"
                            item-value="value"
                            label="Статус"
                            outlined
                            multiple
                            hide-details
                            dense
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.code_1c"
                            clearable
                            outlined
                            dense
                            label="Артикул 1С"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid"
                            clearable
                            outlined
                            dense
                            label="Артикул"
                            hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="get_products"
                            depressed
                            color="purple darken-2"
                            style="width: 100%;"
                            dark
                        >Применить</v-btn>
                    </v-col>
                </v-row>
                <div class="mt-5" style="display: flex; justify-content:space-around;">
                    <v-btn
                        @click="add_all_products_to_list"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Добавить выделенные</v-btn>
                    <v-btn
                        @click="remove_selection"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Снять выделение</v-btn>
                </div>
                <v-data-table
					ref="block_add_template"
                    :headers="table_product.headers"
                    :pageCount="pagination.numberOfPages"
                    v-model="table_product.dedicated_product"
                    :items="table_product.data"
                    item-key="id"
                    dense
                    :loading="table_product.loading"
                    :options.sync="options"
                    :items-per-page="25"
                    :server-items-length="pagination.totalItems"
                    class="elevation-1 mt-5"
					height="800px"
                    style="font-size: .6em;"
					fixed-header
                    :footer-props="{
                        'items-per-page-options': [5, 10, 25, 50, 100]
                    }"
                    show-select
                >
					<template v-slot:item.nmid="{ item }">
						<router-link 
							target="_blank" 
							:to="{ name: 'product_card', params: { id: item.nmid }}"
						>{{ item.nmid }}</router-link>
					</template>
					<template v-slot:item.photo="{ item }">
						<div class="image">
							<img style="max-height:50px" :src="get_image_path(item.nmid)" alt="">
							<img class="big_img" :src="get_image_path(item.nmid)" alt="">
						</div>
						
					</template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon 
							v-if="listAddedProductId.includes(item.id)" 
							color="green" small
							@click="remove_single_added_product(item)"
						>
                            mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon @click="add_single_product_to_list(item)" v-else>mdi-plus</v-icon>
                    </template>
					<template v-slot:footer.prepend>
						<div>Выделено: {{table_product.dedicated_product.length}}</div>
					</template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-card class="mt-5">
            <v-card-title>Выбранные товары</v-card-title>
            <v-card-text>
                <div class="mt-5" style="display: flex; justify-content:space-around;">
                    <v-btn
                        @click="clean_all_added_products"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Очистить список</v-btn>
                    <v-btn
                        @click="form.dialog = true"
                        depressed
                        color="blue darken-2"
                        style="font-size: .8em;"
                        dark
                    >Сохранить</v-btn>
				</div>
                <v-data-table
                    :headers="table_product.headers"
                    :items="added_product"
					hide-default-footer
					fixed-header
					style="width: 100%;"
					height="800px"
					disable-pagination
                    class="elevation-0 mt-5"
                >
					<template v-slot:item.nmid="{ item }">
						<router-link 
							target="_blank" 
							:to="{ name: 'product_card', params: { id: item.nmid }}"
						>{{ item.nmid }}</router-link>
					</template>
					<template v-slot:item.photo="{ item }">
						<div class="image">
							<img style="max-height:100px" :src="get_image_path(item.nmid)" alt="">
							<img class="big_img" :src="get_image_path(item.nmid)" alt="">
						</div>
					</template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="remove_single_added_product(item)" small>
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import { General } from '@/api/general'
import { Products } from '@/api/products'
import { PriceManagement } from '@/api/price_management'


export default {
    name: 'PriceManagenentCreateTemplates',
    data() {
        return {
			form: {
				dialog: false,
				loading: false,
				name: null,
				email: null
			},
            added_product: [],
            table_product: {
                dedicated_product: [],
                loading: false,
                data: [],
                headers: [
					{ text: 'Изображение', sortable: false, value: 'photo', width: '5%' },
                    { text: 'Артикул', sortable: false, value: 'nmid', width: '5%' },
                    { text: 'Артикул 1с', sortable: false, value: 'catalog_item.code1C_code', width: '10%' },
					{ text: 'Наименование', sortable: false, value: 'catalog_item.code1C_name', width: '20%' },
                    { text: 'Статус', sortable: false, value: 'catalog_item.status_name', width: '10%' },
                    { text: 'ЛК', sortable: false, value: 'account', width: '10%' },
                    { text: 'Бренд', sortable: false, value: 'catalog_item.brand', width: '10%' },
                    { text: 'Группа', sortable: false, value: 'catalog_item.code1C_tag_group_name', width: '15%' },
                    { text: 'Менеджер', sortable: false, value: 'catalog_item.our_manager', width: '15%' },
                    { text: 'Действия', sortable: false, value: 'actions', width: '5%' }
                ]
            },
            options: {},
            pagination: {
                next: null,
                totalItems: 0,
                numberOfPages: 0,
                currentPage: 0
            },
            first_init: false,
            filters: {
                account: null,
                group: null,
                brand: null,
                manager: null,
                status: ['0001'],
                code_1c: null,
                nmid: null
            },
            filters_data: {
                list_account: [],
                list_product_group: [],
                list_brand: [],
                list_manager: [],
                list_status: []
            }
        }
    },
    watch: {
        'options': async function (newVal, oldVal) {
            if (oldVal && this.first_init) {
                await this.get_products()
            }
        },
    },
    computed: {
        listAddedProductId(){
            let result = []
            this.added_product.forEach(item => {
                result.push(item.id)
            })
            return result
        },
        transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else if(f=='nmid'){
					query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
				} else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
    },
    methods: {
		async get_choices_data(){
			await this.get_accounts()
			await this.get_groups()
			await this.get_statuses()
			await this.get_managers()
			await this.get_brands()
		},
        async get_accounts () {
            const response = await General.getWbAccounts()
            this.filters_data.list_account = response.data
        },
        async get_groups() {
            const response = await General.fetchProductGroup()
            this.filters_data.list_product_group = response.data
        },
        async get_statuses() {
            const response = await General.fetchProductStatus()
            this.filters_data.list_status = response.data
        },
        async get_managers() {
            const response = await General.fetchProductManager()
            this.filters_data.list_manager = response.data
        },
        async get_brands() {
            const response = await General.fetchProductBrand()
            this.filters_data.list_brand = response.data
        },
        async get_products () {
            this.table_product.loading = true
            const { page, itemsPerPage, sortBy, sortDesc } = this.options
            let pageNumber = page - 1
            try {
                const response = await Products.fetchProducts(
                    {
                        ...this.transfromFiltersForQuery,
                        ...{
                            'offset': itemsPerPage*pageNumber,
                            'limit': itemsPerPage,
                            'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                        }
                    }
                )
                this.table_product.data = response.data.results.objects
                this.pagination.totalItems = response.data.count
                this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				this.$vuetify.goTo(this.$refs.block_add_template, {duration: 300, offset: 150,easing: 'linear',})
            } catch (err) {
                console.log(err.data)
            }
            this.table_product.loading = false
        },
        add_all_products_to_list(){
            let added_list = this.listAddedProductId
            this.table_product.dedicated_product.forEach(item=>{
                if (!added_list.includes(item.id))
                    this.added_product.push(item)
            })
        },
        add_single_product_to_list(item){
            if (!this.listAddedProductId.includes(item.id))
                this.added_product.push(item)
        },
        remove_selection(){
            this.table_product.dedicated_product = []
        },
        clean_all_added_products(){
            this.added_product = []
        },
        remove_single_added_product(item){
            this.added_product = this.added_product.filter(el => el.id != item.id);
        },
		async create_template(){
			this.form.loading = true
			let list_product = this.listAddedProductId.join(',')
			try {
				await PriceManagement.createTemplate(
					{"name": this.form.name, "email": this.form.email, "products": list_product}
				)
				this.$toasted.show(`Успешно`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-center',
					duration: 3000,
				});
				this.$router.push({name: 'price_management_templates'})		
				this.form.dialog = false
			} catch (err) {
				this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
						theme: 'bubble',
						type: 'error',
						position: 'top-center',
						duration: 3000,
					});
			}
			this.form.loading = false
		},
		get_vol_image(t){
			t = Number(t)
			if (t >= 0 && t <= 143)
				return '01'
			if (t >= 144 && t <= 287)
				return '02'
			if (t >= 288 && t <= 431)
				return '03'
			if (t >= 432 && t <= 719)
				return '04'
			if (t >= 720 && t <= 1007)
				return '05'
			if (t >= 1008 && t <= 1061)
				return '06'
			if (t >= 1062 && t <= 1115)
				return '07'
			if (t >= 1116 && t <= 1169)
				return '08'
			if (t >= 1170 && t <= 1313)
				return '09'
			if (t >= 1314 && t <= 1601)
				return '10'
            if (t >= 1602 && t <= 1655)
                return '11'
            if (t >= 1656 && t <= 1919)
                return '12'
            if (t >= 1920 && t <= 2045)
                return '13'
            if (t >= 2046 && t <= 2189)
                return '14'
            if (t >= 2190 && t <= 2405) 
                return '15'
            return '16'
		},
		get_image_path (nmid) {
			let first_part = nmid.slice(0, -3)
			let second_part = nmid.slice(0, -5)
			let third_part = this.get_vol_image(second_part)
			return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
		},
    },
    async mounted(){
		document.title = `Управление ценой (Создание шаблона)`;
		this.$store.commit('setPageTitle', `Управление ценой (Создание шаблона)`)
		await this.get_choices_data()
        await this.get_products()
        this.first_init = true
    }
}
</script>

<style lang="scss" scoped>
	.image{
		height: 50px;
		width: auto;
		margin-top: 5px;
		img{
			width: auto;
			height: 100%;
			object-fit: cover;
		}
		.big_img{
			display: none;
			position: absolute;
			top: calc(50vh - 100px);
			left: calc(50% - 100px);
			right: 0;
			bottom: 0;
			z-index: 20;
			width: 300px;
			height: auto;
		}
		&:hover{
			.big_img{
				display: block;
			}
		}
	}
</style>