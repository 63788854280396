<template>
	<v-card>
		<v-card-title>Изменение названия кампании ({{ campaign_id }})</v-card-title>
		<v-card-text class="mt-5">
			<v-text-field
				v-model="new_name" clearable outlined dense counter 
				label="Введите название рекламной кампании"
				maxlength="100"
			></v-text-field>
		</v-card-text>
		<v-card-actions>
			<v-btn color="blue darken-1" text @click="changeCampaignName" :loading="loading">
				Сохранить
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'

export default {
    name: 'AdvertisementChangeCampaignName',
	props: ['campaign_id', 'campaign_name'],
	data() {
		return {
			new_name: null,
			loading: false
		}
	},
	methods: {
		async changeCampaignName(){
			if (this.campaign_name == this.new_name){
				this.$toasted.show(`Название совпадает с текущем`, {
                    theme: "bubble",
                    type: 'error',
                    position: "top-center",
                    duration: 3000
                })
				return
			}
			this.loading = true
			try{
				await AdvertisementNew.changeAdvertisementName(this.campaign_id, {"name": this.new_name})
				this.$toasted.show(`Название обновлено`, {
                    theme: "bubble",
                    type: 'success',
                    position: "top-center",
                    duration: 3000
                })
				this.loading = false
				this.$emit('update')
			} catch(err){
				this.$toasted.show(`Ошибка изменения названия`, {
                    theme: "bubble",
                    type: 'error',
                    position: "top-center",
                    duration: 3000
                })
			}
			this.loading = false
		}
	},
	mounted(){
		if (this.campaign_name)
			this.new_name = this.campaign_name
	}
}
</script>
