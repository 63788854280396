<template>
    <div>
        <v-navigation-drawer
                v-model="drawer"
                color="white"
                temporary
                absolute
                app

        >
            <div class="pa-4">
                <v-subheader>Рейтинг</v-subheader>
                <v-range-slider
                        v-model="filters.product_rating_range"
                        ticks="always"
                        :tick-size="5"
                        max="5"
                        min="0"
                ></v-range-slider>
                <div>От {{filters.product_rating_range[0]}} до {{filters.product_rating_range[1]}}</div>
            </div>
            <v-divider></v-divider>
            <v-list subheader>
                <v-subheader>По продавцам</v-subheader>
                <v-list-item>
                    <div>
                        <div v-for="account in filter_choices.wbaccount" :key="account.id" class="mb-2">
                            <v-chip
                                    class="mr-2"
                                    :class="{'purple white--text':filters.wbaccount == account.id, 'grey lighten-4': filters.wbaccount !== account.id}"
                                    @click="filters.wbaccount=account.id"
                            >
                                {{ account.name }}
                            </v-chip>
                        </div>
                        <!--                        <v-select-->
                        <!--                                :items="filter_choices.wbaccount"-->
                        <!--                                item-text="name"-->
                        <!--                                filled-->
                        <!--                                @change="wb"-->
                        <!--                                label="Filled style"-->
                        <!--                        ></v-select>-->
                    </div>
                </v-list-item>
            </v-list>
            <div class="pa-5">
                <v-btn
                        @click="fetchFeedbacksWithFilters()"
                        depressed
                        color="purple darken-2"
                        class="mb-3"
                        dark
                >
                    Применить
                </v-btn>
                <v-btn
                        @click="clearFilters()"
                        depressed
                        color="grey lighten-2"
                        class="mb-10"
                >
                    Сбросить
                </v-btn>
            </div>
        </v-navigation-drawer>
        <div class="px-5">
            <v-btn icon @click="drawer = !drawer">
                <v-icon>mdi-filter-variant</v-icon>
            </v-btn>
        </div>
        <v-sheet>
            <v-container fluid>
                <v-row class="">
                    <v-col cols="12" md="2">
                        <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_from"><template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    clearable
                                    @click:clear="filters.date_after = null"
                                    v-model="dateFromFormatted"
                                    label="Начало периода"
                                    prepend-icon="mdi-calendar"
                                    readonly="readonly"
                                    v-bind="attrs"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    v-on="on">
                            </v-text-field>
                        </template>
                            <v-date-picker
                                    v-model="filters.date_after"
                                    :first-day-of-week="1"
                                    @input="datepicker_menu_from = false">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col  cols="12" md="2">
                        <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_to"><template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    clearable
                                    @click:clear="filters.date_before = null"
                                    v-model="dateToFormatted"
                                    label="Конец периода"
                                    prepend-icon="mdi-calendar"
                                    readonly="readonly"
                                    hide-details="auto"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on"
                            ></v-text-field>
                        </template>
                            <v-date-picker
                                    v-model="filters.date_before"
                                    @input="datepicker_menu_to = false"
                                    :first-day-of-week="1"></v-date-picker>
                        </v-menu>
                        <div class="text-right">
                            <a href="#" @click="setYesterdayToDateFilter()">Вчера</a>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col >
                        <v-text-field
                                v-model="filters.text"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchFeedbacksWithFilters()"
                                append-icon="mdi-magnify"
                                label="Текст отзыва"
                                hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                                v-model="filters.nmid"
                                clearable
                                outlined
                                dense
                                @keyup.enter="fetchFeedbacksWithFilters()"
                                append-icon="mdi-magnify"
                                label="Артикул"
                                hide-details="hide-details">
                        </v-text-field>
                        <div v-if="filters.nmid && filters.nmid.length > 5" class="mt-1">
                            <v-btn
                                    :loading="fetchSingleProductFeedbacksLoading"
                                    @click="fetchSingleProductFeedbacks(filters.nmid)"
                                    x-small
                                    rounded>Подгрузить отзывы</v-btn>
                        </div>
                    </v-col>
                    <v-col>
                        <v-btn
                                @click="fetchFeedbacksWithFilters()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            Применить
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">

                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-skeleton-loader type='table' v-if="initLoading"></v-skeleton-loader>
        <v-data-table
                v-else
                :headers="dataTable.headers"
                :pageCount="pagination.numberOfPages"
                :items="items"
                item-key="uid"
                @update:page="pageChanged"
                @update:sort-by="sortChanged"
                @update:sort-desc="sortChanged"
                :loading="tableLoading"
                :options.sync="options"
                :items-per-page="50"
                :server-items-length="pagination.totalItems"
                class="elevation-1"
        >
            <template v-slot:item.pic="{ item }">
                <v-row>
                    <v-col><img style="max-height:80px" :src="`https:${item.pic_url}`" alt=""></v-col>

                    <v-col>
                        Отзывы: {{ item.feedbacks_count }}
<!--                        Отзывы: {{ item.feedbacks_count }}-->
                    </v-col>
                </v-row>

            </template>
            <template v-slot:item.nmid="{ item }">
                <a :href="`https://www.wildberries.ru/catalog/${item.nmid}/detail.aspx`" target="_blank">Смотреть на wb</a> |
                <router-link :to="{ name: 'product_card', params: { id: item.nmid }}">{{ item.nmid }}</router-link>
            </template>
            <template v-slot:item.brand="{ item }">
                <div style="font-size: .7rem;" class="pt-2">{{ item.brand }}</div>
            </template>
            <template v-slot:item.name="{ item }">
                <div style="font-size: .8rem;">{{ item.name }}</div>
                <div style="font-size: .7rem;" class="mt-1">{{ item.brand }}</div>
                <div style="font-size: .7rem;" class="">{{ item.product_group }}</div>
                <div style="font-size: .7rem;" class="">{{ item.nmid }}</div>
            </template>
            <template v-slot:item.product_rating_range="{ item }">
                <div class="rating-chip" :class="`rating-bg-${Math.floor(item.product_rating)}`">{{ item.product_rating }}</div>
            </template>
            <template v-slot:item.manager="{ item }">
                <div v-if="item.manager">{{ item.manager.code }}</div>
                <div v-else>-</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
  import { Products } from '@/api/products'
  import { General } from '@/api/general'

  export default {
    name: 'AnalyticsProductsList',
    data () {
      return {
        initLoading: false,
        tableLoading: false,
        pagination: {
          next: null,
          totalItems: 0,
          numberOfPages: 0,
          currentPage: 0
        },
        options: {},
        drawer: false,
        filters: {
          no_rating: null,
          product_rating: [],
          product_rating_range: [0,5],
          has_photos: null,
          wbaccount: null,
          answer: null,
          date_before: null,
          date_after: null,
          nmid: 14657360,
          is_deleted: null,
        },
        filter_choices: {
          wbaccount:[]
        },
        datepicker_menu_from: null,
        datepicker_menu_to: null,
        items: [],
        dataTable: {
          headers: [
            {text: 'pic', align: 'start', sortable: false, value: 'pic', width: '1%'},
            {text: 'id', align: 'start', sortable: false, value: 'nmid', width: '1%'},
            {text: 'Название', align: 'start', sortable: false, value: 'name', width: '1%'},
            {text: 'Бренд', align: 'start', sortable: false, value: 'brand', width: '1%'},
            { text: 'Рейтинг', value: 'product_rating_range', width: '1%' },
            { text: 'Группа', value: 'product_group', width: '1%', sortable: false },
            { text: 'Статус', value: 'product_status', width: '1%', sortable: false },
            { text: 'Менеджер', value: 'manager', width: '1%', sortable: false },
            // { align: 'left', sortable: false,text: '', value: 'name' }
          ],
        }
      }
    },
    computed: {
      transfromFiltersForQuery () {
        let query = {}
        for (const f in this.filters) {
          if (Array.isArray(this.filters[f])) {
            query[f] = this.filters[f].join(',')
          } else {
            query[f] = this.filters[f]
          }
        }
        return query
      },
      dateFromFormatted () {
        return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
      },
      dateToFormatted () {
        return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
      }
    },
    methods: {
      addUniqueToArray (arr, value) {
        if (arr.indexOf(value) === -1) {
          arr.push(value)
        } else {
          arr.splice(arr.indexOf(value), 1)
        }
      },
      async sortChanged () {
        await this.fetchProducts()
      },
      async pageChanged () {
        await this.fetchProducts()
      },
      async clearFilters () {
        let dirtyFilters = false
        for (let item in this.filters) {
          if (this.filters[item]) {
            dirtyFilters = true
          }
          this.filters[item] = Array.isArray(this.filters[item]) ? [] : null
        }
        if (dirtyFilters) {
          await this.fetchProducts()
        }
      },
      async fetchWbAccounts () {
        const response = await General.getWbAccounts()
        for (let account in response.data) {
          this.filter_choices.wbaccount.push(response.data[account])
        }
      },
      async fetchFeedbacksWithFilters () {
        this.drawer = false
        this.options.page = 1
        await this.fetchProducts()
      },
      async fetchProducts () {
        this.tableLoading = true
        const { page, itemsPerPage, sortBy, sortDesc } = this.options
        let pageNumber = page - 1
        try {
          const response = await Products.fetchAnalyticsProducts(
            {
              ...this.transfromFiltersForQuery,
              ...{
                'offset': itemsPerPage*pageNumber,
                'limit': itemsPerPage,
                'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
              }
            }
          )
          this.items = response.data.results
          this.pagination.totalItems = response.data.count
          this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
        } catch (err) {
          console.log(err.data)
        }
        this.tableLoading = false
      }
    },
    async mounted () {
      await this.fetchWbAccounts()
      this.initLoading = true
      await this.fetchProducts()
      this.initLoading = false
    }
  }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .rating-chip{
        display: inline-block;
        padding: 3px 8px;
        color: #ffffff;
        border-radius: 5px;
        font-size: .9rem;
    }
</style>
