import {Dashboard} from '@/api/dashboard';
import {General} from '@/api/general';

export default {
	state: () => ({
		sellers: [],
		dashboardActiveSeller: null,
		dataRangeDates: [
			new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().substring(0,10),
			new Date().toISOString().substring(0,10)
		]
	}),
	mutations: {
		updateDashboardActiveSeller(state, payload) {
			state.dashboardActiveSeller = payload;
		},
		updateDataRangeDates(state, payload) {
			state.dataRangeDates = payload;
		},
		setSellersDashboard(state, payload) {
			state.sellers = payload;
		},
	},
	actions: {
		async fetchSellers({commit}) {
			const response = await General.getWbAccounts();
			commit('setSellersDashboard', response.data);
		},
	},
	getters: {
		dashboardActiveSellerInfo: (state) => (id) => {
			return state.sellers.find(item => item.id === id)
		},
		getDashboardActiveSeller (state) {
			if (state.dashboardActiveSeller) {
				return state.dashboardActiveSeller
			}
			// по умолчанию первая запись из массива
			return state.sellers[0]?.id
		},
		getDashboardDataRangeDateText (state) {
			return state.dataRangeDates.join(' ~ ')
			// return 0
		},
		getDashboardDataRangeDates (state) {
			return state.dataRangeDates
		}
	}
};
