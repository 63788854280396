<template>
<v-card>
    <v-card-title>
        <span class="text-h5">Создание выкупа</span>
    </v-card-title>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                    <v-form ref="form" v-model="valid">
                        <v-autocomplete
                            v-model="form_data.product_mid"
                            :loading="loading.loading_product_autocomplete"
                            :items="choices.list_filtered_product"
                            item-text="display_sku"
                            item-value="mid"
                            :search-input.sync="search.search_product"
                            cache-items
                            :label="titleProduct"
                            outlined
                            :rules="[rules.required]"
                            tabindex="1"
                            clearable
                            dense
                        ></v-autocomplete>
                        <v-text-field
                            v-model="form_data.quantity_product"
                            label="Количество"
                            required
                            type="number"
                            outlined
                            clearable
                            :rules="[rules.required, rules.positive]"
                            tabindex="2"
                            dense
                        ></v-text-field>
                        <v-select
							v-model="form_data.type_redemption"
                            :items="choices.type_redemption_choices"
                            item-text="name"
                            item-value="value"
                            label="Тип выкупа"
							:rules="[rules.required]"
                            tabindex="3"
                            clearable
                            outlined
                            dense
                        ></v-select>
						<v-select
                            v-model="form_data.service"
                            :items="choices.service_redemption_choices"
							v-if="form_data.type_redemption != 'DISTRIBUTION'"
                            item-text="name"
                            item-value="value"
                            label="Сервис"
                            tabindex="3"
                            clearable
                            outlined
                            dense
                        ></v-select>
						<v-text-field
                            v-model="form_data.order_number"
							v-if="form_data.type_redemption != 'DISTRIBUTION'"
                            label="Номер заказа(через запятую)"
                            outlined
                            tabindex="5"
							:rules="[rules.not_distribution]"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form_data.sum_order"
                            label="Сумма"
                            outlined
                            type="number"
                            tabindex="6"
                            :rules="[rules.required, rules.positive]"
                            dense
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="form_data.type_redemption == 'DISTRIBUTION'"
                            v-model="form_data.cashback"
                            label="Кэшбэк, %"
                            outlined
                            tabindex="7"
                            :rules="[rules.cashback]"
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                        <v-select
                            v-model="form_data.manager_id"
                            :items="choices.list_manager"
                            item-text="username"
                            item-value="id"
                            label="Менеджер"
                            :rules="[rules.required]"
                            tabindex="8"
                            outlined
                            clearable
                            dense
                        ></v-select>
                        <v-text-field
                            v-model="form_data.account_buyer"
                            label="Покупатель"
                            outlined
                            tabindex="9"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="form_data.phone"
                            v-mask="'+7 (###) ###-##-##'"
                            dense
                            outlined
                            clearable
                            tabindex="10"
                            label="Номер аккаунта"
                        ></v-text-field>
                        <v-text-field
                            v-model="form_data.address_pvz"
							v-if="form_data.type_redemption != 'DISTRIBUTION'"
                            label="Адрес ПВЗ."
                            outlined
                            tabindex="11"
                            clearable
                            dense
                        ></v-text-field>
                        <v-menu 
                            v-model="datePicker.datepicker_date_order" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_order"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateOrderFormatted"
                                    @click:clear="form_data.date_order = null"
                                    label="Дата заказа"
                                    :rules="[rules.required]"
                                    readonly="readonly"
                                    tabindex="12"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="form_data.date_order" 
                                @input="datePicker.datepicker_date_order = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
            Cancel
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="save"
        >
            Save
        </v-btn>
    </v-card-actions>
</v-card>    
</template>

<script>

import _ from 'lodash'
import { Redemption } from '@/api/redemption'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
Vue.directive('mask', VueMaskDirective)

export default {
    name: "OzonRedemptionFormCreate",
    data() {
        return {
            valid: true,
            form_data: {
                product_mid : null,
                quantity_product: null,
                type_redemption: 'DISTRIBUTION',
                sum_order: null,
                cashback: null,
                manager_id: null,
                date_order: null,
                account_buyer: null,
                phone: null,
                address_pvz: null,
				order_number: null,
				service: null
            },
            loading: {
                loading_product_autocomplete: false,
            },
			choices: {
				type_redemption_choices: [],
				product_status_redemption_choices: [],
				service_redemption_choices: [],
				list_filtered_product: [],
				list_manager: []
			},
            search: {
                search_product: null,
            },
            datePicker: {
                datepicker_date_order: "",
            },
            rules: {
                required: value => !!value || 'Обязательное поле',
                positive: value => value > 0 || 'Должно быть положительное число',
                cashback: value => (value <= 100 && value >= 0) || 'Число должно быть от 0 до 100',
				not_distribution: value => (this.form_data.type_redemption != 'DISTRIBUTION' && !!value ) || "Обязательное поле"
            },
        }
    },
    computed: {
        dateOrderFormatted () {
            return this.form_data.date_order ? this.$options.filters.formatDateToLocaleNoTime(this.form_data.date_order) : null
        },
        titleProduct () {
            let product = this.choices.list_filtered_product.find(obj => obj['id'] === this.form_data.product_mid)
            return product ? product.name : "Артикул"
        },
        formatPhone(){
            return this.form_data.phone.replace(/[^\d]/g, '')
        }
    },
    watch: {
        'search.search_product': function(val) {
            if (val && val !== this.form_data.product_mid && val.length > 2){
                this.loading.loading_product_autocomplete = true
                this.debouncedGetFilteredProduct(val)
            }
        },
    },
    methods: {
        validate () {
            this.$refs.form.validate()
        },
        cleanData(){
            this.form_data.product_mid = null
            this.form_data.quantity_product = null
            this.form_data.type_redemption = 'DISTRIBUTION'
            this.form_data.sum_order = null
            this.form_data.cashback = null
            this.form_data.date_order = null
            this.form_data.account_buyer = null
            this.form_data.phone = null
            this.form_data.address_pvz = null
            this.form_data.feedback_author = null
			this.form_data.order_number = null
			this.form_data.service = null
        },
        close (){
            this.$emit('close')
        },
        save () {
            this.postForm()
        },
        async postForm(){
            this.validate()
            if (this.validate){
				let data = {
					"product_id": this.form_data.product_mid,
					"quantity_product": this.form_data.quantity_product,
					"sum_order": this.form_data.sum_order,
					"manager_id": this.form_data.manager_id,
					"type_redemption": this.form_data.type_redemption,
					"date_order":this.form_data.date_order
				}
				if (this.form_data.type_redemption == 'DISTRIBUTION'){
					data['cashback'] = this.form_data.cashback
				}
				if (this.form_data.account_buyer){
					data['account_buyer'] = this.form_data.account_buyer
				}
				if (this.form_data.address_pvz && this.form_data.type_redemption != 'DISTRIBUTION'){
					data['address_pvz'] = this.form_data.address_pvz
				}
				if (this.form_data.phone){
					data['phone'] = this.formatPhone
				}
				if (this.form_data.type_redemption != 'DISTRIBUTION'){
					data['order_number'] = this.form_data.order_number
					if (this.form_data.service)
						data['services'] = this.form_data.service
				}
                try{
                    const response = await Redemption.createOzonRedemption(data)
                    this.cleanData()
                    this.$emit('close', "success")
                } catch (err){
                    console.log(err)
                }   
            }
            
        },
        async getFilteredProducts(val){
            try {
                const response = await Redemption.fetchOzonFilteredProductRedemption(
                    {
                    'mid': val
                    }
                )
                this.choices.list_filtered_product = response.data
            } catch (err) {
                console.log(err.data)
            }
            this.loading.loading_product_autocomplete = false
        },
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.choices.list_manager = responce.data
                let current_user = Array.from(responce.data).find(obj => obj['id'] == this.$store.getters.userPK)
                if (current_user)
                    this.form_data.manager_id = current_user.id
            } catch (err) {
                console.log(err.data)
            }
        },
		async getTypeRedemtion(){
            try {
                const responce = await Redemption.fetchBaseTypeRedemption()
                this.choices.type_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
		async getProductStatusRedemption(){
            try {
                const responce = await Redemption.fetchBaseProductStatusRedemption()
                this.choices.product_status_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
		async getServiceRedemption(){
            try {
                const responce = await Redemption.fetchBaseServiceRedemption()
                this.choices.service_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
    },
    created: function () {
        this.debouncedGetFilteredProduct = _.debounce(this.getFilteredProducts, 1000)
    }, 
    mounted(){
        this.getManagers()
		this.getTypeRedemtion()
		this.getProductStatusRedemption()
		this.getServiceRedemption()
    }
}
</script>

<style lang="scss" scoped>

</style>