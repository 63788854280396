import { HTTP } from './http'

export const Advertisement = {
	async fetchAdvertisementChoices() {
		return HTTP.get('/advertisement/choices/')
	},
	async fetchAdvertisementList(query=null) {
		return HTTP.get('/advertisement/', {"params": query})
	},
	async fetchSupplierProducts(query=null) {
		return HTTP.get('/advertisement/supplier_products/', {"params": query})
	},
	async fetchKeywordsMpstatsByProduct(query=null) {
		return HTTP.get('/advertisement/keywords_mpstats/', {"params": query})
	},
	async fetchAdvertisementStats(query=null) {
		return HTTP.get('/advertisement/stats/', {"params": query})
	},
	async exportAdvertisementStats(query=null) {
		return HTTP.get('/advertisement/stats/export/', {"params": query, "responseType": "blob"})
	},
	async fetchAdvertisementGeneralStats(query=null) {
		return HTTP.get('/advertisement/general_stats/', {"params": query})
	},
	async postCreateSearchAdvertisement(data) {
		return HTTP.post('/advertisement/create_search/', data)
	},
	async postCreateAutoAdvertisement(data) {
		return HTTP.post('/advertisement/create_auto/', data)
	},
	async fetchAdvertisementDetail(campaign_id) {
		return HTTP.get(`/advertisement/${campaign_id}/`)
	},
	async fetchAdvertisementDetailStats(campaign_id) {
		return HTTP.get(`/advertisement/${campaign_id}/detail_stats/`)
	},
	async checkAdvertisementProductBoost(campaign_id, data) {
		return HTTP.post(`/advertisement/${campaign_id}/check_boost/`, data)
	},
	async changeAdvertisementStatus(campaign_id){
		return HTTP.post(`/advertisement/${campaign_id}/change_status/`)
	},
	async fetchAdvertisementStatsViews(campaign_id){
		return HTTP.get(`/advertisement/${campaign_id}/stats_views/`)
	},
	async fetchAdvertisementDetailHistoricalData(campaign_id) {
		return HTTP.get(`/advertisement/${campaign_id}/historical_data/`)
	},
	async fetchAdvertisementDetailSearchPhrase(campaign_id) {
		return HTTP.get(`/advertisement/${campaign_id}/search_phrase/`)
	},
	async setAutoDisplayArea(campaign_id, data){
		return HTTP.post(`/advertisement/${campaign_id}/set_auto_display_area/`, data)
	},
	async updateAdvertisementCampaign(campaign_id, data){
		return HTTP.patch(`/advertisement/${campaign_id}/`, data)
	},
	async updateAdvertisementCampaignRobotSettings(id, data){
		return HTTP.patch(`advertisement/robots_settings/${id}/`, data)
	},
	async depositAdvertisementCampaign(campaign_id, deposit) {
		return HTTP.post(`/advertisement/${campaign_id}/deposit_budget/`, {'deposit': deposit})
	},
	async depositAdvertisementCampaignWithType(campaign_id, json_data) {
		return HTTP.post(`/advertisement/${campaign_id}/deposit_budget/`, json_data)
	},
	async changeCPMAdvertisementCampaign(campaign_id, cpm, instrument=6) {
		return HTTP.post(`/advertisement/${campaign_id}/set_cpm/`, {'cpm': cpm, 'instrument': instrument})
	},
	async changeNameAdvertisementCampaign(campaign_id, name) {
		return HTTP.post(`/advertisement/${campaign_id}/set_name/`, {'name': name})
	},
	async fillOneAdvertisementCampaign(campaign_id) {
		return HTTP.post(`/advertisement/${campaign_id}/fill_data/`)
	},
	async changeStatusAdvertisementCampaign(campaign_id, status){
		return HTTP.post(`/advertisement/${campaign_id}/set_status/`, {'status': status})
	},
	async updateSearchPhraseAdvertisementCampaign(id, data){
		return HTTP.patch(`/advertisement/search_phrase/${id}/`, data)
	},
	async fillOneSearchPhraseAdvertisementCampaign(id){
		return HTTP.post(`/advertisement/search_phrase/${id}/fill_search_phrase/`)
	},
	async exportDetailSearchPhrase(id) {
		return HTTP.get(`/advertisement/search_phrase/${id}/export_detail/`, {"responseType": "blob"})
	},
	async saveExcludedPhrase(id, data){
		return HTTP.post(`/advertisement/search_phrase/${id}/save_excluded_phrase/`, {"keywords": data})
	},
	async saveExcludedStrong(id, data){
		return HTTP.post(`/advertisement/search_phrase/${id}/save_excluded_strong/`, {"keywords": data})
	},
	async saveExcluded(id, data){
		return HTTP.post(`/advertisement/search_phrase/${id}/save_excluded/`, {"keywords": data})
	},
	async savePluse(id, data){
		return HTTP.post(`/advertisement/search_phrase/${id}/save_pluse/`, {"keywords": data})
	},
	async createRobotSettings(campaign_id){
		return HTTP.post(`/advertisement/${campaign_id}/create_robot_settings/`)
	},
	async fetchAutoAdvertisementProducts(campaign_id) {
		return HTTP.get(`/advertisement/${campaign_id}/auto_products/`)
	},
	async saveAutoAdvertisementProducts(campaign_id, data){
		return HTTP.post(`/advertisement/${campaign_id}/save_auto_products/`, data)
	},
	async fetchKeywordsInfo(keyword_item_id){
		return HTTP.post(`/advertisement/search_phrase/${keyword_item_id}/info/`)
	},
	async fetchWeekStatsAdvertisementCampaign(campaign_id){
		return HTTP.get(`/advertisement/${campaign_id}/summary_week_stats/`)
	},
	async fetchKeywordsDetailStats(campaign_id){
		return HTTP.post(`/advertisement/${campaign_id}/detail_keywords_stats/`)
	},
	async fetchClusterStats(campaign_id, keyword){
		return HTTP.post(`/advertisement/${campaign_id}/cluster_stats/`, {'keyword': keyword})
	},
	async fetchAdvertisementTaskFilters(){
		return HTTP.get(`/advertisement/task_history/list_types/`)
	},
	async fetchAdvertisementTaskHistory(query=null){
		return HTTP.get(`/advertisement/task_history/`, {"params": query})
	}
}
