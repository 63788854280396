<template>
<v-card>
    <v-card-title>
        <span class="text-h5">Создание</span>
    </v-card-title>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                    <v-form ref="form" v-model="valid">
                        <v-autocomplete
                            v-model="formData.product_id"
                            :loading="loading.loading_product_autocomplete"
                            :items="data.list_filtered_product"
                            item-text="nmid"
                            item-value="id"
                            :search-input.sync="search.search_product"
                            cache-items
                            :label="titleProduct"
                            outlined
                            :rules="[rules.required]"
                            tabindex="1"
                            clearable
                            dense
                        ></v-autocomplete>
                        <v-select
                            v-model="formData.variations"
                            :items="data.list_variations"
                            item-text="size"
                            item-value="id"
                            label="Вариации"
                            tabindex="2"
                            v-if="formData.product_id && data.list_variations.length > 0"
                            clearable
                            outlined
                            dense
                        ></v-select>
                        <v-text-field
                            v-model="formData.quantity_product"
                            label="Количество"
                            required
                            type="number"
                            outlined
                            clearable
                            :rules="[rules.required, rules.positive]"
                            tabindex="3"
                            dense
                        ></v-text-field>
                        <v-select
                            v-model="formData.type_redemption"
                            :items="data.type_redemption_choise"
                            item-text="name"
                            item-value="value"
                            label="Тип выкупа"
                            tabindex="4"
                            :rules="[rules.required]"
                            clearable
                            outlined
                            dense
                        ></v-select>
                        <v-text-field
                            v-model="formData.sum_order"
                            label="Сумма"
                            outlined
                            type="number"
                            tabindex="5"
                            :rules="[rules.required, rules.positive]"
                            dense
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="formData.type_redemption == 'DISTRIBUTION'"
                            v-model="formData.cashback"
                            label="Кэшбэк, %"
                            outlined
                            tabindex="6"
                            :rules="[rules.cashback]"
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                        <v-select
                            v-model="formData.manager_id"
                            :items="data.list_manager"
                            item-text="username"
                            item-value="id"
                            label="Менеджер"
                            :rules="[rules.required]"
                            tabindex="7"
                            outlined
                            clearable
                            dense
                        ></v-select>
                        <v-text-field
                            v-model="formData.account_buyer"
                            label="Покупатель"
                            outlined
                            tabindex="8"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-model="formData.phone"
                            v-mask="'+7 (###) ###-##-##'"
                            dense
                            outlined
                            clearable
                            tabindex="9"
                            label="Номер аккаунта"
                        ></v-text-field>
                        <v-text-field
                            v-model="formData.address_pvz"
                            label="Адрес ПВЗ."
                            outlined
                            tabindex="10"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-if="formData.type_redemption == 'FEEDBACK'"
                            v-model="formData.feedback_author"
                            label="Автор отзыва"
                            required
                            outlined
                            clearable
                            dense
                        ></v-text-field>
                        <v-menu 
                            v-model="datePicker.datepicker_date_order" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_order"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateOrderFormatted"
                                    @click:clear="formData.date_order = null"
                                    label="Дата заказа"
                                    :rules="[rules.required]"
                                    readonly="readonly"
                                    tabindex="11"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="formData.date_order" 
                                @input="datePicker.datepicker_date_order = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
            Cancel
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="save"
        >
            Save
        </v-btn>
    </v-card-actions>
</v-card>    
</template>

<script>

import _ from 'lodash'
import { Redemption } from '@/api/redemption'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
Vue.directive('mask', VueMaskDirective)

export default {
    name: "RedemptionFormCreate",
    data() {
        return {
            valid: true,
            formData: {
                product_id : null,
                quantity_product: null,
                type_redemption: 'DISTRIBUTION',
                variations: null,
                sum_order: null,
                cashback: null,
                manager_id: null,
                date_order: null,
                account_buyer: null,
                phone: null,
                address_pvz: null,
                feedback_author: null
            },
            loading: {
                loading_product_autocomplete: false,
            },
            data: {
                list_filtered_product: [],
                list_variations: [],
                list_manager: [],
                type_redemption_choise: [
                    {'name': 'Выкуп', 'value': 'REDEMPTION'},
                    {'name': 'Отзыв', 'value': 'FEEDBACK'},
                    {'name': 'Раздача', 'value': 'DISTRIBUTION'}
                ],
            },
            search: {
                search_product: null,
            },
            datePicker: {
                datepicker_date_order: "",
            },
            rules: {
                required: value => !!value || 'Обязательное поле.',
                positive: value => value > 0 || 'Должно быть положительное число',
                cashback: value => (value <= 100 && value >= 0) || 'Число должно быть от 0 до 100',
            },
        }
    },
    computed: {
        dateOrderFormatted () {
            return this.formData.date_order ? this.$options.filters.formatDateToLocaleNoTime(this.formData.date_order) : null
        },
        titleProduct () {
            let product = this.data.list_filtered_product.find(obj => obj['id'] === this.formData.product_id)
            return product ? product.name : "Артикул"
        },
        formatPhone(){
            return this.formData.phone.replace(/[^\d]/g, '')
        }
    },
    watch: {
        'formData.product_id': function(val) {
            if (val){
                this.getProductVariations(val)
            }
        },
        'search.search_product': function(val) {
            if (val && val !== this.formData.product_id && val.length > 2){
                this.loading.loading_product_autocomplete = true
                this.debouncedGetFilteredProduct(val)
            }
        },
    },
    methods: {
        async getProductVariations(val){
            try {
                const response = await Redemption.fetchRedemptionFilteredProduct(
                    {
                    'id': val
                    }
                )
                this.data.list_variations = response.data[0].variations
            } catch (err) {
                console.log(err.data)
            }
        },
        validate () {
            this.$refs.form.validate()
        },
        cleanData(){
            this.formData.product_id = null
            this.formData.quantity_product = null
            this.formData.type_redemption = 'DISTRIBUTION'
            this.formData.sum_order = null
            this.formData.cashback = null
            this.formData.date_order = null
            this.formData.account_buyer = null
            this.formData.phone = null
            this.formData.address_pvz = null
            this.formData.variations = null
            this.formData.feedback_author = null
        },
        close (){
            this.$emit('close')
        },
        save () {
            this.postForm()
        },
        async postForm(){
            this.validate()
            if (this.validate){
                const data = {
                    "product": this.formData.product_id,
                    "quantity_product": this.formData.quantity_product,
                    "sum_order": this.formData.sum_order,
                    "cashback": this.formData.type_redemption == 'DISTRIBUTION' ? this.formData.cashback : null,
                    "manager": this.formData.manager_id,
                    "account_buyer": this.formData.account_buyer,
                    "type_redemption": this.formData.type_redemption,
                    "date_order":new Date(this.formData.date_order),
                    "phone": this.formData.phone ? this.formatPhone: null,
                    "date_buy": null,
                    'feedback': null,
                    'address_pvz': this.formData.address_pvz,
                    'variations': this.formData.variations,
                    'feedback_author': this.formData.type_redemption == 'FEEDBACK' ? this.formData.feedback_author : null
                }   
                try{
                    const response = await Redemption.createRedemption(data)
                    this.cleanData()
                    this.$emit('close', "success")
                } catch (err){
                    console.log(err)
                }   
            }
            
        },
        async getFilteredProducts(val){
            try {
                const response = await Redemption.fetchRedemptionFilteredProduct(
                    {
                    'nmid': val
                    }
                )
                this.data.list_filtered_product = response.data
            } catch (err) {
                console.log(err.data)
            }
            this.loading.loading_product_autocomplete = false
        },
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.data.list_manager = responce.data
                let current_user = Array.from(responce.data).find(obj => obj['id'] == this.$store.getters.userPK)
                if (current_user)
                    this.formData.manager_id = current_user.id
            } catch (err) {
                console.log(err.data)
            }
        },
    },
    created: function () {
        this.debouncedGetFilteredProduct = _.debounce(this.getFilteredProducts, 1000)
    }, 
    mounted(){
        this.getManagers()
    }
}
</script>

<style lang="scss" scoped>

</style>