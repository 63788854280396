import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'

Vue.use(VueRouter)

import auth from '@/router/routes/_auth'
import general from '@/router/routes/_general'


const routes = [
  auth,
  general
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('t')
  // If logged in, or going to the Login page.
  console.log(store.state.auth.jwt)
  console.log(to)
  console.log(from)
  if (!localStorage.getItem('t') && to.name !== 'login') {
    console.log('lo')
    next({name: 'login'})
  } else {
    // store.dispatch('inspectToken')
    next()
  }
})

export default router
