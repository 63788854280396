<template>
    <apexchart 
        type="line" 
        height="100" 
        :options="chartOptions"
        :series="seriesSales"
    ></apexchart>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'CompetitorsPriceChart',
        components: {apexchart: VueApexCharts},
        props: ['data_price',],
        data() {
            return {
				
                chartOptions: {
                    chart: {
                        height: 100,
						width: '100%',
                        type: 'line',
                        zoom: {
                            enabled: false,
                        },
						toolbar: {
							show: false,
						}
                    },
					animations: {
						enabled: true,
					},
					markers: {
						size: [4, 4, 0],
						strokeColors: '#fff',
						strokeWidth: 1,
						strokeOpacity: 0.9,
						strokeDashArray: 1,
					},
					stroke: {
						show: true,
						width: [3,3],    
					},
                    colors: ['#122665'],
					dataLabels: {
						enabled: false
					},
					yaxis: {
						show: false
					},
					xaxis: {
						show: true,
						labels: {
							show: true
						}
					},
					tooltip: {
                        shared: false,
                        intersect: true,
                        y: {
                            formatter: function (val) {
                            return val + " руб"
                            }
                        }
                    },
					grid: {
						show: false,
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0,
                        },
                    },
                },
            };
        },
        computed: {
            seriesSales() {
                return [
                    {
                        name: 'Цена',
                        type: 'line',
                        data: this.seriesPrice,
                    },
                ];
            },
            seriesPrice() {
				let map_result = []

				for (const [key, value] of Object.entries(this.data_price)){
					map_result.push({x: key, y: value?.price_with_spp})
				}
				return map_result.sort(function(a,b){
					return new Date(a.x) - new Date(b.x);
				})
            },
        },
    };
</script>

<style scoped>

</style>