<template>
	<div v-if="data && not_fake_product">
		<v-dialog v-model="dialog_products" max-width="1500" v-if="dialog_products">
			<v-card>
				<v-card-title>Все товары рекламной кампании {{ data.campaign_id }}</v-card-title>
				<v-card-text><DialogAdvertisementDetailProduct :products="data.advertisement_products"/></v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_comment" max-width="1200" v-if="dialog_comment">
			<v-card>
				<v-card-title>Комментарий для рекламной кампании {{ data.campaign_id }}</v-card-title>
				<v-card-text class="d-flex mt-5">
					<v-text-field 
						clearable outlined dense v-model="data.comment" label="Комментарий" hide-details
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-btn
						@click="update_advertisement_comment" depressed class="ml-5"
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  
					>Сохранить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_budget" max-width="1200" v-if="dialog_budget">
			<v-card>
				<v-card-title>Пополнение бюджета рекламной кампании {{ data.campaign_id }}</v-card-title>
				<v-card-text class=" mt-5">
					<v-text-field 
						type="number" clearable outlined dense v-model="deposit" label="Пополнение бюджета" hide-details
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-select
						v-model="deposit_type" :items="deposit_type_choices" item-text="name" item-value="id"
						label="Счет пополнения" outlined hide-details dense class="mt-5" 
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
				></v-select>
					<v-btn
						@click="deposit_advertisement" depressed class="mt-5" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  
					>Сохранить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_name" max-width="1200" v-if="dialog_name">
			<v-card>
				<v-card-title>Изменение названия рекламной кампании {{ data.campaign_id }}</v-card-title>
				<v-card-text class="d-flex mt-5">
					<v-text-field 
						clearable outlined dense v-model="data.campaign_name" label="Название" hide-details
						:color="$vuetify.theme.dark ? '#208379' : '#20ddca'"
					></v-text-field>
					<v-btn
						@click="deposit_advertisement" depressed class="ml-5" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  
					>Сохранить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialog_stop" max-width="1200" v-if="dialog_stop">
			<v-card>
				<v-card-title>Уверены что хотети завершить кампанию {{ data.campaign_id }}</v-card-title>
				<v-card-text class="d-flex align-center justify-center mt-5">
					<v-btn
						@click="stop_advertisement" depressed :color="$vuetify.theme.dark ? '#208379' : '#20ddca'"  
					>Да</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-sheet elevation="2" class="pa-3 d-flex align-center w-100">
			<div class="camapign_info_container">
				<div class="campaign_info d-flex">
					<img 
						style="max-height: 120px" :src="get_image_path(not_fake_product.child_product.nmid)" 
						alt="Фото удалено" class="ml-2"
					/>
					<div class="campaign_info__detail ml-5 text--secondary">
						<span>
								<v-chip 
									class="ma-2" :color="$vuetify.theme.dark ? '#4f7844' : '#c0f1b4'" dense
									v-if="data.campaign_status == 'ACTIVE'"
								>{{ data.campaign_status_display }}</v-chip>
								<v-chip 
									class="ma-2" dense :color="$vuetify.theme.dark ? '#cb7029' : '#ffbd67'"
									v-else-if="data.campaign_status == 'PAUSE'"
								>{{ data.campaign_status_display }}</v-chip>
								<v-chip 
									class="ma-2" :color="$vuetify.theme.dark ? 'blue-grey darken-1' : 'blue-grey lighten-3'" dense
									v-else
								>{{ data.campaign_status_display }}</v-chip>
							</span>
						<div class="text-body-1">
							<span class="font-weight-medium">{{ data.campaign_type }}: </span>{{ data.campaign_name }}
							<a target="_blank" :href="`https://cmp.wildberries.ru/campaigns/edit/${data.campaign_id}`">
								<v-icon dense>mdi-arrow-top-right-thin-circle-outline</v-icon>
							</a>
							
						</div>
						<div class="text-body-2 text--secondary mt-3">
							<span>ID: {{ data.campaign_id }}</span>
							<span class="ml-3">
								<router-link class='dashed' target="_blank" :to="{name: 'product_card', params: { id: not_fake_product.child_product.nmid }}">
									Артикул: {{ not_fake_product.child_product.nmid }}
								</router-link>
		
								<v-icon x-small dense class="text--primary" @click="copy_art(not_fake_product.child_product.nmid)">
									mdi-content-copy
								</v-icon>
							</span>
							<span class="ml-3">Артикул 1С: {{ not_fake_product.child_product.code_1c }}</span>
							<v-btn class="ml-3" @click="dialog_products=true" icon>
								<v-icon dense>mdi-arrow-right-thin</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
				<v-tabs class="mt-2" :color="$vuetify.theme.dark ? '#208379' : '#20ddca'" height="35" v-model="tab" @change="$emit('change_tab', tab)">
					<v-tab class="text-body-2 font-weight-medium">Управление</v-tab>
					<v-tab class="text-body-2 font-weight-medium">Фразы</v-tab>
					<v-tab class="text-body-2 font-weight-medium">Статистика</v-tab>
					<v-tab class="text-body-2 font-weight-medium">Графики</v-tab>
					<v-tab class="text-body-2 font-weight-medium">История</v-tab>
				</v-tabs>	
			</div>
			<v-divider class="mx-4" vertical></v-divider>
			<div>
				<div class="d-flex text-center">
					<v-list two-line dense class="">
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-title>Бюджет</v-list-item-title>
								<v-list-item-subtitle>{{ data.budget | toRUB }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-list two-line dense>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-title>ЛК</v-list-item-title>
								<v-list-item-subtitle>{{ data.account }}</v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</div>
				<div class="d-flex justify-space-between">
					<v-btn 
						v-if="data.campaign_status == 'ACTIVE'" icon :loading="loading.change_status"
						@click="change_status" title='Запустить РК'
					><v-icon dense>mdi-pause-circle-outline</v-icon></v-btn>
					<v-btn 
						v-if="data.campaign_status == 'PAUSE'" icon :loading="loading.change_status"
						@click="change_status" title="Остановить РК"
					><v-icon dense>mdi-play-circle-outline</v-icon></v-btn>
					<v-btn icon :loading="loading.comment" @click="dialog_comment=true" title="Комментарий">
						<v-icon dense>mdi-comment-outline</v-icon>
					</v-btn>
					<v-btn v-if="data.campaign_id != 'STOP'" icon :loading="loading.budget" @click="dialog_budget=true" title="Пополнить бюджет">
						<v-icon dense>mdi-piggy-bank-outline</v-icon>
					</v-btn>
					<v-btn icon :loading="loading.name" @click="dialog_name=true" title="Изменить название">
						<v-icon dense>mdi-pencil-outline</v-icon>
					</v-btn>
					<v-btn v-if="data.campaign_id != 'STOP'" @click="dialog_stop=true" icon :loading="loading.stop" title="Завершить РК">
						<v-icon dense>mdi-flag-checkered</v-icon>
					</v-btn>
					<v-btn icon :loading="loading.refresh" @click="fill_advertisement_campaign" title="Обновить данные">
						<v-icon dense>mdi-refresh</v-icon>
					</v-btn>
				</div>
			</div>
			<v-divider class="mx-4" vertical v-if="data.comment"></v-divider>
			<div class="text--secondary" v-if="data.comment" style="max-width: 320px;">{{ data.comment }}</div>
			<v-divider class="mx-4" vertical></v-divider>
			<div class="text--secondary" v-if="week_stats">
				<p class="mb-0 mt-1"><span class="font-weight-medium">Доходность(7 дней) = </span>{{ week_stats.profit ? week_stats.profit : 0 | toRUB}}</p>
				<p class="mb-0 mt-1"><span class="font-weight-medium">Средний ctr(7 дней) = </span>{{ week_stats.ctr ? week_stats.ctr.toFixed(2) : 0 }}%</p>
				<p class="mb-0 mt-1"><span class="font-weight-medium">Средний cpc(7 дней) = </span>{{ week_stats.cpc ? week_stats.cpc : 0 | toRUB}}</p>
				<p class="mb-0 mt-1"><span class="font-weight-medium">Средний ДРР(7 дней) = </span>{{ week_stats.drr ? week_stats.drr.toFixed(2): 0}}%</p>
				<p class="mb-0 mt-1"><span class="font-weight-medium">Общий ДРР(7 дней) = </span>{{ week_stats.general_drr ? week_stats.general_drr.toFixed(2) : 0 }}%</p>
			</div>
		</v-sheet>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import DialogAdvertisementDetailProduct from '@/components/Advertisement/AdvertisementDetail/DialogAdvertisementDetailProduct'
export default {
	name: "AdvertisementDetailInfoComponent",
	props: ['data', 'week_stats'],
	components: { DialogAdvertisementDetailProduct },
	data(){
		return {
			tab: 0,
			child_tabs: this.tab,
			position_phrase:{
				top_keyword: null,
				second_top_keyword: null,
				loading: false
			},
			dialog_products: false,
			dialog_comment: false,
			dialog_budget: false,
			dialog_name: false,
			dialog_stop: false,
			deposit: null,
			deposit_type: 1,
			deposit_type_choices: [
				{ id: 0, name: "Счет рекламы" },
				{ id: 1, name: "Внутренний баланс" },
				{ id: 3, name: "Бонусный счет" },
			],
			loading: {
				change_status: false,
				budget: false,
				comment: false,
				name: false,
				stop: false,
				refresh: false
			}
		}
	},
	computed: {
		not_fake_product(){
			if (this.data.advertisement_products.length <= 0)
				return {}
			let filtered_product = this.data.advertisement_products.filter(el => !el.is_fake)
			if (filtered_product.length <= 0)
				return {}
			return filtered_product[0]
		}
	},
	methods: {
		copy_art(art) {
			const el = document.createElement('textarea')
			el.value = art
			el.setAttribute('readonly', '')
			el.style.position = 'absolute'
			el.style.left = '-99999px'
			document.body.appendChild(el)
			el.select()
			document.execCommand('copy')
			document.body.removeChild(el)
			this.$toasted.show(`Скопировано в буфер`, {
				theme: "bubble", type: 'success', position: "top-center", duration : 500
			})
		},
		calculate_vol_image(t){
			if (t >= 0 && t <= 143) return '01'
			if (t >= 144 && t <= 287) return '02'
			if (t >= 288 && t <= 431) return '03'
			if (t >= 432 && t <= 719) return '04'
			if (t >= 720 && t <= 1007) return '05'
			if (t >= 1008 && t <= 1061) return '06'
			if (t >= 1062 && t <= 1115) return '07'
			if (t >= 1116 && t <= 1169) return '08'
			if (t >= 1170 && t <= 1313) return '09'
			if (t >= 1314 && t <= 1601) return '10'
			if (t >= 1602 && t <= 1655) return '11'
			if (t >= 1656 && t <= 1919) return '12'
			if (t >= 1920 && t <= 2045) return '13'
			if (t >= 2046 && t <= 2189) return '14'
			if (t >= 2190 && t <= 2405) return '15'
			return '16'
		},
		get_image_path (nmid) {
			let first_part = nmid.slice(0, -3)
			let second_part = nmid.slice(0, -5)
			let third_part = this.calculate_vol_image(Number(second_part))
			return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
		},
		async change_status(){
			this.loading.change_status = true
			try{
				const response = await Advertisement.changeAdvertisementStatus(this.data.campaign_id)
				this.$toasted.show(`Статус успешно изменен`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				})
				this.data.campaign_status = response.data.campaign_status
				this.data.campaign_status_display = response.data.campaign_status_display
			} catch(err){
				this.$toasted.show(`Ошибка(${err.response.data.error})`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.change_status = false
		},
		async update_advertisement_comment(){
			this.dialog_comment = false
			this.loading.comment = true
			try{
				await Advertisement.updateAdvertisementCampaign(this.data.campaign_id, {'comment': this.data.comment})
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка изменения данных`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.comment = false
		},
		async deposit_advertisement(){
			this.dialog_budget = false
			this.loading.budget = true
			try{
				let json_data = {'deposit': this.deposit, 'deposit_type': this.deposit_type}
				const response = await Advertisement.depositAdvertisementCampaignWithType(this.data.campaign_id, json_data)
				this.$toasted.show(`Успешное пополнение`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				this.data.budget = response.data.budget
			} catch(err){
				this.$toasted.show(`Ошибка пополнения`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.budget = false
		},
		async change_name(){
			this.loading.name = true
			this.dialog_name = false
			try{
				await Advertisement.changeNameAdvertisementCampaign(this.data.campaign_id, this.data.campaign_name)
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка изменения названия`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.name = false
		},
		async stop_advertisement(){
			this.loading.stop = true
			this.dialog_stop = false
			try{
				const response = await Advertisement.changeStatusAdvertisementCampaign(this.data.campaign_id, 'STOP')
				this.$toasted.show(`Успешно изменено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				this.data.campaign_status = response.data.campaign_status
				this.data.campaign_status_display = response.data.campaign_status_display
			} catch(err){
				this.$toasted.show(`Ошибка изменения статуса`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.stop = false
		},
		async fill_advertisement_campaign(){
			this.loading.refresh = true
			try{
				await Advertisement.fillOneAdvertisementCampaign(this.data.campaign_id)
				this.$toasted.show(`Успешно обновлено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
				this.$emit('get_data')
			} catch(err){
				this.$toasted.show(`Ошибка обновления`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.refresh = false
		},
	},
}
</script>

<style lang="scss" scoped>

</style>