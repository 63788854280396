import {Products} from '@/api/products'

export default {
  state: () => ({
    feedbacks: {},
    products: []
  }),
  mutations: {
    updateFeedbacks (state, payload) {
      state.feedbacks.items = payload.feedbacks.results
      state.feedbacks.filters = payload.filters
      state.feedbacks.count = payload.feedbacks.count
    }
  },
  actions: {
    async fetchFeedbacks ({commit}, {query, filters}) {
      const feedbacks = await Products.getFeedbacks(query)
      commit('updateFeedbacks', {feedbacks: feedbacks.data, filters})
    }
  },
  getters: {

  }
}
