<template>
    <v-container fluid grey lighten-4 class="pa-5">
		<v-dialog v-model="delete_data.dialog" max-width="500px" v-if="delete_data.dialog">
			<v-card>
				<v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="delete_data.dialog = false">Cancel</v-btn>
				<v-btn color="blue darken-1" text :loading="delete_data.loading" @click="delete_template">OK</v-btn>
				<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="promotion.dialog" v-if="promotion.dialog" max-width="1600" scrollable>
			<PriceManagementPromotionComponent :template_id="data.id"/>
		</v-dialog>
		<v-card>
			<v-card-title>Общая информация</v-card-title>
			<v-card-text>
				<p>Название: {{ data.name }}</p>
				<p>Ссылка на гугл таблицу: <a :href="data.url" target="_blank">{{ data.url }}</a></p>
				<p>Список товаров: {{ data.products }}</p>
				<p>Список аккаунтов {{ data.accounts }}</p>
				<p>Дата создания {{ data.date_created | formatDateToLocale }}</p>
				<p v-if="data.date_sync">Последнее обновление {{ data.date_sync | formatDateToLocale }}</p>
			</v-card-text>
		</v-card>
		<v-card class="mt-5">
			<v-card-title>Действия</v-card-title>
			<v-card-text class="d-flex">
				<v-btn 
					color="blue" :loading="loading_sync_table" @click="sync_data_in_google_table"
				>Обновить данные в таблице</v-btn>
				<v-btn color="lime" class="ml-5" @click="promotion.dialog = true">
					Управление акциями
				</v-btn>
				<router-link target="_blank" class="ml-5" :to="{ name: 'price_management_change_price'}">
					<v-btn color="green">Управление ценой</v-btn>
				</router-link>
				<v-btn color="orange" :disabled="true" class="ml-5">Изменить шаблон</v-btn>
				<v-btn color="red" @click="delete_data.dialog = true" class="ml-5">
					Удалить таблицу
				</v-btn>		
			</v-card-text>
		</v-card>
       
    </v-container>
</template>

<script>
import { PriceManagement } from '@/api/price_management'
import PriceManagementPromotionComponent from '@/components/PriceManagement/PriceManagementPromotionComponent'

export default {
    name: 'PriceManagementAdminTemplate',
	components: { PriceManagementPromotionComponent },
	computed: {
		template_id(){
			return this.$route.params.template_id
		}
	},
    data() {
        return {
			data: {},
			delete_data: {
				dialog: false,
				loading: false
			},
			promotion: {
				dialog: false,
				loading: false
			},
			loading_sync_table: false
		}
    },
    methods: {
		async get_template_data(){
			try{
				const response = await PriceManagement.retrieveTemplate(this.template_id)
				this.data = response.data
			} catch(err){
				console.log(err)
			}
		},
		async delete_template(){
			this.delete_data.loading = true
			try{
				await PriceManagement.deleteTemplate(this.template_id)
				this.$toasted.show(`Успешно`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-center',
					duration: 3000,
				});
				this.$router.push({name: 'price_management_templates'})
			} catch(err){
				this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-center',
					duration: 3000,
				});
			}
			this.delete_data.loading = false
			this.delete_data.dialog = false
		},
		async sync_data_in_google_table(){
			this.loading_sync_table = true
			try{
				await PriceManagement.syncTable(this.template_id)
				this.$toasted.show(`Успешно`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-center',
					duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка: ${err.response.data.error}`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-center',
					duration: 3000,
				});
			}
			this.loading_sync_table = false
		}
    },
    async mounted(){
		document.title = `Управление ценой (${this.template_id})`;
		this.$store.commit('setPageTitle', `Управление ценой (${this.template_id})`)
		await this.get_template_data()
		document.title = `Управление ценой (${this.data.name})`;
		this.$store.commit('setPageTitle', `Управление ценой (${this.data.name})`)
    }
}
</script>

<style lang="scss" scoped>
	.image{
		height: 50px;
		width: auto;
		margin-top: 5px;
		img{
			width: auto;
			height: 100%;
			object-fit: cover;
		}
		.big_img{
			display: none;
			position: absolute;
			top: calc(50vh - 100px);
			left: calc(50% - 100px);
			right: 0;
			bottom: 0;
			z-index: 20;
			width: 300px;
			height: auto;
		}
		&:hover{
			.big_img{
				display: block;
			}
		}
	}
</style>