<template>
    <section>
        <IncomesList></IncomesList>
    </section>
</template>

<script>
	import IncomesList from '@/components/WB/IncomesList';

	export default {
		name: 'Incomes',
		components: {IncomesList},
		mounted() {
			this.$store.commit('setPageTitle', `Поставки WB`);
		},
	};
</script>

<style scoped>

</style>