<template>
	<v-row>
		<v-col>
			<router-link :to="{ name: 'advertisement_create'}">
				<v-btn :color="$vuetify.theme.dark ? '#208379' : '#20ddca'">Создать РК</v-btn>
			</router-link>
		</v-col>
		<v-col>
			<router-link :to="{ name: 'advertisement_task_history'}">
				<v-btn :color="$vuetify.theme.dark ? '#208379' : '#20ddca'">Плановые задачи РК</v-btn>
			</router-link>
		</v-col>
	</v-row>
</template>

<script>
export default {
    name: 'AdvertisementListAdditionalFunctionalComponent',
}
</script>