<template>
    <div class="ozon_keywords_component">
		<v-dialog v-model="dialog_delete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_delete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteKeyword()">OK</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <BeeGridTable
			:loading="loading" border class="mt-5 ozon_keyword_monitoring" :showFilter="false"
            :showPager="false" height="380" :columns="keys" :data="data"
        >
			<template slot-scope="{ row, column }" slot="head">
				<div>{{row[column.key]}}</div>
            </template>	
			<template slot-scope="{ row }" slot="delete">
                <v-icon
					v-if="!is_short_version && !is_competitors && !row.is_general_fields" small @click="openDialogDelete(row.text)"
                >mdi-delete</v-icon>
            </template>
			<template slot-scope="{ row }" slot="favorite">
                <v-switch
					v-if="!is_short_version && !is_competitors && !row.is_general_fields" 
					color="primary" class="mt-0" hide-details v-model="row.is_favorite"
                    @change="changeFavoriteKeyword(row)"
                ></v-switch>
            </template>
			<template slot-scope="{ row }" slot="text_keyword">
				<a 
					:href="urlOzonMonitoring(row.text)" target="_blank"
                    :class="{'error_keyword': calculate_not_data(row)}"
                    v-if="!row.is_general_fields"
                >{{row.text}}</a>
                <div v-else> {{row.text}} </div>
            </template>		
			<template slot-scope="{ row, column }" slot="position">
				<div v-if="row && row.keyword_monitoring_historical">
					<div v-if="row.is_general_fields || row.is_calculate_color">
						<span :class="{'fill_cell_keyword': !row.is_general_fields, 'fill_index': row.is_calculate_color}">
							{{ 
								row.keyword_monitoring_historical[column.key].position_from_mpstats ?
								row.keyword_monitoring_historical[column.key].position_from_mpstats : 
								'-' 
							}}
						</span>
						<v-chip
							v-if="row.keyword_monitoring_historical[column.key].index_day_from_mpstats < 0"
							class="mx-1 px-1 font-small" color="red" outlined x-small
						>
							{{ row.keyword_monitoring_historical[column.key].index_day_from_mpstats }}
						</v-chip>
						<v-chip
							v-else-if="row.keyword_monitoring_historical[column.key].index_day_from_mpstats > 0"
							class="mx-1 px-1 font-small" color="success" outlined x-small
						>
							{{ row.keyword_monitoring_historical[column.key].index_day_from_mpstats }}
						</v-chip>
					</div>
					<div v-else>
						<v-tooltip bottom max-width=450 v-if="!row.is_general_fields">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<div v-if="!row.keyword_monitoring_historical[column.key]">-</div>
									<div v-else>
										<span :class="['fill_cell_keyword', {'fill_index': row.is_calculate_color}]">
											{{ 
												row.keyword_monitoring_historical[column.key].position_from_mpstats ?
												row.keyword_monitoring_historical[column.key].position_from_mpstats : 
												'-' 
											}}
										</span>
										<span style="font-weight: bold;" v-if="row.keyword_monitoring_historical[column.key].is_traforetto">T</span>
										<v-chip
											v-if="row.keyword_monitoring_historical[column.key].index_day_from_mpstats < 0"
											class="mx-1 px-1 font-small" color="red" outlined x-small
										>
											{{ row.keyword_monitoring_historical[column.key].index_day_from_mpstats }}
										</v-chip>
										<v-chip
											v-else-if="row.keyword_monitoring_historical[column.key].index_day_from_mpstats > 0"
											class="mx-1 px-1 font-small" color="success" outlined x-small
										>
											{{ row.keyword_monitoring_historical[column.key].index_day_from_mpstats }}
										</v-chip>
									</div>
								</div>
							</template>
							<div style="background: #fff; text-align:center;">
								<div style="color: black; white-space: nowrap;">
									Позиция(с ЛК): {{ row.keyword_monitoring_historical[column.key].position_lk }}
								</div>
								<div style="color: black; white-space: nowrap;">
									Популярность запроса: {{ row.keyword_monitoring_historical[column.key].populatity }}
								</div>
								<div style="color: black; white-space: nowrap;" v-if="row.keyword_monitoring_historical[column.key].is_traforetto">
									Трафарет
								</div>
							</div>
						</v-tooltip>
					</div>
                </div>
                <div v-else>-</div>
            </template>
        </BeeGridTable>
    </div>
    
</template>

<script>
import { KeywordMonitoring } from '@/api/keyword_monitoring'
  export default {
    name: "OzonKeywordMonitoring",
	props: {
		sku: { type: String },
		mid: { type: String },
		favorite: { type: String, default: '' },
		not_show_admin: { type: Boolean, default: false },
		is_competitors: { type: Boolean, default: false},
		is_short_version: {type: Boolean, default: false}
	},
    data(){
        return {
            list_dates: [],
			data: [],
			dialog_delete: false,
			deleted_item_text: null,
			loading: false
        }
    },
    methods:{
        async fetchOzonKeywordMonitoring(){
			this.loading = true
			try{
				const response =  await KeywordMonitoring.getKeywordsMonitoringByDate(this.sku, {"favorite": this.favorite, "mid": this.mid})
				this.list_dates = response.data.list_dates
				this.data = response.data.data
			} catch (err){
				console.log(err)
			}      
			this.loading = false   
        },
        scrollRight(){
            let block = document.querySelectorAll(".ozon_keyword_monitoring .bee-table-body")
			if(block){
				block.forEach(item=>{
					if(item.scrollWidth != 0){
						setTimeout(() => {  item.scrollLeft =  item.scrollWidth }, 1);
					}
				})
			}
        },
		calculateColor(){
            let data = document.getElementsByClassName('fill_cell_keyword')
            data.forEach(element => {
                let item = element.closest('td')
                if (item){
                    let value = element.textContent
                    let color = 'white'
                    if (value <= 10 && value > 0)
                        color = '#cef1c4'
                    else if (value <= 20 && value > 10)
                        color = '#def3d9'
                    else if (value <= 100 && value > 20)
                        color = '#eef5ed'
                    item.style.background = color
                }
                    
            })
        },
		calculateColorIndex(){
            let data = document.getElementsByClassName('fill_index')
            data.forEach(element => {
                let item = element.closest('td')
                if (item){
                    let value = element.textContent
                    let color = 'white'
                    if (value < 0)
                        color = '#fef5f6'
                    else if (value > 0)
                        color = '#f4faf4'
                    item.style.background = color
                }
                    
            })
        },
		urlOzonMonitoring(text){
            this.scrollRight()
            this.calculateColor()
			this.calculateColorIndex()
			return `https://www.ozon.ru/search/?text=${text}&from_global=true`
        },
		async deleteKeyword(){
            try {
                const response = await KeywordMonitoring.deleteOzonKeywords(
					this.mid, this.deleted_item_text
				)
                if (response.status == 200) {
                    await this.fetchOzonKeywordMonitoring()
                }
                this.dialog_delete = false
            }
            catch (err){
                console.log(err)
            }
        },
		openDialogDelete(text){
			this.dialog_delete = true
            this.deleted_item_text = text
		},
		async changeFavoriteKeyword(row){
            try {
                const response = await KeywordMonitoring.updateOzonFavoriteKeyword(
					this.mid, row.text, row.is_favorite
				)
                if (response.status == 200) {
                    await this.fetchOzonKeywordMonitoring()
                }
            }
            catch (err){
                console.log(err)
            }
        },
		calculate_not_data (row){
			if (!row || !row.keyword_monitoring_historical)
				return true
			for (const [key, value] of Object.entries(row.keyword_monitoring_historical)) {
				if (value && value['position_from_mpstats'])
					return false
			}
			return true
		},
		converDateToDDMM(date){
			return date.split('-').reverse().slice(0, 2).join('-')
		}
    },
    computed:{
        keys(){
			let key = [
				{fixed:"left", width: 45, align: "center", slot: "delete"},
				{title: "Избранное", key: "favorite", fixed:"left", width: 70, resizable: true, align: "center", slot: "favorite"},
				{title: "Ключевое слово", key: "text", fixed: "left", width: 350, resizable: true, align: "center", slot: 'text_keyword'},
				{title: "Частотность", key: "frq", fixed: "left", width: 75, resizable: true, align: "center", slot: 'head'},
				{title: "Результатов", key: "results", fixed: "left", width: 80, resizable: true, align: "center", slot: 'head'},
				{title: "Ср. позиция", key: "avg_position", fixed: "left", width: 55, resizable: true, align: "center", slot: 'head'},
			]
			this.list_dates.forEach(element => {key.push({
				title: this.converDateToDDMM(element), key: element, width: 65, 
				resizable: true, align: "center", slot: 'position'
			})})
			return key
        }
    },
    async mounted(){
        await this.fetchOzonKeywordMonitoring()
		this.scrollRight()
    },
  }
</script>

<style lang="scss">
.error_keyword{
    color: red !important;
}
.bee-table-cell{
    padding: 0px 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ozon_keywords_component .bee-table td {
	height: 35px !important;
}
</style>