<template>
    <div class="product_stock_balances">
		<v-row>
			<v-col>
				<v-menu 
					v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
					transition="scale-transition" offset-y="offset-y" min-width="290px" ref="datepicker_menu_from"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-text-field
							clearable v-model="dataRangeText" label="Диапазон" prepend-icon="mdi-calendar"
							readonly="readonly" v-bind="attrs" hide-details outlined dense v-on="on"
						></v-text-field>
					</template>
					<v-date-picker v-model="dates_range" range :first-day-of-week="1"></v-date-picker>
				</v-menu>
			</v-col>
			<v-col>
				<v-btn @click="fetch_product_data_days()" color="purple darken-2" class="mb-3" dark>
					Показать
				</v-btn>
			</v-col>
			<v-spacer></v-spacer>
		</v-row>
		<v-skeleton-loader v-if="table_loading" type="table-heading, table-row@5" class="pa-4"></v-skeleton-loader>
        <BeeGridTable
			v-else border class="product_table mt-5" :showFilter="false" :showPager="false"
            height="800" :columns="prepared_headers" :data="prepared_data"
        >
			<template slot-scope="{ column }" slot="column_name">
				<div class="small-text" style="flex-direction: column; align-items: center; padding: 0px 5px;">
					{{ column.title }}
				</div>
			</template>
			<template slot-scope="{ column, row}" slot="head">
                <div :class="['small-text', {'black-cell': is_empty_cell(row.label)}]">
					{{row[column.key]}}
				</div>
            </template>
			<template slot-scope="{ column, row}" slot="data">
				<div class="black-cell" v-if="is_empty_cell(row.label)">
					{{row[column.key]}}
				</div>
				<div v-else-if="row.label=='price'">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" class="ml-1">
								<a href="#" class="dashed small-text">
									{{ row[column.key][0] ? row[column.key][0] : 0 | toRUB }}
								</a>
							</div>
						</template>
						<span>
							<p>Цена без скидкой: {{ row[column.key][1] ? row[column.key][1] : 0 | toRUB }}</p>
							<p>Цена со скидкой: {{ row[column.key][2] ? row[column.key][2] : 0 | toRUB }}</p>
							<p>Залоговая цена: {{ row[column.key][3] ? row[column.key][3] : 0 | toRUB }}</p>
							<p>Процент скидки: {{ row[column.key][4] ? row[column.key][4] : 0 }}%</p>
						</span>
					</v-tooltip>
				</div>
				<div class="small-text" v-else-if="row.label == 'cost_price'">
					<a class="dashed" @click="open_cost_price_dialog(column.key)">
						{{ row[column.key] ? row[column.key] : 0 | toRUB}}
					</a>
				</div>
				<div class="yellow-cell" v-else-if="row.label=='vp_plan'">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text">
									{{row[column.key] && row[column.key][0] ? row[column.key][0] : 0 | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Цена: {{row[column.key] && row[column.key][1] ? row[column.key][1] : 0 | toRUB}}
							</div>
							<div class="small-text">
								Комиссия : {{row[column.key] && row[column.key][1] ? row[column.key][1] * 0.2 : 0 | toRUB}}
							</div>
							<div class="small-text">
								Логистика : {{row[column.key] && row[column.key][1] ? row[column.key][1] * 0.02 : 0 | toRUB}}
							</div>
							<div class="small-text">
								Себестоимость : {{row[column.key] && row[column.key][2] ? row[column.key][2] : 0 | toRUB}}
							</div>
							<div class="small-text">
								Налог({{ row[column.key] && row[column.key][3] ? row[column.key][3] : 0 }}%): 
								{{row[column.key] && row[column.key][3] && row[column.key][1] ? row[column.key][3] * row[column.key][1] / 100 : 0 | toRUB}}
							</div>
						</span>
					</v-tooltip>
				</div>
				<div class="yellow-cell" v-else-if="row.label=='rentab_plan' || row.label=='markup_plan'">
					<div class="small-text">
						{{ row[column.key] ? (row[column.key] * 100).toFixed(2) : 0 }} %
					</div>
				</div>
				<div v-else-if="
					row.label=='orders_sum' || row.label=='sales_sum' || 
					row.label=='sales_cost_price' || row.label=='warenwert_mp'
				">
					<div class="small-text">
						{{ row[column.key] ? row[column.key] : 0 | toRUB }}
					</div>
				</div>
				<div class="yellow-cell" v-else-if="row.label=='vp_plan_orders'">
					<div class="small-text">
						{{ row[column.key] ? row[column.key] : 0 | toRUB }}
					</div>
				</div>
				<div v-else-if="row.label=='stocks_1c'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && (row[column.key][0] || row[column.key][1] || row[column.key][2])">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
									{{ row[column.key][0] || row[column.key][2] ? Math.floor(row[column.key][0] + row[column.key][2]) : '-'}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Остаток на складе: {{row[column.key] ? row[column.key][0]: 0}}
							</div>
							<div class="small-text">
								Остаток основного компонента: {{row[column.key] ? row[column.key][2]: 0}}
							</div>
							<div class="small-text">
								Возможные комплекты: {{row[column.key] ? row[column.key][1]: 0}}
							</div>
						</span>
					</v-tooltip>
				</div>
				<div v-else-if="row.label=='warenwert_1c'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][0] && (row[column.key][1] || row[column.key][2])">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text">
									{{ row[column.key][0] * (row[column.key][1] + row[column.key][2]) | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Склад: {{row[column.key][1] * row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Основной компонент: {{row[column.key][2] * row[column.key][0] | toRUB}}
							</div>
						</span>
					</v-tooltip>
					<div class="small-text" v-else>-</div>
				</div>
                <div v-else class="small-text">
					<div class="small-text" v-if="row[column.key]">{{row[column.key]}}</div>
					<div class="small-text" v-else>-</div>
				</div>
            </template>
        </BeeGridTable>
		<v-dialog v-model="dialogs.cost_price_dialog" width="600" v-if="dialogs.cost_price_dialog">
			<v-card>
				<v-card-title class="text-h5">
					Себестоимость на {{ dates.cost_price_dates | formatDateToLocaleNoTime}}
				</v-card-title>
				<div style="height: 400px;">
					<CostPriceDetails 
						:catalog_item_id="catalog_id" :date="dates.cost_price_dates"
					></CostPriceDetails>
				</div>
			</v-card>
		</v-dialog>
    </div>
</template>

<script>
import {Products} from '@/api/products';
import CostPriceDetails from '@/components/Product/CostPriceDetails';

export default {
	name: 'DetmirProductDataDays',
	props: {
		product_id: { type: String },
		catalog_id: {type: String}
	},
	components: { CostPriceDetails },
	data() {
		return {
			table_loading: false,
			list_dates: [],
			data: [],
			changePriceDialog: false,
			datepicker_menu_from: false,
			dates_range: [],
			dialogs: {
				cost_price_dialog: false
			},
			dates: {
				cost_price_dates: null
			}
		};
	},
	computed: {
		dataRangeText() {
			return this.dates_range.join(' ~ ');
		},
		prepared_data(){
			return  [
				{'label': 'empty', 'naming': " "},
				{'label': 'price', 'naming': "Цена", ...this.findField('sale_price', ['base_price', 'sale_price', 'pledge_price', 'sale_percent'])},
				{'label': 'cost_price', 'naming': "Себестоимость", ...this.findField('cost_price')},
				{'label': 'vp_plan', 'naming': "ВП план", ...this.findField('vp_plan', ['sale_price', 'cost_price', 'tax'])},
				{'label': 'rentab_plan', 'naming': "Рентабельность план", ...this.findField('rentab_plan')},
				{'label': 'markup_plan', 'naming': "Наценка план", ...this.findField('markup_plan')},

				{'label': 'empty', 'naming': "Заказы"},
				{'label': 'orders_sum', 'naming': "Заказы руб", ...this.findField('ordered_sum')},
				{'label': 'orders_count', 'naming': "Заказы шт", ...this.findField('ordered_qty')},
				{'label': 'vp_plan_orders', 'naming': "ВП с заказов план", ...this.findField('vp_plan_orders')},

				{'label': 'empty', 'naming': "Продажи"},
				{'label': 'sales_sum', 'naming': "Продажи руб", ...this.findField('sales_sum')},
				{'label': 'sales_count', 'naming': "Продажи шт", ...this.findField('sales_qty')},
				{'label': 'sales_cost_price', 'naming': "Продажи по с/с руб", ...this.findField('sales_cost_price')},
				
				{'label': 'empty', 'naming': "Остатки"},
				{'label': 'stocks', 'naming': "Остатки", ...this.findField('current_stock')},
				{'label': 'stocks_1c', 'naming': "Остатки 1С", ...this.findField('catalog_count', ['kit_count', 'kit_main_count'])},
				{'label': 'warenwert_mp', 'naming': "Стоимость товара на МП", ...this.findField('warenwert_mp')},
				{'label': 'warenwert_1c', 'naming': "Стоимость товара 1С", ...this.findField('cost_price', ['catalog_count', 'kit_main_count'])},	
			]
		},
		prepared_headers(){
			let key = [{title: " ", key: "naming", fixed:"left", width: 125, slot: 'head'}]
			this.list_dates.forEach(element => {key.push({
				title: element, key: element, width: 120, resizable: true, align: "center",
				slot: 'data', headSlot: 'column_name',
			})})
			return key
		},
	},
	methods: {
		is_empty_cell(label){
			if (label == 'empty') return true
			return false
		},
		findField(field, kwargs=[]){
			let result = {}
			this.list_dates.forEach(el => {
				let filter_data = this.data.filter(item => item.date == el);
				try{
					if (kwargs.length == 0)
						result[el] = filter_data[0][field]
					else{
						result[el] = [filter_data[0][field]]
						kwargs.forEach(el_kw=>{
							result[el].push(filter_data[0][el_kw])
							
						})
					}
				} catch{
					if (kwargs.length == 0)
						result[el] = '-'
					else{
						result[el] = ['-']
						kwargs.forEach(el_kw=>{
							result[el].push('-')
							
						})
					}
				}
			})
			return result
		},
		async fetch_product_data_days() {
			this.table_loading = true;
			try {
				let query = {"dates_range": this.dates_range ? this.dates_range.join(',') : null}
				const res = await Products.fetchDetmirProductDataDays(this.product_id, query)
				this.data = res.data.data;
				this.list_dates = res.data.list_dates
			} catch (err) {
				console.log(err);
			}
			this.table_loading = false;
		},
		open_cost_price_dialog(date){
			this.dates.cost_price_dates = date;
			this.dialogs.cost_price_dialog = true;
		}
	},
	async mounted() {
		await this.fetch_product_data_days();
	}
};
</script>