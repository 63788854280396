<template>
    <v-card rounded elevation="0" :loading="loadingData">
        <v-card-title>Сводная информация</v-card-title>
        <v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="3">
                        <v-select
                                :items="sellers"
                                item-text="name"
                                item-value="id"
                                value=""
                                v-model="dashboardActiveSeller"
                                outlined
                                dense
                                label="Аккаунт"
                        ></v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-menu
                                v-model="dataRangeDateMenu"
                                :close-on-content-click="false"
                                prepend-icon="mdi-calendar"
                                transition="scale-transition"
                                offset-y="offset-y"
                                nudge-right="40"
                                min-width="290px"
                                ref="dataRangeDateMenu"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        clearable
                                        @click:clear="dataRangeDateText = null"
                                        v-model="$store.getters.getDashboardDataRangeDateText"
                                        label="Даты"
                                        readonly="readonly"
                                        v-bind="attrs"
                                        outlined
                                        dense
                                        hide-details
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dataRangeDates"
                                    range
                                    @change="changeDates"
                                    :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="3">
                        <v-btn
                                @click="fetchData()"
                                depressed
                                color="purple darken-2"
                                class="mb-3"
                                dark
                        >
                            <v-icon dark>
                                mdi-send
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid>
                <v-row v-if="loadingInit">
                    <v-col cols="12" v-for="index in 3" :key="index">
                        <v-skeleton-loader type="card-heading, list-item-two-line@3"></v-skeleton-loader>
                    </v-col>
                </v-row>

                <v-row v-else>
                    <v-col cols="12" v-for="(item, index) in sellerData" :key="index" style="position:relative;">
                        <v-card
                                class="mx-auto"
                                :loading="loadingData"
                                outlined
                        >
                            <v-card-title v-text="item.meta.name"></v-card-title>

                            <v-card-text class="px-0">
                                <v-row>
                                    <v-col cols="3">
                                        <v-list two-line dense>
                                            <v-list-item v-for="(value, name) in item.data.summary" :key="name">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="matchLabels[name] || name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="value || '-'"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="9">
                                        <div v-if="index==='sales' && item.data.timeseries">
                                            <sales-chart :series="item.data.timeseries"></sales-chart>
                                        </div>
                                        <div v-if="index==='orders' && item.data.timeseries">
                                            <orders-chart :series="item.data.timeseries"></orders-chart>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
	import {Dashboard} from '@/api/dashboard';
	import SalesChart from './SalesChart';
	import OrdersChart from './OrdersChart';

	export default {
		name: 'SellerSummaryData',
		components: {OrdersChart, SalesChart},
		data: function() {
			return {
				loadingData: false,
                loadingInit: true,
				sellerID: null,
				seller: {},
				dataRangeDates: this.$store.state.dashboard.dataRangeDates,
				dataRangeDateMenu: false,
				sellerData: {},
				matchLabels: {
					count_sales: 'Продажи',
					count_storno: 'Сторно',
					sum_sales: 'Сумма продаж',
					sum_storno: 'Сумма сторно',
					count_products: 'Кол-во товаров',
					count_products_catalog: 'Товары в каталоге',
					count_orders: 'Заказы',
					orders_amount: 'Сумма заказов',
					count_orders_cancel: 'Отмененные',
                    returns: 'Возвраты'
				},
			};
		},
		computed: {

			dashboardActiveSeller: {
				get() {
					return this.$store.getters.getDashboardActiveSeller;
				},
				set(sellerID) {
					this.$store.commit('updateDashboardActiveSeller', sellerID);
				},
			},
			// dateRangeDates: {
			// 	get() {
			// 		return this.$store.state.dashboard.dateRangeDates
			// 	}
			// },
			dataRangeDateText() {
				return this.dataRangeDates?.join(' ~ ');
			},
		},
		async mounted() {
			// await this.$store.dashboard
			await this.setSellers();
			// используем в mounted дефолтное значение продавца
			await this.fetchData(this.$store.getters.getDashboardActiveSeller);
			this.loadingInit = false
		},
		methods: {
			changeDates(dates) {
				let date1 = new Date(dates[0]);
				let date2 = new Date(dates[1]);
				if (date1 > date2) {
					dates = dates.reverse();
				}
				this.$store.commit('updateDataRangeDates', dates);
			},
			async setSellers() {
				try {
					await this.$store.dispatch('fetchSellers');
					this.sellers = this.$store.state.dashboard.sellers;
					this.$store.commit('updateDashboardActiveSeller', this.$store.state.dashboard.sellers[0]?.id);
				} catch (err) {
					console.log(err);
				}
			},
			async fetchData() {
				this.loadingData = true;
				try {
					const response = await Dashboard.fetchSellerSummaryData({
						'seller_id': this.$store.state.dashboard.dashboardActiveSeller,
						'date_from': this.$store.state.dashboard.dataRangeDates[0],
						'date_to': this.$store.state.dashboard.dataRangeDates[1],
					});
					this.sellerData = response.data;
				} catch (err) {
					console.log(err);
				}
				this.loadingData = false;
			},
		},
	};
</script>

<style scoped lang="scss">

</style>