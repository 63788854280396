<template>
<v-card>
    <v-card-title>
        <span class="text-h5">Редактирование</span>
    </v-card-title>
    <v-card-text>
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="12"
                >
                    <v-form>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="edit_data.quantity_product"
                            label="Количество"
                            required
                            type="number"
                            outlined
                            clearable
                            :rules="[rules.required, rules.positive]"
                            tabindex="1"
                            dense
                        ></v-text-field>
                        <v-menu
                            v-if="isRedemptionManager" 
                            v-model="datePicker.datepicker_date_order" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_order"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateOrderFormatted"
                                    @click:clear="edit_data.date_order = null"
                                    label="Дата заказа"
                                    :rules="[rules.required]"
                                    readonly="readonly"
                                    tabindex="2"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="edit_data.date_order" 
                                @input="datePicker.datepicker_date_order = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="edit_data.sum_order"
                            label="Сумма"
                            outlined
                            type="number"
                            tabindex="3"
                            :rules="[rules.required, rules.positive]"
                            dense
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="item.type_redemption == 'DISTRIBUTION' && isRedemptionManager"
                            v-model="edit_data.cashback"
                            label="Кэшбэк, %"
                            outlined
                            tabindex="4"
                            :rules="[rules.cashback]"
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                        <v-select
                            v-if="isRedemptionManager"
                            v-model="edit_data.manager_id"
                            :items="choices.list_manager"
                            item-text="username"
                            item-value="id"
                            label="Менеджер"
                            :rules="[rules.required]"
                            tabindex="5"
                            outlined
                            clearable
                            dense
                        ></v-select>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="edit_data.account_buyer"
                            label="Покупатель"
                            outlined
                            tabindex="6"
                            clearable
                            dense
                        ></v-text-field>
                        <v-text-field
                            v-if="isRedemptionManager"
                            v-model="edit_data.phone"
                            v-mask="'+7 (###) ###-##-##'"
                            dense
                            outlined
                            clearable
                            tabindex="7"
                            label="Номер аккаунта"
                        ></v-text-field>
                        <v-text-field
                            v-if="isRedemptionManager && item.type_redemption != 'DISTRIBUTION'"
                            v-model="edit_data.address_pvz"
                            label="Адрес ПВЗ."
                            outlined
                            tabindex="8"
                            clearable
                            dense
                        ></v-text-field>
                        <v-menu 
                            v-if="item.type_redemption != 'DISTRIBUTION'"
                            v-model="datePicker.datepicker_date_buy" 
                            :close-on-content-click="false" 
                            :nudge-right="40" 
                            transition="scale-transition" 
                            offset-y="offset-y" 
                            min-width="290px" 
                            ref="datePicker.datepicker_date_buy"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable
                                    v-model="dateBuyFormatted"
                                    @click:clear="edit_data.date_buy = null"
                                    label="Дата покупки"
                                    readonly="readonly"
                                    tabindex="9"
                                    v-bind="attrs"
                                    outlined
                                    dense
                                    v-on="on">  
                                </v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="edit_data.date_buy" 
                                @input="datePicker.datepicker_date_buy = false"
                                :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
						<v-textarea
							v-model="edit_data.feedback_text"
							v-if="isRedemptionManager && item.type_redemption == 'FEEDBACK'"
							label="Текст отзыва"
							outlined
							clearable
							tabindex="10"
							auto-grow
							rows="3"
							col="2"
							dense
						></v-textarea>
						<v-select
                            v-model="edit_data.product_status"
                            :items="choices.product_status_redemption_choices"
							v-if="isRedemptionManager && item.type_redemption != 'DISTRIBUTION'"
                            item-text="name"
                            item-value="value"
                            label="Статус товара"
                            tabindex="8"
                            outlined
                            clearable
                            dense
                        ></v-select>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
            Cancel
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="save"
        >
            Save
        </v-btn>
    </v-card-actions>
</v-card>    
</template>

<script>

import _ from 'lodash'
import { Redemption } from '@/api/redemption'
import { VueMaskDirective } from 'v-mask'
import Vue from 'vue'
Vue.directive('mask', VueMaskDirective)

export default {
    name: "OzonRedemptionFormUpdate",
    props: ['item'],
    data() {
        return {
            edit_data: {
                id: null,
                quantity_product: null,
                sum_order: null,
                cashback: null,
                manager_id: null,
                account_buyer: null,
                date_order: null,
                date_buy: null,
                phone: null,
                address_pvz: null,
				feedback_text: null,
				product_status: null
                
            },
			choices: {
				product_status_redemption_choices: [],
				list_manager: []
			},
            datePicker: {
                datepicker_date_buy: "",
                datepicker_date_order: ""
            },
            rules: {
                required: value => !!value || 'Обязательное поле.',
                positive: value => value > 0 || 'Должно быть положительное число',
                cashback: value => (value <= 100 && value >= 0) || 'Число должно быть от 0 до 100',
            },
        }
    },
    computed: {
        dateBuyFormatted () {
            return this.edit_data.date_buy ? this.$options.filters.formatDateToLocaleNoTime(this.edit_data.date_buy) : null
        },
        dateOrderFormatted () {
            return this.edit_data.date_order ? this.$options.filters.formatDateToLocaleNoTime(this.edit_data.date_order) : null
        },
        isRedemptionManager() {
            return this.$store.getters.isRedemptionManager || false
        },
        isRedemptionUser() {
            return this.$store.getters.isRedemptionUser || false
        },
    },
    methods: {
        close (){
            this.$emit('close')
        },
        save () {
            this.updateForm()
        },
        async updateForm(){
            let redemption_id = this.edit_data.id
			let data = {
					"quantity_product": this.edit_data.quantity_product,
					"sum_order": this.edit_data.sum_order,
					"manager_id": this.edit_data.manager_id,
					"type_redemption": this.item.type_redemption,
					"date_order":this.edit_data.date_order
				}
				if (this.item.type_redemption == 'DISTRIBUTION'){
					data['cashback'] = this.edit_data.cashback
				}
				if (this.edit_data.account_buyer){
					data['account_buyer'] = this.edit_data.account_buyer
				}
				if (this.edit_data.address_pvz && this.item.type_redemption != 'DISTRIBUTION'){
					data['address_pvz'] = this.edit_data.address_pvz
				}
				if (this.edit_data.phone){
					data['phone'] = this.edit_data.phone.replace(/[^\d]/g, '')
				}
				if (this.edit_data.date_buy && this.item.type_redemption != 'DISTRIBUTION'){
					data['date_buy'] = this.edit_data.date_buy
				} 
				if (this.edit_data.feedback_text && this.item.type_redemption == 'FEEDBACK'){
					data['feedback_text'] = this.edit_data.feedback_text
				} 
				if (this.edit_data.product_status && this.item.type_redemption != 'DISTRIBUTION'){
					data['status_product'] = this.edit_data.product_status
				}
            try{
                const response = await Redemption.updateOzonRedemption(redemption_id, data)
                this.$emit('close', "success")
            } catch (err){
                console.log(err)
            }   
        },
        async getManagers(){
            try {
                const responce = await Redemption.fetchRedemptionManager()
                this.choices.list_manager = responce.data   
            } catch (err) {
                console.log(err.data)
            }
        },
		async getProductStatusRedemption(){
            try {
                const responce = await Redemption.fetchBaseProductStatusRedemption()
                this.choices.product_status_redemption_choices = responce.data
            } catch (err) {
                console.log(err.data)
            }
        },
        transformDate(date){
            let tmp = new Date(date)
            return tmp.getFullYear() + "-" + (tmp.getMonth() + 1) + "-" + tmp.getDate()
        },
        autoComplete(){
            let manager = this.choices.list_manager.find(obj => obj['username'] === this.item.manager)
			let product_status = this.choices.product_status_redemption_choices.find(obj => obj['value'] === this.item.status_product)
            this.edit_data.id = this.item.id
            this.edit_data.quantity_product = this.item.quantity_product
            this.edit_data.sum_order = this.item.sum_order
            this.edit_data.manager_id = manager ? manager.id : ""
			this.edit_data.product_status = product_status ? product_status.value : ""
            this.edit_data.account_buyer = this.item.account_buyer
            this.edit_data.address_pvz = this.item.address_pvz
            this.edit_data.date_order = this.item.date_order ? this.transformDate(this.item.date_order) : ""
            this.edit_data.cashback = this.item.cashback
			this.edit_data.phone = this.item.phone ? "+7 (" + this.item.phone.substring(1, 4) + ") " + this.item.phone.substring(4, 7) + "-" + this.item.phone.substring(7,9) + "-" + this.item.phone.substring(9,11) : null
            this.edit_data.feedback_text = this.item.feedback_text
            this.edit_data.date_buy = this.item.date_buy ? this.transformDate(this.item.date_buy) : ""
            
        },
    },
    async mounted(){
        await this.getManagers()
		await this.getProductStatusRedemption()
        this.autoComplete()
    }
}
</script>

<style lang="scss" scoped>
</style>