<template>
    <v-container fluid>
        <v-card>
            <v-card-title>Динамика показателей по дням</v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="group_item" :items="group_choices"
                            item-text="name" item-value="value" label="Группа товаров"
                            outlined hide-details dense clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-menu 
                            v-model="datepicker_menu_from" :close-on-content-click="false" 
                            :nudge-right="40" transition="scale-transition" offset-y="offset-y" 
                            min-width="290px" ref="datepicker_menu_from"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    clearable v-model="dataRangeText" label="Диапазон"
                                    prepend-icon="mdi-calendar" readonly="readonly" 
                                    v-bind="attrs" hide-details="auto" outlined dense v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dates_range" range :first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                        <div class="d-flex justify-space-between">
                            <a style="white-space: nowrap;" @click="set_base_period(1, 7)">7 дней</a>
                            <a style="white-space: nowrap;" class="ml-2" @click="set_base_period(1, 14)">14 дней</a>
                            <a style="white-space: nowrap;" class="ml-2" @click="set_base_period(1, 30)">30 дней</a>
                            <a style="white-space: nowrap;" class="ml-2" @click="set_base_period(-1, -1)">пр. неделя</a>
                            <a style="white-space: nowrap;" class="ml-2" @click="set_base_period(-10, -10)">пр. месяц</a>
                        </div>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="fetch_data()" color="blue" class="mb-3"
                            :disabled="!group_item" :loading="table_loading" 
                        >Показать</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="change_root_display" color="blue" class="mb-3">
                            Группировка по цветам({{ is_show_root ? 'on': 'off' }})
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn @click="change_week_display" color="blue" class="mb-3">
                            Группировка по неделям({{ is_show_week ? 'on': 'off' }})
                        </v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="is_open_graphs = !is_open_graphs" color="blue" class="mb-3"
                            :disabled="table_data.length <= 0" 
                        >{{ !is_open_graphs ? 'Показать график': 'Скрыть график' }}</v-btn>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="export_data" color="blue" class="mb-3" :loading="loading_export"
                            :disabled="table_data.length <= 0" 
                        >Экспорт</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.account_name" :items="account_list"
                            item-text="name" item-value="name" label="Аккаунт"
                            outlined hide-details dense clearable multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.brand" :items="brand_list"
                            item-text="name" item-value="name" label="Бренд"
                            outlined hide-details dense clearable multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.tag" clearable outlined dense label="Тег" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="filters.status_name" :items="status_list"
                            item-text="name" item-value="name" label="Статус"
                            outlined hide-details dense clearable multiple
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.name" clearable outlined dense label="Название" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.code_1c" clearable outlined dense label="Артикул 1С" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                            v-model="filters.nmid" clearable outlined dense label="Артикул МП" hide-details="hide-details">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn
                            @click="apply_filters" color="blue"
                            :loading="table_loading" 
                        >Применить фильтры</v-btn>
                    </v-col>
                </v-row>
                <div v-if="is_open_graphs" class="mt-5">
                    <SummaryGraphVPRentab :summary_data="summary.summary" :is_show_week="is_show_week"/>
                </div>
                <v-alert density="compact" type="warning" v-if="!group_item && table_data.length <= 0" class="mt-5">
                    Выберите группу и нажмите "показать" для формирования отчета
                </v-alert>
                <div class="mt-5" v-if="table_data && table_data.length > 0">
                    <BeeGridTable
                        class="product_table2" :showFilter="false" :showPager="false"
                        height="850" :columns="prepared_headers" :data="prepared_data" 
                        :loading="table_loading" border
                    >
                        <template slot-scope="{ column }" slot="column_name">
                            <a @click="change_sort(column.title, column.our_sort)" v-if="!is_show_week" class="dashed small-text">{{ column.title | formatDateToLocaleNoTime }}</a>
                            <a @click="change_sort(column.title, column.our_sort)" v-else class="dashed small-text">{{ column.title }}</a>
                            
                            <div v-if="sort_dict.sort_fields == column.title">
                                <v-icon small v-if="sort_dict.sort_asc == 0">mdi-arrow-down</v-icon>
                                <v-icon small v-else>mdi-arrow-up</v-icon>
                            </div>
                        </template>
                        <template slot-scope="{ column }" slot="head">
                            <a @click="change_sort(column.title, column.our_sort)" class="small-text">{{ column.title }}</a>
                            <p style="color: red;" class="mb-0 small-text" v-if="column.title=='План/прогноз'">{{ dates_range[1] | formatDateMonth}}</p>
                            <div v-if="sort_dict.sort_fields == column.title">
                                <v-icon small v-if="sort_dict.sort_asc == 0">mdi-arrow-down</v-icon>
                                <v-icon small v-else>mdi-arrow-up</v-icon>
                            </div>
                        </template>
                        <template slot-scope="{ column, row}" slot="data">
                            <div v-if="column.key=='photo'" class="image d-flex justify-center" :class="{'grey-cell': row.is_root}">
                                <v-icon size="15" v-if="opened_nmid.includes(row.nmid)" @click="open_nmid(row.nmid)">mdi-arrow-up</v-icon>
                                <v-icon size="15" v-if="!opened_nmid.includes(row.nmid)" @click="open_nmid(row.nmid)">mdi-arrow-down</v-icon>
                                <img class="ml-2" v-if="!row.is_summary && !row.is_root" style="max-height:55px" :src="getImgPath(row.nmid)" alt="">
                                <img v-if="!row.is_summary && !row.is_root" class="big_img" :src="getImgPath(row.nmid)" alt="">
                            </div>
                            <div v-else-if="column.key=='product'" :class="{'grey-cell': row.is_root}">
                                <p class="mb-0 pb-0 font-weight-bold" v-if="row.is_summary">Итого</p>
                                <p class="small-text mb-0" v-else-if="row.is_root">
                                    Группа цветов: {{ row.imtid }} <br>
                                    <a class="d-block" @click="toggle_imtid_products(row.imtid)">
                                        {{ closed_imtid.includes(row.imtid) ? 'открыть': 'скрыть' }} группу
                                    </a>
                                </p>
                                <div v-else>
                                    <p class="small-text mb-0" style="white-space: nowrap; line-height: 1.4;">
                                        Артикул МП: 
                                        <a :href="`https://www.wildberries.ru/catalog/${row.nmid}/detail.aspx`" target="_blank">{{row.nmid}}</a>
                                    </p>
                                    <p class="small-text pb-0 mb-0" style="white-space: nowrap; line-height: 1.4;">
                                        Артикул 1С: <router-link target="_blank" :to="{ name: 'product_card', params: { id: row.nmid }}">{{ row.code_1c }}</router-link>
                                    </p>
                                    <main class="d-flex justify-center">
                                        <div :title="row.status" class="text-truncate mb-0 pb-0 small-text pr-1" style="max-width: 100px;">{{ row.status }}</div>
                                        <v-chip v-if="row.is_fbs" color="red" outlined x-small v-bind="attrs" v-on="on">fbs</v-chip>
                                        <v-icon color="red" v-if="!row.is_warehouse_expenses || row.is_warehouse_expenses == 0">mdi-exclamation</v-icon>
                                    </main>
                                    <p class="small-text pb-0 mb-0" style="white-space: normal;" v-if="opened_nmid.includes(row.nmid)">
                                        Название: {{ row.name }}
                                    </p>
                                </div>
                            </div>
                            <div v-else-if="column.key=='days'" :class="{'grey-cell': row.is_root}">
                                <div :class="['hidden_block', 'small-text', {'open_block': opened_nmid.includes(row.nmid)}]">
                                    <div>ВП_МП пр</div>
                                    <div>Рент ВП_МП</div>
                                    <div class="hidden_indicators">Сумма заказов</div>
                                    <div class="hidden_indicators">Кол-во заказов</div>
                                    <div class="hidden_indicators">Средняя цена</div>
                                    <div class="hidden_indicators">ВП_бс_н</div>
                                    <div class="hidden_indicators">Рентабельность</div>
                                    <div class="hidden_indicators">вн. реклама</div>
                                    <div class="hidden_indicators">ост. реклама</div>
                                    <div class="hidden_indicators">ДРР</div>
                                    <div class="hidden_indicators">Хранение</div>
                                </div>
                            </div>
                            <div v-else-if="column.key=='progress'" :class="{'grey-cell': row.is_root}">
                                <div v-if="!opened_nmid.includes(row.nmid)" class="small-text" :class="{'green_rentab': row.plan_execution >= 99 && row.plan_vp_mp != 0, 'orange_rentab': row.plan_execution >= 90 && row.plan_execution < 99 && row.plan_vp_mp != 0, 'red_rentab2': row.plan_execution < 90 && row.progress_vp_mp < 0 && row.plan_vp_mp != 0, 'red_rentab': row.plan_execution < 90 && row.progress_vp_mp >= 0 && row.plan_vp_mp != 0}">
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">План: {{ row.plan_vp_mp ? row.plan_vp_mp : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">Прогноз: {{ row.progress_vp_mp ? row.progress_vp_mp : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6; font-weight: bold">Выполнение: {{ row.plan_execution ? row.plan_execution.toFixed(2) : 0 }}%</p>
                                </div>
                                <div v-else style="height: 50%;" class="small-text" :class="{'green_rentab': row.plan_execution >= 99 && row.plan_vp_mp != 0, 'orange_rentab': row.plan_execution >= 90 && row.plan_execution < 99 && row.plan_vp_mp != 0, 'red_rentab2': row.plan_execution < 90 && row.progress_vp_mp < 0 && row.plan_vp_mp != 0, 'red_rentab': row.plan_execution < 90 && row.progress_vp_mp >= 0 && row.plan_vp_mp != 0}">
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">План: {{ row.plan_vp_mp ? row.plan_vp_mp : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">Прогноз: {{ row.progress_vp_mp ? row.progress_vp_mp : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6; font-weight: bold">Выполнение: {{ row.plan_execution ? row.plan_execution.toFixed(2) : 0 }}%</p>
                                </div>
                                <div style="height: 50%;" v-if="opened_nmid.includes(row.nmid)" class="small-text" :class="{'green_rentab': row.plan_execution_orders_sum >= 99 && row.plan_orders_sum != 0, 'orange_rentab': row.plan_execution_orders_sum >= 90 && row.plan_execution_orders_sum < 99 && row.plan_orders_sum != 0, 'red_rentab2': row.plan_execution_orders_sum < 90 && row.progress_orders_sum < 0 && row.plan_orders_sum != 0, 'red_rentab': row.plan_execution_orders_sum < 90 && row.progress_orders_sum >= 0 && row.plan_orders_sum != 0}">
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">План(З): {{ row.plan_orders_sum ? row.plan_orders_sum : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6;">Прогноз(З): {{ row.progress_orders_sum ? row.progress_orders_sum : 0 | toRUBWithoutKopecks }}</p>
                                    <p class="mb-0 pb-0" style="line-height: 1.6; font-weight: bold">Выполнение(З): {{ row.plan_execution_orders_sum ? row.plan_execution_orders_sum.toFixed(2) : 0 }}%</p>
                                </div>
                            </div>
                            <div v-else-if="column.key=='days_data'" :class="{'grey-cell': row.is_root}">
                                <div :class="['hidden_block', 'small-text', {'open_block': opened_nmid.includes(row.nmid)}]">
                                    <div>
                                        {{get_date_day(row, column.title).vp_mp | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).vp_mp_delta > 0, 'red_value': get_date_day(row, column.title).vp_mp_delta < 0}"> ({{get_date_day(row, column.title).vp_mp_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div :class="{'green_rentab': get_date_day(row, column.title).rentab_mp > 18, 'red_rentab': get_date_day(row, column.title).rentab_mp <=8, 'orange_rentab': get_date_day(row, column.title).rentab_mp > 8 && get_date_day(row, column.title).rentab_mp <=18}">
                                        {{get_date_day(row, column.title).rentab_mp.toFixed(2)}}% 
                                        <span :class="{'green_value': get_date_day(row, column.title).rentab_mp_delta > 0, 'red_value': get_date_day(row, column.title).rentab_mp_delta < 0}"> ({{get_date_day(row, column.title).rentab_mp_delta  .toFixed(2)}}%)</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).orders_sum | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).orders_sum_delta > 0, 'red_value': get_date_day(row, column.title).orders_sum_delta < 0}"> ({{get_date_day(row, column.title).orders_sum_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).orders_count}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).orders_count_delta > 0, 'red_value': get_date_day(row, column.title).orders_count_delta < 0}"> ({{get_date_day(row, column.title).orders_count_delta}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).price | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).price_delta > 0, 'red_value': get_date_day(row, column.title).price_delta < 0}"> ({{get_date_day(row, column.title).price_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).vp | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).vp_delta > 0, 'red_value': get_date_day(row, column.title).vp_delta < 0}"> ({{get_date_day(row, column.title).vp_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).rentab.toFixed(2)}}% 
                                        <span :class="{'green_value': get_date_day(row, column.title).rentab_delta > 0, 'red_value': get_date_day(row, column.title).rentab_delta < 0}"> ({{get_date_day(row, column.title).rentab_delta.toFixed(2)}}%)</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).internal_adverts | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).internal_adverts_delta < 0, 'red_value': get_date_day(row, column.title).internal_adverts_delta > 0}"> ({{get_date_day(row, column.title).internal_adverts_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).red_blogger_expenses | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).red_blogger_expenses_delta < 0, 'red_value': get_date_day(row, column.title).red_blogger_expenses_delta > 0}"> ({{get_date_day(row, column.title).red_blogger_expenses_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).drr.toFixed(2)}}% 
                                        <span :class="{'green_value': get_date_day(row, column.title).drr_delta < 0, 'red_value': get_date_day(row, column.title).drr_delta > 0}"> ({{get_date_day(row, column.title).drr_delta.toFixed(2)}}%)</span>
                                    </div>
                                    <div class="hidden_indicators">
                                        {{get_date_day(row, column.title).storage | toRUBWithoutKopecks}} 
                                        <span :class="{'green_value': get_date_day(row, column.title).storage_delta < 0, 'red_value': get_date_day(row, column.title).storage_delta > 0}"> ({{get_date_day(row, column.title).storage_delta | toRUBWithoutKopecks}})</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="column.key=='summary'" :class="{'grey-cell': row.is_root}">
                                <div :class="['hidden_block', 'small-text', {'open_block': opened_nmid.includes(row.nmid)}]">
                                    <div class="font-weight-bold">
                                        {{row.summary.vp_mp | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="font-weight-bold" :class="{'green_rentab': row.summary.rentab_mp > 18, 'red_rentab': row.summary.rentab_mp > 8 && row.summary.rentab_mp <=18}">
                                        {{row.summary.rentab_mp.toFixed(2)}}% 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.orders_sum | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.orders_count}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.price | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.vp | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.rentab.toFixed(2)}}% 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.internal_adverts | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.red_blogger_expenses | toRUBWithoutKopecks}} 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.drr.toFixed(2)}}% 
                                    </div>
                                    <div class="hidden_indicators font-weight-bold">
                                        {{row.summary.storage | toRUBWithoutKopecks}} 
                                    </div>
                                </div>
                            </div>
                            <div v-else></div>
                        </template>
                    </BeeGridTable>
                    <v-pagination 
                        :length="all_page" v-model="current_page" @change="prepared_data_func"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
    
</template>

<script>
	import {Products} from '@/api/products';
    import {General} from '@/api/general'
    import SummaryGraphVPRentab from '@/components/Product/SummaryGraphVPRentab'

	export default {
		name: 'DynamicIndicatorsDay',
        components: { SummaryGraphVPRentab },
		data() {
			return {
                is_show_root: false,
                is_show_week: false,
                is_open_graphs: false,
                filters: {
                    tag: null,
                    status_name: ['Продвигаем', 'Новинка'],
                    account_name: [],
                    name: null,
                    code_1c: null,
                    nmid: null,
                    brand: null
                },
                brand_list: [],
                status_list: [],
                account_list: [],
                items_on_page: 50,
                current_page: 1,
                summary: {},
                opened_nmid: [],
                closed_imtid: [],
                group_loading: false,
                group_choices: [],
                group_item: null,
                table_loading: false,
                table_data: [],
                table_headers: [],
				datepicker_menu_from: false,
                all_page: 0,
				dates_range: [],
				summaryDataPerDay: [],
                sort_dict: {
                    sort_fields: 'Итого',
                    sort_asc: 0 // 0 - убывание, 1 - возрастание
                },
                prepared_data: [],
                loading_prepare: false,
                loading_export: false                
			};
		},
        watch: {
			'current_page': function (newVal, oldVal) {
                this.prepared_data_func()
			},
		},
		computed: {
			dataRangeText() {
				return this.dates_range.join(' ~ ');
			},
            prepared_headers(){
                let key = [
                    {title: 'Фото', key: 'photo', fixed: 'left', width: 80, slot: 'data', headSlot: 'head', align: 'center'},
                    {title: 'Товар', key: 'product', fixed: 'left', width: 150, slot: 'data', headSlot: 'head', align: 'center'},
                    {title: 'Показатели', key: 'days', fixed: 'left', width: 110, slot: 'data', headSlot: 'head', align: 'center'},
                    {title: 'План/прогноз', key: 'progress', fixed: 'left', width: 150, slot: 'data', headSlot: 'head', align: 'center', our_sort: true},
                    {title: 'Итого', key: 'summary', fixed: 'left', width: 115, slot: 'data', headSlot: 'head', align: 'center', our_sort: true},
                ];
                if(this.table_headers && this.table_headers.length > 0){
                    this.table_headers.forEach(element => {
                        key.push({
                            title: element, key: 'days_data', width: 130, resizable: true, align: 'center',
                            slot: 'data', headSlot: 'column_name', our_sort: true
                        })
                    });
                }
				return key
            },
            transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else if(f=='nmid'){
						query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
					}else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
		},
		methods: {
            async change_root_display(){
				this.is_show_root = !this.is_show_root
				if (this.is_show_root){
					localStorage.setItem('dynamic_indicators_by_group', true)
				} else {
					localStorage.removeItem('dynamic_indicators_by_group')
				}
				await this.fetch_data()
			},
            async change_week_display(){
				this.is_show_week = !this.is_show_week
				if (this.is_show_week){
					localStorage.setItem('dynamic_indicators_show_week', true)
				} else {
					localStorage.removeItem('dynamic_indicators_show_week')
				}
				await this.fetch_data()
			},
            set_base_period(start, end){
                let date = new Date().toISOString().slice(0,10)
                if (start == -1 && end == -1){
                    let current_day_of_week = new Date().getDay()
                    start = current_day_of_week
                    end = current_day_of_week + 6
                }   
                if (start == -10 && end == -10){
                    let current_day = new Date().getDate()
                    start = current_day
                    end =  new Date(new Date(date)
                        .setDate(new Date(date)
                        .getDate() - start)).getDate() + current_day - 1
                }
                let date_to =  new Date(new Date(date)
                    .setDate(new Date(date)
                    .getDate() - start))
                let date_from =  new Date(new Date(date)
                    .setDate(new Date(date)
                    .getDate() - end))
                
                this.dates_range = [date_from.toISOString().slice(0, 10), date_to.toISOString().slice(0, 10)]
            },
            prepared_data_func(){
                this.prepared_data = []
                let summary = [{
                    'is_summary': true, 'nmid': 'abra', 'days_data': this.summary.summary, 
                    'summary': this.summary.prev_summary, 'plan_vp_mp': this.summary.plan_vp_mp,
                    'progress_vp_mp': this.summary.progress_vp_mp, 'plan_execution': this.summary.plan_execution,
                    'plan_orders_sum': this.summary.plan_orders_sum, 'progress_orders_sum': this.summary.progress_orders_sum, 
                    'plan_execution_orders_sum': this.summary.plan_execution_orders_sum
                }]
                let table_data = this.table_data
                let data = []
                table_data = this.sort_data(table_data)
                if (!this.is_show_root){
                    table_data.forEach(item => {
                        data.push({
                            'nmid': item.nmid, 'code_1c': item.code_1c, 'days_data': item.data,
                            'summary': item.summary, 'name': item.name, 'plan_vp_mp': item.plan_vp_mp,
                            'progress_vp_mp': item.progress_vp_mp, 'plan_execution': item.plan_execution,
                            'is_fbs': item.is_fbs, 'plan_orders_sum': item.plan_orders_sum, 
                            'progress_orders_sum': item.progress_orders_sum, 'is_warehouse_expenses': item.is_warehouse_expenses,
                            'plan_execution_orders_sum': item.plan_execution_orders_sum, 'status': item.status
                        })
                    })
                } else {
                    table_data.forEach(item => {
                        data.push({
                            'is_root': true, 'nmid': item.root_product, 'imtid': item.root_product, 'days_data': item.root_data.summary,
                            'summary': item.root_data.prev_summary, 'plan_vp_mp': item.root_data.plan_vp_mp,
                            'progress_vp_mp': item.root_data.progress_vp_mp, 'plan_execution': item.root_data.plan_execution,
                            'plan_orders_sum': item.root_data.plan_orders_sum, 'progress_orders_sum': item.root_data.progress_orders_sum, 
                            'plan_execution_orders_sum': item.root_data.plan_execution_orders_sum
                        })
                        if (!this.closed_imtid.includes(item.root_product)){
                            item.products.forEach(product => {
                                data.push({
                                    'nmid': product.nmid, 'code_1c': product.code_1c, 'days_data': product.data,
                                    'summary': product.summary, 'name': product.name, 'plan_vp_mp': product.plan_vp_mp,
                                    'progress_vp_mp': product.progress_vp_mp, 'plan_execution': product.plan_execution,
                                    'is_fbs': product.is_fbs, 'plan_orders_sum': product.plan_orders_sum, 
                                    'progress_orders_sum': product.progress_orders_sum, 'is_warehouse_expenses': product.is_warehouse_expenses,
                                    'plan_execution_orders_sum': product.plan_execution_orders_sum, 'status': product.status
                                })
                            })
                        }   
                    })
                }       
                console.log(data.length)         
                this.all_page = Math.ceil((data.length - 1) / this.items_on_page)
                this.prepared_data = [...summary, ...data.slice((this.current_page - 1) * this.items_on_page, this.current_page * this.items_on_page)]
            },
            sort_data(data){
                if (this.sort_dict.sort_fields == '')
                    return data
                if (this.sort_dict.sort_fields == 'План/прогноз'){
                    if (!this.is_show_root){
                        data = data.sort(function(a,b){
                            return new Date(b.plan_execution) - new Date(a.plan_execution);
                        })
                    } else {
                        data = data.sort(function(a,b){
                            return new Date(b.root_data.plan_execution) - new Date(a.root_data.plan_execution);
                        })
                    }
                }
                if (this.sort_dict.sort_fields == 'Итого'){
                    if (!this.is_show_root){
                        data = data.sort(function(a,b){
                            return new Date(b.summary.vp_mp) - new Date(a.summary.vp_mp);
                        })
                    } else {
                        data = data.sort(function(a,b){
                            return new Date(b.root_data.prev_summary.vp_mp) - new Date(a.root_data.prev_summary.vp_mp);
                        })
                    }
                } else {
                    let sort_field = this.sort_dict.sort_fields
                    if (!this.is_show_root){
                        data = data.sort(function(a,b){
                            return new Date(b.data[sort_field].vp_mp) - new Date(a.data[sort_field].vp_mp);
                        })
                    } else {
                        data = data.sort(function(a,b){
                            return new Date(b.root_data.summary[sort_field].vp_mp) - new Date(a.root_data.summary[sort_field].vp_mp);
                        })
                    }
                }
                return this.sort_dict.sort_asc == 0 ? data : data.reverse()
            },
			async fetch_data() {
				this.table_loading = true;
                this.is_open_graphs = false
                this.current_page = 1
				try {
					let query = {
						'product_group': this.group_item,
                        'dates_range': this.dates_range ? this.dates_range.join(',') : null,
                        ...this.transfromFiltersForQuery
                    }
                    if (this.is_show_root)
                        query['is_show_root'] = this.is_show_root
                    if (this.is_show_week)
                        query['is_weekly_group'] = this.is_show_week
					const res = await Products.fetchDynamicIndicatorsDay(query);
					this.table_data = res.data.data;
					this.table_headers = res.data.date_list;
                    this.summary = res.data.summary
                    this.status_list = res.data.status_filters
                    this.brand_list = res.data.brand_filters
                    this.account_list = res.data.account_filters
                    this.prepared_data_func()
				} catch (err) {
					console.log(err);
				}
				this.table_loading = false;
			},
            async fetch_product_groups(){
                this.group_loading = true;
				try {
					const res = await General.fetchProductGroup({'is_permission': true});
					this.group_choices = res.data;
				} catch (err) {
					console.log(err);
				}
				this.group_loading = false;
            },
            calculateVolImage(t){
				t = Number(t)
				if (t >= 0 && t <= 143)
					return '01'
				if (t >= 144 && t <= 287)
					return '02'
				if (t >= 288 && t <= 431)
					return '03'
				if (t >= 432 && t <= 719)
					return '04'
				if (t >= 720 && t <= 1007)
					return '05'
				if (t >= 1008 && t <= 1061)
					return '06'
				if (t >= 1062 && t <= 1115)
					return '07'
				if (t >= 1116 && t <= 1169)
					return '08'
				if (t >= 1170 && t <= 1313)
					return '09'
				if (t >= 1314 && t <= 1601)
					return '10'
				if (t >= 1602 && t <= 1655)
					return '11'
				if (t >= 1656 && t <= 1919)
					return '12'
				if (t >= 1920 && t <= 2045)
					return '13'
				if (t >= 2046 && t <= 2189)
					return '14'
                if (t >= 2190 && t <= 2405) 
                    return '15'
				return '16'
			},
			getImgPath (nmid) {
				let first_part = nmid.slice(0, -3)
				let second_part = nmid.slice(0, -5)
				let third_part = this.calculateVolImage(second_part)
				return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
			},
            open_nmid(nmid){
                if (this.opened_nmid.includes(nmid)){
                    this.opened_nmid = this.opened_nmid.filter(item => item != nmid)
                } else {
                    this.opened_nmid.push(nmid)
                }
            },
            get_date_day(row, date){
                return row.days_data[date]
            },
            apply_filters(){
                this.current_page = 1
                this.fetch_data()
            },
            change_sort(title, flag){
                this.current_page = 1
                if (!flag)
                    return
                if (this.sort_dict.sort_fields == title && this.sort_dict.sort_asc == 1){
                    this.sort_dict.sort_fields = ''
                    this.sort_dict.sort_asc = 0
                }
                else if (this.sort_dict.sort_fields == title && this.sort_dict.sort_asc == 0){
                    this.sort_dict.sort_asc = 1
                }
                else{
                    this.sort_dict.sort_fields = title
                    this.sort_dict.sort_asc = 0
                }
                this.prepared_data_func()
            },
            async export_data(){
                this.loading_export = true
                try {
                    const query = {
						'product_group': this.group_item,
                        'dates_range': this.dates_range ? this.dates_range.join(',') : null,
                        'export': true,
                        ...this.transfromFiltersForQuery
                    }
					const response = await Products.exportDynamicIndicatorsDay(query);
                    const url = URL.createObjectURL(response.data)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'dynamic_indicators.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                } catch (err) {
                    console.log(err.data)
                }
                this.loading_export = false
            },
            toggle_imtid_products(imtid){
                if (this.closed_imtid.includes(imtid)){
                    this.closed_imtid = this.closed_imtid.filter(item => item != imtid)
                } else {
                    this.closed_imtid.push(imtid)
                }
                this.prepared_data_func()
            }
		},
		async mounted() {
            document.title = 'Динамика показателей по дням WB | mp.lomex-group.ru';
            this.$store.commit('setPageTitle', `Динамика показателей по дням WB`);
            if (localStorage.getItem("dynamic_indicators_by_group")){
				this.is_show_root = true
			}
            if (localStorage.getItem("dynamic_indicators_show_week")){
				this.is_show_week = true
			}
            this.set_base_period(1, 14)
			await this.fetch_product_groups();
		},
	};
</script>

<style lang="scss">
    .image{
        display: flex;
        justify-items: center;
        align-items: center;
		width: 100%;
		img{
			max-width: 100%;
			object-fit: cover;
		}
		.big_img{
			display: none;
			position: fixed;
			top: calc(50vh - 100px);
			left: 150px;
			right: 0;
			bottom: 0;
			z-index: 20;
			width: 300px;
			height: auto;
		}
		&:hover{
			.big_img{
				display: block;
			}
		}
	}
    .product_table2 .bee-table td {
        height: 30px !important;
        border-bottom: 1px solid black;
    }
    .product_table2 .bee-table-cell {
        padding: 0px !important;
        width: 100%;
        height: 100%;

        div {
            white-space: nowrap;
            width: 100%;
            height: 100%;
        }
    }
    .small-text {
        font-size: 12px;
    }
	.hidden_block{
        div{
            border-bottom: 1px solid #c6c6c6;
            height: 30px !important;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & .hidden_indicators{
            display: none;
        }
        &.open_block .hidden_indicators{
            display: flex;
        }
    }
    .green_value{
        color: #08a008;
        font-weight: bold;
        margin-left: 2px;
    }
    .red_value{
        color: #f56565;
        font-weight: bold;
        margin-left: 2px;
    }
    .green_rentab{
		background-color: #edf9e9;
	}
	.orange_rentab{
		background-color: #f1f1d2;
	}
	.red_rentab{
		background-color: #ffe0e0;
	}
    .red_rentab2{
        background-color: #ffbbbb;
    }
    .white_rentab{
        background-color: #fff !important;
    }
    .grey-cell{
        background-color: #d8d8d8;
    }
</style>