<template>
    <div :class="[{'lighten-4 grey': !$vuetify.theme.dark}]">
        <v-container fluid class="pa-5">
            <v-row>
                <v-col sm="4" md="3">
                        <v-skeleton-loader
                                v-if="feedbackLoading"
                                type="article, card-heading, list-item-two-line@3"
                        ></v-skeleton-loader>
                        <v-card
                                v-else
                                flat
                        >
                            <v-card-text>
                                <div>{{ profile ? profile.country: null }}</div>
                                <p class="text-h6 text--primary">
                                    {{ profile ? profile.username: null }}
                                </p>
                            </v-card-text>
                            <v-list two-line>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Кол-во любимых брендов</v-list-item-title>
                                        <v-list-item-subtitle>{{ profile ? profile.favorite_brands_count: null }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Кол-во отзывов</v-list-item-title>
                                        <v-list-item-subtitle>{{ profile ? profile.feedbacks_count: null }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Зарегистрирован: </v-list-item-title>
                                        <v-list-item-subtitle>{{ profile ? profile.registered_time: null }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title>Источник: </v-list-item-title>
                                        <v-list-item-subtitle>{{ profile ? profile.source: null }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>
											<a 
												target="_blank" 
												:href="`https://wildberries.ru${profile.wb_profile_url}`"
												v-if="profile"
											>Профиль на WB</a>
										</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                <v-col sm="8" md="9">
                    <v-skeleton-loader
                            v-if="feedbackLoading"
                            type="article, card-heading, list-item-two-line@3"
                    ></v-skeleton-loader>
                    <v-card
                            v-else
                            class="mx-auto"
                            flat
                    >
                        <v-card-text>
                            <div>{{ product.parent }} | {{ product.object }}</div>
                            <p class="text-h5 text--primary">
                                <v-chip
                                        class="mr-2"
                                        color="blue"
                                        dark
                                        label
                                        @click:close="chip4 = false"
                                >
                                    {{ feedback.wbaccount.name }}
                                </v-chip>{{ product.brand }} | {{ product.name }}
                            </p>
                            <p>
                                <router-link :to="{ name: 'product_card', params: { id: product.child_products[0].nmid }}">Карточка товара</router-link> |
                                <a :href="`https://www.wildberries.ru/catalog/${product.child_products[0].nmid}/detail.aspx`" target="_blank">На WB</a>
                            </p>
                            <v-row class="px-2">
                                <v-rating
                                        :value="product.rating"
                                        color="amber"
                                        dense
                                        half-increments
                                        readonly
                                        size="18"
                                ></v-rating>
                                <div class="grey--text ms-4">
                                    {{ product.rating }}
                                </div>

                            </v-row>
                        </v-card-text>
                        <v-list two-line>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-title>Текст отзыва</v-list-item-title>
                                    <div class="text-body-2 grey--text text--darken-1">{{ feedback.text }}</div>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line v-if="feedback.answer">
                                <v-list-item-content>
                                    <v-list-item-title>Ответ от {{ feedback.answer_date | formatDateToLocaleNoTime }}</v-list-item-title>
                                    <div class="text-body-2 grey--text text--darken-1">{{ feedback.answer }}</div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-simple-table v-if="feedback">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">
                                    Дата отзыва
                                </th>
                                <th class="text-left">
                                    Полезность
                                </th>
                                <th class="text-left">
                                    Рейтинг
                                </th>
                                <th class="text-left">
                                    Исключен
                                </th>
                                <th class="text-left">
                                    Удален
                                </th>
								<th class="text-left">
                                    Статус удаления
                                </th>
                                <th>Фото</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ feedback.formatted_date }}</td>
                                <td>
                                    <div>
                                        <v-chip
                                                class="ma-1"
                                                color="green"
                                                text-color="white"
                                                x-small
                                        >
                                            {{ feedback.likes }}
                                        </v-chip>
                                        <v-chip
                                                class="ma-1"
                                                color="red"
                                                text-color="white"
                                                x-small
                                        >
                                            {{ feedback.dislikes }}
                                        </v-chip>
                                    </div>
                                </td>
                                <td>
                                    <v-rating
                                            length="5"
                                            small
                                            readonly
                                            background-color="orange lighten-3"
                                            color="orange"
                                            :value="feedback.product_rating"
                                    ></v-rating>
                                </td>
                                <td>
                                    <v-icon
                                            v-if="feedback.no_rating"
                                            color="red"

                                    >mdi-close-circle</v-icon>
                                </td>
                                <td>
                                    <v-icon
                                            v-if="feedback.is_deleted"
                                            color="red"

                                    >mdi-close-circle</v-icon>
                                    <span v-if="feedback.is_deleted_date" style="font-size: .75rem;" class="red--text text--accent-2 mt-1 ml-2">{{ feedback.is_deleted_date | formatDateToLocaleNoTime }}</span>
                                </td>
								<td>
                                    <span 
										v-if="feedback.deletion_status_trans" 
										style="font-size: .75rem;" 
										class="text--accent-2 mt-1 ml-2"
									>{{ feedback.deletion_status_trans }}</span>
                                </td>
                                <td>
                                    <v-icon
                                            v-if="feedback.has_photos"
                                            color="green"

                                    >mdi-checkbox-marked-circle</v-icon>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: "Feedback",
    props: {
      item: {
        type: Object,
        require: false
      }
    },
    data () {
      return {
        feedback: null,
        feedbackLoading: true
      }
    },
    computed: {
      feedback_id () {
        return this.$route.params.id
      },
      product () {
        return this.feedback.product
      },
      profile () {
        return this.feedback.user_profile
      }
    },
    methods: {
      async fetchFeedback () {
        this.feedbackLoading = true
        const response = await Products.getSingleFeedback(this.feedback_id)
        this.feedback = response.data
        this.feedbackLoading = false
      },
    },
    async mounted () {
        this.$store.commit('setPageTitle', `Отзыв ${this.feedback_id}`)
        if (this.item) {
          this.feedback = this.item
          this.feedbackLoading = false
        } else {
          await this.fetchFeedback()
        }
    }
  }
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
</style>
