<template>
	<div v-if="loading_data" style="display: flex; justify-content:center;">
		<v-progress-circular
			:size="50"
			color="primary"
			indeterminate
		></v-progress-circular>
	</div>
    <div v-else-if="!loading_data && data">
		<apexchart 
			type="line" 
			height="600" 
			:options="chartOptions"
			:series="seriesMain"
			ref="chart"
			@mounted="moundetHiddenSeries"
		></apexchart>
		<div class="control">
			<button @click="hiddenAll">Скрыть все</button>
		</div>
	</div>
	
	<p v-else>error</p>
</template>

<script>
	import { Products } from '@/api/products'
	import VueApexCharts from 'vue-apexcharts';

    export default {
        name: 'ChartsProductData',
        props: ['filters'],
		components: {
			apexchart: VueApexCharts,
        },
        data() {
            return {
                data: null,
				loading_data: false,
				chartOptions: {
					theme: { mode: this.$vuetify.theme.dark ? 'dark': 'light'},
                    chart: {
                        height: 1000,
						width: '100%',
                        type: 'line',
                        zoom: {
                            enabled: true,
                        },
						toolbar: {
							show: true,
						},
						background: 'rgba(0, 0, 0, 0)'
                    },
					stroke: {
						curve: 'smooth',
						width: [3, 0, 3, 0, 3, 0],
					},
                    //colors: ['#cd5b45', '#cd5b45', '#27a327', '#27a327', '#000099', '#000099'],
					dataLabels: {
						enabled: false
					},
					tooltip: {
						y: {
							formatter: function (val) {
								return val
							}
						}
					},
					yaxis: [
						{
							showAlways: true,
							seriesName: 'Продажи (руб)',
							title: {
								text: 'Рубли',
							},
							labels: {
								rotate:'-45',
								show: true,
								formatter: function(val) {
									return `${val}₽`;
								},
							},
						}, 
						{
							showAlways: true,
							seriesName: 'Продажи (шт)',
							opposite: true,
							title: {
								text: 'шт'
							},
							labels: {
								rotate:'45',
								show: true,
								formatter: function(val) {
									return `${val}шт`;
								},
							}
						},
						{
							seriesName: 'Продажи (руб)',
							show:false
						},
						{
							seriesName: 'Продажи (шт)',
							opposite: true,
							show:false
						}, 
						{
							seriesName: 'Продажи (руб)',
							show:false
						},
						{
							seriesName: 'Продажи (шт)',
							opposite: true,
							show:false
						}, 
					],
					xaxis: {
						type: 'datetime'
					},
                },
            }
        },
		computed: {
            seriesMain() {
                return [
					{
						name: 'Продажи (руб)',
						type: 'line',
						data: this.seriesSalesSum,
						color: "#cd5b45",
					},
					{
						name: 'Продажи (шт)',
						type: 'column',
						data: this.seriesSalesCount,
						color: "#d5aba3",
					},
					{
						name: 'Заказы (руб)',
						type: 'line',
						data: this.seriesOrdersSum,
						color: "#27a327",
					},
					{
						name: 'Заказы (шт)',
						type: 'column',
						data: this.seriesOrdersCount,
						color: "#b3dab3",
					},
					{
						name: 'Заказы ВБ(руб)',
						type: 'line',
						data: this.seriesOrdersWBSum,
						color: "#000099",
					},
					{
						name: 'Заказы ВБ (шт)',
						type: 'column',
						data: this.seriesOrdersWBCount,
						color: "#bfbfe1",
					},
				];
            },
			seriesSalesCount(){
				const map_result =  this.data?.sales.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.sales_count ? item.sales_count : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
            seriesSalesSum() {
                const map_result =  this.data?.sales.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.sales ? item.sales : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
			seriesOrdersSum() {
                const map_result = this.data?.orders.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.order_sum ? item.order_sum : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
			seriesOrdersCount() {
                const map_result = this.data?.orders.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.order_count ? item.order_count : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
			seriesOrdersWBSum() {
                const map_result = this.data?.orders_wb.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.orders_wb ? item.orders_wb : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
            },
			seriesOrdersWBCount() {
                const map_result = this.data?.orders_wb.map(item => (
					{
						x: item.date.split('-').reverse().join('-'),
						y: item.orders_wb_count ? item.orders_wb_count : 0,
					}
				))
				return map_result.sort(function(a,b){
					return new Date(b.x) - new Date(a.x);
				})
			},
        },
        methods: {
			moundetHiddenSeries(){
				if (this.$refs.chart){
					this.$refs.chart.hideSeries("Заказы ВБ (руб)")
					this.$refs.chart.hideSeries("Заказы ВБ (шт)")
				}
			},
			hiddenAll(){
				this.seriesMain.forEach(item=>{
					this.$refs.chart.hideSeries(item.name)
				})
			},
			async getData(){
				this.loading_data = true
				try {
					const response = await Products.fetchProductChartsData(this.filters)
					this.data = response.data
				} catch (err) {
					console.log(err.data)
				}
				this.loading_data = false
			},
		},
		async mounted(){
			await this.getData()
		}
	}
</script>

<style scoped lang="scss">
	.control{
		display: flex;
		justify-content: center;
		width: 100%;
		button:hover {
			text-decoration: underline;
		}
	}
</style>