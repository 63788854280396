<template>
    <v-sheet>
        <v-skeleton-loader
                v-if="loading"
                type="table-heading, table-row@5"
                class="pa-4"
        ></v-skeleton-loader>
		<v-simple-table dense v-else>
			<template v-slot:default>
				<thead>
					<tr>
						<th class="text-left">
							code_1c
						</th>
						<th class="text-left">
							sku
						</th>
						<th class="text-left">
							type
						</th>
						<th class="text-left">
							cost
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row, index) in data"
						:key="index"
					>
						<td>{{ row.code_1c}}</td>
						<td>{{ row.sku }}</td>
						<td>{{ row.type }}</td>
						<td>{{ row.cost | toRUB }}</td>
					</tr>
				</tbody>
			</template>
		</v-simple-table>
    </v-sheet>
</template>

<script>
import { Products } from '@/api/products'

export default {
	name: "ShippingPriceByDay",
	props: {
		catalog_sku: {
			type: String
		},
		date: {
			type: String
		}
	},
	data () {
		return {
		data: null,
		loading: true,
		}
	},
	methods: {
		async fetchShippingPacksPrice () {
			this.loading = true
			try {
				const res = await Products.fetchShippingPacksPrice(
					this.catalog_sku,
					this.date.split('-').join('')
				)
				this.data = res.data
			} catch (err) {
				console.log(err)
			}
			this.loading = false
		}
	},
	async mounted () {
		await this.fetchShippingPacksPrice()
	}
}
</script>

<style scoped>

</style>
