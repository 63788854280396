<template>
	<div v-if="loading" style="display: flex; justify-content:center;">
		<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
	</div>
    <div v-else>
		<apexchart 
            type="line" height="600" :options="chartOptions" :series="seriesMain" ref="chart"
		></apexchart>
        <div class="d-flex justify-center">
			<button @click="hidden_all">Скрыть все</button>
			<button @click="show_all" class="ml-3">Показать все</button>
		</div>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import VueApexCharts from 'vue-apexcharts';

export default {
    name: 'AdvertisementListChartsComponent',
    props: ['campaign_id'],
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            data: [],
            loading: false,
            chartOptions: {
                theme: {
                    mode: this.$vuetify.theme.dark ? 'dark': 'light'
                },
                chart: {
                    height: 1000,
                    width: '100%',
                    type: 'line',
                    zoom: { enabled: true },
                    toolbar: { show: true },
                    background: 'rgba(0, 0, 0, 0)'
                },
                stroke: { curve: 'smooth', width: [3, 2] },
                yaxis: [
                    {
                        showAlways: true,
                        seriesName: 'Просмотры',
                        labels: {
                            rotate:'-45',
                            show: true,
                            formatter: function(val) {
                                return val
                            },
                        },
                    }, 
                    {
                        showAlways: true,
                        seriesName: 'CTR(%)',
                        opposite: true,
                        title: { text: '%' },
                        labels: {
                            rotate:'45',
                            show: true,
                            formatter: function(val) {
                                return `${val}%`;
                            },
                        }
                    },
                ],
                xaxis: {
                    type: 'datetime'
                },
            },
        }
    },
    computed: {
        seriesMain() {
            return [
                {
                    name: 'Просмотры',
                    type: 'line',
                    data: this.seriesViews,
                    color: "#23b523",
                },
                {
                    name: 'CTR(%)',
                    type: 'line',
                    data: this.seriesCTR,
                    color: "#e33889",
                },
            ];
        },
        seriesViews(){
            const map_result =  this.data.map(item => ({
                x: item.date, y: item.views ? item.views : 0,
            }))
            return map_result.sort(function(a, b){ 
                return new Date(b.x) - new Date(a.x) 
            })
        },
        seriesCTR() {
            const map_result =  this.data.map(item => ({
                x: item.date, y: item.ctr ? item.ctr : 0,
            }))
            return map_result.sort(function(a, b){ 
                return new Date(b.x) - new Date(a.x)
            })
        },
    },
    methods: {
        hidden_all(){
            this.seriesMain.forEach(item=>{
                this.$refs.chart.hideSeries(item.name)
            })
        },
        show_all(){
            this.seriesMain.forEach(item=>{
				this.$refs.chart.showSeries(item.name)
			})
		},
        async get_campaign_stats(){
            this.loading = true
            try {
                const response = await Advertisement.fetchAdvertisementStatsViews(this.campaign_id)
                this.data = response.data
            } catch (err) {
                console.log(err.data)
            }
            this.loading = false		
        },
    },
    async mounted(){
        await this.get_campaign_stats()
    }
}
</script>
