<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<v-dialog v-model="dialog_change_name" max-width="700" v-if="dialog_change_name && data && data.campaign_id">
			<AdvertisementChangeCampaignName 
				:campaign_id="advertisement_id"
				:campaign_name="data.campaign_name"
				@update="updateName"
			/>
        </v-dialog>
		<v-dialog v-model="dialog_checker" v-if="dialog_checker && keyword_checker" max-width="1200px">
			<v-card>
				<v-card-title>Ставка по фразе: {{ keyword_checker }}</v-card-title>
				<v-card-text>
					<AdvertisementCheckerBets :keyword="keyword_checker" :adver_id="data.campaign_id"/>
				</v-card-text>
			</v-card>
		</v-dialog>
		
		<v-card class="mx-auto" flat>
			<v-card-title>
				<div style="display: flex; padding: 5px 0">
					<img
						style="max-height: 100px"
						:src="el"
						alt="Фото удалено"
						v-for="(el, index) in data.banner"
						:key="index"
					/>
				</div>
			</v-card-title>
			<v-card-text class="pb-0">
				<div class="d-flex">
					<div class="title">
						<v-btn icon title="Изменение названия" @click="dialog_change_name=true">
							<v-icon small>mdi-pencil</v-icon>
						</v-btn>
						{{ data.campaign_name ? data.campaign_name : "-" }}
						<v-icon
							class="ml-5"
							@click="updateOneItem"
						>
							mdi-reload
						</v-icon>
						<v-icon
							v-if="data.status=='Активна'"
							class="ml-3"
							@click="changeStatus(0)"
						>
							mdi-pause-circle-outline							
						</v-icon>
						<v-icon
							v-else-if="data.status=='Приостановлена'"
							class="ml-3"
							@click="changeStatus(1)"
						>
							mdi-arrow-right-drop-circle-outline
						</v-icon>
					</div>
				</div>
			</v-card-text>
			<v-sheet class="d-flex">
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Вид рекламы</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.campaign_type ? data.campaign_type : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Статус</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.status ? data.status : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Создан</v-list-item-title>
							<v-list-item-subtitle>{{
								showDate(data.create_date)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Приостановлено</v-list-item-title>
							<v-list-item-subtitle>{{
								showDate(data.paused_date)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Архив</v-list-item-title>
							<v-list-item-subtitle>{{
								showDate(data.finish_date)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Группа товаров</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.group ? data.group : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Статус товаров</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.status_product ? data.status_product : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Бюджет</v-list-item-title>
							<v-list-item-subtitle>{{
								data.all_budget ? data.all_budget : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Дневной лимит</v-list-item-title>
							<v-list-item-subtitle>{{
								data.daily_budget ? data.daily_budget : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content  style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">Последнее обновление</v-list-item-title>
							<v-list-item-subtitle style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								showDateWithTime(data.date_updated)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content  style="max-width:170px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:170px; overflow: unset; text-overflow: unset; white-space: unset;">Комментарий</v-list-item-title>
							<v-list-item-subtitle style="max-width:170px; overflow: unset; text-overflow: unset; white-space: unset;">
								<p>{{data.comment ? data.comment : "-"}}</p>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Цель РК</v-list-item-title>
							<v-list-item-subtitle>
								<p
									:class="{
										'green_purpose': data.purpose=='PROMOTED',
										'red_purpose': data.purpose=='SLIV', 
										'blue_purpose': data.purpose=='TEST_PRODUCT', 
										'yellow_purpose': data.purpose=='TEST_PHOTO',
										'purple_purpose': data.purpose=='PROFILE'
									}"
								>{{data.purpose_display ? data.purpose_display : "-"}}</p>
							</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-sheet>
			<v-sheet class="d-flex">
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">Id кампании</v-list-item-title>
							<v-list-item-subtitle style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.campaign_id ? data.campaign_id : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:200px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:200px; overflow: unset; text-overflow: unset; white-space: unset;">ЛК</v-list-item-title>
							<v-list-item-subtitle style="max-width:200px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								data.account ? data.account : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-sheet>
			<div class="d-flex mt-5 align-center px-3" style="width: 500px;">
				Родительская кампания: 
				<v-text-field
					class="ml-3" type="Number"
					clearable outlined dense
					v-model="parent_advert" 
					label="Родительская кампания" 
					hide-details
					@change="updateParentAdvert"
				></v-text-field>
			</div>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title> Товары </v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5 pb-5">
					<v-data-table
						:headers="header_product"
						:items="data.products"
						hide-default-footer
						style="width: 100%"
					>
						<template v-slot:item.nmid="{ item }">
							<a 
								:href="`https://www.wildberries.ru/catalog/${item.nmid}/detail.aspx`"
								target="_blank"
							>{{item.nmid}}</a>
							<router-link 
								v-if="item.our_product"
								target="_blank" 
								class="ml-3"
								:to="{ name: 'product_card', params: { id: item.nmid }}"
							>
								<v-icon class="ml-3">mdi-arrow-right-bold-box</v-icon>
							</router-link>
						</template>
						<template v-slot:item.rentab_plan="{ item }">
							<div :class="{'green_purpose': item.rentab_plan >= 15, 'red_purpose': item.rentab_plan < 15}">
								{{ item.rentab_plan ? item.rentab_plan : 0 }}% ({{ item.vp_plan ? item.vp_plan : 0 | toRUB }})
							</div>
						</template>
						<template v-slot:item.rentab_report="{ item }">
							<div :class="{'green_purpose': item.rentab_sales >= 15, 'red_purpose': item.rentab_sales < 15}">
								{{ item.rentab_sales ? item.rentab_sales : 0 }}% ({{ item.vp_sales ? item.vp_sales : 0 | toRUB }})
							</div>
						</template>
					</v-data-table>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				Ставки
				<v-icon
					small
					class="ml-5"
					@click="openAdvertisementCampaignForm()"
				>
					mdi-pencil
				</v-icon>	
				<div class="ml-5 d-flex" style="font-size:14px" v-if="data.url_type_name != 'catalog'">
					Робот:
					<v-switch
						color="primary"
						class="mt-0 ml-3"
						hide-details
						v-model="data.robot"
						@change="changeRobotStatus(data.robot)"
					></v-switch>
				</div>
				<div class="ml-3 mt-0" v-if="data.url_type_name == 'search' && data.robot">
					<v-select
						v-model="data.strategy"
						@change="changeRobotStrategy(data.strategy)"
						:items="choices.strategy_items"
						item-text="name"
						item-value="value"
						label="Стратегия робота"
						outlined
						dense
						hide-details
						class="ml-3 mt-0"
						clearable
					></v-select>
					<div 
						class="mt-3 ml-3 d-flex align-center" 
						v-if="data.strategy == 'STRATEGY_3' || data.strategy == 'STRATEGY_2'"
						style="font-size:14px"
					>
						Стратегия в ночное время:
						<v-switch
							color="primary"
							hide-details
							class="mt-0 ml-3"
							v-model="data.strategy_night"
							@change="changeStrategyNight(data.strategy_night)"
						></v-switch>
					</div>
					<v-text-field
						v-if="data.strategy == 'STRATEGY_3'"
						v-model="data.strategy_3_cpc"
						@change="changeCPCStrategy(data.strategy_3_cpc)"
						label="CPC"
						outlined
						class="ml-3 mt-3"
						type="number"
						dense
						clearable
						hide-details
					></v-text-field>
				</div>
				<div class="ml-5 d-flex align-center" style="font-size:14px" v-if="data.url_type_name != 'catalog'">
					Автопополнение бюджета:
					<v-switch
						color="primary"
						class="mt-0 ml-3"
						hide-details
						v-model="data.auto_payment_robot"
						@change="changeRobotAutoPayment(data.auto_payment_robot)"
					></v-switch>
					<v-text-field
						v-if="data.auto_payment_robot"
						v-model="data.auto_payment_value"
						@change="changeAutoPaymentValue(data.auto_payment_value)"
						label="Автопополнение бюджета"
						outlined
						type="number"
						dense
						clearable
						hide-details
					></v-text-field>
				</div>
				<div 
					class="ml-5 d-flex align-center" 
					style="font-size:14px" 
					v-if="data.url_type_name == 'search'" 
				>
					Включить отбор по CTR:
					<v-switch
						color="primary"
						class="mt-0 ml-3"
						hide-details
						v-model="data.selection_by_ctr_robot"
						@change="changeRobotSelectionCTR(data.selection_by_ctr_robot)"
					></v-switch>
					<v-text-field
						v-if="data.selection_by_ctr_robot"
						v-model="data.selection_by_ctr_value"
						@change="changeMinSelectionCTRValue(data.selection_by_ctr_value)"
						label="Минимальный CTR"
						outlined
						type="number"
						dense
						clearable
						hide-details
					></v-text-field>
				</div>
			</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<v-sheet class="d-flex">
						<v-list two-line dense>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Ставка</v-list-item-title>
									<v-list-item-subtitle>
										{{ data.current_bet ? data.current_bet : "-" }}
										<hr>
										<div 
											v-if="data.recommended_bet_test && data.current_bet != data.recommended_bet_test && !preloader_current_bet" 
											:class="{
												'green-recommended-bet': data.current_bet > data.recommended_bet_test, 
												'red-recommended-bet': data.current_bet < data.recommended_bet_test
											}"
											style="cursor: pointer;"
											@click="updateBetToRecommended(data.recommended_bet_test)"
										>
											{{data.recommended_bet_test}} Ок
										</div>
										<div v-else-if="preloader_current_bet">
											<v-progress-circular
												:size="15"
												:width="2"
												color="primary"
												indeterminate
											></v-progress-circular>
										</div>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list two-line dense>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Место в карточке</v-list-item-title>
									<v-list-item-subtitle>{{
										data.place ? data.place : "-"
									}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list two-line dense>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Топовый запрос</v-list-item-title>
									<v-list-item-subtitle>
										{{data.top_keyword ? data.top_keyword : "-"	}}
										<div style="color:red; font-size:20px;" v-if="!data.is_real_keyword">!</div>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list two-line dense>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Желаемое место</v-list-item-title>
									<v-list-item-subtitle>{{
										data.wanted_placement ? data.wanted_placement : "-"
									}}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list two-line dense>
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Максимальная ставка</v-list-item-title>
									<v-list-item-subtitle>
										{{  data.max_bet ? data.max_bet : "-" }}
										<hr>
										<div 
											v-if="data.recommended_max_bet && data.max_bet != data.recommended_max_bet && !preloader_max_bet" 
											:class="{
												'green-recommended-bet': data.max_bet > data.recommended_max_bet, 
												'red-recommended-bet': data.max_bet < data.recommended_max_bet
											}"
											style="cursor: pointer;"
											@click="updateMaxBetToRecommended(data.recommended_max_bet)"
										>
											{{data.recommended_max_bet}} Ок
										</div>
										<div v-else-if="preloader_max_bet">
											<v-progress-circular
												:size="15"
												:width="2"
												color="primary"
												indeterminate
											></v-progress-circular>
										</div>
										<div 
											v-if="	data.recommended_max_bet_by_wanted_placement && 
													data.max_bet != data.recommended_max_bet_by_wanted_placement && 
													!preloader_max_bet
												"
											class="yellow-recommended-bet" 
											style="cursor: pointer;"
											@click="updateMaxBetToRecommended(data.recommended_max_bet_by_wanted_placement)"
										>
											{{data.recommended_max_bet_by_wanted_placement}} Ок
										</div>
										<div v-else-if="preloader_max_bet">
											<v-progress-circular
												:size="15"
												:width="2"
												color="primary"
												indeterminate
											></v-progress-circular>
										</div>
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list two-line dense v-if="data.priority_categories_from_parsing">
							<v-list-item two-line>
								<v-list-item-content>
									<v-list-item-title>Приоритетность категорий</v-list-item-title>
									<v-list-item-subtitle>
										<p 
											v-for="item, index in (data.priority_categories_from_parsing)" 
											:key="index"
											class="mb-0"
										>
											{{item.index}} - {{item.id}} - {{item.name}}
										</p>										
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-sheet>
				</v-row>
				<div
					class="subtitle-1 mt-5"
					style="color: black"
					v-if="data.all_bet_from_RK && data.all_bet_from_RK.length != 0"
				>Ставки с ЛК</div>
				<div
					class="mt-5 pb-3"
					v-if="data.all_bet_from_RK && data.all_bet_from_RK.length != 0"
				>
					<v-data-table
						:headers="advertisement_bet_header"
						:items="advertisement_bet_data"
						hide-default-footer
						style="width: 100%"
					></v-data-table>
				</div>
				<div
					class="subtitle-1 mt-5"
					style="color: black"
					v-if="exist_keyword_bet"
				>	
					Ставки с парсинга
					<router-link 
						v-if="data.products && data.products.length != 0 && data.products[0].our_product"
						target="_blank" 
						class="ml-3"
						:to="{ name: 'product_card', params: { id: data.products[0].nmid }}"
					>Смотреть у нас</router-link>
					<a 
						v-if="data.products && data.products.length != 0 && data.products[0].our_product"
                        class="ml-3" 
                        :href="`https://mpstats.io/wb/item/${data.products[0].nmid}`"
                        target="_blank"
                    >Открыть в mpstats</a>
				</div>
				<div class="mt-5 pb-3" v-if="exist_keyword_bet">
					<v-skeleton-loader
						v-if="loading_change_top_keyword"
						type="table-heading, table-row@10"
					></v-skeleton-loader>
					<v-data-table
						v-else
						:headers="keyword_bet_headers"
						:items="keyword_bet_transform_table"
						style="width: 100%"
					>
						<template v-slot:item="{ item }">
							<tr>
								<td v-if="data.url_type_name == 'search'">
									<v-checkbox
										v-model="item.is_top"
										@change="changeTopKeyword(item)"
									></v-checkbox>
									
								</td>
								<td>
									<div v-if="data.url_type_name=='search'">
										<a  :href="urlWBMonitoring(item.keyword)" target="_blank">
											{{item.keyword}}
										</a>
										<v-icon @click="openCheckerBets(item.keyword)" small>mdi-information-outline</v-icon>
									</div>
									
									<div v-else>{{ item.keyword }}</div>
								</td>
								<td v-if="keyword_bet_headers[2].value == 'views'">{{ item.views }}</td>
								<td v-if="keyword_bet_headers[3].value == 'frq'">{{ item.frq }}</td>
								<td v-if="keyword_bet_headers[4].value == 'frq_wb'">{{ item.frq_wb }}</td>
								<td v-if="keyword_bet_headers[5].value == 'prev_pos'">{{item.prev_pos}}</td>
								<td 
									v-for="(obj, index) in max_length_header_bet_for_parsing(keyword_bet_transform_table) - 8" 
									:key="index"
								>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												v-bind="attrs"
												v-on="on"
												color="green"
												text-color="white"
												v-if="
													item.passing_bid && 
													item.passing_bid.length > 0 && 
													item[obj] == item.passing_bid[0]&&
													obj == 1
												"
											>
												{{item[obj]}}
											</v-chip>
											<v-chip
												v-bind="attrs"
												v-on="on"
												color="green"
												text-color="white"
												v-else-if="
													item.passing_bid && 
													item.passing_bid.length > 0 && 
													item[obj] == item.passing_bid[0]&&
													item[obj] != item[obj - 1]&&
													obj != 1
												"
											>
												{{item[obj]}}
											</v-chip>
											<div v-else v-bind="attrs" v-on="on">
												{{item[obj]}}
											</div>
										</template>
										<span v-if="data.url_type_name=='search'">{{get_current_placement(obj, item.placement_parsing)}}</span>
									</v-tooltip>
								</td>
							</tr>
						</template>
					</v-data-table>
				</div>
				<div v-if="data.bet_parsing_from_mpstats && Object.keys(data.bet_parsing_from_mpstats).length > 0" class="mt-5">
					<div
						class="subtitle-1 mt-5"
						style="color: black"
						v-if="data.all_bet_from_RK && data.all_bet_from_RK.length != 0"
					>Ставки с МПСТАТС</div>
					<v-simple-table dense class="mt-5 small-font vertical-border elevation-1">
						<template v-slot:default>
							<thead>
								<tr v-for="(entries, index) in Object.entries(data.bet_parsing_from_mpstats)" :key="`th_row_${index}`">
									<th class="text-left">Фраза</th>
									<th v-for="i in entries[1].length" :key="`th_pos_${i}`">
										{{ i }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(entries, index) in Object.entries(data.bet_parsing_from_mpstats)" :key="`tr_row_${index}`">
									<td>{{ entries[0] }}</td>
									<td v-for="i in entries[1]" :key="`tr_pos_${i.index}`">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-chip
													v-bind="attrs" v-on="on" color="green"
													text-color="white" small
													v-if="nmidList.indexOf(String(i.id)) != -1"
												>{{ i.cpm }}</v-chip>
												<div v-else v-bind="attrs" v-on="on">{{ i.cpm }}</div>
											</template>
											<span>
												{{ `Страница: ${i.page}, место: ${i.position}` }}
											</span>
										</v-tooltip>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
				<div v-if="data.all_keyword && data.all_keyword.length > 0 && data.url_type_name == 'search'">
					<div
						class="subtitle-1 mt-5"
						style="color: black"
					>РК с такой же ключевой фразой</div>
					<v-data-table
						:headers="other_adverts_by_top_keyword_headers"
						:items="other_adverts_by_top_keyword"
						style="width: 100%"
						:loading="other_adverts_loading"
					>
						<template v-slot:item.campaign_id="{ item }">
							<router-link 
								target="_blank" 
								:to="{name: 'advertisement_new_detail', params: { id: item.id }}"
							>{{ item.campaign_id }}</router-link>
						</template>
					</v-data-table>
				</div>
				<div v-if="data.all_keyword && data.all_keyword.length > 0" class="mt-5">
					<div
						class="subtitle-1 mt-5"
						style="color: black"
					>Реальные ставки с парсинга</div>
					<v-skeleton-loader type='table-row' v-if="loading_real_bet"></v-skeleton-loader>
					<v-simple-table 
						v-else-if="real_bet && Object.keys(real_bet)"
						dense
						class="mt-5 small-font vertical-border elevation-1">
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Фраза</th>
									<th v-for="i in maxLengthRealBet" :key="`rth_pos_${i}`">
										{{ i }}
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(entries, index) in Object.entries(real_bet)" :key="`rtr_row_${index}`">
									<td>{{ entries[0] }}</td>
									<td v-for="i in entries[1]" :key="`rtr_pos_${i.index}`">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-chip
													v-bind="attrs" v-on="on" color="green"
													text-color="white" small
													v-if="nmidList.indexOf(String(i.product_id)) != -1"
												>{{ i.cpm }}</v-chip>
												<div v-else v-bind="attrs" v-on="on">{{ i.cpm }}</div>
											</template>
											<span>
												{{ `Страница: ${i.page}, место: ${i.position}` }}
											</span>
										</v-tooltip>
									</td>
									<td v-for="i in difLength(entries[1])" :key="`test_oi_${i}`">
										
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
			</v-card-text>
		</v-card>
		<AdvertisementFormChangeKeyword 
			v-if="data.url_type_name == 'search'" 
			:data="data"
			:data_stats="data_stats"
			@succesfullExcludedKeyword="getDetailData"
			@openDialog="dialog_stats=true"
			
		/>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				Статистика
			</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<div v-for="(obj, index) in data.products" :key="index" style="width: 100%;">
						<p class="title px-3">Артикул: {{obj.nmid}}</p>
						<v-data-table
							:headers="header_statics"
							:items="obj.statics.slice(0,-1)"
							style="width: 100%;"
							class="elevation-0 mt-0 vertical-border small-font"
							:items-per-page="14"
							:footer-props="{
								'items-per-page-options': [5, 14, 25, 50]
							}"
						>
							<template v-slot:item.current_date="{ item }">
								<p>{{showDate(item.current_date)}}</p>
							</template>
							<template v-slot:item.stats.ctr="{ item }">
								<dir 
									:class="{'green_stats': item.stats.ctr > 1, 'red_stats': item.stats.ctr == 0, 'yellow_stats': item.stats.ctr > 0 && item.stats.ctr <= 1}"
								>{{item.stats.ctr}}
								</dir>
							</template>
							<template v-slot:item.cpm="{ item }">
								<dir>{{item.cpm ? item.cpm.toFixed(2) : 0}}</dir>
							</template>
							<template v-slot:item.stats.orders="{ item }">
								<dir 
									:class="{'green_stats': item.stats.orders >= 1, 'red_stats': item.stats.orders == 0}"
								>{{item.stats.orders}}
								</dir>
							</template>
							<template v-slot:item.profit="{ item }">
								<dir 
									:class="{'green_stats': item.profit >= 0, 'red_stats': item.profit < 0}"
								>{{item.profit}}</dir>
							</template>
							<template v-slot:item.profit_plan_delta="{ item }">
								<dir 
									:class="{'green_stats': item.profit_plan_delta >= 0, 'red_stats': item.profit_plan_delta < 0}"
								>{{item.profit_plan_delta}}</dir>
							</template>
							<template v-slot:item.vp_unit="{ item }">
								{{item.vp * item.stats.orders}}
							</template>
							<template v-slot:body.append>
								<tr class="sticky-table-footer" v-if="obj.statics.length != 0">
									<td style="font-weight:bold;" v-text="'Всего'" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].views" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].frq" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].clicks" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].cpc" />
									<td></td>
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].ctr" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].orders" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].cr" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].expenses" />
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].atbs" />
									<td></td>
									<td></td>
									<td></td>
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].profit" />
									<td></td>
									<td></td>
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].delta_orders" />
									<td></td>
									<td style="font-weight:bold;" v-text="obj.statics[obj.statics.length-1].profit_plan_delta" />
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</template>
						</v-data-table>
					</div>
				</v-row>
			</v-card-text>
		</v-card>
		<AdvertisementCharts :data="data.products"/>
		<v-card>
			<v-card-title>Другие РК с текущими товарами</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5 pb-5" v-if="data_other_adver">
					<v-data-table
						:headers="header_other_adver"
						:items="data_other_adver"
						style="width: 100%"
						:loading="tableOtherLoading"
						hide-default-footer
						disable-pagination>      
					>
						<template v-slot:item.campaign_name="{ item }">
							<router-link 
								target="_blank" 
								:to="{ 
									name: 'advertisement_new_detail', 
									params: { id: item.id }
							}">
								{{ item.campaign_name }}
							</router-link>
						</template>
						<template v-slot:item.create_date="{ item }">
							<p>{{showDateWithTime(item.create_date)}}</p>
						</template>
						<template v-slot:item.top_keyword="{ item }">
							<div>
								{{item.top_keyword}} 
							</div>
							<div style="color:red; font-size:20px;" v-if="!item.is_real_keyword">!</div>
						</template>
					</v-data-table>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				История изменений
			</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<div style="width: 100%;">
						<v-data-table
							:headers="headers_adver_historical"
							:items="data.adver_historical"
							style="width: 100%;"
							class="elevation-0 mt-0 vertical-border small-font"
						>
							<template v-slot:item.date_created="{ item }">
								<p>{{showDateWithTime(item.date_created)}}</p>
							</template>
							<template v-slot:item.user="{ item }">
								<p v-if="item.robot">Robot</p>
								<p v-else-if="item.user">{{item.user}} <span v-if="item.recommended">(A)</span></p>
								<p v-else>Изменено с ЛК ВБ</p>
							</template>
							<template v-slot:item.bet="{ item }">
								<p>{{item.old_bet}}</p>
								<hr>
								<p>{{item.new_bet}}</p>
							</template>
							<template v-slot:item.max_bet="{ item }">
								<p>{{item.old_max_bet}}</p>
								<hr>
								<p>{{item.new_max_bet}}</p>
							</template>
							<template v-slot:item.wanted_place="{ item }">
								<p>{{item.old_wanted_place}}</p>
								<hr>
								<p>{{item.new_wanted_place}}</p>
							</template>
							<template v-slot:item.daily_budget="{ item }">
								<p>{{item.old_daily_budget}}</p>
								<hr>
								<p>{{item.new_daily_budget}}</p>
							</template>
							<template v-slot:item.budget="{ item }">
								<p>{{item.old_budget}}</p>
								<hr>
								<p>{{item.new_budget}}</p>
							</template>
							<template v-slot:item.strategy="{ item }">
								<p>{{item.strategy}}</p>
							</template>
						</v-data-table>
					</div>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				История средней позиции
			</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<div style="width: 100%;">
						<v-data-table
							:headers="avg_pos_headers"
							:items="avg_pos_list"
							:loading="avg_pos_loading"
							style="width: 100%;"
							class="elevation-0 mt-0 vertical-border small-font"
						>
							<template v-slot:item.avg_pos="{ item }">
								{{ item.avg_pos ? item.avg_pos.toFixed(3) : 'Нет данных' }}
							</template>
						</v-data-table>
					</div>
				</v-row>
			</v-card-text>
		</v-card>
		<v-toolbar flat>
			<v-row class="justify-space-between" style="padding: 0 24px 24px 24px">
				<v-dialog v-model="dialog" max-width="500px">
					<v-card>
						<v-card-title>Окно редактирования</v-card-title>
						<v-card-text class="mt-5">
							<v-text-field
								v-model="editData.daily_budget"
								label="Дневной лимит"
								outlined
								type="number"
								dense
								clearable
							></v-text-field>
							<v-text-field
								v-model="editData.current_bet"
								label="Ставка"
								outlined
								type="number"
								dense
								clearable
							></v-text-field>
							<v-text-field
								v-model="editData.wanted_placement"
								label="Желаемое место"
								outlined
								type="number"
								dense
								clearable
							></v-text-field>
							<v-text-field
								v-model="editData.max_bet"
								label="Максимальная ставка"
								outlined
								type="number"
								dense
								clearable
							></v-text-field>
							<v-text-field
								v-model="editData.comment"
								label="Комментарий"
								outlined
								dense
								clearable
							></v-text-field>
							<v-select
								v-model="editData.purpose"
								:items="choices.advertisement_purpose"
								item-text="name"
								item-value="value"
								label="Цель"
								outlined
								dense
								hide-details
								clearable
							></v-select>
							<v-text-field
								v-model="editData.change_budget"
								label="Пополнение бюджета"
								outlined
								type="number"
								class="mt-6"
								dense
								clearable
								hint="Сумма должна быть в пределе [100; 10000] рублей. Сумма должна быть кратна 50"
							></v-text-field>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="blue darken-1" text @click="dialog = false"
								>Закрыть</v-btn
							>
							<v-btn color="blue darken-1" text @click="updateAdvertisement()"
								>Изменить</v-btn
							>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</v-toolbar>
		<v-toolbar flat>
			<v-row class="justify-space-between" style="padding: 0 24px 24px 24px">
				<v-dialog v-model="dialog_stats" max-width="1200px">
					<v-card>
						<v-card-title>Статистика по ключ. словам</v-card-title>
						<v-card-text>
							<AdvertisementStatsKeyword :data_stats="data_stats"/>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn 
								color="blue darken-1" 
								text
								@click="exportKeywordsStats"
								:loading="loading_export"
							>Экспорт в эксель</v-btn>
							<v-btn 
								color="blue darken-1" 
								text 
								@click="dialog_stats = false"
							>Закрыть</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
		</v-toolbar>
	</v-container>
</template>

<script>
import { AdvertisementNew } from "@/api/advertisement_new";
import AdvertisementStatsKeyword from '@/components/AdvertisementNew/AdvertisementStatsKeyword'
import AdvertisementFormChangeKeyword from '@/components/AdvertisementNew/AdvertisementFormChangeKeyword'
import {General} from '@/api/general'
import AdvertisementCharts from '@/components/AdvertisementNew/AdvertisementCharts'
import axios from 'axios'
import AdvertisementChangeCampaignName from '@/components/AdvertisementNew/AdvertisementChangeCampaignName'
import AdvertisementCheckerBets from '@/components/AdvertisementNew/AdvertisementCheckerBets'

export default {
	name: "AdvertisementNewDetail",
	components:{
		AdvertisementStatsKeyword,
		AdvertisementFormChangeKeyword,
		AdvertisementCharts,
		AdvertisementChangeCampaignName,
		AdvertisementCheckerBets
	},
	data() {
		return {
			dialog_checker: false,
			keyword_checker: null,
			parent_advert: null,
			dialog_change_name: false,
			other_adverts_by_top_keyword: [],
			other_adverts_loading: false,
			other_adverts_by_top_keyword_headers: [
				{ text: "id кампании", value: "campaign_id", sortable: false, width: "1%" },
				{ text: "Название", value: "campaign_name", sortable: false, width: "1%" },
				{ text: "Ставка", value: "current_bet", sortable: false, width: "1%" },
				{ text: "Макс. ставка", value: "max_bet", sortable: false, width: "1%" },
				{ text: "Цель", value: "purpose", sortable: false, width: "1%" },
				{ text: "Стратегия", value: "strategy", sortable: false, width: "1%" },
				{ text: "Топовый запрос", value: "top_keyword", sortable: false, width: "1%" },
			],
			data_stats: null,
			dialog: false,
			data_other_adver: null,
			preloader_current_bet: false,
			preloader_max_bet: false,
			dialog_stats: false,
			loading_export: false,
			loading_change_top_keyword: false,
			tableOtherLoading: false,
			real_bet: [],
			loading_real_bet: false,
			editData: {
				current_bet: null,
				wanted_placement: null,
				max_bet: null,
				comment: null,
				daily_budget: null,
				purpose: null,
				change_budget: null
			},
			choices: {
				advertisement_purpose: null,
				strategy_items: [
					{value: 'STRATEGY_1', name: 'Стратегия 1'},
					{value: 'STRATEGY_2', name: 'Стратегия 2'},
					{value: 'STRATEGY_3', name: 'Стратегия 3'},
				]
			},
			data: null,
			header_product: [
				{ text: "Артикул", value: "nmid", sortable: false, width: "1%" },
				{ text: "Название", value: "name", sortable: false, width: "1%" },
				{ text: "Бренд", value: "brand", sortable: false, width: "1%" },
				{ text: "Маржинальность план", value: "rentab_plan", sortable: false, width: "1%" },
				{ text: "Маржинальность факт", value: "rentab_report", sortable: false, width: "1%" },
			],
			headers_adver_historical: [
				{ text: "Дата изменения", value: "date_created", sortable: false, width: "1%" },
				{ text: "Пользователь", value: "user", sortable: false, width: "1%" },
				{ text: "Старая / новая ставка", value: "bet", sortable: false, width: "1%" },
				{ text: "Старая / новая макс. ставка", value: "max_bet", sortable: false, width: "1%" },
				{ text: "Старое / новое желаемое место", value: "wanted_place", sortable: false, width: "1%" },
				{ text: "Старый / новый днев. бюджет", value: "daily_budget", sortable: false, width: "1%" },
				{ text: "Старый / новый бюджет", value: "budget", sortable: false, width: "1%" },
				{ text: "Стратегия", value: "strategy", sortable: false, width: "1%" },
			],
			header_statics: [
				{ text: "Дата", value: "date", sortable: false, width: "2%" },
				{ text: "Просмотры",  value: "views", sortable: false, width: "1%" },
				{ text: "Частота", value: "frq", sortable: false, width: "1%" },
				{ text: "Клики", value: "clicks", sortable: false, width: "1%" },
				{ text: "CPC", value: "cpc", sortable: false, width: "1%" },
				{ text: "CPM", value: "cpm", sortable: false, width: "1%" },
				{ text: "CTR(%)", value: "ctr", sortable: false, width: "1%" },
				{ text: "Оформление заказа",value: "orders",sortable: false,width: "1%" },
				{ text: "CR(%)", value: "cr", sortable: false, width: "1%" },
				{ text: "Сумма", value: "expenses", sortable: false, width: "1%" },
				{ text: "Добавление в корзину",value: "add_to_basket",sortable: false,width: "1%" },
				{ text: "Цена продажи", value: "calc_price", sortable: false, width: "1%" },
				{ text: "ВП, шт", value: "vp_unit_plan_simple", sortable: false, width: "1%" },
				{ text: "ВП", value: "vp_total_plan_simple", sortable: false, width: "1%"},
				{ text: "Доходность", value: "profit_plan_simple", sortable: false, width: "1%" },
				{ text: "ДРР", value: "drr", sortable: false, width: "1%" },
				{ text: 'CR delta', value: 'delta_conversion', sortable: false, width: '1%' },
				{ text: 'Заказы дельта', value: 'delta_orders', sortable: false, width: '1%' },
				{ text: 'Заказы с парсера', value: 'product_orders_from_parsing', sortable: false, width: '1%' },
				{ text: 'Доходность дельта', value: 'profit_plan_delta', sortable: false, width: '1%' },
				{ text: 'ДРР дельта', value: 'delta_drr', sortable: false, width: '1%' },
				{ text: 'Выручка дельта', value: 'delta_revenue', sortable: false, width: '1%' },
				{ text: 'ВП дельта', value: 'vp_total_plan_delta', sortable: false, width: '1%' },
				{ text: 'Выкупов', value: 'count_redemproduct_count_redemptionption', sortable: false, width: '1%' },
			],
			header_other_adver: [
				{ text: 'Название', value: 'campaign_name', sortable: false, width: '1%' },
				{ text: 'Создан', value: 'create_date', sortable: false , width: '1%'},
				{ text: 'Цель РК', value: 'purpose_display', sortable: false, width: '1%' },
				{ text: 'Вид рекламы', value: 'campaign_type', sortable: false , width: '1%'},
				{ text: 'Статус', value: 'status', sortable: false , width: '1%'},
				{ text: 'Бюджет', value: 'all_budget', sortable: false , width: '1%'},
				{ text: 'Желаемое место', value: 'wanted_placement', sortable: false , width: '1%'},
				{ text: 'Макс. ставка', value: 'max_bet', sortable: false , width: '1%'},
				{ text: "Oбщее кол-во заказов", value: 'general_orders', sortable: false, width: '1%'},
				{ text: "Расход", value: 'general_sum', sortable: false, width: '1%'},
				{ text: "Доход ", value: 'profit_plan', sortable: false, width: '3%'},
				{ text: "ДРР", value: 'drr_plan', sortable: false, width: '3%'},
				{ text: 'Топовая поисковая фраза', value: 'top_keyword', sortable: false, width: '1%' },
				{ text: 'Комментарий', value: 'comment', sortable: false, width: '1%' },
			],
			avg_pos_loading: false,
			avg_pos_list: [],
			avg_pos_headers: [
				{ text: "Дата", value: "date", sortable: true, width: "1%" },
				{ text: "Средняя позиция", value: "avg_pos", sortable: true, width: "1%" },
			]
		}
	},
	computed: {
		maxLengthRealBet(){
			if (this.real_bet && Object.values(this.real_bet)){
				let i = 0
				
				Object.values(this.real_bet).forEach(item => {
					if (item.length > i)
						i = item.length
				})
				return i
			}
			return 0
		},
		transformNmidToList(){
			let nmid_list = []
			this.data.products.forEach(item => {
				nmid_list.push(item.nmid)
			})
			if(nmid_list)
				return nmid_list.join(',')
			else return ""
		},
		nmidList(){
			let nmid_list = []
			this.data.products.forEach(item => {
				nmid_list.push(item.nmid)
			})
			return nmid_list
		},
		advertisement_id() {
			return this.$route.params.id;
		},
		exist_keyword_bet() {
			let test= (
				!!this.data.all_bet_from_parsing &&
				!Object.keys(this.data.all_bet_from_parsing).length == 0
			)
			return test
		},
		keyword_bet_headers() {
			let max_len = 0;
			let header_keyword = [
				{
					text: "keyword/предмет",
					value: "keyword",
					sortable: false,
					width: "1%",
				},
			]
			if (this.data.url_type_name == 'search'){
				const additional_column = [
					{ text: "Показы", value: "views", sortable: false, width: "1%"},
					{ text: "Частотность", value: "frq", sortable: false, width: "1%"},
					{ text: "Част. WB", value: "frq_wb", sortable: false, width: "1%"},
					{ text: "Позиция", value: "prev_pos", sortable: false, width: "1%"}
				]
				header_keyword.push(...additional_column)
				header_keyword.unshift({
					text: "Топовый запрос", value: "is_top", sortable: false, width: "1%"
				})
			}
			for (var key in this.data.all_bet_from_parsing) {
				if (
					this.data.all_bet_from_parsing[key].position &&
					this.data.all_bet_from_parsing[key].position.length > max_len
				)
				max_len = this.data.all_bet_from_parsing[key].position.length;
			}
			for (let i = 1; i <= max_len; i++)
				header_keyword.push({
					text: `${i}e место`,
					value: `${i}`,
					sortable: false,
					width: "1%",
				});
			return header_keyword;
		},
		keyword_bet_transform_table() {
			let list_keyword = [];
			for (var key in this.data.all_bet_from_parsing) {
				let tmp_dict = {};
				if (this.data.all_bet_from_parsing[key].position)
					this.data.all_bet_from_parsing[key].position.forEach((item, index) => {
						tmp_dict[index + 1] = item;
					});
					tmp_dict["is_top"] = this.data.top_keyword && this.data.top_keyword == key ? true : false
					tmp_dict["keyword"] = key;
					tmp_dict["views"] = this.data.all_bet_from_parsing[key].views
					tmp_dict["frq"] = this.data.all_bet_from_parsing[key].frq
					tmp_dict["frq_wb"] = this.data.all_bet_from_parsing[key].frq_wb
					tmp_dict["prev_pos"] = this.data.all_bet_from_parsing[key].prev_pos
					tmp_dict['passing_bid'] = this.data.all_bet_from_parsing[key].passing_bid
					tmp_dict['placement_parsing'] = this.data.all_bet_from_parsing[key].placement_parsing
					list_keyword.push(tmp_dict);
			}
			return list_keyword;
		},
		advertisement_bet_header() {
			let header_keyword = [];
			for (let i = 1; i <= this.data.all_bet_from_RK.length; i++)
				header_keyword.push({
					text: `${i}e место`,
					value: `${i}`,
					sortable: false,
					width: "1%",
				});
			return header_keyword;
		},
		advertisement_bet_data() {
			let list_keyword = [];
			let tmp_dict = {};
			for (let i = 0; i <= this.data.all_bet_from_RK.length; i++) {
				tmp_dict[i + 1] = this.data.all_bet_from_RK[i];
			}
			list_keyword.push(tmp_dict);
			return list_keyword;
		},
	},
	methods: {
		openCheckerBets(item){
			this.dialog_checker = true
			this.keyword_checker = item
		},
		difLength(el){
			if(el.length < this.maxLengthRealBet){
				return this.maxLengthRealBet - el.length
			}
			return 0
		},
		get_current_placement(index, dict){
			if (!dict)
				return "Нет данных"
			let len_dict = dict.length
			if (index > len_dict * 5){
				return "Нет данных"
			}
			let page = Math.floor((index - 1) / 5)
			let new_index = (index - 1) - 5*page
			if (dict[page] && dict[page].positions && dict[page].positions[new_index])
				return `Страница: ${page + 1}, место: ${dict[page].positions[new_index]}`
			return "Нет данных"
			
		},
		async updateMaxBetToRecommended(new_max_bet){
			let send_dict = {}
            this.preloader_max_bet = true
            if (this.data.max_bet != new_max_bet){
                send_dict['max_bet'] = new_max_bet
                send_dict['recommended'] = true
            }
            if (send_dict){                
                try {
                    let response = await AdvertisementNew.updateAdvertisement(
                        this.data.id, 
                        send_dict
                    )
                    if (response.status == 200){
                        await this.getDetailData() 
                    }
                } catch (err) {
                    console.error(err.response)
                } finally {
                    this.preloader_max_bet = false
                }
            }
            this.preloader_max_bet = false
		},
		async updateBetToRecommended (new_bet){
            let send_dict = {}
            this.preloader_current_bet = true
            if (this.data.current_bet != new_bet){
                send_dict['current_bet'] = new_bet
                send_dict['recommended'] = true
            }
            if (send_dict){                
                try {
                    let response = await AdvertisementNew.updateAdvertisement(
                        this.data.id, 
                        send_dict
                    )
                    if (response.status == 200){
                        await this.getDetailData() 
                    }
                } catch (err) {
                    console.error(err.response)
                } finally {
                    this.preloader_current_bet = false
                }
            }
            this.preloader_current_bet = false
        },
		async updateOneItem(){
            try {
                const response = await AdvertisementNew.updateOneAdvertisementData(this.data.id)
                if (response.status == 200)
                    await this.getDetailData()
                } catch (err) {
                 console.log(err.data)
            }
        },
		urlWBMonitoring(text){
            return `https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${text}`
        },
		async changeTopKeyword(item){
			this.loading_change_top_keyword = true
			let post_data = {
				"our_top_keyword": item.keyword
			}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					this.data.id,
					post_data
				)
				if (response.status == 200) {
					await this.getDetailData();
					this.loading_change_top_keyword = false
				}
			} catch (err) {
				console.error(err.response);
			}
			this.loading_change_top_keyword = false
		},
		max_length_header_bet_for_parsing(item){
			let max_length = 0
			if (item)
				item.forEach(el => {
					let range = Object.keys(el).length
					if (range > max_length)
						max_length = range
				})
			return max_length
		},
		openAdvertisementCampaignForm() {
			this.editData.current_bet = this.data.current_bet
			this.editData.wanted_placement = this.data.wanted_placement
			this.editData.max_bet = this.data.max_bet
			this.editData.comment = this.data.comment
			this.editData.daily_budget = this.data.daily_budget
			this.editData.purpose = this.data.purpose
			this.editData.change_budget = null
			this.dialog = true;
		},
		async updateAdvertisement() {
			let send_dict = {};
			if (this.editData.current_bet != this.data.current_bet)
				send_dict["current_bet"] = this.editData.current_bet ? this.editData.current_bet : null
			if (this.editData.wanted_placement != this.data.wanted_placement)
				send_dict['wanted_placement'] = this.editData.wanted_placement ? this.editData.wanted_placement : null
			if (this.editData.max_bet != this.data.max_bet)
				send_dict['max_bet'] = this.editData.max_bet ? this.editData.max_bet : null
			if (this.editData.comment != this.data.comment)
				send_dict['comment'] = this.editData.comment ? this.editData.comment : null
			if (this.editData.daily_budget != this.data.daily_budget)
				send_dict['daily_budget'] = this.editData.daily_budget ? this.editData.daily_budget : null
			if (this.editData.purpose != this.data.purpose)
                send_dict['purpose'] = this.editData.purpose ? this.editData.purpose : null
			if (this.editData.change_budget && this.editData.change_budget >= 100 && this.editData.change_budget <= 10000 && this.editData.change_budget % 50 === 0)
				send_dict['change_budget'] = this.editData.change_budget ? this.editData.change_budget : 0
			if (send_dict) {
				try {
					let response = await AdvertisementNew.updateAdvertisement(
						this.data.id,
						send_dict
					)
					if (response.status == 200) {
						await this.getDetailData();
						this.dialog = false;
					}
				} catch (err) {
					console.error(err.response);
				}
			}
		},
		async changeStatus(status){
			try {
				let response = await AdvertisementNew.changeStatusAdvertisement(
					this.data.id,
					status
				)
				if (response.status == 200) {
					await this.getDetailData()
				}
			} catch (err) {
				console.error(err.response);
			}
		},
		async getDetailData() {
			try {
				let response = await AdvertisementNew.getDetailAdvertisementCampaign(
					this.advertisement_id
				);
				this.data = response.data;
				this.parent_advert = response.data.parent_advertisement
			} catch (err) {
				console.error(err.response);
			}
		},
		showDate(date) {
			if (date == 'Всего')
				return date
			return date ? this.$options.filters.formatDateToLocaleNoTime(date) : "-";
		},
		async getAdvertisementPurpose(){
            try {
                const responce = await General.fetchAdvertisementPurpose()
                this.choices.advertisement_purpose = responce.data
            } catch (err) {
                console.log(err)
            }
        },
		showDateWithTime(date) {
			return date ? this.$options.filters.formatDateToLocale(date) : "-";
		},
		async exportKeywordsStats(){
			this.loading_export = true
			try {
                const response = await AdvertisementNew.exportKeywordsStatsFromWB({
					'advert_id': this.data.id
				})
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'keywords_stats.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export = false
		},
		async changeCPCStrategy(strategy_3_cpc){
			let send_dict = {'strategy_3_cpc': strategy_3_cpc}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					this.data.id, 
					send_dict
				)
				if (response.status == 200){
					this.$toasted.show(`Данные изменены`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
					await this.getDetailData() 
				}
			} catch (err) {
				console.error(err.response)
			}
		},
		async changeRobotStrategy(strategy){
			let send_dict = {'strategy': strategy}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					this.data.id, 
					send_dict
				)
				if (response.status == 200){
					this.$toasted.show(`Данные изменены`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
					await this.getDetailData() 
				}
			} catch (err) {
				console.error(err.response)
			}
		},
		async changeRobotStatus(robot){
			let send_dict = {'robot': robot}
			try {
				let response = await AdvertisementNew.updateAdvertisement(
					this.data.id, 
					send_dict
				)
				if (response.status == 200){
					this.$toasted.show(`Данные изменены`, {
						theme: 'bubble',
						type: 'success',
						position: 'top-right',
						duration: 3000,
					});
					await this.getDetailData() 
				}
			} catch (err) {
				console.error(err.response)
			}
		},
		async changeRobotAutoPayment(robot){
			let json_data = {'auto_payment_robot': robot}
			try {
				await AdvertisementNew.updateAdvertisement(this.data.id, json_data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch (err) {
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		},
		async changeAutoPaymentValue(value){
			if (!value || value < 100 || value > 10000 || value % 50 != 0){
				this.$toasted.show(`Ошибка: введите сумму в пределах [100:10000] и кратную 50`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
				return
			}
			let json_data = {'auto_payment_value': value}
			try {
				await AdvertisementNew.updateAdvertisement(this.data.id, json_data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch (err) {
				console.error(err.response)
				this.$toasted.show(`Ошибка: введите сумму в пределах [100:10000] и кратную 50`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		},
		async changeRobotSelectionCTR(robot){
			let json_data = {'selection_by_ctr_robot': robot}
			try {
				await AdvertisementNew.updateAdvertisement(this.data.id, json_data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch (err) {
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		},
		async changeStrategyNight(strategy_night){
			let json_data = {'strategy_night': strategy_night}
			try {
				await AdvertisementNew.updateAdvertisement(this.data.id, json_data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch (err) {
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		},
		async changeMinSelectionCTRValue(value){
			let json_data = {'selection_by_ctr_value': value}
			try {
				await AdvertisementNew.updateAdvertisement(this.data.id, json_data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch (err) {
				console.error(err.response)
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		},
		async getOtherAdvertisement(){
            this.tableOtherLoading = true
            try {
                let response = await AdvertisementNew.getAdvertisementCampaigns(
                    {
                        "nmid_list": this.transformNmidToList,
                    }
                )
                this.data_other_adver = response.data.results.objects.filter(item=>item.id != this.data.id)
            } catch (err) {
                console.error(err.response)
            }
            this.tableOtherLoading = false
        },
		async getStatsKeyword(){
			try {
				let res = await AdvertisementNew.getStatsKeyword(this.data.id)
                this.data_stats = res.data
				
			} catch (err) {
				console.log(err)
			} 
		},
		async getRealBetByKeywords(){
			let keywords = this.data.all_keyword
			if (keywords && keywords.length > 0){
				keywords = keywords.slice(0,5)
				keywords = keywords.map(u => u.keyword)
				this.loading_real_bet = true
				const resp = await axios.post('https://mp.lomex-group.ru/parser_bet/', {keywords: keywords});
				this.loading_real_bet = false
				this.real_bet = resp.data
			}
		},
		async getOtherAdvertsByTopKeyword(){
			if (this.data.url_type_name != 'search')
				return
			this.other_adverts_loading = true
			try{
				const resp = await AdvertisementNew.getOtherAdvertsByTopKeyword(this.advertisement_id)
				this.other_adverts_by_top_keyword = resp.data
			} catch(err){
				console.log(err)
			}
			this.other_adverts_loading = false
		},
		async getAvgPlacement(){
			this.avg_pos_loading = true
			try{
				const resp = await AdvertisementNew.getAvgPos(this.advertisement_id)
				this.avg_pos_list = resp.data
			} catch(err){
				console.log(err)
			}
			this.avg_pos_loading = false
		},
		async updateName(){
			this.dialog_change_name=false
			await this.getDetailData()
		},
		async updateParentAdvert(){
			try{
				let data = {'parent': this.parent_advert}
				await AdvertisementNew.changeParentAdvertisement(this.advertisement_id, data)
				this.$toasted.show(`Данные изменены`, {
					theme: 'bubble',
					type: 'success',
					position: 'top-right',
					duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка`, {
					theme: 'bubble',
					type: 'error',
					position: 'top-right',
					duration: 3000,
				});
			}
		}
	},
	async mounted() {
		document.title = `Реклама | ${this.advertisement_id} | mp.lomex-group.ru`;
		this.$store.commit("setPageTitle", `Рекламная кампания ${this.advertisement_id}`);
		await this.getDetailData()
		await this.getStatsKeyword()
		await this.getAvgPlacement()
		await this.getOtherAdvertsByTopKeyword()
		await this.getRealBetByKeywords()	
		await this.getAdvertisementPurpose()
		await this.getOtherAdvertisement()
			
	}
}
</script>

<style lang="scss" scoped>
	.green_stats{
		height: 100%;
		width: calc(100% + 32px);
		margin-left: -16px;
		background-color: #def3d9;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.red_stats{
		height: 100%;
		background-color: #f5d4d7;
		width: calc(100% + 32px);
		margin-left: -16px;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.yellow_stats{
		height: 100%;
		background-color: #f3ebb9;
		width: calc(100% + 32px);
		margin-left: -16px;
		display: flex;
		justify-content: flex-start;
		padding: 0 16px;
		align-items: center;
	}
	.red_purpose{
		background-color: #f5d4d7;
	}
	.blue_purpose{
		background-color: #afbced;
	}
	.yellow_purpose{
		background-color: #ecedaf;
	}
	.green_purpose{
		background-color: #afedaf;
	}
	.purple_purpose{
		background-color: #e8afed;
	}
	.red-recommended-bet{
        background-color: #f1b7bd;
    }
    .green-recommended-bet{
        background-color: #c0f1b4;
    }
	.yellow-recommended-bet{
        background-color: #f1dbb4;
    }
</style>