import { HTTP } from './http'

export const Stats = {
  async fetchOrders (query=null) {
    return HTTP.get('/stats/orders/', {
      params: query
    } )
  },
  async fetchSales (query=null) {
    return HTTP.get('/stats/sales/', {
      params: query
    } )
  },
  async fetchSingleProductOzonTransactions (product_id, query=null) {
    return HTTP.get(`/stats/ozon/transactions/${product_id}/`, {
      params: query
    })
  },
  async fetchSingleProductOzonOrders(product_id, query=null) {
    return HTTP.get(`/stats/ozon/orders/${product_id}/`, {
      params: query
    })
  },
  async fetchWBIncomes(query=null) {
    return HTTP.get(`/stats/incomes/`, {
      params: query
    })
  },
  async fetchWBWeeklyReport(query=null) {
    return HTTP.get(`/stats/weekly_wb_reports/`, {
      params: query
    })
  },
  async fetchRegionSaleReports(query=null) {
    return HTTP.get(`/stats/region_sale_reports/`, {
      params: query
    })
  },
  async fetchOzonFinanceReports(query=null) {
    return HTTP.get(`/stats/ozon/finance_reports/`, {
      params: query
    })
  },
  async getOzonTransactionsTotalReport(payload) {
    return HTTP.post(`/stats/ozon/totals_reports/`, payload)
  },
  async getOzonTransactionsTotalReportDetail(payload) {
    return HTTP.post(`/stats/ozon/totals_reports_detailed/`, payload)
  },
  async fetchProductCardAnalyticsReport(query=null){
	return HTTP.get(`/stats/product_card_analytics_reports/`, {params: query} )
  },
  async fetchIncomeFiles(income_id){
    return HTTP.get('/stats/income_files/', {params: {"income": income_id}})
  },
  async importOzonWarehousingCost(query=null){
    return HTTP.post('/stats/ozon/import_warehousing_cost/', query)
  }
}
