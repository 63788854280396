<template>
	<div class="py-5">
		<v-dialog
			v-model="dialog_detail"
			width="1000"
			v-if="dialog_detail"
		>
			<v-card>
				<v-card-title class="text-h5">
					Детализация суммы удержания
				</v-card-title>
				<v-card-text>
					<div v-for="(item, index) in detail_data" :key="index">
						<p>Сумма удержания: {{item.sum}}</p>
						<p>Дата: {{item.date}}</p>
						<p>№ документа: {{item.doc_id}}</p>
						<p>Комментарий: {{item.desc}}</p>
						<hr>
					</div>
				</v-card-text>					
			</v-card>
		</v-dialog>
		<div class="filters px-5">
			<v-row>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_create_after" 
						:close-on-content-click="false"  
						prepend-icon="mdi-calendar"
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px"
						ref="datePicker.datepicker_date_create_after"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_create_after = null"
								v-model="dateCreateFormattedAfter"
								label="Дата (начало)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								v-on="on"
								dense
							></v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_create_after" 
							@input="datePicker.datepicker_date_create_after = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-menu 
						v-model="datePicker.datepicker_date_create_before" 
						:close-on-content-click="false" 
						transition="scale-transition" 
						offset-y="offset-y"
						nudge-right="40" 
						min-width="290px" 
						ref="datePicker.datepicker_date_create_before"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable
								@click:clear="filters.date_create_before = null"
								v-model="dateCreateFormattedBefore"
								label="Дата (конец)"
								readonly="readonly"
								v-bind="attrs"
								hide-details
								outlined
								dense
								v-on="on"
							>  
							</v-text-field>
						</template>
						<v-date-picker 
							v-model="filters.date_create_before" 
							@input="datePicker.datepicker_date_create_before = false"
							:first-day-of-week="1"
						></v-date-picker>
					</v-menu>
				</v-col>
				<v-col cols="12" sm="2">
					<v-autocomplete
						v-model="filters.wbaccount"
						:items="wbaccounts"
						item-text="name"
						item-value="id"
						multiple
						label="Аккаунты продавцов"
						clearable
						outlined
						hide-details
						dense
					></v-autocomplete>
				</v-col>
				<v-col cols="12" sm="2">
					<v-text-field
						v-model="filters.report_id"
						clearable
						outlined
						dense
						label="№ отчета"
						hide-details
					></v-text-field>
				</v-col>
				<v-spacer></v-spacer>
				<v-col cols="12" sm="2">
					<v-btn
						depressed
						color="purple darken-2"
						dark
						@click="fetchWeeklyReports"
					>Применить</v-btn>
				</v-col>
			</v-row>
		</div>
		<v-data-table
			ref="start_block"
			:headers="table.headers"
			:pageCount="pagination.numberOfPages"
			:items="table.data"
			:loading="table.loading"
			:options.sync="options"
			:items-per-page="25"
			:server-items-length="pagination.totalItems"
			class="elevation-1 vertical-border small-font mt-5"
			:footer-props="{
				'items-per-page-options': [5, 15, 25, 50, 100]
			}"
        >
			<template v-slot:item.total_sale="{ item }">
                {{ item.total_sale | toRUB }}
            </template>
			<template v-slot:item.advertising_payment="{ item }">
                {{ item.advertising_payment | toRUB }}
            </template>
			<template v-slot:item.avg_sale_percent="{ item }">
                {{ item.avg_sale_percent.toFixed(2)  }}%
            </template>
			<template v-slot:item.for_pay="{ item }">
                {{ item.for_pay | toRUB }}
            </template>
			<template v-slot:item.delivery_rub="{ item }">
                {{ item.delivery_rub | toRUB }}
            </template>
			<template v-slot:item.penalty_logistics="{ item }">
                {{ item.penalty_logistics | toRUB }}
            </template>
			<template v-slot:item.penalty_without_logistics="{ item }">
                {{ item.penalty_without_logistics | toRUB }}
            </template>
			<template v-slot:item.penalty="{ item }">
                {{ item.penalty | toRUB }}
            </template>
			<template v-slot:item.additional_payment="{ item }">
                {{ item.additional_payment | toRUB }}
            </template>
			<template v-slot:item.paid_storage_sum="{ item }">
                {{ item.paid_storage_sum | toRUB }}
            </template>
			<template v-slot:item.paid_acceptance_sum="{ item }">
                {{ item.paid_acceptance_sum | toRUB }}
            </template>
			<template v-slot:item.paid_withholding_sum="{ item }">
				<div v-if="!item.paid_withholding_sum">
					{{ item.paid_withholding_sum | toRUB }}
				</div>
				<a v-else @click="openDetailDialog(item)">{{ item.paid_withholding_sum | toRUB }}</a>
            </template>
			<template v-slot:item.bank_payment_sum="{ item }">
                {{ item.bank_payment_sum | toRUB }}
            </template>

		</v-data-table>
	</div>
</template>

<script>

import { Stats } from '@/api/stats'
import { General } from '@/api/general'

export default {
	name: 'WeeklyWbReports',
	data() {
		return {
			table: {
				data: [],
				headers: [
					{ text: '№ отчета', value: 'report_id', sortable: true, width: '1%' },
					{ text: 'Аккаунт', value: 'wbaccount', sortable: true, width: '1%' },
					{ text: 'Юр. лицо', value: 'supplier_finance_name', sortable: false, width: '1%' },
					{ text: 'Дата начала', value: 'date_from', sortable: true, width: '5%' },
					{ text: 'Дата конца', value: 'date_to', sortable: true, width: '5%' },
					{ text: 'Дата формирования', value: 'date_create', sortable: true, width: '3%' },
					{ text: 'Продажа', value: 'total_sale', sortable: true, width: '1%' },
					{ text: 'Согласованная скидка, %', value: 'avg_sale_percent', sortable: true, width: '1%' },
					{ text: 'К перечислению за товар', value: 'for_pay', sortable: true, width: '1%' },
					{ text: "Расходы на рекламу по счету", value: 'advertising_payment', sortable: true, width: '1%' },
					{ text: 'Стоимость логистики', value: 'delivery_rub', sortable: true, width: '1%' },
					{ text: 'Штраф. Повышенная логистика ', value: 'penalty_logistics', sortable: true, width: '1%' },
					{ text: 'Другие виды штрафов', value: 'penalty_without_logistics', sortable: true, width: '1%' },
					{ text: 'Штрафы', value: 'penalty', sortable: true, width: '1%' },
					{ text: 'Доплаты', value: 'additional_payment', sortable: true, width: '1%' },
					{ text: 'Стоимость хранения', value: 'paid_storage_sum', sortable: true, width: '1%' },
					{ text: 'Стоимость платной приемки', value: 'paid_acceptance_sum', sortable: true, width: '1%' },
					{ text: 'Прочие удержания', value: 'paid_withholding_sum', sortable: true, width: '1%' },
					{ text: 'Итого к оплате', value: 'bank_payment_sum', sortable: true, width: '1%' },
					{ text: 'Статус отчета', value: 'current_status_document', sortable: true, width: '1%' },
					{ text: 'Статус оплаты', value: 'bank_payment_status_name', sortable: true, width: '1%' },
				],
				
				loading: false
			},
			first_init: false,
			options: {
				sortBy: ['date_create'],
				sortDesc: [true]
			},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			filters:{
				report_id: null,
				wbaccount: null,
				date_create_after: "",
                date_create_before: "",
			},
			wbaccounts: [],
			datePicker: {
                datepicker_date_create_after: "",
                datepicker_date_create_before: "",
            },
			dialog_detail: false,
			detail_data: []
		}
	},
	computed: {
		transfromFiltersForQuery () {
            let query = {}
            for (const f in this.filters) {
                if (Array.isArray(this.filters[f])) {
                    query[f] = this.filters[f].join(',')
                } else {
                    query[f] = this.filters[f]
                }
                if (this.filters[f] == false) {
                    query[f] = null
                }
            }
            return query
        },
		dateCreateFormattedAfter () {
            return this.filters.date_create_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_create_after) : null
        },
        dateCreateFormattedBefore () {
            return this.filters.date_create_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_create_before) : null
        },
	},
	watch: {
		'options': async function (newVal, oldVal) {
			if (oldVal && this.first_init) {
				await this.fetchWeeklyReports()
			}
		},
	},
	methods: {
		async fetchWeeklyReports(){
			console.log('work')
			this.table.loading = true
			this.$vuetify.goTo(this.$refs.start_block, {
				duration: 500,
				offset: 150,
				easing: 'linear',
			})
			const { page, itemsPerPage, sortBy, sortDesc } = this.options
			let pageNumber = page - 1
			try {
				const response = await Stats.fetchWBWeeklyReport(
					{
						'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage,
						'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
						...this.transfromFiltersForQuery
					}
				)
				this.table.data = response.data.results
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.log(err.data)
			}
			this.table.loading = false
		},
		async fetchWbAccounts () {
			try{
				const response = await General.getWbAccounts()
				this.wbaccounts = response.data
			} catch (err){
				console.log(err.data)
			}
		},
		openDetailDialog(item){
			this.dialog_detail = true
			this.detail_data = item.detailing_withholding
		}
	},
	async mounted() {
		document.title = 'Недельные отчеты ВБ | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Недельные отчеты ВБ`);
		await this.fetchWeeklyReports()
		this.first_init = true
		await this.fetchWbAccounts()
	},
};
</script>

<style scoped>

</style>