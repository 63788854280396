<template>
   <div>
       <v-sheet>
           <v-container fluid>
               <v-row class="">
                   <v-col >
                       <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                               transition="scale-transition" offset-y="offset-y" min-width="290px"
                               ref="datepicker_menu_from">
                           <template v-slot:activator="{ on, attrs }">
                               <v-text-field
                                       clearable
                                       @click:clear="filters.date_after = null"
                                       v-model="dateFromFormatted"
                                       label="Начало периода"
                                       prepend-icon="mdi-calendar"
                                       readonly="readonly"
                                       v-bind="attrs"
                                       hide-details="auto"
                                       outlined
                                       dense
                                       v-on="on">
                               </v-text-field>
                           </template>
                           <v-date-picker
                                   v-model="filters.date_after"
                                   :first-day-of-week="1"
                                   @input="datepicker_menu_from = false"
                           >
                           </v-date-picker>
                       </v-menu>
                   </v-col>
                   <v-col >
                       <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                               transition="scale-transition" offset-y="offset-y" min-width="290px"
                               ref="datepicker_menu_to">
                           <template v-slot:activator="{ on, attrs }">
                               <v-text-field
                                       clearable
                                       @click:clear="filters.date_before = null"
                                       v-model="dateToFormatted"
                                       label="Конец периода"
                                       prepend-icon="mdi-calendar"
                                       readonly="readonly"
                                       hide-details="auto"
                                       v-bind="attrs"
                                       outlined
                                       dense
                                       v-on="on"
                               ></v-text-field>
                           </template>
                           <v-date-picker
                                   v-model="filters.date_before"
                                   @input="datepicker_menu_to = false"
                                   :first-day-of-week="1"
                           ></v-date-picker>
                       </v-menu>
                       <div class="text-right" style="font-size: .8rem;">
                           <a @click="setYesterdayToDateFilter()">Вчера</a>
                       </div>
                   </v-col>
                   <v-spacer></v-spacer>
                   <v-spacer></v-spacer>
                   <v-col>
                       <v-text-field
                               v-model="filters.odid"
                               clearable
                               outlined
                               dense
                               @keyup.enter="fetchDataWithFilters()"
                               append-icon="mdi-magnify"
                               label="Номер заказа"
                               hide-details="hide-details">
                       </v-text-field>
                   </v-col>
                   <v-col>
                       <v-btn
                               @click="fetchDataWithFilters()"
                               depressed
                               color="purple darken-2"
                               class="mb-3"
                               dark
                       >
                           Применить
                       </v-btn>
                   </v-col>
               </v-row>
               <v-row justify="end">
                   <v-col md="4" class="text-right py-0">

                   </v-col>
               </v-row>
           </v-container>
       </v-sheet>
       <v-data-table
               v-if="data"
               dense
               :headers="headers"
               :pageCount="pagination.numberOfPages"
               :items="data"
               item-key="id"
               @update:page="fetchData"
               @update:sort-by="fetchData"
               @update:sort-desc="fetchData"
               :loading="tableLoading"
               :options.sync="options"
               :items-per-page="50"
               :server-items-length="pagination.totalItems"
               class="elevation-0 vertical-border small-font"
       >
           <template v-slot:item.cancel_dt="{ item }">
               <div v-if="item.cancel_dt">{{ item.cancel_dt | formatDateToLocaleNoTime }}</div>
               <div v-else>-</div>
           </template>
       </v-data-table>
   </div>
</template>

<script>
  import { Products } from '@/api/products'
  export default {
    name: 'ProductOrders',
    props: {
      product_id: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        loading: false,
        tableLoading: false,
        options: {},
        pagination: {
          next: null,
          totalItems: 0,
          numberOfPages: 0,
          currentPage: 0
        },
        data: null,
        headers: [
          { text: 'barcode', sortable: false, value: 'barcode' },
          { text: 'cancel_dt', sortable: false, value: 'cancel_dt' },
          { text: 'date', sortable: true, value: 'date' },
          { text: 'discount_percent', sortable: false, value: 'discount_percent' },
          { text: 'incomeID', sortable: false, value: 'incomeID' },
          { text: 'is_cancel', sortable: false, value: 'is_cancel' },
          { text: 'number', sortable: false, value: 'number' },
          { text: 'oblast', sortable: false, value: 'oblast' },
          { text: 'odid', sortable: false, value: 'odid' },
          { text: 'quantity', sortable: false, value: 'quantity' },
          { text: 'supplier_article', sortable: false, value: 'supplier_article' },
          { text: 'tech_size', sortable: false, value: 'tech_size' },
          { text: 'total_price', sortable: false, value: 'total_price' },
          { text: 'warehouse_name', sortable: false, value: 'warehouse_name' }
        ],
        filters: {
          date_before: null,
          date_after: null,
          odid: null
        },
        datepicker_menu_from: null,
        datepicker_menu_to: null
      }
    },
    computed: {
      transfromFiltersForQuery () {
        let query = {}
        for (const f in this.filters) {
          if (Array.isArray(this.filters[f])) {
            query[f] = this.filters[f].join(',')
          } else {
            query[f] = this.filters[f]
          }
          if (this.filters[f] == false) {
            query[f] = null
          }
        }
        return query
      },
      dateFromFormatted () {
        return this.filters.date_after ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) : null
      },
      dateToFormatted () {
        return this.filters.date_before ? this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) : null
      }
    },
    async mounted () {
        await this.fetchData()
    },
    methods: {
      setYesterdayToDateFilter() {
        const d = new Date()
        d.setDate(d.getDate() - 1)
        const d_str = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        this.filters.date_after = d_str
        this.filters.date_before = d_str
      },
      async fetchDataWithFilters () {
        // this.drawer = false
        this.options.page = 1
        await this.fetchData()
      },
      async fetchData () {
        this.loading = true
        const { page, itemsPerPage, sortBy, sortDesc } = this.options
        let pageNumber = page - 1
        console.log(page, itemsPerPage, pageNumber)
        try {
          const res = await Products.fetchProductOrders(
              this.product_id,
        {
                ...this.transfromFiltersForQuery,
                ...{
                  'offset': itemsPerPage*pageNumber,
                  'limit': itemsPerPage,
                  // 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
                }
              }
          )
          this.data = res.data.results
          this.pagination.totalItems = res.data.count
          this.pagination.numberOfPages = Math.ceil(res.data.count / itemsPerPage)
        } catch (err) {
          console.log(err)
        }
        this.loading = false
      }
    },
  }
</script>

<style scoped>

</style>