<template>
    <div>
        <OrdersList></OrdersList>
    </div>
</template>

<script>
    import OrdersList from '@/components/OrdersList'

    export default {
        name: "Orders",
      components: {
        OrdersList
      },
      mounted () {
        this.$store.commit('setPageTitle', `Список заказов`)
      }
    }
</script>

<style scoped>

</style>
