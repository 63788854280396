<template>
    <div>
        <v-sheet class="px-2 py-5">
            <v-subheader>Каталог</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillDeliveryStages')">Заполнить Delivery Stages</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillDeliveryStagesCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillDeliveryStagesProducts')">Заполнить Delivery Stages Products</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillDeliveryStagesProductsCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillCatalog')">Заполнить каталог из 1С</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillCatalogCelery')">celery</v-btn>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('shippingPacksCalc')">Пересчет расходов по материалам (с 1.6.2022)</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('shippingPacksCalcCelery')">celery</v-btn>
                </div>
            </div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <v-subheader>Остатки</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('stocks')">Парсинг остатков</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillStocksAPI')">Получить остатки с API</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillStocksAPICelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillStocks')">Получить остатки из 1С</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillStocksCelery')">celery</v-btn>
                </div>
				<div class="mr-5">
                    <v-btn @click="touchServerDebug('fillStocksFBS')">Получить остатки FBS из API</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillStocksFBSCelery')">celery</v-btn>
                </div>
            </div>
        </v-sheet>
<!--        <v-btn @click="addFields">Добавить группу, менеджера, статус</v-btn>-->
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <v-subheader>WB Stats</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fetchWbIncomes')">Подгрузить поставки</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fetchWbIncomesCelery')">celery</v-btn>
                </div>
            </div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <v-subheader>Мониторинг конкурентов</v-subheader>
			<div class="d-flex">
				<div class="mr-5">
					<v-btn @click="touchServerDebug('getStatsMonitoring')">Заполнить мониторинг продуктов</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('getStatsMonitoringCelery')">celery</v-btn>
				</div>
				<div class="mr-5">
					<v-btn @click="touchServerDebug('getCompetitorsStatsMonitoring')">Заполнить мониторинг продуктов конкурентов</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('getCompetitorsStatsMonitoringCelery')">celery</v-btn>
				</div>
			</div>
			<div class="d-flex">
				<div class="mr-5 mt-5">
					<v-btn @click="touchServerDebug('parsePriceCompetitor')">Получение цены конкурентов </v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('parsePriceCompetitorCelery')">celery</v-btn>
				</div>
				<div class="mr-5 mt-5">
					<v-btn @click="touchServerDebug('fillVariationCompetitor')">Получение вариаций конкурентов </v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillVariationCompetitorCelery')">celery</v-btn>
				</div>
				<div class="mr-5 mt-5">
					<v-btn @click="touchServerDebug('positionTracker')">Трекер позиций</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('positionTrackerCelery')">celery</v-btn>
				</div>
				<div class="mr-5 mt-5">
					<v-btn @click="touchServerDebug('positionTrackerAsync')">Трекер позиций(Async)</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('positionTrackerAsyncCelery')">celery</v-btn>
				</div>
			</div>
        </v-sheet>
        <v-divider></v-divider>
		<v-sheet class="px-2 py-5">
            <v-subheader>Мониторинг позиций OZON</v-subheader>
			<div class="d-flex">
				<div class="mr-5">
					<v-btn @click="touchServerDebug('fillOzonPositionFromMpstats')">Мониторинг с мпстатс</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillOzonPositionFromMpstatsCelery')">celery</v-btn>
				</div>
				<div class="mr-5">
					<v-btn @click="touchServerDebug('fillOzonPositionFromMpstatsThreads')">Мониторинг с мпстатс используя потоки</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillOzonPositionFromMpstatsThreadsCelery')">celery</v-btn>
				</div>
				<div class="mr-5">
					<v-btn @click="touchServerDebug('fillOzonPositionFromLK')">Получение позиций с seller-ozon</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillOzonPositionFromLKCelery')">celery</v-btn>
				</div>
			</div>
			<div class="d-flex">
				<div class="mr-5 mt-5">
					<v-btn @click="touchServerDebug('fillOzonKeywordInfo')">Получение информаций по фразам seller-ozon </v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillOzonKeywordInfoCelery')">celery</v-btn>
				</div>
			</div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <v-subheader>Аналитика</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillStocksAnalytics')">Заполнить таблицу остатки</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillStocksAnalyticsCelery')">celery</v-btn>
                </div>
            </div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <v-subheader>Рекламные кампании</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillAdvertisementData')">Получить данные по РК</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillAdvertisementDataCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('particalFillAdvertisementData')">Частичное получение данных по РК</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('particalFillAdvertisementDataCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillBasicPriceFromMpstats')">Заполнить basic price из mpstats</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillBasicPriceFromMpstatsCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('calculationAdvertisingIndicators')">Расчет показалей рекламы</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('calculationAdvertisingIndicatorsCelery')">celery</v-btn>
                </div>
            </div>
            <div class="d-flex">
                <div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('fillAdvertisementDataAsync')">Получить данные по РК ассинхронно</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillAdvertisementDataAsyncCelery')">celery</v-btn>
                </div>
				<div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('alertAdvertisementTelegramm')">Отправка проверка бюджета в телеграмм</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('alertAdvertisementTelegrammCelery')">celery</v-btn>
                </div>
				<div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('autoUpdateBet')">Робот для изменения ставок</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('autoUpdateBetCelery')">celery</v-btn>
                </div>
            </div>
			<div class="d-flex">
				<div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('updateAllAdeverDailyMax')">Обновление нулевого днев. бюджета на последний</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('updateAllAdeverDailyMaxCelery')">celery</v-btn>
                </div>
				<div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('updateAllAdeverDailyMax500')">Обновление нулевого днев. бюджета на 500</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('updateAllAdeverDailyMax500Celery')">celery</v-btn>
                </div>
				<div class="mr-5 mt-5">
                    <v-btn @click="touchServerDebug('fillAdverParsing')">Получить ставки с парсинга</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillAdverParsingCelery')">celery</v-btn>
                </div>
            </div>
        </v-sheet>
        <v-divider></v-divider>
		<v-sheet class="px-2 py-5">
            <v-subheader>Рекламные кампании ОЗОН</v-subheader>
            <div class="d-flex">
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillOzonAdvertisementCampaign')">Получить данные по РК и товарам ОЗОН</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillOzonAdvertisementCampaignCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillOzonAdvertisementStats')">Получить статистику по РК</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillOzonAdvertisementStatsCelery')">celery</v-btn>
                </div>
                <div class="mr-5">
                    <v-btn @click="touchServerDebug('fillOzonAdvertisementProductStats')">Получить статистику по товарам из РК</v-btn>
                    <v-btn color="blue" dark @click="touchServerDebug('fillOzonAdvertisementProductStatsCelery')">celery</v-btn>
                </div>
				<div class="mr-5">
					<v-btn @click="touchServerDebug('fillOzonAdvertisementKeywordSearch')">Получение ключ. фраз</v-btn>
					<v-btn color="blue" dark @click="touchServerDebug('fillOzonAdvertisementKeywordSearchCelery')">celery</v-btn>
				</div>
            </div>
        </v-sheet>
        <v-divider></v-divider>
        <v-sheet class="px-2 py-5">
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchProducts')">Обновить список товаров</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('checkParams')">Проверка параметров товаров</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('checkParamsCelery')">With Celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('checkParamsWbalerts')">Проверка параметров товаров wbalerts</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('checkParamsWbalertsCelery')">With Celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchFeedbacks')">Запустить парсинг отзывов</v-btn>
                <v-btn color="blue" @click="touchServerDebug('fetchFeedbacksCelery')">Запустить парсинг отзывов (celery)</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchOrders')">Получить заказы с API</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchOrdersCelery')">Получить заказы с API (celery)</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchSales')">Получить продажи с API</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchSalesCelery')">Получить продажи с API (celery)</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchGatherTask')">Получить сборочные задания с API</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchGatherTaskCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchReport')">Получить отчеты о продажах с API</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchReportCelery')">celery</v-btn>
            </div>

            <div class="mt-5">
                <v-btn @click="touchServerDebug('childproductDaySnapshot')">Сделать снэпшоты товаров</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('childproductDaySnapshotCelery')">celery</v-btn>
            </div>        
            <div class="mt-5">
                <v-btn @click="touchServerDebug('ozonReport')">Отчеты OZON</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('ozonReportCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('ozonOrdersFBO')">Заказы OZON FBO</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('ozonOrdersFBOCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('alertSalesOrdersByGroup')">Оправка в телеграм данных о продажах и заказах</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('alertSalesOrdersByGroupCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchChildProductPrice')">Получение цен API</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchChildProductPriceCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('setOzonReportRedemption')">Связка документов с выкупами ОЗОН</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('setOzonReportRedemptionCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="exportUsersExel" :loading="loading_download">Выгрузка пользователей</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('loadFrqDataFromWB')">Получение частотности по запросам с WB</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('loadFrqDataFromWBCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('loadPackingExpenses')">Загрузить расходы на фасовку</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('loadPackingExpensesCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('checkFeedbackSupportRequest')">Проверка ответов на запросы удаления отзывов</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('checkFeedbackSupportRequestCelery')">celery</v-btn>
            </div>
            <div class="mt-5 mb-5 d-flex">
                <v-file-input
                    label="Файл с планами" outlined dense id="file_upload" hide-details prepend-icon="" style="max-width: 250px"
                ></v-file-input>
                <v-btn class="ml-5" @click="fillProductsPlans">Загрузить планы ВБ/ОЗОН</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('getPriorityCategories')">Приоритетность категорий РК</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('getPriorityCategoriesCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('prepareChildProductData')">Подготовка вычисленных полей ChildProduct</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('prepareChildProductDataCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('prepareOzonProductData')">Подготовка вычисленных полей OzonProduct</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('prepareOzonProductDataCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('checkFeedbacksWithSupportRequest')">Проверка отзывов с обращениями</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('checkFeedbacksWithSupportRequestCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fillOzonProductInfo')">Заполнение информации о товаре ОЗОН(цены, комиссии)</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fillOzonProductInfoCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fillOzonStocksV2')">Заполнение остатков ОЗОН V2</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fillOzonStocksV2Celery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fillOzonContentRating')">Заполнение ОЗОН Контент рейтинга</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fillOzonContentRatingCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('alertProductStockFbs')">Уведомления по остаткам FBS</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('alertProductStockFbsCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fillWarehouseFromWb')">Получение складов v2</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fillWarehouseFromWbCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('checkCharsProduct')">Получение хар-к товаров</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('checkCharsProductCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchRedemptionReception')">Получение выкупов(список на получение)</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchRedemptionReceptionCelery')">celery</v-btn>
            </div>

            <div class="mt-5">
                <v-btn @click="touchServerDebug('calculationAdvertisingIndicatorsV2')">Расчет показателей ВБ РК V2</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('calculationAdvertisingIndicatorsV2Celery')">celery</v-btn>
            </div>

            <div class="mt-5">
                <v-btn @click="touchServerDebug('autoBundleFeedbackRedemption')">Связка отзывов с выкупами</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('autoBundleFeedbackRedemptionCelery')">celery</v-btn>
            </div>
            <div class="mt-5 mb-5 d-flex">
                <v-file-input
                    label="Файл с комиссиями" outlined dense id="file_upload_commission" hide-details prepend-icon="" style="max-width: 250px"
                ></v-file-input>
                <v-btn class="ml-5" @click="fillСommissionWB()">Загрузить комиссии с ЛК ВБ</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchWBWeeklyReports')">Получение недельных отчетов ВБ</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchWBWeeklyReportsCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchMpstatsData')">Получение остатков с mpstats</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchMpstatsDataCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchAdvertisingPayment')">Получение расходов на оплату рекламы</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchAdvertisingPaymentCelery')">celery</v-btn>
            </div>
            <div class="mt-5">
                <v-btn @click="touchServerDebug('fetchWbSubject')">Получение предметов товаров WB</v-btn>
                <v-btn color="blue" dark @click="touchServerDebug('fetchWbSubjectCelery')">celery</v-btn>
            </div>	
        </v-sheet>
    </div>
</template>

<script>
    import { Administration } from '@/api/admin'
  export default {
    name: "Main",
	data() {
		return {
			loading_download: false
		}
	},
    methods: {
      async addFields () {
        return await Administration.setNewCatalogFields()
      },
      async touchServerDebug (endpoint_name) {
        return await Administration.debug(endpoint_name)
      },
      async exportUsersExel() {
		this.loading_download = true
		try {
			const response = await Administration.exportListUser()
			const url = URL.createObjectURL(response.data)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'users.xlsx')
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (err) {
			console.log(err.data)
		}
		this.loading_download = false
      },
      async fillProductsPlans(){
		let fileToUpload = document.getElementById('file_upload').files[0]
		let formData = new FormData()
		formData.append('fileToUpload', fileToUpload)
		try{
			await Administration.uploadProductsPlans(formData)
		} catch (err){
			console.log(err)
		}
      },
      async fillСommissionWB(){
		console.log('worl')
		let fileToUpload = document.getElementById('file_upload_commission').files[0]
		let formData = new FormData()
		formData.append('fileToUpload', fileToUpload)
		try{
			await Administration.uploadCommissionWB(formData)
        } catch (err){
			console.log(err)
		}
      }
    },
    mounted () {
      this.$store.commit('setPageTitle', `Служебное`)
    }
  }
</script>

<style scoped>

</style>
