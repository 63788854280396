<template>
	<v-container fluid>
		<v-card>
			<v-card-title>Фильтры</v-card-title>
			<v-card-text>
				<v-row>
					<v-col>
						<v-text-field
							v-model="filters.nmid" clearable outlined dense
							label="Артикул МП" hide-details="hide-details" @change="fetch_detail_with_filters"
						></v-text-field>
					</v-col>
					<v-col>
						<v-text-field
							v-model="filters.code_1c" clearable outlined dense
							label="Артикул 1С" hide-details="hide-details" @change="fetch_detail_with_filters"
						></v-text-field>
					</v-col>
					<v-col>
						<v-autocomplete
							outlined clearable label="Склад" v-model="filters.warehouse_name"
							:items="get_warehouses_list" item-text="name" item-value="value" 
							hide-details dense
						></v-autocomplete>
					</v-col>
					<v-col>
						<v-switch
							v-model="filters.is_active" inset class="mt-0 mb-2" hide-details 
							:label="`Активные`" @change="fetch_detail_with_filters"
                        ></v-switch>
					</v-col>
					<v-col>
						<v-menu offset-y>
							<template v-slot:activator="{ on }">
								<v-btn 
									depressed color="purple darken-2" dark v-on="on" :loading="loading_export"
								>Скачать excel</v-btn>
							</template>
							<v-list>
								<v-list-item>
									<v-list-item-title @click="export_general_data" style="cursor: pointer;">
										Выгрузка расчета поставки в формате Excel
									</v-list-item-title>
								</v-list-item>
								<v-list-item>
									<v-list-item-title @click="export_abc_data" style="cursor: pointer;">
										Выгрузка сводного отчета АВС в формате Excel
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card class="mt-5">
			<v-card-title>Данные</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="headers" :items="filtered_data" class="small-font elevation-0 mt-5 vertical-border"
					:items-per-page="25" :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
					:loading="loading" :options.sync="options" :server-items-length="pagination.totalItems"
				>
					<template v-slot:item.nmid="{ item }">
						<p class="mb-0" style="white-space: nowrap;">Артикул МП: {{ item.nmid }}</p>
						<p class="mb-0" style="white-space: nowrap;">Артикул 1C: {{ item.code_1c }}</p>
						<p class="mb-0" style="white-space: nowrap;">ШК: {{ item.barcode }}</p>
						<p class="mb-0" style="white-space: nowrap;">Бренд: {{ item.brand }}</p>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { Suppl } from '@/api/suppl';

export default {
	name: 'SupplyPlanningDetailComponent',
	props: ['id', 'warehouses_list'],
	data() {
		return {
			loading_export: false,
			filters: {
				warehouse_name: "",
				nmid: null,
				code_1c: null,
				is_active: true
			},
			headers: [
				{ text: "Артикул", value: "nmid", sortable: true, width: "1%" },
				{ text: "Активный", value: "active", sortable: true, width: "1%" },
				{ text: "Наименование",  value: "name", sortable: false, width: "1%" },
				{ text: 'Заказы, шт', value: 'orders_count', sortable: false, width: "1%" },
				{ text: "Продажи, шт", value: "sales_count", sortable: false, width: "1%" },
				{ text: "Остаток на складе, шт", value: "stocks_seller_end", sortable: false, width: "1%" },
				{ text: 'Прогноз остатка, шт', value: 'forecast_stocks', sortable: false, width: "1%" },
				{ text: 'Оборачиваемость, дн', value: 'turnover_product', sortable: false, width: "1%" },
				{ text: 'В продаже, дн', value: 'days_in_sale', sortable: false, width: "1%" },
				{ text: 'Прогноз заказов, шт', value: 'forecast_orders', sortable: false, width: "1%" },
				{ text: 'Закончится через, дн', value: 'finish_to', sortable: false, width: "1%" },
				{ text: 'На пути к ВБ, шт', value: 'on_the_way_to_mp', sortable: false, width: "1%" },
				{ text: 'Классификация', value: 'classification_abc', sortable: false, width: "1%" },
				{ text: 'K days', value: 'k_days', sortable: false, width: "1%" },
				{ text: 'Отгрузить на склад, шт(без К)', value: 'ship_to_warehouse_base', sortable: false, width: "1%" },
				{ text: 'Отгрузить на склад, шт', value: 'ship_to_warehouse', sortable: false, width: "1%" },
				{ text: 'Кратность', value: 'quantity_box', sortable: false, width: "1%" },
				{ text: 'С/С закупки', value: 'cost_price_purchase', sortable: false, width: "1%" },
				{ text: 'Ожид. выручка', value: 'expected_revenue', sortable: false, width: "1%" },
				{ text: 'Ожид. прибыль', value: 'expected_profit', sortable: false, width: "1%" },
				{ text: 'Ожид. маржинальность', value: 'expected_rentab', sortable: false, width: "1%" },
				{ text: 'Поставлено на ВБ, дн', value: 'income', sortable: false, width: "1%" },
				{ text: "Цена со скидкой", value: "price", sortable: false, width: "1%" },
				{ text: "Хранение", value: "storage_cost", sortable: false, width: "1%" },
				{ text: "Логистика", value: "logistic", sortable: false, width: "1%" },
				{ text: "Упущенная выручка", value: "lost_revenue", sortable: false, width: "1%" },
			],
			loading: false,
			options: {},
			pagination: {
				next: null,
				totalItems: 0,
				numberOfPages: 0,
				currentPage: 0
			},
			data: []
		}
	},
	watch:{
        'options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && newVal) {
				await this.fetch_detail_suppl()
			}
        },
	},
	computed: {
		get_warehouses_list(){
			let list_warehouse = [{"name": "Все", "value": ""}]
			this.warehouses_list.forEach(item => {
				list_warehouse.push(item)
			})
			return list_warehouse
		},
		filtered_data(){
			if (this.filters.warehouse_name == "")
				return this.data
			let data_warehouse = []
			this.data.forEach(item => {
				try{
					let tmp = item.warehouse_data[this.filters.warehouse_name]
					if (tmp)
						data_warehouse.push(tmp)
				} catch(err){
					console.log(err)
				}
			})
			return data_warehouse
		},
	},
	methods: {
		async fetch_detail_with_filters(){
			this.options.page = 1
			await this.fetch_detail_suppl()
		},
		async fetch_detail_suppl(){
			this.loading = true
			const { page, itemsPerPage, sortDesc, sortBy} = this.options
			try {
				let pageNumber = page - 1
				if (this.data.length != 0)
					window.scrollTo(0,0);
				let data = {
					'offset': itemsPerPage * pageNumber, 'limit': itemsPerPage, 
					'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				}			
				if (this.filters.nmid)
					data['code_mp'] = this.filters.nmid
				if (this.filters.code_1c)
					data['code_1c'] = this.filters.code_1c
				if (this.filters.is_active)
					data['is_active'] = this.filters.is_active
				const response = await Suppl.detailSupplyPlanning(this.id, data)
				this.data = response.data.results
				this.pagination.totalItems = response.data.count
				this.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
			} catch (err) {
				console.error(err.response)
			}
			this.loading = false
		},
		async export_general_data(){
			this.loading_export = true
            try {
                const response = await Suppl.exportGeneralSupplyPlanning(this.id, this.filters.warehouse_name)
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'general_supply_planning.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export = false
        },
		async export_abc_data(){
			this.loading_export = true
            try {
                const response = await Suppl.exportABCSupplyPlanning(this.id, this.filters.warehouse_name)
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'abc_supply_planning.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.loading_export = false
        },
	},
	async mounted(){
		await this.fetch_detail_suppl()
	},
};
</script>
