<template>
  <div>
    <v-container fluid>
      <v-subheader>Вывод отчета</v-subheader>
      <v-row class="">
        <v-col cols="4" md="4" lg="3">
          <div class="mb-2">
            <v-select
                v-model="filters.seller"
                :items="choices.sellers"
                item-text="name"
                item-value="id"
                label="Аккаунт"
                prepend-icon="mdi-account"
                outlined
                hide-details
                dense
                clearable
            ></v-select>
          </div>
          <div class="mb-1">
            <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y="offset-y" min-width="290px"
                    ref="datepicker_menu_from">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    @click:clear="filters.date_from = null"
                    v-model="dateFromFormatted"
                    label="Начало периода"
                    prepend-icon="mdi-calendar"
                    readonly="readonly"
                    v-bind="attrs"
                    hide-details="auto"
                    outlined
                    dense
                    v-on="on">
                </v-text-field>
              </template>
              <v-date-picker
                  v-model="filters.date_from"
                  :first-day-of-week="1"
                  @input="datepicker_menu_from = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div>
            <v-menu v-model="datepicker_menu_to" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y="offset-y" min-width="290px"
                    ref="datepicker_menu_to">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    clearable
                    @click:clear="filters.date_to = null"
                    v-model="dateToFormatted"
                    label="Конец периода"
                    prepend-icon="mdi-calendar"
                    readonly="readonly"
                    hide-details="auto"
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="filters.date_to"
                  @input="datepicker_menu_to = false"
                  :first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex justify-end mt-2">
            <v-btn
                :disabled="!allowGenerateReport"
                depressed
                color="primary"
                @click="getTransactionsTotalsReport"
            >Сформировать</v-btn>
          </div>
        </v-col>
        <v-col cols="8" lg="9">
          <div v-if="reportsData && reportsData.summary_reports_data" class="mb-3">
            <h2 class="mb-1">Суммарный ({{reportsData.date_from }} - {{reportsData.date_to }})</h2>
            <v-data-table
              :headers="reportsTable.headersSummary"
              :items="reportsData.summary_reports_data"
              hide-default-footer
              item-key="id"
              class="elevation-1 small-font"
            >
              <template v-slot:item.processing_and_delivery="{ item }">
                {{item.processing_and_delivery | toRUB}}
              </template>
              <template v-slot:item.accruals_for_sale="{ item }">
                {{item.accruals_for_sale | toRUB}}
              </template>
              <template v-slot:item.sale_commission="{ item }">
                {{item.sale_commission | toRUB}}
              </template>
              <template v-slot:item.refunds_and_cancellations="{ item }">
                {{item.refunds_and_cancellations | toRUB}}
              </template>
              <template v-slot:item.compensation_amount="{ item }">
                {{item.compensation_amount | toRUB}}
              </template>
              <template v-slot:item.money_transfer="{ item }">
                {{item.money_transfer | toRUB}}
              </template>
              <template v-slot:item.others_amount="{ item }">
                {{item.others_amount | toRUB}}
              </template>
              <template v-slot:item.detail="{ }">
                <v-btn
                    color="primary"
                    depressed
                    small
                    @click="reportDetailOpen(reportsData.date_from, reportsData.date_to, reportsData.account)"
                >
                  Детализация
                </v-btn>
              </template>
            </v-data-table>
          </div>
          <div v-if="reportsData && reportsData.everyday_reports_data.length > 0" class="mb-3">
            <h2 class="mb-1">Ежедневный</h2>
            <v-data-table
                :headers="reportsTable.headersEveryday"
                :items="reportsData.everyday_reports_data"
                item-key="id"
                class="elevation-1 small-font"
            >
              <template v-slot:item.processing_and_delivery="{ item }">
                {{item.processing_and_delivery | toRUB}}
              </template>
              <template v-slot:item.accruals_for_sale="{ item }">
                {{item.accruals_for_sale | toRUB}}
              </template>
              <template v-slot:item.sale_commission="{ item }">
                {{item.sale_commission | toRUB}}
              </template>
              <template v-slot:item.refunds_and_cancellations="{ item }">
                {{item.refunds_and_cancellations | toRUB}}
              </template>
              <template v-slot:item.compensation_amount="{ item }">
                {{item.compensation_amount | toRUB}}
              </template>
              <template v-slot:item.money_transfer="{ item }">
                {{item.money_transfer | toRUB}}
              </template>
              <template v-slot:item.others_amount="{ item }">
                {{item.others_amount | toRUB}}
              </template>
              <template v-slot:item.detail="{ item }">
                <v-btn
                    color="primary"
                    depressed
                    small
                    @click="reportDetailOpen(item.date, item.date, item.account)"
                >
                  Детализация
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="reportDetailModal"
        max-width="960"
        v-if="reportDetailModal"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{reportDetailModalHeadingTitle}}
        </v-card-title>
          <OzonTransactionsReportsDetail :date-from="reportDetailQuery.date_from" :date-to="reportDetailQuery.date_to" :account-id="reportDetailQuery.account_id"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {General} from '@/api/general';
import {Stats} from '@/api/stats';
import async from 'async';
import OzonTransactionsReportsDetail from '@/components/OzonTransactionsReports/OzonTransactionsReportsDetail'

export default {
  name: 'OzonTransactionsReportsList',
  components: {OzonTransactionsReportsDetail},
  data () {
    return {
      reportDetailQuery: {
        date_from: null,
        date_to: null,
        account_id: null,
      },
      reportDetailFetchLoading: true,
      reportDetailModal: false,
      reportDetailModalHeadingTitle: 'Детализация отчета',
      reportDetail: null,
      filters: {
        seller: null,
        date_from: null,
        date_to: null,
        report_date_type: "everyday"
      },
      choices: {
        sellers: null
      },
      datepicker_menu_to: false,
      datepicker_menu_from: false,
      reportsData: null,
      reportsTable: {
        headersSummary: [
          { text: 'Общая стоимость', align: 'start', sortable: false, value: 'accruals_for_sale', width: '1%'},
          { text: 'Сумма комиссии', align: 'start', sortable: false, value: 'sale_commission', width: '1%'},
          { text: 'Стоимость услуг обработка', align: 'start', sortable: false, value: 'processing_and_delivery', width: '1%'},
          { text: 'Возвраты и отмены', align: 'start', sortable: false, value: 'refunds_and_cancellations', width: '1%'},
          { text: 'Доп. услуги', align: 'start', sortable: false, value: 'sale_commission', width: '1%'},
          { text: 'Компенсации', align: 'start', sortable: false, value: 'compensation_amount', width: '1%'},
          { text: 'Начисления за доставку и возвраты', align: 'start', sortable: false, value: 'money_transfer', width: '1%'},
          { text: 'Прочие начисления', align: 'start', sortable: false, value: 'others_amount', width: '1%'},
          { text: '', align: 'start', sortable: false, value: 'detail', width: '1%'},
        ],
        headersEveryday: [
          { text: 'Дата', align: 'start', sortable: false, value: 'date', width: '1%'},
          { text: 'Общая стоимость', align: 'start', sortable: false, value: 'accruals_for_sale', width: '1%'},
          { text: 'Сумма комиссии', align: 'start', sortable: false, value: 'sale_commission', width: '1%'},
          { text: 'Стоимость услуг обработка', align: 'start', sortable: false, value: 'processing_and_delivery', width: '1%'},
          { text: 'Возвраты и отмены', align: 'start', sortable: false, value: 'refunds_and_cancellations', width: '1%'},
          { text: 'Доп. услуги', align: 'start', sortable: false, value: 'sale_commission', width: '1%'},
          { text: 'Компенсации', align: 'start', sortable: false, value: 'compensation_amount', width: '1%'},
          { text: 'Начисления за доставку и возвраты', align: 'start', sortable: false, value: 'money_transfer', width: '1%'},
          { text: 'Прочие начисления', align: 'start', sortable: false, value: 'others_amount', width: '1%'},
          { text: '', align: 'start', sortable: false, value: 'detail', width: '1%'},
        ]
      }
    }
  },
  computed: {
    allowGenerateReport () {
      return Object.values(this.filters).every(i => i !== null)
    },
    dateFromFormatted() {
      return this.filters.date_from ?
          this.$options.filters.formatDateToLocaleNoTime(this.filters.date_from) :
          null;
    },
    dateToFormatted() {
      return this.filters.date_to ?
          this.$options.filters.formatDateToLocaleNoTime(this.filters.date_to) :
          null;
    },
  },
  methods: {
    async reportDetailOpen(date_from, date_to, account_id) {
      this.reportDetailModalHeadingTitle = `Детализация отчета за ${date_from} - ${date_to}`
      this.reportDetailQuery.date_from = date_from
      this.reportDetailQuery.date_to = date_to
      this.reportDetailQuery.account_id = account_id
      this.reportDetailModal = true

    },
    setFilterDefaultDates () {
      const d = new Date();
      this.filters.date_to = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
      d.setDate(d.getDate() - 7);
      this.filters.date_from = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
    },
    async get_sellers() {
      try {
        const response = await General.getOzonApiAccounts()
        this.choices.sellers = response.data
      } catch (err) {
        console.log(err)
      }
    },
    async getTransactionsTotalsReport() {
      const response = await Stats.getOzonTransactionsTotalReport(this.filters)
      this.reportsData = response.data
    }
  },
  async mounted(){
    this.setFilterDefaultDates()
    await this.get_sellers()
  }
}
</script>

<style scoped lang="scss">

</style>