import Vue from 'vue'

Vue.filter('toUSD', function (value){
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.filter('toRUB', function (value){
  if (typeof value !== 'number') {
    if (typeof value == 'string') {
      try {
        value = Number(value)
      } catch (e) {
        return value
      }
    } else {
      return value
    }
  }
  var formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})

Vue.filter('toRUBWithoutKopecks', function (value){
  if (typeof value !== 'number') {
    if (typeof value == 'string') {
      try {
        value = Number(value)
      } catch (e) {
        return value
      }
    } else {
      return value
    }
  }
  if (value > 10 || value < -10){
    value = value.toFixed(0)
  }
  var formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})