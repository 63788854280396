<template>
    <div>
        <DetmirProductsListComponent></DetmirProductsListComponent>
    </div>
</template>

<script>
	import DetmirProductsListComponent from '@/components/DetmirProduct/DetmirProductsListComponent';

	export default {
		name: 'DetmirProductsList',
		components: { DetmirProductsListComponent },
		mounted() {
			document.title = 'Товары ДМ | mp.lomex-group.ru';
			this.$store.commit('setPageTitle', `Список товаров ДМ`);
		},
	};
</script>

<style scoped>

</style>
