import { HTTP } from './http'

export const Dashboard = {
  async fetchTaskHistory (query=null) {
    return HTTP.get('/dashboard/task-history/', {
      params: query
    } )
  },
  async fetchSellerSummaryData (query=null) {
    return HTTP.get('/dashboard/summary/', {
      params: query
    } )
  },
}
