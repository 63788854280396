<template>
    <div>
        <v-navigation-drawer v-model="drawer" v-if="drawer" temporary absolute app>
            <div class="pa-4">
				<v-autocomplete
					v-model="filters.manager" :items="filter_choices.product_manager_choices"
					label="Менеджер" outlined hide-details dense clearable multiple item-text="name" item-value="id"
				></v-autocomplete>
            </div>
			<div class="pa-4">
				<v-select
					v-model="filters.profile" :items="filter_choices.product_profile_choices" item-text="name"
					item-value="value" label="Профильность" outlined multiple hide-details dense clearable
				></v-select>
            </div>
            <div class="pa-4">
				<v-text-field
					v-model="filters.tag" clearable outlined dense label="Тег" hide-details="hide-details">
				</v-text-field>
            </div>
            <div class="pa-5">
                <v-btn
                    @click="fetchProductsWithFilters" depressed color="purple darken-2" class="mb-3" dark
                >Применить</v-btn>
                <v-btn
                    @click="clearFilters" depressed class="mb-10"
                >Сбросить</v-btn>
            </div>
        </v-navigation-drawer>
        <div class="px-5">
            <v-btn icon @click="drawer = !drawer"> <v-icon>mdi-filter-variant</v-icon></v-btn>
        </div>
        <v-sheet ref="product_table_filters">
            <v-container fluid>
                <v-row class="align-center">
                    <v-col>
						<v-select
							v-model="filters.brand" :items="filter_choices.product_brand_choices"
							item-text="name" item-value="name" label="Бренд" outlined multiple
							hide-details dense clearable
						></v-select>
                    </v-col>
                    <v-col>
						<v-select
							v-model="filters.group" :items="filter_choices.product_group_choices"
							item-text="name" item-value="value" label="Группа" outlined multiple
							hide-details dense clearable
						></v-select>
                    </v-col>
                    <v-col>
						<v-select
							v-model="filters.status" :items="filter_choices.product_status_choices"
							item-text="name" item-value="value" label="Статус" outlined multiple 
							hide-details dense clearable
						></v-select>
                    </v-col>
                    <v-col>
						<v-radio-group v-model="filters.stocks" hide-details row class="mt-1">
							<v-radio label="Все" :value=0></v-radio>
							<v-radio label="Есть остатки" :value=1></v-radio>
							<v-radio label="Нет остатков" :value=-1></v-radio>
						</v-radio-group>
                    </v-col>
                    <v-col>
						<v-radio-group v-model="filters.cost_price" hide-details row class="mt-1">
							<v-radio label="Все" :value=0></v-radio>
							<v-radio label="Себестоимость больше 0" :value=1></v-radio>
							<v-radio label="Себестоимость 0" :value=-1></v-radio>
						</v-radio-group>
                    </v-col>
                    <v-col>
                        <v-text-field
							v-model="filters.name" clearable outlined dense 
							label="Название товара" hide-details
						></v-text-field>
                    </v-col>
					<v-col>
						<v-text-field
							v-model="filters.code_1c" clearable outlined dense 
							label="Артикул 1С" hide-details
						></v-text-field>
                    </v-col>
                    <v-col>
						<v-text-field
							v-model="filters.mp_id" clearable outlined dense 
							label="Артикул" hide-details
						></v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="end">
                    <v-col md="4" class="text-right py-0">
						<v-btn
							@click="fetchProductsWithFilters" depressed color="purple darken-2" class="mb-3" dark
						>Применить</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-data-table
			:headers="table.headers"
			:pageCount="table.pagination.numberOfPages"
			:items="table.data"
			item-key="id"
			:loading="table.loading"
			:options.sync="table.options"
			:items-per-page="25"
			:server-items-length="table.pagination.totalItems"
			class="elevation-1"
			:footer-props="{
				'items-per-page-options': [5, 15, 25, 50, 100]
			}"
        >
			<template v-slot:item.image="{ item }">
				<div style="display: flex; align-items: center; justify-content: center;" class="py-2">
					<div v-if="!item.image">Нет изображения</div>
					<img style="max-height:80px" :src="`https://catalog.detmir.st/media/${item.image}`" alt="" v-else>
				</div>
			</template>
            <template v-slot:item.mp_id="{ item }">
				<router-link 
					target="_blank" class="small-text"
					:to="{ name: 'detmir-product-detail', params: { id: item.mp_id }}"
				><span class="grey--text">ДМ ID: </span>{{ item.mp_id }}</router-link>
				<div class="small-text" style="white-space: nowrap;"><span class="grey--text">Code 1c: </span>{{ item.catalog_item.code1C_code }}</div>
				<div class="small-text" style="white-space: nowrap;"><span class="grey--text">ШК: </span>{{ item.barcode }}</div>
				<a 
					target="_blank" :href="`https://www.detmir.ru/product/index/id/${item.promo_id}/`" 
					class="small-text" style="white-space: nowrap;"
				>Смотреть на ДМ</a>
				<div 
					style="max-width: 120px; width: 120px" v-if="item.dynamic_fields && item.dynamic_fields.orders_by_graph"
				><OrdersChartProductList :series="getOrdersGraph(item, 1, 15)"/></div>
			</template>
			<template v-slot:item.name="{ item }">
                <div class="small-text">{{ item.name }}</div>
            </template>
            <template v-slot:item.brand="{ item }">
                <div class="pt-2 small-text">{{ item.catalog_item.brand ? item.catalog_item.brand : "-" }} |</div>
                <div class="pt-2 small-text">{{ item.catalog_item.code1C_tag_group_name ? item.catalog_item.code1C_tag_group_name : "-"}} |</div>
            </template>
            <template v-slot:item.status="{ item }">
                <div class="pt-2 small-text">
					{{ item.catalog_item.status_name ? item.catalog_item.status_name : "-" }} |
				</div>
                <div class="pt-2 small-text">
					{{ item.catalog_item.account_name ? item.catalog_item.account_name : "-" }}
				</div>
            </template>
			<template v-slot:item.price="{ item }">
				<v-tooltip bottom max-width=350>
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on" class="ml-1">
							<a href="#" class="dashed small-text">{{ item.sale_price ? item.sale_price : 0 | toRUB }}</a>
						</div>
					</template>
					<span>
						<p>Цена без скидкой: {{ item.base_price ? item.base_price : 0 | toRUB }}</p>
						<p>Цена со скидкой: {{ item.sale_price ? item.sale_price : 0 | toRUB }}</p>
						<p>Залоговая цена: {{ item.pledge_price ? item.pledge_price : 0 | toRUB }}</p>
						<p>Процент скидки: {{ item.sale_percent }}%</p>
					</span>
				</v-tooltip>
            </template>
			<template v-slot:item.cost_price="{ item }">
				<div class="small-text">
					{{ item.dynamic_fields && item.dynamic_fields.cost_price ? item.dynamic_fields.cost_price : 0 | toRUB }}
				</div>
            </template>
			<template v-slot:item.stocks="{ item }">
                <div class="small-text">
					{{ item.dynamic_fields && item.dynamic_fields.stocks ? item.dynamic_fields.stocks : 0 }}
				</div>
            </template>
            <template v-slot:item.stocks_1c="{ item }">
				<div class="small-text">
					{{ item.dynamic_fields && item.dynamic_fields.stocks_1c ? item.dynamic_fields.stocks_1c : 0 }}
				</div>
            </template>
			<template v-slot:item.orders_30="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_sum_30 ? item.dynamic_fields.orders_sum_30 : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_30 ? item.dynamic_fields.orders_count_30 : 0 }} шт</div>
					<div>
						({{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_sum_30, item.dynamic_fields.orders_sum_30
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_count_30, item.dynamic_fields.orders_count_30
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.orders_7="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_sum_7 ? item.dynamic_fields.orders_sum_7 : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_7 ? item.dynamic_fields.orders_count_7 : 0 }} шт</div>
					<div>
						({{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_sum_7, item.dynamic_fields.orders_sum_7
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_count_7, item.dynamic_fields.orders_count_7
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.orders_1="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.orders_sum_1 ? item.dynamic_fields.orders_sum_1 : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.orders_count_1 ? item.dynamic_fields.orders_count_1 : 0 }} шт</div>
					<div>
						({{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_sum_1, item.dynamic_fields.orders_sum_1
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.orders_delta.orders_count_1, item.dynamic_fields.orders_count_1
						) }}%) 
					</div>
				</div>
            </template>
			<template v-slot:item.sales_30="{ item }">
				<div class="small-text" v-if="item.dynamic_fields">
					<div>{{item.dynamic_fields.sales_sum_30 ? item.dynamic_fields.sales_sum_30 : 0 | toRUB }}</div>
					<div>{{item.dynamic_fields.sales_count_30 ? item.dynamic_fields.sales_count_30 : 0 }} шт</div>
					<div>
						({{ calculateDelta(
							item.dynamic_fields.sales_delta.sales_sum_30, item.dynamic_fields.sales_sum_30
						) }}% / 
						{{ calculateDelta(
							item.dynamic_fields.sales_delta.sales_count_30, item.dynamic_fields.sales_count_30
						) }}%) 
					</div>
				</div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import { Products } from '@/api/products'
	import { General } from '@/api/general'
	import OrdersChartProductList from '@/components/Product/OrdersChartProductList'

	export default {
		name: 'DetmirProductsListComponent',
		components: {OrdersChartProductList},
		data () {
			return {
				first_init: false,
				drawer: false,
				table:{
					loading: false,
					pagination: {
						next: null,
						totalItems: 0,
						numberOfPages: 0,
						currentPage: 0
					},
					options: {
						sortBy: ['orders_30'],
						sortDesc: [true]
					},
					data: [],
					headers: [
						{ text: 'Картинка', align: 'start', sortable: false, value: 'image', width: '1%'},
						{ text: 'id', align: 'start', sortable: false, value: 'mp_id', width: '1%' },
						{ text: 'Название', align: 'start', sortable: false, value: 'name', width: '2%' },
						{ text: 'Бренд / Группа', align: 'start', sortable: false, value: 'brand', width: '1%' },
						{ text: 'Статус / ЛК', value: 'status', width: '1%', sortable: false },
						{ text: 'Цена', value: 'price', width: '1%', sortable: true },
						{ text: 'Себестоимость', value: 'cost_price', width: '1%', sortable: true },
						{ text: 'Остаток МП', value: 'stocks', width: '1%', sortable: true },
						{ text: 'Остаток 1C (комплекты)', value: 'stocks_1c', width: '1%', sortable: true},
						{ text: 'Заказы за вчера', value: 'orders_1', width: '1%', sortable: false },
						{ text: 'Заказы за 7 дней', value: 'orders_7', width: '1%', sortable: false },
						{ text: 'Заказы за 30 дней', value: 'orders_30', width: '1%', sortable: true },
						{ text: 'Продажи за 30 дней', value: 'sales_30', width: '1%', sortable: false},
					],
				},		
				filters: {
					mp_id: null,
					code_1c:null,
					name: null,
					brand: null,
					group: null,
					status: ['0001'],
					profile: 0,
					stocks: 0,
					cost_price: 0,
					manager: null,
					tag: null,
				},
				filter_choices: {
					wbaccount:[],
					product_status_choices: [],
					product_manager_choices: [],
					product_brand_choices: [],
					product_group_choices: [],
					product_profile_choices: [],
				},
			}
		},
		watch: {
			'table.options': async function (newVal, oldVal) {
				if (oldVal && this.first_init) {
					await this.fetchProducts()
				}
			},
		},
		computed: {
			transfromFiltersForQuery () {
				let query = {}
				for (const f in this.filters) {
					if (Array.isArray(this.filters[f])) {
						query[f] = this.filters[f].join(',')
					} else if(f=='nmid'){
						query[f] = this.filters[f] ? this.filters[f].replace(/\s/g,'') : this.filters[f]
					}else {
						query[f] = this.filters[f]
					}
					if (this.filters[f] == false) {
						query[f] = null
					}
				}
				return query
			},
		},
		methods: {
			calculateDelta(old_val, new_val){
				if (old_val > 0 && new_val > 0)
					return ((new_val - old_val) / old_val * 10000 / 100).toFixed(2)
				return 0
			},
			async clearFilters () {
				let dirtyFilters = false
				for (let item in this.filters) {
					if (this.filters[item]) {
						dirtyFilters = true
					}
					this.filters[item] = Array.isArray(this.filters[item]) ? [] : null
				}
				if (dirtyFilters) {
					await this.fetchProductsWithFilters()
				}
			},
			async fetchProductsWithFilters () {
				this.drawer = false
				this.table.options.page = 1
				await this.fetchProducts()
			},
			async fetchProducts () {
				this.table.loading = true
				if (this.table.data.length != 0)
					window.scrollTo(0,0);
				const { page, itemsPerPage, sortBy, sortDesc } = this.table.options
				let pageNumber = page - 1
				try {
					const response = await Products.fetchDetmirProductsList({
						...this.transfromFiltersForQuery, 'offset': itemsPerPage * pageNumber,
						'limit': itemsPerPage, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
					})
					this.table.data = response.data.results.objects
					this.filter_choices.product_brand_choices = response.data.results.filters.brand
					this.filter_choices.product_status_choices = response.data.results.filters.status
					this.filter_choices.product_group_choices = response.data.results.filters.group
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
				} catch (err) {
					console.log(err.data)
				}
				this.table.loading = false
			},
			async fetchProductGroup() {
				try {
					const response = await General.fetchProductGroup()
					this.filter_choices.product_group_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductStatus() {
				try {
					const response = await General.fetchProductStatus()
					this.filter_choices.product_status_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductManager() {
				try {
					const response = await General.fetchProductManager()
					this.filter_choices.product_manager_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			async fetchProductProfile() {
				try {
					const response = await General.fetchProductProfile()
					this.filter_choices.product_profile_choices = response.data
				} catch (err) {
					console.log(err.data)
				}
			},
			getOrdersGraph(item, day_start, day_end){
				if (!item.dynamic_fields || !item.dynamic_fields.orders_by_graph)
					return []
				let orders_dict = item.dynamic_fields.orders_by_graph
				return orders_dict.slice(day_start, day_end).reverse()
			},
		},
		async mounted () {
			await this.fetchProducts()
			this.first_init = true
			this.fetchProductManager()
			this.fetchProductProfile()
		}
	}
</script>

<style scoped lang="scss">
    ::v-deep .v-rating .v-icon{
        padding: 0 !important;
    }
    .rating-chip{
        display: inline-block;
        padding: 3px 8px;
        color: #ffffff;
        border-radius: 5px;
        font-size: .9rem;
    }
    .text-danger{
      color: red;
    }
    
</style>
<style lang="scss">
.bee-table-wrapper{
      max-width: calc(100vw - 47px);
    }
</style>
