<template>
	<div class="py-10">
		<v-progress-linear
			v-if="num_step == 0"
			indeterminate
			color="blue darken-2"
		></v-progress-linear>
		<AdvertisementAuthorization 
			@successAuthorization="getSuppliers" 
			v-else-if="num_step == 1"
		/>
		<AdvertisementTable 
			v-else-if="num_step == 2"
			:suppliers="data.suppliers"
			@changeStep=changeStep
		/>
	</div>
</template>

<script>
import {AdvertisementNew} from '@/api/advertisement_new'
import AdvertisementAuthorization from '@/components/AdvertisementNew/AdvertisementAuthorization'
import AdvertisementTable from '@/components/AdvertisementNew/AdvertisementTable'

export default {
	name: 'AdvertisementMainComponent',
	data() {
		return {
			num_step: 0,
			data: {
				suppliers: null
			}
		}
	},    
	components: {
		AdvertisementAuthorization,
		AdvertisementTable
	},
	methods: {
		async getSuppliers(){
			try {
				this.data.suppliers = await AdvertisementNew.getSuppliers()
				if (this.data.suppliers)
					this.num_step = 2
				else
					this.num_step = 1
			} catch (err) {
				this.num_step = 1
			}
		},
		changeStep(n){
            this.num_step = n
        },
	},
	async mounted(){
		await this.getSuppliers()
	}   
}
</script>