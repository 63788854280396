<template>
    <div>
        <v-dialog
			v-model="change_item.dialog"
			max-width="800"
			v-if="change_item.dialog"
		>
			<v-card class="py-5 px-5">
				<v-card-text>
					<h1>Изменить налоги на {{change_item.edit_item.date}} год</h1>
					<div class="mt-5">
                        <v-text-field
                            v-model="change_item.first_third_quarter"
                            label="1-3 квартал"
                            outlined
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="change_item.fourth_quarter"
                            label="4 квартал"
                            outlined
                            type="number"
                            dense
                            clearable
                        ></v-text-field>
                    </div>
                    <v-btn 
                        color="primary" 
                        dark 
                        :loading="change_item.send_loading"
                        @click="updateSellerFax"
                    >Обновить</v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>
        <v-container>
            <v-card>
                <v-card-title>Установка налогов</v-card-title>
                <v-card-text>
                    <div class="d-flex mt-5" style="align-items: center;">
                        <h3>Аккаунт: </h3>
                        <div style="max-width:500px;" class="ml-3">
                            <v-autocomplete
                                v-model="current_seller"
                                @change="changeCurrentSeller"
                                :items="sellers"
                                item-text="name"
                                item-value="value"
                                label="Аккаунты продавцов"
                                clearable
                                outlined
                                hide-details
                                dense
                            ></v-autocomplete>
                        </div>
                    </div>
                    <div class="mt-10" v-if="loading">
                        <hr>
                        <v-progress-circular
                            class="mt-3"
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                    <div class="mt-10" v-else-if="general_data">
                        <div 
                            class="seller_item mt-3" 
                            v-for="(item, index) in general_data" 
                            :key="index"
                        >
                            <hr>
                            <h3 class="mt-3">Год: {{ item.date }}</h3>
                            <h3 class="mt-3">
                                Налог за 1-3 квартал: {{ item.first_third_quarter }} %
                                <v-icon small class="ml-2" @click="openChangeDialog(item)">
                                    mdi-pencil
                                </v-icon>
                            </h3>
                            <h3 class="mt-3">
                                Налог за 4 квартал: {{ item.fourth_quarter }} %
                                <v-icon small class="ml-2" @click="openChangeDialog(item)">
                                    mdi-pencil
                                </v-icon>
                            </h3>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
    
</template>

<script>
    import {General} from '@/api/general'
    import {Catalog} from '@/api/catalog'
    
    export default {
        name: 'CatalogTax',
        data() {
            return{
                sellers: [],
                current_seller: null,
                general_data: [],
                loading: false,
                change_item: {
                    send_loading: false,
                    dialog: false,
                    edit_item: null,
                    first_third_quarter: null,
                    fourth_quarter:null
                }
            }
        },
        methods:{
            async fetchSeller(){
                try {
                    const responce = await General.fetchSellersChoices()
                    this.sellers = responce.data
                }catch (err) {
                    console.log(err)
                }
            },
            async fetchSellerTax(){
                if (this.current_seller){
                    this.general_data = null
                    this.loading = true 
                    try {
                        const responce = await Catalog.fetchSellerTax({"seller_id": this.current_seller})
                        this.general_data = responce.data
                    }catch (err) {
                        console.log(err)
                    }
                    this.loading = false
                }
            },
            async changeCurrentSeller(){
               await this.fetchSellerTax()
            },
            openChangeDialog(item){
                this.change_item.dialog = true
                this.change_item.edit_item = item
                this.change_item.first_third_quarter = item.first_third_quarter
                this.change_item.fourth_quarter = item.fourth_quarter
            },
            async updateSellerFax(){
                this.change_item.send_loading = true
                let dict = {
                    'tax_id': this.change_item.edit_item.id,
                    "first_third_quarter": this.change_item.first_third_quarter,
                    "fourth_quarter": this.change_item.fourth_quarter,
                }
                try {
                    let response = await Catalog.updateSellerTax(dict)
                    if (response.status == 200){
                        await this.fetchSellerTax() 
                    }
                } catch (err) {
                    console.error(err.response)
                }
                this.change_item.send_loading = false
                this.change_item.dialog = false
                this.change_item.edit_item = null
                this.change_item.first_third_quarter = null
                this.change_item.fourth_quarter = null
            }
        },
        async mounted() {
            this.$store.commit('setPageTitle', `Налоги аккаунтов`);
            await this.fetchSeller()
        },
    };
</script>

<style scoped>

</style>