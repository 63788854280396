import {Suppl} from '@/api/suppl';

export default {
	state: () => ({
		supplierID: null,
		suppliers: [],
		sellerData: {
			preorders: [],
			warehouses: [],
			incomes: [],
			shop_requisites: [],
		},
		supplyTasks: [],
	}),
	mutations: {
		setSupplier(state, supplier) {
			state.supplier = supplier;
		},
		setSuppliers(state, suppliers) {
			state.suppliers = suppliers;
		},
		setSellerData(state, payload) {
			state.sellerData = payload;
		},
		setSupplyTasks(state, payload) {
			state.supplyTasks = payload;
		},
	},
	actions: {
		async fetchSuppliers({commit}, {query, filters}) {
			const response = await Suppl.getSuppliers();
			if (response) {
				commit('setSuppliers', response);
			}
		},
		async createSupplyOnServer({commit, state}, payload) {
			const response = await Suppl.supplyPlan(
				payload['supplyData'],
				payload.dates,
				state.supplier.id,
				state.supplier.name,
				payload.monoCount ? payload.monoCount : 0,
				payload.shop_requisites_id ? payload.shop_requisites_id : '',
				payload.maxCoef,
			);
			console.log(response);
			return response;
		},
		async changeSupplier({commit, dispatch}, supplier) {
			commit('setSupplier', supplier);
			await dispatch('getTotalSellerData', supplier.id);
		},
		async getTotalSellerData({commit}, supplierID) {
			const response = await Suppl.getTotalSellerData(supplierID);
			if (response) {
				commit('setSellerData', response);
			}
		},
		async fetchSupplyTasks({commit}) {
			const response = await Suppl.fetchSupplyTasks();
			if (response) {
				commit('setSupplyTasks', response);
			}
		},
	},
	getters: {},
};
