<template>
    <v-container fluid grey lighten-4 class="pa-5">
        <v-skeleton-loader
                v-if="productBaseDataLoading"
                type="article, card-heading"
        ></v-skeleton-loader>
        <v-card v-else class="mx-auto" flat>
            <v-card-text class="pb-0">
                <div class="d-flex">
                    <div><img :src="getImgPath(product.nmid)" width="80" alt=""/></div>
                    <div class="mx-4" style="width: 100%">
                        <p class="text-body-1 text--primary">
                            {{ product.name }} 
							<router-link 
								target="_blank" 
								:to="{ name: 'product_char', params: { id: product.nmid }}"
								class="ml-3"
							>
								<v-icon style="font-size: 1rem;">mdi-pencil</v-icon>
							</router-link>
							<span class="ml-5">
								Дата последнего изменения: {{ product.last_change_char ? showDate(product.last_change_char) : '-' }}
							</span>
                        </p>
                        <div style="display: flex;">
                            <div>
                                <div>{{ product.nmid }} | {{product.catalog_item ? product.catalog_item.code1C_code : ''}} | {{ product.catalog_item.code1C_tag_group_name }}</div>
                                <div class="mt-3 d-flex align-center">
                                    <v-rating
                                            :value="product.product_rating"
                                            color="amber"
                                            dense
                                            half-increments
                                            readonly
                                            size="18"
                                    ></v-rating>
                                    <div class="grey--text ms-4">
                                        {{ product.product_rating }}
                                    </div>
									<div class="ml-5" v-if="product.feedback_stats">
										<p>
											<span :class="{'red_stats': product.product_rating < 4.5}">{{feedbackStatsFirstNumber}}</span>
											{{product.feedback_stats.first_count}}
										</p>
										<p>
											<span :class="{'red_stats': product.product_rating < 3.5}">{{feedbackStatsSecondNumber}}</span>
											{{product.feedback_stats.second_count}}
										</p>
										<p>Всего: {{product.feedback_stats.all_count}}</p>
									</div>
                                </div>
                                <div class="mt-3">
                                    <artificial-sales
                                            :dates.sync="product.artificial_sales_dates"
                                            marketplace="wildberries"
                                            :product_id="product.id">
                                    </artificial-sales>
                                </div>
                            </div>
                            <div class="different">
                                <div
                                        class="different_color"
                                        v-if="product.different_colors && product.different_colors.length > 1">
                                    <p class="text-body-1 text--primary">
                                        Другие цвета:
                                    </p>
                                    <div class="different_color__images">
                                        <div
                                                v-for="(color, index_color) in product.different_colors"
                                                :key="index_color"
                                                class="different_color__images__item"
                                        >
                                            <div v-if="color.nmid != product.nmid">
                                                <router-link
                                                        target="_blank"
                                                        :to="{ name: 'product_card', params: { id: color.nmid }}"
                                                >{{ color.nmid }}
                                                </router-link>
                                                <img
                                                        style="white-space:pre-line; text-align:center;"
                                                        :src="getImgPath(color.nmid)"
                                                        alt="
                                                    Фото удалено"
                                                        width="80"
                                                        :title="color.catalog_item__code1C__name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="different_variations mt-3" v-if="product.variations.length != 0">
                                    <p class="text-body-1 text--primary">
                                        Другие размеры:
                                    </p>
                                    <v-chip-group
                                            v-model="current_size"
                                            active-class="deep-purple--text text--accent-4"
                                            mandatory
                                    >
                                        <v-chip
                                                value=""
                                                class="different_variations__sizes"
                                        >
                                            Все
                                        </v-chip>
                                        <v-chip
                                                v-for="(size, index_size) in product.variations"
                                                :key="index_size"
                                                :value="size.id"
                                                class="different_variations__sizes"
                                        >{{ size.size }}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </div>
							<PlansBlockComponent :nmid="product_id" :is_ozon="false"/>
                        </div>
                    </div>
                </div>


            </v-card-text>
			
            <v-row class="py-3 px-4">
                <v-col class="d-flex align-center">
                    <a
                            :href="`https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${product.nmid}`"
                            target="_blank"
                    >Открыть на wb</a>
                    <a
                            class="ml-5"
                            :href="`https://mpstats.io/wb/item/${product.nmid}`"
                            target="_blank"
                    >Открыть в mpstats</a>
					<div style="font-size:14px" class="ml-5 d-flex align-center">
						Ночное повышение цены:
						<v-switch
							color="primary"
							class="mt-0 ml-3"
							hide-details
							v-model="product.increase_price_night"
							@change="changeIncreaseMode(product.id)"
						></v-switch>
					</div>
					<v-btn
						elevation="1" 
						x-small 
						class="ml-5" 
						@click="flag_old_table = !flag_old_table"
					>Переключить вид таблицы</v-btn>
					<div style="font-size:14px" class="ml-5 d-flex align-center">
						Расчет хранения из паллет:
						<v-switch
							color="primary"
							class="mt-0 ml-3"
							hide-details
							v-model="product.is_storage_pallet"
							@change="changeIsStoragePallet(product.id)"
						></v-switch>
					</div>
					<div style="max-width: 150px;">
						<v-text-field
							v-if="product.is_storage_pallet || product.is_mixed_logistic" v-model="product.quantity_on_pallet" 
							label="Кол-во на паллете" outlined type="number" class="mt-0 ml-3" 
							dense hide-details @change="changeQuantityOnPallet(product.id, product.quantity_on_pallet)"
						></v-text-field>
					</div>
					<div style="font-size:14px" class="ml-5 d-flex align-center">
						Смешанная логистика:
						<v-switch
							color="primary"
							class="mt-0 ml-3"
							hide-details
							v-model="product.is_mixed_logistic"
							@change="changeIsMixedLogistic(product.id)"
						></v-switch>
					</div>
					<div style="font-size:14px" class="ml-5 d-flex align-center">
						Логистика FBS:
						<v-switch
							color="primary"
							class="mt-0 ml-3"
							hide-details
							v-model="product.is_fbs_logistic"
							@change="changeIsFbsLogistic(product.id)"
						></v-switch>
					</div>
                </v-col>
            </v-row>
			
            <v-sheet class="d-flex">
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Бренд</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								{{ product.catalog_item.brand }}
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Группа товара</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								{{ product.catalog_item.code1C_tag_group_name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
				<v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Предмет</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								<p style="padding: 5px 0; margin-bottom: 0;">{{ product.object ? product.object : "-" }}</p>
								<hr>
								<p style="padding-top: 5px;">{{ product.subject_id ? product.subject_id : '-'}}</p>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Менеджер</v-list-item-title>
                            <v-list-item-subtitle v-if="product.catalog_item">{{ product.catalog_item.our_manager }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>-</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title>Аккаунт</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								{{ product.catalog_item.account_name }}
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title>Цена</v-list-item-title>
                            <div v-if="product.last_price && product.last_price.price_in_the_moment">
                                <v-list-item-subtitle class="wrap_subsection">
									{{ product.last_price.price_in_the_moment.toFixed(2) }}
                                    <span style="font-size: .7rem; color: #999999;">(Информация на {{ product.last_price.date_created | formatDateToLocale }})</span>
                                </v-list-item-subtitle>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title class="wrap_subsection">Тег</v-list-item-title>
                            <v-list-item-subtitle class="tag_info">{{ product.catalog_item.code1C_name }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
                <v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Статус</v-list-item-title>
                            <v-list-item-subtitle>
								<div>{{ product.catalog_item.status_name }}</div>
								<div class="mt-2 small-text orange_rentab" style="white-space: nowrap;" v-if="product.dynamic_fields.days_from_first_stocks <= 91 || product.catalog_item.status_name == 'Новинка' ">
									{{daysLabel(product.dynamic_fields.days_from_first_stocks)}}
								</div>
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
				<v-list two-line dense v-if="product.kit_components">
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title>Рецепт</v-list-item-title>
                            <v-list-item-subtitle class="wrap_subsection">
								<div v-for="c in product.kit_components" :key="c.id" style="font-size: 12px;">
									<router-link 
										:class="['mb-0', 'kit_text', {'font-weight-bold': c.main_kit, 'black--text': c.main_kit}]" 
										target="_blank" 
										:to="{ name: 'kits_component_card', params: { id: c.id }}"
									>{{ c.name }} ({{ c.code }}) - {{ c.count }}</router-link>
									<hr>
								</div>
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
				<v-list two-line dense>
                    <v-list-item two-line>
                        <v-list-item-content class="wrap_subsection">
                            <v-list-item-title></v-list-item-title>
                            <v-list-item-subtitle 
								class="wrap_subsection" 
							>
								<v-btn
									small dense dark color="purple darken-2"
									@click="$refs.product_stock_balances.openDialogCharts()"
								>Показать график</v-btn>
								<v-btn
									small dense dark color="purple darken-2" class="mt-3"
									:loading="loading_export"
									@click="exportProductDetail"
								>Экспорт</v-btn>
								
							</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-sheet>
        </v-card>
        <v-card
                class="mx-auto my-2 elevation-0"
                flat
        >
			<v-card-title>Динамика показателей</v-card-title>
            <ProductStockBalances
				v-if="flag_old_table"
				:product_id="$route.params.id"
				:variations_id="current_size"
				:catalog_item_id="product.catalog_item ? product.catalog_item.code1C_code : null"
				:artificial_sales_dates="product.artificial_sales_dates"
				:variations_all="product.variations_all"
				ref="product_stock_balances"
            />
			<ProductStockBalancesNew
				v-else
				:product_id="$route.params.id"
				:variations_id="current_size"
				:catalog_item_id="product.catalog_item ? product.catalog_item.code1C_code : null"
				:artificial_sales_dates="product.artificial_sales_dates"
				:variations_all="product.variations_all"
				:root_recommendation_percent="product.commission_percent_from_lk_wb"
				ref="product_stock_balances"
            />
        </v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				Мониторинг позиций WB
			</v-card-title>
			<v-card-text>
				<a :href="urlMPStats" target="_blank">Открыть в mpstats</a>

				<v-row class="align-center mt-5">
					<v-col cols="6">
						<v-row class="align-center">
							<v-col cols="8" class="d-flex flex-row">
								<v-textarea
										class="elevation-0"
										outlined
										clearable
										auto-grow
										rows="3"
										col="2"
										v-model="key_list_monitoring"
										label="Введите название ключей (каждый ключ с новой строки)"
										hide-details
								></v-textarea>
							</v-col>
							<v-btn
									@click="saveKeyword"
									depressed
									color="purple darken-2"
									dark
							>Добавить
							</v-btn>
						</v-row>
					</v-col>
					<v-col cols="5">
						<v-row class="align-center justify-end">
							<v-col cols="8">
								<v-text-field
										v-model="copy_nmid"
										clearable
										outlined
										dense
										label="Артикул"
										hide-details>
								</v-text-field>
							</v-col>
							<v-btn
									@click="copyKeyword"
									depressed
									color="purple darken-2"
									dark
							>Скопировать
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
				<KeywordTable ref='child_table' :product_id="$route.params.id"/>
			</v-card-text>
		</v-card>

		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				Блок конкурентов
			</v-card-title>
			<v-card-text>

				<v-row class="align-center mt-5">
					<v-col cols="6">
						<v-row class="align-center">
							<v-col cols="8" class="d-flex flex-row">
								<v-textarea
										class="elevation-0"
										outlined
										clearable
										auto-grow
										rows="3"
										col="2"
										v-model="nmid_list_competitors"
										label="Введите артикулы конкурентов (каждый артикул с новой строки)"
										hide-details
								></v-textarea>
							</v-col>
							<v-btn
									@click="addCompetitors"
									depressed
									color="purple darken-2"
									dark
							>Добавить
							</v-btn>
						</v-row>
					</v-col>
				</v-row>
				<Competitors ref='competitors_block' :product_id="$route.params.id"/>
			</v-card-text>
		</v-card>
		<AdvertisementOnProductCardComponent :product_id="$route.params.id"/>
		<v-card class="mt-5">
			<v-card-title>Аналитика по карточке товара</v-card-title>
			<v-card-text>
				<ProductCardAnalyticsReports :nmid="$route.params.id"/>
			</v-card-text>
		</v-card>
		<v-card class="my-3 elevation-2 flat">
			<v-card-title>История изменения статусов и менеджеров</v-card-title>
			<v-card-text>
				<CatalogMpHistory :nmid="$route.params.id" :mid="null"/>
			</v-card-text>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-text>
				<v-row>
					<v-col>
						<v-tabs
								v-model="tab"
								align-with-title
						>
							<v-tabs-slider color="purple"></v-tabs-slider>

							<v-tab key="1">
								Заказы
							</v-tab>
							<v-tab key="2">
								Продажи
							</v-tab>
							<v-tab key="3">
								Отчеты
							</v-tab>
						</v-tabs>
						<v-tabs-items v-model="tab">
							<v-tab-item>
								<ProductOrders v-if="product.nmid" :product_id="product.nmid"/>
							</v-tab-item>
							<v-tab-item>
								<ProductSales v-if="product.nmid && tab == 1" :product_id="product.nmid"/>
							</v-tab-item>
							<v-tab-item>
								<ProductReportSales v-if="product.nmid && tab == 2" :product_id="product.nmid"/>
							</v-tab-item>
						</v-tabs-items>

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<v-card flat class="my-3 elevation-2 flat">
			<v-card-title>
				История изменений
			</v-card-title>
			<v-card-text>
				<v-row class="align-center mt-5">
					<div style="width: 100%;">
						<v-data-table
							:headers="headers_historical"
							:items="historical_data"
							style="width: 100%;"
							class="elevation-0 mt-0 vertical-border small-font"
						>
							<template v-slot:item.date_created="{ item }">
								<p>{{showDateWithTime(item.date_created)}}</p>
							</template>
							<template v-slot:item.stock="{ item }">
								<p>{{item.old_stock}}</p>
								<hr>
								<p>{{item.new_stock}}</p>
							</template>
							<template v-slot:item.name="{ item }">
								<p>{{item.old_name}}</p>
								<hr>
								<p>{{item.new_name}}</p>
							</template>
							<template v-slot:item.sales="{ item }">
								<p>{{item.old_sales}}</p>
								<hr>
								<p>{{item.new_sales}}</p>
							</template>
							<template v-slot:item.price="{ item }">
								<p>{{item.old_price}}</p>
								<hr>
								<p>{{item.new_price}}</p>
							</template>
						</v-data-table>
					</div>
				</v-row>
			</v-card-text>
		</v-card>
        
    </v-container>
</template>

<script>
	import ProductStockBalances from '@/components/Product/ProductStockBalances';
	import ProductStockBalancesNew from '@/components/Product/ProductStockBalancesNew';
	import {Products} from '@/api/products';
	import ProductOrders from '@/components/Product/ProductOrders';
	import ProductSales from '@/components/Product/ProductSales';
	import ProductReportSales from '@/components/Product/ProductReportSales';
	import KeywordTable from '@/components/Product/KeywordTable';
	import Competitors from '@/components/Product/Competitors';
	import ArtificialSales from '../components/Products/ArtificialSales';
	import ProductCardAnalyticsReports from '@/components/Product/ProductCardAnalyticsReports'
	import AdvertisementOnProductCardComponent from '@/components/Advertisement/AdvertisementOnProductCard/AdvertisementOnProductCardComponent'
	import PlansBlockComponent from '@/components/Product/PlansBlockComponent'
	import CatalogMpHistory from '@/components/Product/CatalogMpHistory.vue';
	export default {
		name: 'Product',
		components: {
			ArtificialSales,
			ProductStockBalances,
			ProductOrders,
			ProductSales,
			ProductReportSales,
			KeywordTable,
			Competitors,
			ProductStockBalancesNew,
			ProductCardAnalyticsReports,
			AdvertisementOnProductCardComponent,
			PlansBlockComponent,
			CatalogMpHistory
		},
		data() {
			return {
				loading_export: false,
				product_plans: {},
				flag_old_table: false,
				show: true,
				current_size: '',
				key_list_monitoring: null,
				nmid_list_competitors: null,
				copy_nmid: null,
				product: {},
				productBaseDataLoading: true,
				tab: null,
				loaded: [],
				headers_historical: [
					{ text: "Дата", value: "date_created", sortable: false, width: "1%" },
					{ text: "Пользователь", value: "user", sortable: false, width: "1%" },
					{ text: "Размер", value: "size", sortable: false, width: "1%" },
					{ text: "Старый / новый остаток", value: "stock", sortable: false, width: "1%" },
					{ text: "Старое / новое название", value: "name", sortable: false, width: "1%" },
					{ text: "Старая / новая скидка", value: "sales", sortable: false, width: "1%" },
					{ text: "Старая / новая цена", value: "price", sortable: false, width: "1%" },
				],
				historical_data: []
			};
		},
		computed: {
			product_id() {
				return this.$route.params.id;
			},
			urlMPStats() {
				return `https://mpstats.io/wb/keywords/${this.product.nmid}`;
			},
			feedbackStatsFirstNumber(){
				if(this.product.product_rating >= 4.5)
					return "Запас 5: "
				if (this.product.product_rating >= 2.5)
					return "До 5: "
				return ""
			},
			feedbackStatsSecondNumber(){
				if(this.product.product_rating >= 3.5)
					return "Запас 4: "
				if (this.product.product_rating >= 2.5)
					return "До 4: "
				return ""
			}
		},
		methods: {
			calculate_percent_per_month(new_value, old_value){
				if (!old_value || old_value == 0 || !new_value)
					return 0
				try {
					let result = new_value / old_value * 100
					return result.toFixed(2)
				} catch(e){
					return 0
				}
			},
			calculate_revenue_per_month(){
				if (this.$refs.product_stock_balances){
					let redemption_sum = this.$refs.product_stock_balances.findField('redemption_sum')
					let report_sale_sum = this.$refs.product_stock_balances.findField('report_sales_sum')
					let result = 0
					if (this.product_plans && this.product_plans.actual_date_list && this.product_plans.actual_date_list.length > 0){
						this.product_plans.actual_date_list.forEach(date => {
							let tmp_report_sale_sum = report_sale_sum[date] ? report_sale_sum[date] : 0
							let tmp_redemption_sum = redemption_sum[date] ? redemption_sum[date]: 0
							try{
								result += (tmp_report_sale_sum - tmp_redemption_sum)
							} catch (e){
								console.log(e)
							}					
						})
					}
					return result
				}
				return 0
			},
			calculate_vp_per_month(){
				if (this.$refs.product_stock_balances){
					let report_sale_sum = this.$refs.product_stock_balances.findField('report_sales_sum')
					let report_sale_count = this.$refs.product_stock_balances.findField('report_sales_count')
					let cost_price = this.$refs.product_stock_balances.findField('cost_price')
					let comission_sum = this.$refs.product_stock_balances.findField('comission_sum')
					let delivery_sum = this.$refs.product_stock_balances.findField('delivery_sum')
					let redemption_sum = this.$refs.product_stock_balances.findField('redemption_sum')
					let result = 0
					if (this.product_plans && this.product_plans.actual_date_list && this.product_plans.actual_date_list.length > 0){
						this.product_plans.actual_date_list.forEach(date => {
							let tmp_report_sale_sum = report_sale_sum[date] ? report_sale_sum[date] : 0
							let tmp_report_sale_count = report_sale_count[date] ? report_sale_count[date] : 0
							let tmp_cost_price = cost_price[date] ? cost_price[date] : 0
							let tmp_comission_sum = comission_sum[date] ? comission_sum[date] : 0
							let tmp_delivery_sum = delivery_sum[date] ? delivery_sum[date] : 0
							let tmp_redemption_sum = redemption_sum[date] ? redemption_sum[date]: 0
							try{
								result += (
									tmp_report_sale_sum - (tmp_cost_price * tmp_report_sale_count) - tmp_delivery_sum - 
									tmp_comission_sum - tmp_redemption_sum
								)
							} catch (e){
								console.log(e)
							}
							
						})
					}
					return result
				}
				return 0
			},
			roundFunction(x){
				return Math.round(x * 100) / 100
			},
			async changeIncreaseMode(id){
				try {
					await Products.ChangeIncreaseMode(id)
					this.$toasted.show("Успешно", {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				}
				catch (err){
					this.$toasted.show("Ошибка", {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
					console.log(err)
				}
			},
			async changeIsStoragePallet(id){
				try {
					await Products.changeIsStoragePallet(id)
					this.$toasted.show("Успешно", {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				}
				catch (err){
					this.$toasted.show("Ошибка", {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
					console.log(err)
				}
			},
			
			async changeIsFbsLogistic(id){
				try {
					await Products.changeIsFbsLogistic(id)
					this.$toasted.show("Успешно", {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				}
				catch (err){
					this.$toasted.show("Ошибка", {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
					console.log(err)
				}
			},
			async changeIsMixedLogistic(id){
				try {
					await Products.changeIsMixedLogistic(id)
					this.$toasted.show("Успешно", {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				}
				catch (err){
					this.$toasted.show("Ошибка", {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
					console.log(err)
				}
			},
			async changeQuantityOnPallet(id, quantity){
				try {
					await Products.changeQuantityOnPallet(id, quantity)
					this.$toasted.show("Успешно", {
						theme: "bubble",
						type: 'success',
						position: "top-center",
						duration: 3000
					})
				}
				catch (err){
					this.$toasted.show(err.response.data.error, {
						theme: "bubble",
						type: 'error',
						position: "top-center",
						duration: 3000
					})
					console.log(err)
				}
			},
			showDateWithTime(date) {
				return date ? this.$options.filters.formatDateToLocale(date) : "-";
			},
			feedbackStatsText(){
				if (this.product.product_rating >= 4.5){
					let one = `Запас 5: ${this.product.feedback_stats.first_count}\n`
					let two = `\nЗапас 4: ${this.product.feedback_stats.second_count}\n`
					let three = `Всего: ${this.product.feedback_stats.all_count}\n`
					return one + two + three
				}
				if (this.product.product_rating >= 3.5){
					let one = `До 5: ${this.product.feedback_stats.first_count}\n`
					let two = `Запас 4: ${this.product.feedback_stats.second_count}\n`
					let three = `Всего: ${this.product.feedback_stats.all_count}\n`
					return one + two + three
				}
				if (this.product.product_rating >= 2.5){
					let one = `До 5: ${this.product.feedback_stats.first_count}\n`
					let two = `До 4: ${this.product.feedback_stats.second_count}\n`
					let three = `Всего: ${this.product.feedback_stats.all_count}\n`
					return one + two + three
				}
			},
			showDate(date){
				return date  ? this.$options.filters.formatDateToLocaleNoTime(date ) : "-"
			},
			async addCompetitors() {
				try {
					const response = await Products.addCompetitors({
						'product_id': this.product.id, 'list_competitors': this.nmid_list_competitors,
					});
					if (response.status == 204) {
						this.$toasted.show(`Конкуренты для товара ${this.product.nmid} добавлены"`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
						this.nmid_list_competitors = null;
						this.$refs.competitors_block.getCompetitors();
					}
				} catch (err) {
					console.log(err);
				}
			},
			async copyKeyword() {
				try {
					const response = await Products.copyKeywordMonitoring({
						'from_product': this.copy_nmid, 'to_product': this.product.nmid,
					});
					if (response.status == 204) {
						this.$toasted.show(`Ключевые запросы скопированы`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
						this.copy_nmid = null;
						this.$refs.child_table.fetchData();
					}
				} catch (err) {
					console.log(err);
				}
			},
			async saveKeyword() {
				try {
					const response = await Products.setKeywordMonitoring({
						'product_id': this.product.id, 'text': this.key_list_monitoring,
					});
					if (response.status == 204) {
						this.$toasted.show(`Ключевые запросы для товара ${this.product.nmid} изменены"`, {
							theme: 'bubble',
							type: 'success',
							position: 'top-center',
							duration: 3000,
						});
						this.key_list_monitoring = null;
						this.$refs.child_table.fetchData();
						this.$refs.competitors_block.getCompetitors();
					}
				} catch (err) {
					console.log(err);
				}
			},
			calculateVolImage(t){
				t = Number(t)
				if (t >= 0 && t <= 143)
					return '01'
				if (t >= 144 && t <= 287)
					return '02'
				if (t >= 288 && t <= 431)
					return '03'
				if (t >= 432 && t <= 719)
					return '04'
				if (t >= 720 && t <= 1007)
					return '05'
				if (t >= 1008 && t <= 1061)
					return '06'
				if (t >= 1062 && t <= 1115)
					return '07'
				if (t >= 1116 && t <= 1169)
					return '08'
				if (t >= 1170 && t <= 1313)
					return '09'
				if (t >= 1314 && t <= 1601)
					return '10'
				if (t >= 1602 && t <= 1655)
					return '11'
				if (t >= 1656 && t <= 1919)
					return '12'
				if (t >= 1920 && t <= 2045)
					return '13'
				if (t >= 2046 && t <= 2189)
					return '14'
				if (t >= 2190 && t <= 2405) 
					return '15'
				return '16'
			},
			getImgPath (nmid) {
				let first_part = nmid.slice(0, -3)
				let second_part = nmid.slice(0, -5)
				let third_part = this.calculateVolImage(second_part)
				return `https://basket-${third_part}.wbbasket.ru/vol${second_part}/part${first_part}/${nmid}/images/c516x688/1.jpg`
			},
			async fetchProductData() {
				this.productBaseDataLoading = true;
				const response = await Products.fetchBaseProductData(this.product_id);
				this.product = response.data;
				this.productBaseDataLoading = false;
			},
			async fetchProductLogs() {
				this.loading_logs = true;
				const response = await Products.fetchProductLogs(this.product_id);
				this.historical_data = response.data;
				this.loading_logs = false;
			},
			async exportProductDetail(){
				this.loading_export = true
				try {
					const response = await Products.exportProductDetailData(this.$route.params.id, {
						'variations_id': this.current_size != 0 ? this.current_size : '',
					})
					const url = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = url
					link.setAttribute('download', `product_${this.$route.params.id}.xlsx`)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
				} catch (err) {
					console.log(err.data)
				}
				this.loading_export = false
			},
			daysLabel(number) {
				let titles = ['день', 'дня', 'дней']
				const cases = [2, 0, 1, 1, 1, 2];
				return `Новинка ${number} ${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
			}
		},
		async mounted() {
			document.title = `Товары WB | ${this.product_id} | mp.lomex-group.ru`;
			this.$store.commit('setPageTitle', `Товар ${this.product_id}`);
			this.fetchProductData();
			this.fetchProductLogs()
		},
	};
</script>

<style scoped lang="scss">
	.red_stats{
		color: red;
	}
	.wrap_subsection{
		max-width: 150px; 
		overflow: unset; 
		text-overflow: unset; 
		white-space: unset;
	}
    ::v-deep .small-font td {
        font-size: .7rem !important;
    }

    ::v-deep .vertical-border td {
        border-right: 1px solid #eaeaea;
    }

    ::v-deep tbody {
        tr:hover {
            background-color: #f5f5f5 !important;
        }
    }

    .different_color {
        margin-left: 25px;

        &__images {
            display: flex;

            &__item:not(:first-of-type) {
                margin-left: 10px;
            }

            &__item {
                max-width: 80px;

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }

    .different_variations {
        margin-left: 25px;
        margin-top: 50px;
        display: flex;
        align-items: center;

        p {
            margin-bottom: 0;
            padding-right: 0px;
        }
    }

    .tag_info {
        max-width: 200px;
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }
	.red_text{
		color: red !important;
	}
	.kit_text{
		color: rgba(0, 0, 0, 0.6);
	}
</style>
