<template>
    <v-sheet>
        <v-skeleton-loader v-if="loading" type="table-heading, table-row@5" class="pa-4"></v-skeleton-loader>
		<v-data-table
			v-else :headers="headers" :items="data" class="elevation-0 mt-0 vertical-border small-font"
		>
			<template v-slot:item.orders="{ item }">
				{{ item.orders ? item.orders : 0 }} / {{ item.part_orders ? (item.part_orders * 100).toFixed(2) : 0 }}%
			</template>
			<template v-slot:item.stocks="{ item }">
				{{ item.stocks ? item.stocks : 0 }} / {{ item.part_stocks ? (item.part_stocks * 100).toFixed(2) : 0 }}%
			</template>
			<template v-slot:item.base_tariff_logistic="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<a class="dashed" v-bind="attrs" v-on="on">
							{{ item.base_logistic ? item.base_logistic : 0 | toRUB }}
						</a>
					</template>
					<span style="font-weight: bold; white-space: pre;" v-text="item.help_text_base_logistic"></span>
				</v-tooltip>
			</template>
			<template v-slot:item.base_tariff_storage="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<a class="dashed" v-bind="attrs" v-on="on">
							{{ item.base_storage_cost ? item.base_storage_cost : 0 | toRUB }}
						</a>
					</template>
					<span style="font-weight: bold; white-space: pre;" v-text="item.help_text_base_storage_cost"></span>
				</v-tooltip>
			</template>
			
			<template v-slot:item.warehouse_tariffs="{ item }">
				{{ item.tariffs_logistic ? item.tariffs_logistic : 0 }}% / {{ item.tariffs_storage ? item.tariffs_storage : 0 }}%
			</template>
			<template v-slot:item.logistic_stocks="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<a class="dashed" v-bind="attrs" v-on="on">
							{{ item.logistic_stocks ? item.logistic_stocks : 0 | toRUB }}
						</a>
					</template>
					<span style="font-weight: bold; white-space: pre;" v-text="item.help_text_logistic_stocks"></span>
				</v-tooltip>
			</template>
			<template v-slot:item.logistic_orders="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<a class="dashed" v-bind="attrs" v-on="on">
							{{ item.logistic_orders ? item.logistic_orders : 0 | toRUB }}
						</a>
					</template>
					<span style="font-weight: bold; white-space: pre;" v-text="item.help_text_logistic_orders"></span>
				</v-tooltip>
			</template>
			<template v-slot:item.storage_cost="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<a class="dashed" v-bind="attrs" v-on="on">
							{{ item.storage_cost ? item.storage_cost : 0 | toRUB }}
						</a>
					</template>
					<span style="font-weight: bold; white-space: pre;" v-text="item.help_text_storage_cost"></span>
				</v-tooltip>
			</template>
			<template v-slot:body.append>
				<tr class="sticky-table-footer" style="font-weight: bold;">
					<td v-for="col, index in headers" :key="index">
						<div v-if="index == 0">Итог: </div>
						<div v-else-if="!col.summary"></div>
						<div v-else>{{ sum_values(col.value) }}</div>
					</td>
				</tr>
			</template>
		</v-data-table>
    </v-sheet>
</template>

<script>
import { Products } from '@/api/products'

export default {
	name: "LogisticDeliveryCost",
	props: ['product_id', 'date'],
	data () {
		return {
			data: null,
			loading: true,
			headers : [
				{ text: "Склад", value: "warehouse_name", sortable: false, width: "1%", summary: false },
				{ text: "Заказы / доля", value: "orders", sortable: false, width: "1%", summary: true },
				{ text: "Остатки / доля", value: "stocks", sortable: false, width: "1%", summary: true },
				{ text: "Базовый тариф(логистика)", value: "base_tariff_logistic", sortable: false, width: "1%", summary: false },
				{ text: "Базовый тариф(хранение)", value: "base_tariff_storage", sortable: false, width: "1%", summary: false },
				{ text: "Коэффициент складка(логистика / хранение)", value: "warehouse_tariffs", sortable: false, width: "1%", summary: false },
				{ text: "Итоговая логистика(заказы)", value: "logistic_orders", sortable: false, width: "1%", summary: true },
				{ text: "Итоговая логистика(остатки)", value: "logistic_stocks", sortable: false, width: "1%", summary: true },
				{ text: "Итоговое хранение", value: "storage_cost", sortable: false, width: "1%", summary: true },
			],
		}
	},
	methods: {
		async fetchData () {
			this.loading = true
			try {
				const res = await Products.logisticAndStorageCostByWh(this.product_id, this.date)
				this.data = res.data
			} catch (err) {
				console.log(err)
			}
			this.loading = false
		},
		sum_values(field){
			let result = 0
			this.data.forEach(item => {
				result += item[field]
			});
			return result
		}
	},
	async mounted () {
		await this.fetchData()
	}
}
</script>

<style scoped>

</style>
