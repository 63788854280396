<template>
    <div>
		<router-link class="ml-3" target="_blank" :to="{name: 'price_management_create_templates'}">
			<v-btn color="primary" dark class="mt-5 ml-5">Создать новый шаблон</v-btn>
		</router-link>	
        <v-data-table
			ref="table_templates"
			:headers="table.headers"
			:items="table.data"
			:loading="table.loading"
			class="elevation-0 mt-3 vertical-border"
			:pageCount="table.pagination.numberOfPages"
			:options.sync="table.options"
			:items-per-page="50"
			:server-items-length="table.pagination.totalItems"
			:footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
        >
			<template v-slot:item.name="{ item }">
				<router-link target="_blank" :to="{ name: 'price_management_detail_templates', params: { template_id: item.id }}">{{ item.name}}</router-link>
            </template>
			<template v-slot:item.date_created="{ item }">
				{{ item.date_created | formatDateToLocale }}
            </template>
			<template v-slot:item.url="{ item }">
				<a :href="item.url" target="_blank">{{ item.url }}</a>
            </template>
        </v-data-table>
    </div>
</template>

<script>
	import {PriceManagement} from '@/api/price_management';

	export default {
		name: 'PriceManagementTemplates',
		data() {
			return {
				table:{
					headers: [
						{ text: 'Дата создания', sortable: true, value: 'date_created', width: '1%' },	
						{ text: 'Название', sortable: true, value: 'name', width: '2%' },
						{ text: 'Ссылка', sortable: false, value: 'url', width: '2%' },
						{ text: 'Товары', sortable: false, value: 'products', width: '2%' },
						{ text: 'Аккаунты', sortable: false, value: 'accounts', width: '2%' },
					],
					data: [],
					loading: false,
					pagination: {
						next: null,
						totalItems: 0,
						numberOfPages: 0,
						currentPage: 0
					},
					options: {},
					first_init: false
				},
			};
		},
		watch: {
			'table.options': async function (newVal, oldVal) {
				if (oldVal != newVal && this.table.first_init) {
					await this.get_template_list()
				}
			},
		},
		methods: {
			async get_template_list(){
				this.table.loading = true
				const { page, itemsPerPage, sortBy, sortDesc } = this.table.options
				let pageNumber = page - 1
				try {
					const response = await PriceManagement.fetchTemplateList(
						{
							'offset': itemsPerPage*pageNumber,
							'limit': itemsPerPage,
						}
					)
					this.table.data = response.data.results
					this.table.pagination.totalItems = response.data.count
					this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
					this.$vuetify.goTo(this.$refs.table_templates, {duration: 300, offset: 150, easing: 'linear',})
				} catch (err) {
					console.log(err)
				}
				this.table.loading = false
			}
		},
		async mounted() {
			document.title = 'Управление ценой (список шаблонов)';
			this.$store.commit('setPageTitle', `Управление ценой (список шаблонов)`)
			await this.get_template_list()
			this.table.first_init = true
		},
	};
</script>

<style scoped lang="scss">
    
</style>
