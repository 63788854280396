<template>
	<v-container fluid grey lighten-4 class="pa-5">
		<v-card class="mx-auto" flat>
			<v-card-text class="pb-0">
				<div class="d-flex">
					<div class="title">
						{{ adver_data ? adver_data.campaign_name : "-" }}
					</div>
				</div>
			</v-card-text>
			<v-sheet class="d-flex">
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Аккаунт</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								adver_data && adver_data.account_name ? adver_data.account_name : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Вид рекламы</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								adver_data && adver_data.type_display ? adver_data.type_display : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">Статус</v-list-item-title>
							<v-list-item-subtitle style="max-width:100px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								adver_data && adver_data.status_display ? adver_data.status_display : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Создана</v-list-item-title>
							<v-list-item-subtitle>{{
								showDate(adver_data.date_created)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Завершена</v-list-item-title>
							<v-list-item-subtitle>{{
								showDate(adver_data.date_finish)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Бюджет</v-list-item-title>
							<v-list-item-subtitle>{{
								adver_data && adver_data.budget ? adver_data.budget : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-title>Дневной лимит</v-list-item-title>
							<v-list-item-subtitle>{{
								adver_data && adver_data.daily_budget ? adver_data.daily_budget : "-"
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
				<v-list two-line dense>
					<v-list-item two-line>
						<v-list-item-content  style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">
							<v-list-item-title style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">Последнее обновление</v-list-item-title>
							<v-list-item-subtitle style="max-width:150px; overflow: unset; text-overflow: unset; white-space: unset;">{{
								showDateWithTime(adver_data.date_our_update)
							}}</v-list-item-subtitle>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-sheet>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Товары из рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="product_table.headers"
					:items="product_table.data"
					:loading="product_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto"
					disable-pagination
				>
					<template v-slot:item.bid="{ item }">
						{{ convertKopecksToRub(item.bid) | toRUB }}
					</template>
					<template v-slot:item.sku="{ item }">
						<router-link 
							target="_blank" 
							class="link" 
							:to="{ name: 'ozon_product_card', params: { id: item.product }}"
						>{{ item.sku }}</router-link>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Статистика по рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="adver_stats_table.headers"
					:items="adver_stats_table.data"
					:loading="adver_stats_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%;  max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font overflow-y-auto"
					disable-pagination
				>
					<template v-slot:item.bid="{ item }">
						{{ convertKopecksToRub(item.bid) | toRUB }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-card class="mx-auto mt-5" flat>
			<v-card-title>Статистика по товарам рекламной кампании</v-card-title>
			<v-card-text class="pb-0">
				<v-data-table
					:headers="adver_product_stats_table.headers"
					:items="adver_product_stats_table.data"
					:loading="adver_product_stats_table.loading"
					hide-default-footer
					fixed-header
					style="width: 100%; max-height: 500px; overflow: auto;"
					class="elevation-1 vertical-border small-font"
					disable-pagination
				>
					<template v-slot:item.expenses="{ item }">
						{{ item.expenses ? item.expense : 0 | toRUB }}
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { OzonAdvertisement } from '@/api/ozon_advertisement'

export default {
	name: "OzonAdvertisementDetail",
	data() {
		return {
			adver_data: null,
			product_table: {
				loading: false,
				headers: [
					{ text: 'sku', value: 'sku', sortable: false, width: '1%' },
					{ text: 'Название', value: 'title', sortable: false, width: '1%' },
					{ text: 'Ставка', value: 'bid', sortable: false, width: '1%' },
				],
				data: []
			},
			adver_stats_table: {
				loading: false,
				headers: [
					{ text: 'id', value: 'id', sortable: false, width: '1%' },
					{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
					{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'CTR(%)', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expense', sortable: false, width: '1%' },
					{ text: 'Средняя ставка', value: 'avg_bid', sortable: false, width: '1%' },
					{ text: 'Заказы шт', value: 'orders_count', sortable: false, width: '1%' },
					{ text: 'Заказы руб', value: 'orders_sum', sortable: false, width: '1%' },
					{ text: 'ДРР', value: 'drr', sortable: false, width: '1%' },
				],
				data: []
			},
			adver_product_stats_table: {
				loading: false,
				headers: [
					{ text: 'sku', value: 'sku', sortable: false, width: '1%' },
					{ text: 'Дата', value: 'date', sortable: false, width: '1%' },
					{ text: 'Просмотры', value: 'views', sortable: false, width: '1%' },
					{ text: 'Клики', value: 'clicks', sortable: false, width: '1%' },
					{ text: 'CTR(%)', value: 'ctr', sortable: false, width: '1%' },
					{ text: 'Расход', value: 'expenses', sortable: false, width: '1%' },
					{ text: 'Средняя ставка', value: 'avg_bid', sortable: false, width: '1%' },
					{ text: 'Заказы шт', value: 'orders_count', sortable: false, width: '1%' },
					{ text: 'Кол-во', value: 'quantity', sortable: false, width: '1%' },
					{ text: 'Цена продажи', value: 'sale_price', sortable: false, width: '1%' },
					{ text: 'Ставка %', value: 'bid_percent', sortable: false, width: '1%' },
					{ text: 'Ставка руб', value: 'bid', sortable: false, width: '1%' },
				],
				data: []
			}
			
		}
	},
	computed: {
		advertisement_id() {
			return this.$route.params.campaign_id;
		},
	},
	methods: {
		async getAdvertisementDetail() {
			try {
				let response = await OzonAdvertisement.getDetailAdvertisements(
					this.advertisement_id
				);
				this.adver_data = response.data;
			} catch (err) {
				console.error(err.response);
			}
		},
		async getAdverProducts() {
			this.product_table.loading = true
			try {
				let response = await OzonAdvertisement.getDetailOzonCampaign(
					this.advertisement_id
				);
				this.product_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.product_table.loading = false
		},
		async getAdverStats() {
			this.adver_stats_table.loading = true
			try {
				let response = await OzonAdvertisement.getOzonAdvertisementStats(
					this.advertisement_id
				);
				this.adver_stats_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.adver_stats_table.loading = false
		},
		async getAdverStatsByProduct() {
			this.adver_product_stats_table.loading = true
			try {
				let response = await OzonAdvertisement.getOzonAdvertisementProductStats(
					{ "id": this.advertisement_id }
				);
				this.adver_product_stats_table.data = response.data;
			} catch (err) {
				console.error(err.response);
			}
			this.adver_product_stats_table.loading = false
		},
		convertKopecksToRub(money){
			return money ? money / 1000000: 0
		},
		showDate(date){
            return date  ? this.$options.filters.formatDateToLocale(date ) : "-"
        },
		showDateWithTime(date) {
			return date ? this.$options.filters.formatDateToLocale(date) : "-";
		},
	},
	async mounted() {
		document.title = `Реклама Ozon | ${this.advertisement_id} | mp.lomex-group.ru`;
		this.$store.commit("setPageTitle", `Ozon Рекламная кампания ${this.advertisement_id}`);
		await this.getAdvertisementDetail()
		await this.getAdverProducts()
		await this.getAdverStats()
		await this.getAdverStatsByProduct()

	}
}
</script>

<style lang="scss" scoped>

</style>