<template>
  <div>
    <v-skeleton-loader
        v-if="reportDetailFetchLoading"
        class="mx-auto"
        type="table"
    ></v-skeleton-loader>
    <v-data-table
        v-else-if="items && items.length > 0"
        :headers="reportDetailTable.headers"
        :items="items"
        class="elevation-1 small-font"
    >
      <template v-slot:item.date="{ item }">
        {{item.date | formatDateToLocaleNoTime}}
      </template>
      <template v-slot:item.accruals_for_sale="{ item }">
        {{item.accruals_for_sale | toRUB}}
      </template>
      <template v-slot:item.sale_commission="{ item }">
        {{item.sale_commission | toRUB}}
      </template>
      <template v-slot:item.amount="{ item }">
        {{item.amount | toRUB}}
      </template>
      <template v-slot:item.expenses="{ item }">
        {{item.expenses | toRUB}}
      </template>
      <template v-slot:item.operation_type_name="{ item }">
        <strong>{{item.operation_type_name}}</strong>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {General} from '@/api/general';
import {Stats} from '@/api/stats';
import async from 'async';

export default {
  name: 'OzonTransactionsReportsDetail',
  props: {
    dateFrom: {
      type: String,
      require: true,
    },
    dateTo: {
      type: String,
      require: true,
    },
    accountId: {
      type: Number,
      require: true,
    },
  },
  data () {
    return {
      reportDetailFetchLoading: true,
      items: null,
      reportDetailTable: {
        headers: [
          { text: 'Операция', align: 'start', sortable: false, value: 'operation_type_name', width: '1%'},
          { text: 'Тип', align: 'start', sortable: false, value: 'type', width: '1%'},
          { text: 'Стоимость товаров с учётом скидок продавца', align: 'start', sortable: false, value: 'accruals_for_sale', width: '1%'},
          { text: 'Комиссия', align: 'start', sortable: false, value: 'sale_commission', width: '1%'},
          { text: 'Итоговая сумма', align: 'start', sortable: false, value: 'amount', width: '1%'},
          { text: 'Расходы (доставка)', align: 'start', sortable: false, value: 'expenses', width: '1%'},
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    async getReportDetail() {
      try {
        this.reportDetailFetchLoading = true
        const response = await Stats.getOzonTransactionsTotalReportDetail({
          date_from: this.dateFrom, date_to: this.dateTo, account_id: this.accountId
        })
        console.log(response.data)
        return response.data
      } catch (e) {
        console.error(e)
      } finally {
        this.reportDetailFetchLoading = false
      }
    },
    async getTransactionsTotalsReport() {
      const response = await Stats.getOzonTransactionsTotalReport(this.filters)
      this.reportsData = response.data
    }
  },
  async mounted(){
    this.items = await this.getReportDetail()
  }
}
</script>

<style scoped lang="scss">

</style>